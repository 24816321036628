import { useState } from 'react';
import { Backdrop, Box, Divider } from '@material-ui/core';
import { useSelectedResourcePreviewStyles } from './style';
import { Resource } from '../../../../interfaces';
import { SelectedResorucePreviewHeader } from './header';
import { SelectedResourcesPreviewList } from './list';

interface SelectedResourcesPreviewProps {
  resources: Resource[];
  defaultExpanded?: boolean;
  customAction?: React.ReactNode;
  onRemoveResource: (resource: Resource) => void;
}

export const SelectedResourcesPreview = ({
  resources,
  defaultExpanded = false,
  customAction,
  onRemoveResource
}: SelectedResourcesPreviewProps) => {
  const styles = useSelectedResourcePreviewStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);

  return (
    <>
      <Backdrop open={isExpanded} className={styles.backdrop} />
      <Box className={styles.container}>
        {isExpanded && (
          <Box className={styles.listContainer}>
            <SelectedResourcesPreviewList
              resources={resources}
              onRemoveItem={onRemoveResource}
            />
          </Box>
        )}
        <Box>
          <Divider className={styles.primaryDivider} />
          <SelectedResorucePreviewHeader
            numberOfSelectedResources={resources.length}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
          <Divider />
          <Box className={styles.actionContainer}>{customAction}</Box>
        </Box>
      </Box>
    </>
  );
};
