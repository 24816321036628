import { Box, Theme, makeStyles } from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import { getInitials } from '@setvi/shared/utils';
import { SAvatar, SText } from '@setvi/shared/components';

interface ContactProps {
  selected?: boolean;
  firstName: string;
  lastName: string;
  email: string;
}

interface StyleProps {
  selected: boolean;
}

const useStyle = makeStyles<Theme, StyleProps>(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridGap: spacing(3),
    padding: spacing(2, 3),
    cursor: ({ selected }) => (selected ? 'default' : 'pointer'),
    '&:hover': {
      backgroundColor: palette.grey[200]
    }
  }
}));

export const Contact = ({
  selected,
  firstName,
  lastName,
  email
}: ContactProps) => {
  const classes = useStyle({ selected });
  const fullName = `${firstName} ${lastName}`;

  return (
    <Box className={classes.root}>
      <Box flex={1} display="flex" alignItems="center">
        <Box mr={2}>
          <SAvatar value={getInitials({ firstName, lastName, email })} />
        </Box>
        <Box display="flex" flexDirection="column">
          <SText title={fullName.trim()} />
          <SText size={fullName.trim() ? 'xs' : 'base'} title={email} />
        </Box>
      </Box>
      {selected && <CheckRoundedIcon htmlColor="#2196F3" />}
    </Box>
  );
};
