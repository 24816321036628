import { Box } from '@material-ui/core';
import styled from 'styled-components';

import { SContentLayoutProps } from '../../../interfaces';

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  max-width: 100vw;
  width: 100%;
  height: 100%;
  background: #fff;
`;

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
`;

const ContentContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
`;

const SContentLayout = ({
  header,
  footer,
  sidebar,
  children,
  contentStyle
}: SContentLayoutProps) => (
  <Container>
    <Box>{header}</Box>
    <MainContainer>
      {sidebar}
      <ContentContainer style={contentStyle}>{children}</ContentContainer>
    </MainContainer>
    {footer}
  </Container>
);

export default SContentLayout;
