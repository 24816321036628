import { useState, useEffect } from 'react';

export enum BROWSERS {
  chrome = 'Chrome',
  firefox = 'Mozilla Firefox',
  ie = 'Internet Explorer',
  edge = 'Edge',
  safari = 'Safari',
  opera = 'Opera',
  yandex = 'Opera'
}

export const useBrowser = () => {
  const [browser, setBrowser] = useState<string>('unknown');

  function checkBrowser() {
    if (navigator.userAgent.indexOf('Chrome') !== -1) {
      setBrowser(BROWSERS.chrome);
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      setBrowser(BROWSERS.firefox);
    } else if (navigator.userAgent.indexOf('MSIE') !== -1) {
      setBrowser(BROWSERS.ie);
    } else if (navigator.userAgent.indexOf('Edge') !== -1) {
      setBrowser(BROWSERS.edge);
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      setBrowser(BROWSERS.safari);
    } else if (navigator.userAgent.indexOf('Opera') !== -1) {
      setBrowser(BROWSERS.opera);
    } else if (navigator.userAgent.indexOf('Opera') !== -1) {
      setBrowser(BROWSERS.yandex);
    }
  }

  useEffect(() => {
    checkBrowser();
  }, []);

  return {
    browser
  };
};
