import React, { FC } from 'react';
import { Box, Divider, makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    margin: theme.spacing(1, 0, 0, 0),
    padding: theme.spacing(0, 2)
  },
  leftSide: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 'auto'
    }
  },
  rightSide: {
    height: '100%',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    gridGap: '20px',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'auto',
      margin: 'auto'
    }
  }
}));

const LeftSide = styled.div`
  flex: 1;
  width: auto;
  display: flex;
  min-height: 1px;
  align-items: space-between;
`;

interface HeaderProps {
  leftSide?: React.ReactNode;
  rightSide?: React.ReactNode;
  underneath?: React.ReactNode;
  searchComponent: React.ReactNode;
}

const SHeaderContainer: FC<HeaderProps> = ({
  leftSide,
  rightSide,
  underneath,
  searchComponent
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.container}>
        <LeftSide className={classes.leftSide}>{leftSide}</LeftSide>
        <Box className={classes.rightSide}>
          <Box>{searchComponent}</Box>
          <Box>{rightSide}</Box>
        </Box>
      </Box>

      {underneath && <Box>{underneath}</Box>}

      <Divider />
    </>
  );
};

export default SHeaderContainer;
