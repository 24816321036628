import { useEffect, useState } from 'react';
import { Document as PDFDocument, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Loader } from '../loader';
import { Resource } from '../../../../../interfaces';
import { LoadingPDFPage } from './loading-page';

interface DocumentProps {
  document: Resource;
  loading: boolean;
  activePage: number;
}

export const Document = ({
  document,
  loading,
  activePage = 1
}: DocumentProps) => {
  const [page, setPage] = useState(1);
  const [loadingInfo, setLoadingInfo] = useState({
    loaded: 0,
    total: 0
  });

  useEffect(() => {
    setPage(activePage);
  }, [activePage]);

  if (loading) return <Loader />;

  return (
    <PDFDocument
      file={document.DownloadURLWithSas}
      loading={
        <LoadingPDFPage loaded={loadingInfo.loaded} total={loadingInfo.total} />
      }
      onLoadProgress={info =>
        setLoadingInfo({ loaded: info.loaded, total: info.total })
      }
      onLoadSuccess={() => setPage(1)}>
      <Page pageNumber={page} />
    </PDFDocument>
  );
};
