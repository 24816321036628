import { ReactNode, useEffect, useRef } from 'react';

interface WrapperProps {
  classes: string;
  children: ReactNode;
  enableContentHeight: boolean;
  handleSetIsExpand?: (initExpand: boolean) => void;
  showAll: boolean;
}

const BreadcrumbsList = ({
  children,
  showAll,
  classes,
  enableContentHeight,
  handleSetIsExpand
}: WrapperProps) => {
  const ref = useRef<HTMLOListElement>();

  useEffect(() => {
    if (!showAll) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const element = ref.current!;
      const isOverflow = element.scrollHeight > element.clientHeight;
      handleSetIsExpand(!isOverflow);
    }
  }, [handleSetIsExpand, showAll]);

  return (
    <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
      <ol
        className={classes}
        ref={ref}
        style={{ height: enableContentHeight ? 'auto' : 20 }}>
        {children}
      </ol>
    </div>
  );
};

export default BreadcrumbsList;
