import { ResourceBookmark } from '@setvi/shared/interfaces';
import { SConfirmDialog } from '@setvi/shared/components/sdialogs';
import { Types } from '@setvi/shared/components/sdialogs/sconfirm/helper';
import { useDialog } from '@setvi/shared/providers';
import { useSubscribedMutation } from '@setvi/shared/hooks';
import { useSnackbar } from 'notistack';
import {
  RESOURCE_BOOKMARKS,
  saveResourceBookmarks
} from '@setvi/shared/services';
import { DIALOG } from '../useDropdown';

interface DeleteProps {
  bookmark: ResourceBookmark;
  filteredBookmarks: ResourceBookmark[];
}
export const DeleteBookmark = ({
  bookmark,
  filteredBookmarks
}: DeleteProps) => {
  const { mutateAsync: deleteBookmark, isLoading } = useSubscribedMutation(
    saveResourceBookmarks(),
    [RESOURCE_BOOKMARKS.GET_ALL]
  );
  const { enqueueSnackbar } = useSnackbar();
  const { closeDialog } = useDialog();

  const handleDelete = async () => {
    const response = await deleteBookmark({
      body: {
        resourceID: bookmark.ResourceID,
        bookmarks: filteredBookmarks
      }
    });

    if (!response.Errors) {
      closeDialog(DIALOG.DELETE_BOOKMARK);
      enqueueSnackbar(`Bookmark successfully deleted`, {
        variant: 'success'
      });
    }
  };

  return (
    <SConfirmDialog
      type={Types.delete}
      loading={isLoading}
      title="Delete bookmark"
      description="Are you sure you want to delete this bookmark?"
      customAdditionalMessage={
        <span>
          The action <strong>CANNOT</strong> be reverted back
        </span>
      }
      confirmText="Yes, Delete"
      closeText="No, Keep It"
      onClose={() => closeDialog(DIALOG.DELETE_BOOKMARK)}
      onConfirm={handleDelete}
    />
  );
};
