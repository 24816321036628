export const style = `
 @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
 
body { 
    font-family: 'Roboto', sans-serif !important; 
    font-size: 14px;
    padding: 10px;
    margin: 0;
 }

p  {
    padding: 0;
    margin: 0;
}

ul, ol {
    margin: 0;
}

.mce-mergetag {
    color: #ffffff !important;
    white-space: nowrap !important;
    border-radius: 25px !important;
    background: #1E96FC !important;
    padding: 3px 8px !important;
    font-weight: 500 !important;
    font-family: 'Roboto', sans-serif !important;
    text-decoration: none !important;
    font-style: normal !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    -o-user-select: none !important;
    user-select: none !important;
}

.mce-mergetag-affix {
    color: #fff !important;
}

.mce-mergetag:hover {
    background: #0671cc !important;
}

.image-placeholder {
    height: 72px;
    width: 260px;
    display: flex;
    color: #1E96FC;
    font-weight: 600;
    border-radius: 4px;
    align-items: center;
    background: #E9F4FE;
    justify-content: center;
    border: 2px dashed #50B5FF;
}
`;
