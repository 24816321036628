import { Box, ClickAwayListener, PopperProps, Popper } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './style';

interface SSelectDropdownProps
  extends Omit<PopperProps, 'children' | 'open' | 'content'> {
  width?: number;
  anchor: JSX.Element;
  anchorElement: HTMLElement | null;
  content: JSX.Element;
  placement?: PopperProps['placement'];
  onClose: () => void;
  marginTop?: number;
  className?: string;
}

const SSelectDropdown = ({
  width,
  anchor,
  anchorElement,
  content,
  placement = 'bottom-end',
  onClose,
  className = '',
  marginTop = 4,
  ...rest
}: SSelectDropdownProps) => {
  const classes = useStyles({ width });

  return (
    <>
      {anchor}
      <Popper
        style={{ marginTop }}
        className={classes.popper}
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        placement={placement}
        {...rest}>
        <ClickAwayListener onClickAway={onClose}>
          <Box className={clsx(classes.content, className)}>{content}</Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default SSelectDropdown;
