import { VARIABLE_TYPE } from '../../enums';
import { VARIABLE_LIST } from './getBodyVariables';

export const getSubjectVariables = (
  Subject: string,
  variableSelector = '.mce-mergetag'
) => {
  const SubjectVariables: VARIABLE_TYPE[] = [];
  const parser = new DOMParser();

  const htmlSubject = parser.parseFromString(Subject, 'text/html');
  htmlSubject
    .querySelectorAll(variableSelector)
    .forEach(e =>
      VARIABLE_LIST.some(
        v => e.textContent.includes(v) && SubjectVariables.push(v)
      )
    );

  return SubjectVariables;
};
