import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings } from '@material-ui/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntercom } from 'react-use-intercom';
import { Exit, MessageDetails } from 'Components/Shared/Icons';
import { ROUTES } from 'enumsV2';
import { useAppContext } from 'Providers/AppProvider/AppContext';

const useDropdownDetails = () => {
  const { logout } = useAuth0();
  const { user } = useAppContext();
  const { show, boot } = useIntercom();
  const navigate = useNavigate();

  const goToSettings = useCallback(
    () => navigate(ROUTES.SETTINGS_GENERAL),
    [navigate]
  );
  const userLogout = useCallback(() => {
    logout({
      logoutParams: { returnTo: window.location.origin + ROUTES.LOGIN }
    });
    localStorage.clear();
  }, [logout]);

  const menuItems = [
    {
      text: 'Settings',
      subText: 'Manage your account',
      icon: <Settings />,
      onClick: goToSettings
    },
    {
      text: 'Live Help',
      subText: 'Get in touch for assistance',
      icon: <MessageDetails viewBox="0 0 25 25" />,
      onClick: () => {
        boot({
          alignment: 'left',
          userId: user.ID.toString(),
          email: user.Email,
          name: user.FullName
        });
        show();
      }
    },

    {
      text: 'Logout',
      subText: 'Exit from signed in account',
      color: 'red',
      icon: <Exit />,
      onClick: userLogout
    }
  ];
  return { menuItems };
};

export default useDropdownDetails;
