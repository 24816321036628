import {
  Box,
  Button,
  Typography,
  Breadcrumbs as MuiBreadcrumbs
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const BreadcrumbWrapper = withStyles(({ spacing }) => ({
  root: {
    background: 'rgba(234, 238, 249, 0.49)',
    boxShadow:
      'inset 0px 0.5px 0px rgba(0, 0, 0, 0.05), inset 0px -0.5px 0px rgba(0, 0, 0, 0.05)',
    padding: `${spacing(2)}px ${spacing(1)}px`,
    '& .MuiSvgIcon-root': {
      fontSize: '0.8rem'
    },
    minHeight: 50,
    display: 'flex',
    alignItems: 'center'
  }
}))(Box);

export const BreadcrumbButton = withStyles({
  root: {
    background: 'transparent'
  }
})(Button);

export const BreadcrumbActiveButton = withStyles({
  root: {
    background: 'rgba(33, 150, 243, 0.1)'
  }
})(Button);

export const BackButtonWrapper = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(1),
    '& .MuiIconButton-root': {
      marginLeft: spacing(2)
    }
  }
}))(Box);

export const BreadcrumbList = withStyles({
  li: {
    maxWidth: 180
  }
})(MuiBreadcrumbs);

export const BlankCategoryName = withStyles({
  root: {
    height: '1.3em'
  }
})(Typography);
