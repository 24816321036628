import { Box, Divider } from '@material-ui/core';
import SButton from '../../../sbutton';

interface ConfirmSelectionProps {
  confirmLabel?: string;
  cancelLabel?: string;
  confirmDisabled?: boolean;
  cancelDisabled?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
}

export const ConfirmSelection = ({
  confirmLabel = 'Add',
  cancelLabel = 'Cancel',
  confirmDisabled = false,
  cancelDisabled = false,
  onConfirm,
  onCancel
}: ConfirmSelectionProps) => (
  <>
    <Divider />
    <Box p={2}>
      <SButton fullWidth disabled={confirmDisabled} onClick={onConfirm}>
        {confirmLabel}
      </SButton>
      {onCancel && (
        <Box mt={2}>
          <SButton
            fullWidth
            color="default"
            disabled={cancelDisabled}
            onClick={onCancel}>
            {cancelLabel}
          </SButton>
        </Box>
      )}
    </Box>
  </>
);
