import { useCallback, useEffect } from 'react';
import { TableContentProps } from '.';
import { useSelected } from '../../../../hooks';

type UseTableProps = Pick<
  TableContentProps,
  | 'columns'
  | 'onSelect'
  | 'rowKeyGetter'
  | 'selected'
  | 'selectDisabled'
  | 'removeItems'
>;

export const useTable = ({
  columns,
  onSelect,
  rowKeyGetter,
  selected: preselected,
  selectDisabled,
  removeItems = []
}: UseTableProps) => {
  const { selected: selectedItems, handleSelect } = useSelected<any>({
    preselected,
    dataKeyGetter: rowKeyGetter
  });

  useEffect(() => {
    const isEmpty = preselected?.length === 0 && selectedItems?.length === 0;

    if ((Boolean(preselected) && isEmpty) || !onSelect) return;

    if (!preselected && onSelect) return onSelect(selectedItems);

    const filteredPreselected = preselected?.filter(i =>
      selectedItems.some(j => rowKeyGetter(i) === rowKeyGetter(j))
    );

    const a =
      selectedItems.length > 0 &&
      preselected?.length !== filteredPreselected?.length;
    const b = selectedItems?.length !== preselected?.length;

    if (a || b) {
      onSelect(selectedItems);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const removeDisabledItems = useCallback(
    (items: any[]) => {
      if (selectDisabled) {
        return items?.filter(item => !selectDisabled(item));
      }
      return items;
    },
    [selectDisabled]
  );

  const isAllSelected = (allItems: any[]) => {
    const selectableItems = removeDisabledItems(allItems);
    if (selectableItems?.length === 0) return false;

    const newArray = selectableItems?.filter(i =>
      selectedItems?.some((j: any) => rowKeyGetter(j) === rowKeyGetter(i))
    );

    return newArray?.length === selectableItems?.length;
  };

  let tableWidth = null;

  if (!columns.some(column => typeof column.width === 'string')) {
    // @ts-ignore
    tableWidth = columns.reduce((a, b) => a + b.width, 0);
  }

  const removeSelected = (items: any) => {
    const newItems = selectedItems?.filter(
      selItems =>
        !items.find(
          (dataItem: any) => rowKeyGetter(dataItem) === rowKeyGetter(selItems)
        )
    );

    handleSelect(false, newItems);
    onSelect(newItems);
  };

  useEffect(() => {
    if (removeItems?.length) removeSelected(removeItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeItems]);

  return {
    handleSelect,
    selectedItems,
    isAllSelected,
    tableWidth
  };
};
