export enum DrawerContent {
  webLink = 'webLink',
  snippet = 'snippet',
  favorites = 'favorites',
  resources = 'resources',
  uploadFile = 'uploadFile',
  myResources = 'myResources',
  reviewLinks = 'reviewLinks',
  presentations = 'presentations',
  templates = 'templates',
  resourcesNavigation = 'resourcesNavigation',
  contactsNavigation = 'contactsNavigation',
  groups = 'groups',
  crmLeads = 'crmLeads',
  contacts = 'contacts',
  recentContacts = 'recentContacts',
  crmAccounts = 'crmAccounts',
  requiredReading = 'static'
}

export enum SortType {
  ASC = 'asc',
  DESC = 'desc'
}

export enum RolesEnum {
  ADMIN = 'Admin',
  STANDARD_USER = 'Standard user',
  SUB_ADMIN = 'Sub Admin',
  STANDARD_ADMIN = 'StandardAdmin'
}

export enum RolesNames {
  Admin = 'Admin',
  User = 'User',
  StandardAdmin = 'Standard Admin'
}

export enum RolesEnumId {
  ADMIN = 1,
  STANDARD_USER = 2,
  STANDARD_ADMIN = 3
}

export enum PresentationViewDevice {
  Unknown = 0,
  Desktop = 1,
  Tablet = 2,
  Mobile = 3
}

export enum DateRange {
  MIN = '0001/01/01',
  MAX = '9999/12/31'
}

export enum DefaultImages {
  CATEGORY = '/images/default-category.png',
  RESOURCE = '/images/default-resource.png'
}

export enum Browsers {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Opera = 'Opera',
  IE = 'IE',
  Edge = 'Edge',
  Unknown = 'Unknown'
}

export enum StandardAdminRights {
  AccessPresentationTemplates = 1,
  AccessAnalytics = 2,
  AccessSendPushNotification = 3,
  AccessUsers = 4,
  AccessSettings = 5,
  AccessActivityLogs = 6,
  AccessHome = 7,
  AccessResources = 8,
  AddEditDeleteCategory = 9,
  RearrangeCategories = 10,
  ManageCategoryPermissions = 11,
  ManagePublicResources = 12,
  Reporting = 13
}
