export enum OutboxApi {
  OutboxEmails = '/api/v1.0/emails/outbox',
  OutboxEmailDetails = '/api/v1.0/emails/outbox/:Id/details/:recipientId',
  OutboxShare = '/api/v1.0/emails/outbox/:emailId',
  EmailRecipients = '/api/v1.0/emails/recipients',
  GetLinks = '/api/v1.0/emails/link',
  GetLinksDetails = '/api/v1.0/emails/link/:Id',
  GetLinksViews = '/api/v1.0/emails/link/:Id/views',
  GetLinksViewsDetails = '/api/v1.0/emails/link/:Id/viewdetails?sessionId=:sessionId',
  GetLinksContent = '/api/v1.0/emails/link/:Id/content',
  DeteleGetLink = '/api/v1.0/emails/link',
  OutboxOutlook = '/api/v1.0/plainemails',
  Outlook = '/api/v1.0/plainemails',
  OutlookDetails = '/api/v1.0/plainemails/:id',
  OutlookDisableLinks = '/api/v1.0/plainemails/:id/links/disable',
  OutlookViews = '/api/v1.0/plainemails/:id/views',
  OutlookRecipients = '/api/v1.0/plainemails/:id/recipients',
  OutlookViewDetails = '/api/v1.0/plainemails/:id/viewdetails',
  EngageEmailTimeline = '/api/v1.0/emails/outbox/:Id/timeline/:recipientId',
  EmailEngageViews = '/api/v1.0/emails/outbox/:Id/views/:recipientId',
  EmailEngageViewDetails = '/api/v1.0/emails/outbox/:Id/viewdetails/:recipientId?sessionId=:sessionId',
  EmailOldShareViews = '/api/v1.0/emails/outbox/:Id/views/',
  EmailOldShareViewDetails = '/api/v1.0/emails/outbox/:recipientId/viewdetails?sessionId=:sessionId',
  MultipleEmailDetails = '/api/v1.0/emails/outbox/:Id/details',
  MultipleEmailAnalytics = '/api/v1.0/emails/outbox/summary/:Id',
  MultipleRecipientsList = '/api/v1.0/emails/outbox/:Id/recipients',
  OutboxDisableLinksEngage = '/api/v1.0/emails/links/disable',
  OutboxDisableLinksOldShare = '/api/v1.0/emails/outbox/disable'
}
