import { useCallback, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getDatabase,
  ref,
  onChildAdded,
  query,
  orderByChild,
  startAt
} from 'firebase/database';
import moment from 'moment';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { NotificationsQuery_notifications_Result } from 'Types/api/NotificationsQuery';

export const useFirebaseNotifications = () => {
  const { user } = useAppContext();
  const [firebaseNotification, setFirebaseNotification] =
    useState<NotificationsQuery_notifications_Result>();

  initializeApp({
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIRBEASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID
  });

  const getNotifications = useCallback(() => {
    if (!user) return;
    const db = getDatabase();
    const notificationRef = query(
      ref(db, `PushNotifications/${user.ID}`),
      orderByChild('DateTimeStamp'),
      startAt(moment().unix())
    );
    onChildAdded(notificationRef, snapshot => {
      const n = snapshot.val();
      const formattedNotification: NotificationsQuery_notifications_Result = {
        __typename: 'Notification',
        ID: n.ID.toString(),
        Text: n.Text,
        UserID: n.UserID.toString(),
        DeviceID: n.DeviceID,
        DateTimeSent: n.DateTimeSent,
        NotificationRead: n.NotificationRead,
        Type: n.Type,
        ParentObjectID: n.ParentObjectID,
        ParentObjectActivityDate: n.ParentObjectActivityDate,
        ItemID: n.ItemID,
        ItemTypeID: '',
        Material: n.Material
      };
      setFirebaseNotification(formattedNotification);
    });
  }, [user]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return {
    firebaseNotification
  };
};
