import { Link } from '@setvi/shared/interfaces';
import { useCallback } from 'react';
import { List } from './list';
import { EditLinks } from './edit-links';

interface ReviewLinksProps {
  link: Link;
  links: Link[];
  handleEdit: (link: Link) => void;
  handleBack?: () => void;
  handleOpen?: () => void;
  handleClose: () => void;
  handleInsert(link: Link, currentLink?: Link): void;
  handleRemoveLink(link?: Link): void;
}

export const ReviewLinks = ({
  link,
  links,
  handleBack,
  handleEdit,
  handleOpen,
  handleClose,
  handleInsert,
  handleRemoveLink
}: ReviewLinksProps) => {
  const handleRemove = useCallback(
    (removeLink?: Link) => {
      // If this is the last link
      if (links.length < 2) {
        handleRemoveLink(removeLink);
        handleClose();
      }
      handleRemoveLink(removeLink);
    },
    [handleRemoveLink, handleClose, links]
  );

  if (link)
    return (
      <EditLinks
        link={link}
        handleBack={handleBack}
        handleInsert={handleInsert}
        handleRemoveLink={handleRemove}
        handleClose={handleClose}
        handleOpen={handleOpen}
      />
    );

  return (
    <List
      links={links}
      handleEdit={handleEdit}
      handleRemoveLink={handleRemove}
      handleClose={handleClose}
    />
  );
};
