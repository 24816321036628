export const plugins = [
  'autolink',
  'lists',
  'advlist',
  'image',
  'charmap',
  'preview',
  'anchor',
  'searchreplace',
  'visualblocks',
  'code',
  'fullscreen',
  'insertdatetime',
  'media',
  'table',
  'help',
  'wordcount'
];

export const toolbar = `formatselect |
bold italic underline strikethrough | fontfamily fontsize forecolor |
bullist numlist | alignleft aligncenter alignright alignjustify | 
outdent indent |  localimage removeformat`;

export const fontSizes = '8px 10px 12px 14px 16px 18px 24px 36px 48px';
export const fontFamily =
  "Arial=arial, helvetica, sans-serif; Roboto='Roboto', sans-serif; Times=times new roman, times serif; Monospace=monaco, courier new, monospace; Verdana=verdana; Trebuchet=trebuchet ms; Georgia=georgia, times new roman, serif";

export const supportedImageTypes = [
  'image/apng',
  'image/avif',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/webp'
];
