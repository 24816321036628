export enum Analytics {
  IdleToActive = 1,
  ActiveToIdle = 2,
  ResourceViewed = 3,
  ResourceFavorited = 4,
  ResourceUnfavorited = 5,
  PresentationCreated = 6,
  PresentationUpdated = 7,
  PresentationDeleted = 8,
  PresentationViewed = 9,
  PresentationResourceAdded = 10,
  PresentationWebsiteAdded = 11,
  PresentationYoutubeAdded = 12,
  PresentationVimeoAdded = 13,
  PresentationTemplateAdded = 14,
  PresentationResourceDeleted = 15,
  PresentationResourceReordered = 16,
  PresentationPlayed = 17,
  PresentationWebResourceViewed = 18,
  PresentationSlideViewed = 19,
  PresentationSlideAdded = 20,
  PresentationSlideDeleted = 21,
  CustomModuleViewed = 22,
  HomeBannerViewed = 23,
  HomeBannerClicked = 24,
  CategoryClicked = 25
}
