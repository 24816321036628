import { useSubscribedMutation } from 'Hooks/React-Query';
import { PresentationQueryKey } from 'Services';
import {
  createPresentationMutation,
  deletePresentationMutation,
  modifyPresentationMutation
} from 'Services/Mutations/Presentations';

export const usePresentation = () => {
  const { mutateAsync: create, isLoading: createLoading } =
    useSubscribedMutation(createPresentationMutation(), [
      PresentationQueryKey.PRESENTATIONS
    ]);

  const { mutateAsync: modify, isLoading: modifyLoading } =
    useSubscribedMutation(modifyPresentationMutation(), [
      PresentationQueryKey.PRESENTATIONS,
      PresentationQueryKey.PRESENTATION
    ]);

  const { mutateAsync: remove, isLoading: deleteLoading } =
    useSubscribedMutation(deletePresentationMutation(), [
      PresentationQueryKey.PRESENTATIONS
    ]);

  return {
    create,
    modify,
    remove,
    loading: createLoading || modifyLoading || deleteLoading
  };
};
