import { Box, Divider } from '@material-ui/core';
import { HomeIcon } from '@setvi/shared/components/sicons';
import { useHomeButtonStyles, HomeButton as HomeBtn } from './style';

interface HomeButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export const HomeButton = ({ onClick, disabled = false }: HomeButtonProps) => {
  const classes = useHomeButtonStyles();

  return (
    <Box display="flex" alignItems="center">
      <HomeBtn onClick={onClick} disabled={disabled}>
        <HomeIcon className={classes.homeIcon} viewBox="-2 -2 28 28" />
      </HomeBtn>
      <Divider orientation="vertical" flexItem />
    </Box>
  );
};
