import { Box, withStyles } from '@material-ui/core';

export const ItemWrapper = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(3, 2),
    justifyContent: 'space-between'
  }
}))(Box);
