import { Box, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

interface IBackButton {
  onClick: () => void;
}

const BackButton = ({ onClick }: IBackButton) => (
  <Box pl={4} pb={3} display="flex" alignItems="center">
    <IconButton size="small" onClick={onClick}>
      <ArrowBack />
    </IconButton>
    <Box fontWeight={500} lineHeight={0} pl={2}>
      Back to result
    </Box>
  </Box>
);

export default BackButton;
