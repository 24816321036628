import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useGridLoaderStyles } from './styles';

export interface GridLoaderProps {
  gap?: number;
  itemWidth?: number;
}

export const GridLoader = ({ gap = 8, itemWidth = 250 }: GridLoaderProps) => {
  const classes = useGridLoaderStyles({ gap, itemWidth });
  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        {Array(6)
          .fill(null)
          .map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index}>
              <Box p={2} className={classes.grayBorder}>
                <Skeleton className={classes.imageSkeleton} variant="rect" />
                <Skeleton className={classes.title} />
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={2}
                  px={1}>
                  <Box className={classes.resource}>
                    <Skeleton variant="rect" height={24} width={24} />
                    <Skeleton width={100} height={18} />
                  </Box>
                  <Skeleton variant="rect" height={24} width={24} />
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};
