import { makeStyles } from '@material-ui/core/styles';
import { distances } from '@setvi/shared/styles';

export const useResourceChildrenStyles = makeStyles({
  container: {
    display: 'flex',
    overflow: 'hidden',
    height: '100%',
    maxHeight: '100%',
    width: '100%'
  },
  activeItem: {
    width: '80%',
    border: '1px solid blue'
  },
  page: {
    width: 106,
    height: 118,
    borderRadius: 3,
    position: 'relative',
    border: '3px solid  #E6E7E9',
    cursor: 'pointer',
    '& img': {
      borderRadius: 1.3
    }
  },
  resourceInfoContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${distances.px.small} ${distances.px.base} ${distances.px.medium}`,
    gap: distances.px.base,
    marginBottom: distances.px.xlarge,
    borderBottom: '1px solid #e0e0e0'
  },
  selectedItem: {
    borderColor: '#2196F3',
    '& div': {
      background: '#037AE2',
      borderColor: '#037AE2',
      '& svg': {
        display: 'block'
      }
    }
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  listWrapper: {
    overflow: 'hidden scroll',
    borderRight: '1px solid #e0e0e0',
    maxWidth: 280,
    minWidth: 'min-content'
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'fit-content',
    gap: `0 ${distances.px.xlarge}`
  },
  activePage: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    justifyContent: 'center',
    paddingLeft: distances.px.base,
    '& img': {
      height: '100%'
    }
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    margin: `${distances.px.xsmall} 0 ${distances.px.xmedium} 0`
  },
  skeletonItem: {
    border: '1px solid red'
  },
  skeletonActiveImage: {}
});
