import { makeStyles } from '@material-ui/core';

export const usePresentationCardStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: spacing(2)
  },
  image: {
    width: 70,
    height: 55,
    borderRadius: 6,
    objectFit: 'cover'
  }
}));
