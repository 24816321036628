import { Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const InputContainer = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    minHeight: 44
  }
}))(Box);

export const ChipInputContainer = withStyles({
  root: {
    width: '100%',
    '& .MuiInputBase-root': {
      fontSize: '0.875rem'
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  }
})(Box);

export const useStyles = makeStyles(() => ({
  chipInput: {
    '& > div': {
      alignItems: 'center',
      marginBottom: 0
    }
  }
}));
