import { withStyles } from '@material-ui/core';
import CenterBox from '../../../../../scenter-box';

export const DropSection = withStyles(({ palette }) => ({
  root: {
    flex: 1,
    border: `2px ${palette.divider} dashed`,
    borderRadius: 5
  }
}))(CenterBox);
