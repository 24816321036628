import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from 'enumsV2';
import { useAppContext } from 'Providers/AppProvider/AppContext';

export const AuthorizedRoutes = () => {
  const { token } = useAppContext();

  if (token) return <Outlet />;

  return <Navigate to={ROUTES.LOGIN} replace />;
};

export const ModuleAuthorizedRoutes = ({ module }: { module: string }) => {
  const { companyData } = useAppContext();

  if (companyData[module as keyof typeof companyData]) return <Outlet />;
  return <Navigate to={ROUTES.NOT_FOUND} replace />;
};
