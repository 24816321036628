import { Box, makeStyles } from '@material-ui/core';
import { SETVIImage, SResourceThumb, SText } from '@setvi/shared/components';
import { GetResourceTypeIcon } from '@setvi/shared/utils';
import { distances } from '../../../../styles';

const useStyles = makeStyles({
  resourceInfo: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: distances.px.base
  },
  image: {
    width: 70,
    minWidth: 70,
    height: 52,
    borderRadius: 4
  },
  fileType: {
    display: 'flex',
    flexDirection: 'column',
    gap: distances.px.xxsmall
  },
  fileTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: distances.px.xxsmall,
    color: '#92929D',
    fontWeigth: 400,
    fontSize: 10
  },
  fileIcon: {
    height: 16
  }
});

interface ResourceInfoProps {
  thumbUrl: string;
  name: string;
  resourceTypeID: number;
  resourceTypeName: string;
  resourceID: string;
}

export const ResourceInfo = ({
  thumbUrl,
  name,
  resourceID,
  resourceTypeID,
  resourceTypeName
}: ResourceInfoProps) => {
  const classes = useStyles();

  // @ts-ignore
  const handleSelected = () => {};

  return (
    <Box className={classes.resourceInfo}>
      <SResourceThumb
        thumbUrl={thumbUrl}
        name={name}
        resourceID={resourceID}
        resourceTypeID={resourceTypeID}
      />
      <Box className={classes.fileType}>
        <SText title={name} size="sm" />
        <Box className={classes.fileTitle}>
          <SETVIImage
            className={classes.fileIcon}
            src={GetResourceTypeIcon(resourceTypeID)}
            alt="File icon"
          />
          {resourceTypeName}
        </Box>
      </Box>
    </Box>
  );
};
