import { Tooltip, Chip, makeStyles, Theme } from '@material-ui/core';
import { ChipRendererArgs } from 'material-ui-chip-input';
import ErrorIcon from '@material-ui/icons/Error';

import { distances } from '@setvi/shared/styles';

interface StyleProps {
  maxWidth: number;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  displayChip: {
    cursor: 'pointer',
    margin: distances.px.xxsmall,
    maxWidth: ({ maxWidth }) => `${maxWidth}px`
  }
}));

export interface Chip {
  label: string;
  errors: string[];
  disableChipTooltip?: boolean;
}

interface IRecipientChip {
  chip: Chip;
  maxWidth?: number;
  handleClick?: ChipRendererArgs['handleClick'];
  handleDelete?: ChipRendererArgs['handleDelete'];
}

const RecipientChip = ({
  chip,
  maxWidth,
  handleClick,
  handleDelete
}: IRecipientChip) => {
  const classes = useStyles({ maxWidth: maxWidth || 180 });

  return (
    <Tooltip
      disableHoverListener={chip.disableChipTooltip}
      title={chip.label}
      placement="top"
      arrow>
      <Chip
        icon={
          chip.errors.length > 0 ? (
            <Tooltip title={chip.errors[0]} arrow>
              <ErrorIcon />
            </Tooltip>
          ) : null
        }
        color={chip.errors.length > 0 ? 'secondary' : 'default'}
        className={classes.displayChip}
        onDelete={handleDelete}
        onClick={handleClick}
        label={chip.label}
        variant="outlined"
      />
    </Tooltip>
  );
};

export default RecipientChip;
