import { Box, IconButton, Divider, makeStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { SText } from '@setvi/shared/components';
import { Group as GProps } from '@setvi/shared/services/react-query/query/contacts/types';
import { memo } from 'react';
import { distances } from '../../../../../styles';

const useContactStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    padding: distances.base,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    }
  },
  details: {
    flexGrow: 1
  },
  secondaryText: {
    fontSize: 13,
    fontWeight: 500,
    color: palette.primary.main
  }
}));

interface GroupProps {
  group: GProps;
  onGroupClick?: (group: GProps) => void;
  checkbox?: JSX.Element;
}

export const Group = memo(({ group, onGroupClick, checkbox }: GroupProps) => {
  const styles = useContactStyles();

  return (
    <Box onClick={() => onGroupClick(group)}>
      <Box className={styles.container}>
        <Box className={styles.details}>
          <SText title={group.Name} />
          <SText
            className={styles.secondaryText}
            title={`${group.ContactCount} Contacts`}
          />
        </Box>
        <Box display="flex" alignItems="center">
          {checkbox && <Box onClick={e => e.stopPropagation()}>{checkbox}</Box>}
          <IconButton size="medium">
            <ChevronRightIcon fontSize="medium" />
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
});
