import { useMemo } from 'react';
import { Rename } from '@setvi/shared/components/sicons';
import { Delete } from '@material-ui/icons';
import { useDialog } from '@setvi/shared/providers';
import { DropdownProps } from './index';
import { DeleteBookmark } from './delete-bookmark';
import { ISMenuItem } from '../../../../../../../sdropdown-menu/MenuItem';

export enum DIALOG {
  DELETE_BOOKMARK = 'delete-bookmark'
}

export const useDropdown = ({
  bookmark,
  handleRename,
  filteredBookmarks
}: DropdownProps) => {
  const { openDialog } = useDialog();

  const menuItems = useMemo(
    (): ISMenuItem[] => [
      {
        icon: <Rename />,
        text: 'Rename',
        subText: 'Change bookmark name',
        onClick: handleRename
      },
      {
        text: 'Delete',
        subText: 'Delete this bookmark',
        icon: <Delete />,
        color: 'danger',
        closeOnClick: false,
        onClick: () =>
          openDialog(
            <DeleteBookmark
              bookmark={bookmark}
              filteredBookmarks={filteredBookmarks}
            />,
            {
              id: DIALOG.DELETE_BOOKMARK
            }
          )
      }
    ],
    [bookmark, filteredBookmarks, handleRename, openDialog]
  );

  return { menuItems };
};
