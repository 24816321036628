export const addFreeSpaceToEmailContent = (html: string, rows = 5) => {
  const parser = new DOMParser();

  const parsedHTML = parser.parseFromString(html, 'text/html');

  const body = parsedHTML.getElementsByTagName('body')?.[0];

  // adding empty rows before added content
  Array(rows)
    .fill(null)
    .forEach(() => {
      const brElement = document.createElement('br');
      brElement.setAttribute('data-mce-bogus', '1');
      // This paragraph element is used because the editor works with the p element as a wrapper for other elements and without it does not work as expected
      const paragraph = document.createElement('p');
      paragraph.appendChild(brElement);

      body.insertBefore(paragraph, body.firstElementChild);
    });

  return parsedHTML.documentElement.innerHTML;
};
