import { Box, makeStyles } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { ResourceBookmark } from '@setvi/shared/interfaces';
import { Dropdown } from './dropdown';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  button: {
    borderRadius: '50%',
    height: 'min-content',
    minWidth: 32,
    width: 16
  },
  buttonActive: {
    backgroundColor: '#E0ECFF'
  }
}));

interface ItemProps {
  bookmark: ResourceBookmark;
  filteredBookmarks: ResourceBookmark[];
  handleRename: () => void;
  setActivePage: () => void;
}

export const Item = ({
  bookmark,
  handleRename,
  filteredBookmarks,
  setActivePage
}: ItemProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} onClick={setActivePage}>
      <Box>
        <SText size="sm" weight="bold" title={bookmark.BookmarkTitle} />
        <SText
          size="sm"
          fontColor="#92929D"
          title={`Page ${bookmark.PageNumber}`}
        />
      </Box>
      <Dropdown
        filteredBookmarks={filteredBookmarks}
        bookmark={bookmark}
        handleRename={handleRename}
      />
    </Box>
  );
};
