import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { defaultIconColor } from '@setvi/shared/components/sicons';
import { TextColor } from '@setvi/shared/enums';

export const Home = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10.006L12 4.60596L6.00001 10.006V19.1111C6.00001 19.4793 6.29848 19.7778 6.66667 19.7778H8.22223V17.3063C8.22223 15.7232 9.51803 14.4444 11.1111 14.4444H12.8889C14.482 14.4444 15.7778 15.7232 15.7778 17.3063V19.7778H17.3333C17.7015 19.7778 18 19.4793 18 19.1111V10.006ZM20.2222 19.1111C20.2222 20.7066 18.9288 22 17.3333 22H14.6667C14.053 22 13.5556 21.5025 13.5556 20.8889V17.3063C13.5556 16.9564 13.2603 16.6667 12.8889 16.6667H11.1111C10.7397 16.6667 10.4444 16.9564 10.4444 17.3063V20.8889C10.4444 21.5025 9.94698 22 9.33333 22H6.66667C5.07118 22 3.77778 20.7066 3.77778 19.1111V12.0001C3.32263 12.3422 2.67339 12.2857 2.28524 11.8544C1.87473 11.3983 1.9117 10.6957 2.36783 10.2852L11.2567 2.28523C11.6793 1.90492 12.3207 1.90492 12.7433 2.28523L21.6322 10.2852C22.0883 10.6957 22.1253 11.3983 21.7148 11.8544C21.3266 12.2857 20.6774 12.3422 20.2222 12.0001V19.1111Z"
    />
  </SvgIcon>
);

export const DocumentIcon = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      width="100%"
      d="M4.50065 0.203613H13.238C13.7821 0.203613 14.2981 0.445289 14.6464 0.863275L18.7424 5.77849C19.017 6.10797 19.1673 6.52328 19.1673 6.95216V16.7036C19.1673 18.7287 17.5257 20.3703 15.5007 20.3703H4.50065C2.47561 20.3703 0.833984 18.7287 0.833984 16.7036V3.87028C0.833984 1.84524 2.47561 0.203613 4.50065 0.203613ZM17.334 16.7036V7.53695H14.584C13.5715 7.53695 12.7507 6.71614 12.7507 5.70361V2.03695H4.50065C3.48813 2.03695 2.66732 2.85776 2.66732 3.87028V16.7036C2.66732 17.7161 3.48813 18.5369 4.50065 18.5369H15.5007C16.5132 18.5369 17.334 17.7161 17.334 16.7036ZM16.2935 5.70361L14.584 3.65216V5.70361H16.2935ZM6.33398 12.1203C5.82772 12.1203 5.41732 11.7099 5.41732 11.2036C5.41732 10.6974 5.82772 10.2869 6.33398 10.2869H13.6673C14.1736 10.2869 14.584 10.6974 14.584 11.2036C14.584 11.7099 14.1736 12.1203 13.6673 12.1203H6.33398ZM6.33398 15.7869C5.82772 15.7869 5.41732 15.3765 5.41732 14.8703C5.41732 14.364 5.82772 13.9536 6.33398 13.9536H11.834C12.3402 13.9536 12.7507 14.364 12.7507 14.8703C12.7507 15.3765 12.3402 15.7869 11.834 15.7869H6.33398ZM6.33398 8.45361C5.82772 8.45361 5.41732 8.04321 5.41732 7.53695C5.41732 7.03069 5.82772 6.62028 6.33398 6.62028H9.08398C9.59025 6.62028 10.0007 7.03069 10.0007 7.53695C10.0007 8.04321 9.59025 8.45361 9.08398 8.45361H6.33398Z"
      fill="white"
    />
  </SvgIcon>
);

export const Resources = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.30896H15.5316C16.1252 1.30896 16.6881 1.57261 17.0681 2.02859L21.5364 7.39064C21.836 7.75007 22 8.20314 22 8.67101V19.309C22 21.5181 20.2091 23.309 18 23.309H6C3.79086 23.309 2 21.5181 2 19.309V5.30896C2 3.09982 3.79086 1.30896 6 1.30896ZM20 19.309V9.30896H17C15.8954 9.30896 15 8.41353 15 7.30896V3.30896H6C4.89543 3.30896 4 4.20439 4 5.30896V19.309C4 20.4135 4.89543 21.309 6 21.309H18C19.1046 21.309 20 20.4135 20 19.309ZM18.865 7.30896L17 5.07101V7.30896H18.865ZM8 14.309C7.44772 14.309 7 13.8612 7 13.309C7 12.7567 7.44772 12.309 8 12.309H16C16.5523 12.309 17 12.7567 17 13.309C17 13.8612 16.5523 14.309 16 14.309H8ZM8 18.309C7.44772 18.309 7 17.8612 7 17.309C7 16.7567 7.44772 16.309 8 16.309H14C14.5523 16.309 15 16.7567 15 17.309C15 17.8612 14.5523 18.309 14 18.309H8ZM8 10.309C7.44772 10.309 7 9.86125 7 9.30896C7 8.75668 7.44772 8.30896 8 8.30896H11C11.5523 8.30896 12 8.75668 12 9.30896C12 9.86125 11.5523 10.309 11 10.309H8Z"
    />
  </SvgIcon>
);

export const Workspaces = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9998 16.309V4.30896H7.99976V16.309H19.9998ZM19.9998 18.309H4.99976C4.44747 18.309 3.99976 18.7567 3.99976 19.309C3.99976 19.8612 4.44747 20.309 4.99976 20.309H19.9998V18.309ZM21.9998 20.309C21.9998 21.4135 21.1043 22.309 19.9998 22.309H4.99976C3.3429 22.309 1.99976 20.9658 1.99976 19.309V4.30896C1.99976 3.20439 2.89519 2.30896 3.99976 2.30896H19.9998C21.1043 2.30896 21.9998 3.20439 21.9998 4.30896V20.309ZM3.99976 16.4797C4.31253 16.3691 4.64912 16.309 4.99976 16.309H5.99976V4.30896H3.99976V16.4797ZM11.4871 10.8212C10.9348 10.8212 10.4871 10.3735 10.4871 9.82123C10.4871 9.26894 10.9348 8.82123 11.4871 8.82123H14.4871C15.0394 8.82123 15.4871 9.26894 15.4871 9.82123C15.4871 10.3735 15.0394 10.8212 14.4871 10.8212H11.4871ZM11.4871 13.8212C10.9348 13.8212 10.4871 13.3735 10.4871 12.8212C10.4871 12.2689 10.9348 11.8212 11.4871 11.8212H16.4871C17.0394 11.8212 17.4871 12.2689 17.4871 12.8212C17.4871 13.3735 17.0394 13.8212 16.4871 13.8212H11.4871Z"
    />
  </SvgIcon>
);

export const Outbox = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.94083 19.8023L8.58855 18.7122L8.58858 21.1574C8.58761 21.1731 8.59674 21.1956 8.62777 21.2252C8.72162 21.3149 8.89465 21.3381 9.02785 21.2888L9.94083 19.8023ZM11.5107 21.0678L10.6149 22.5263C10.5583 22.6185 10.4871 22.7009 10.404 22.7702C9.4933 23.5313 8.10211 23.4896 7.24575 22.6709C6.79513 22.2401 6.5541 21.6463 6.59054 21.0954L6.58875 17.2472L2.18923 13.6692C1.7553 13.3163 1.42076 12.8566 1.21859 12.3354C0.574962 10.676 1.401 8.81053 3.06213 8.16888L20.6339 1.38131C20.9741 1.2499 21.2949 1.30782 21.54 1.472C21.8445 1.66704 22.0233 2.01637 21.9982 2.38178C21.9939 2.45158 21.9818 2.52172 21.9612 2.59114L17.1697 19.8531C16.6969 21.5564 14.9245 22.5543 13.2116 22.0891C12.78 21.9719 12.3777 21.7668 12.0302 21.4866L11.5107 21.0678ZM9.11475 16.5676L13.2852 19.9295C13.4172 20.036 13.5706 20.1142 13.7355 20.159C14.3907 20.3369 15.065 19.9573 15.2424 19.3182L18.9414 5.99182L9.11475 16.5676ZM7.47941 15.3923L17.3165 4.80859L3.78316 10.0346C3.15236 10.2781 2.83961 10.9842 3.08314 11.6119C3.15971 11.8092 3.28653 11.9834 3.45119 12.1173L7.47941 15.3923Z"
    />
  </SvgIcon>
);

export const Exit = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9662 12.5H9.75C9.33579 12.5 9 12.1642 9 11.75C9 11.3358 9.33579 11 9.75 11H17.3271L16.4161 10.0768C16.1252 9.78194 16.1284 9.30708 16.4232 9.01615C16.7181 8.72521 17.1929 8.72838 17.4839 9.02322L19.4839 11.0501C19.7734 11.3435 19.7718 11.8157 19.4803 12.1072L17.4803 14.1072C17.1874 14.4001 16.7126 14.4001 16.4197 14.1072C16.1268 13.8143 16.1268 13.3394 16.4197 13.0465L16.9662 12.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 17.35V15.75C15 14.75 16.5 14.75 16.5 15.75V17.35C16.5 19.1033 14.9239 20.5 13 20.5H7.5C5.57609 20.5 4 19.1033 4 17.35V6.15C4 4.39675 5.57609 3 7.5 3H13C14.9239 3 16.5 4.39675 16.5 6.15V7.75C16.5 8.16421 16.1642 8.5 15.75 8.5C15.3358 8.5 15 8.16421 15 7.75V6.15C15 5.25486 14.1152 4.5 13 4.5H7.5C6.38483 4.5 5.5 5.25486 5.5 6.15V17.35C5.5 18.2451 6.38483 19 7.5 19H13C14.1152 19 15 18.2451 15 17.35Z"
    />
  </SvgIcon>
);

export const ComposeEmail = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9574 8.21618C18.9574 7.66528 19.404 7.21869 19.9549 7.21869C20.5058 7.21869 20.9524 7.66528 20.9524 8.21618V18.132C20.9524 20.4389 19.0823 22.309 16.7754 22.309H6.177C3.87011 22.309 2 20.4389 2 18.132V7.53359C2 5.22669 3.87011 3.35658 6.177 3.35658H15.7779C16.3288 3.35658 16.7754 3.80317 16.7754 4.35408C16.7754 4.90498 16.3288 5.35157 15.7779 5.35157H6.177C4.97191 5.35157 3.99499 6.32849 3.99499 7.53359V18.132C3.99499 19.337 4.97191 20.314 6.177 20.314H16.7754C17.9805 20.314 18.9574 19.337 18.9574 18.132V8.21618ZM19.8376 2.61858C20.2175 2.21962 20.8489 2.20418 21.2478 2.58409C21.6468 2.96399 21.6622 3.59538 21.2823 3.99434L11.7318 14.0237C11.3519 14.4226 10.7205 14.4381 10.3216 14.0581C9.92263 13.6782 9.90719 13.0469 10.2871 12.6479L19.8376 2.61858Z"
    />
  </SvgIcon>
);

export const Notifications = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.02257 4.64094C9.2 3.15337 10.466 2 12.0013 2C13.5367 2 14.8026 3.15337 14.98 4.64094C17.4827 5.77546 19.2235 8.29555 19.2235 11.2222V16.2399L20.894 19.5494C21.2298 20.2145 20.7463 21 20.0013 21H14.8306C14.4188 22.1652 13.3075 23 12.0013 23C10.6951 23 9.58385 22.1652 9.17201 21H4.00131C3.25626 21 2.77286 20.2145 3.10858 19.5494L4.77908 16.2399V11.2222C4.77908 8.29555 6.5199 5.77546 9.02257 4.64094ZM6.6718 16.9286L5.62624 19H18.3764L17.3308 16.9286C17.2603 16.7888 17.2235 16.6345 17.2235 16.478V11.2222C17.2235 8.33807 14.8855 6 12.0013 6C9.11715 6 6.77908 8.33807 6.77908 11.2222V16.478C6.77908 16.6345 6.74234 16.7888 6.6718 16.9286Z"
    />
  </SvgIcon>
);

export const MoreActionBlue = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 12C20 13.1046 20.8954 14 22 14C23.1046 14 24 13.1046 24 12C24 10.8954 23.1046 10 22 10C20.8954 10 20 10.8954 20 12Z"
      fill="#2196F3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 12C13 13.1046 13.8954 14 15 14C16.1046 14 17 13.1046 17 12C17 10.8954 16.1046 10 15 10C13.8954 10 13 10.8954 13 12Z"
      fill="#2196F3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12C6 13.1046 6.89543 14 8 14C9.10457 14 10 13.1046 10 12C10 10.8954 9.10457 10 8 10C6.89543 10 6 10.8954 6 12Z"
      fill="#2196F3"
    />
  </SvgIcon>
);

export const Edit = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8619 6.44376L16.9767 5.329L14.5196 2.87194L13.4049 3.98669L15.8619 6.44376ZM14.4552 7.85053L11.9981 5.39347L2.80663 14.5849L2.24111 17.6075L5.26369 17.042L14.4552 7.85053ZM18.4429 3.98164C19.187 4.72577 19.187 5.93224 18.4429 6.67636L6.45272 18.6665C6.31087 18.8084 6.12945 18.904 5.93227 18.9409L1.17913 19.8302C0.491809 19.9588 -0.110175 19.3568 0.0184207 18.6695L0.907717 13.9164C0.944609 13.7192 1.04026 13.5378 1.1821 13.3959L13.1723 1.40575C13.9164 0.661625 15.1229 0.661625 15.867 1.40575L18.4429 3.98164ZM18.0109 19.8477H9.99108C8.67094 19.8477 8.67094 17.8477 9.99108 17.8477H18.0109C19.331 17.8477 19.331 19.8477 18.0109 19.8477Z"
    />
  </SvgIcon>
);

export const Delete = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.10105 2.43L5.7064 0.615395C5.82901 0.247887 6.1732 0 6.5609 0H11.4248C11.8125 0 12.1567 0.247887 12.2793 0.615395L12.8846 2.43H17.0993C17.5967 2.43 18 2.83294 18 3.33C18 3.82706 17.5967 4.23 17.0993 4.23H16.3273L15.6663 15.4585C15.5823 16.8857 14.3995 18 12.9688 18H5.0169C3.58617 18 2.40342 16.8857 2.3194 15.4585L1.65837 4.23H0.900719C0.403266 4.23 0 3.82706 0 3.33C0 2.83294 0.403266 2.43 0.900719 2.43H5.10105ZM6.99994 2.43H10.9858L10.7756 1.8H7.2101L6.99994 2.43ZM14.5228 4.23H3.46292L4.11773 15.3528C4.14574 15.8286 4.53999 16.2 5.0169 16.2H12.9688C13.4457 16.2 13.8399 15.8286 13.868 15.3528L14.5228 4.23ZM10.5258 6.51386C10.5569 6.01777 10.9845 5.64075 11.481 5.67175C11.9775 5.70276 12.3548 6.13005 12.3238 6.62614L11.9184 13.1061C11.8874 13.6022 11.4598 13.9793 10.9633 13.9482C10.4668 13.9172 10.0895 13.4899 10.1205 12.9939L10.5258 6.51386ZM7.86519 12.9939C7.89622 13.4899 7.5189 13.9172 7.02241 13.9482C6.52593 13.9793 6.09829 13.6022 6.06726 13.1061L5.66194 6.62614C5.63091 6.13005 6.00823 5.70276 6.50472 5.67175C7.0012 5.64075 7.42884 6.01777 7.45987 6.51386L7.86519 12.9939Z"
    />
  </SvgIcon>
);

export const Email = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00918 3H20.0577C21.7196 3 23.0668 4.34315 23.0668 6V6.42743C23.0669 6.43617 23.0669 6.44489 23.0668 6.45362V17C23.0668 18.6569 21.7196 20 20.0577 20H4.00918C2.3473 20 1.00009 18.6569 1.00009 17V6.45358C0.999972 6.44488 0.999972 6.43618 1.00009 6.42747V6C1.00009 4.34315 2.3473 3 4.00918 3ZM3.00615 8.36205V17C3.00615 17.5523 3.45522 18 4.00918 18H20.0577C20.6117 18 21.0607 17.5523 21.0607 17V8.36205L13.7591 13.4578C12.723 14.1809 11.3439 14.1809 10.3078 13.4578L3.00615 8.36205ZM21.0578 5.9228L12.6086 11.8193C12.2633 12.0603 11.8036 12.0603 11.4582 11.8193L3.0091 5.9228C3.04863 5.40659 3.48128 5 4.00918 5H20.0577C20.5856 5 21.0183 5.40659 21.0578 5.9228Z"
      fill={props.fill || defaultIconColor}
    />
  </SvgIcon>
);

export const Slideshow = (props: any) => (
  <SvgIcon width="23" height="23" viewBox="0 0 10 14" fill="none" {...props}>
    <path
      d="M6.66667 8V10.6667H8.66667V12H3.33333V10.6667H5.33333V8H0.666667C0.489856 8 0.320286 7.92976 0.195262 7.80474C0.0702379 7.67971 0 7.51014 0 7.33333V0.666667C0 0.489856 0.0702379 0.320286 0.195262 0.195262C0.320286 0.0702379 0.489856 0 0.666667 0H11.3333C11.5101 0 11.6797 0.0702379 11.8047 0.195262C11.9298 0.320286 12 0.489856 12 0.666667V7.33333C12 7.51014 11.9298 7.67971 11.8047 7.80474C11.6797 7.92976 11.5101 8 11.3333 8H6.66667ZM1.33333 6.66667H10.6667V1.33333H1.33333V6.66667ZM3.33333 3.33333H8.66667V4.66667H3.33333V3.33333Z"
      fill="#1E96FC"
    />
  </SvgIcon>
);

export const CreatePresentation = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2921 15.2691C7.12503 15.0111 7.03619 14.7107 7.03619 14.4038V5.59614C7.03619 4.71495 7.7533 4 8.63865 4C8.9473 4 9.24935 4.08878 9.50853 4.25564L16.3488 8.65947C17.0917 9.13778 17.3049 10.1251 16.8255 10.8653C16.7022 11.0555 16.5397 11.2175 16.3488 11.3405L9.50853 15.7443C8.76469 16.2232 7.7721 16.0102 7.2921 15.2691ZM9.04524 13.6607L14.7314 9.99996L9.04524 6.33918V13.6607Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.02262 0H18.086C20.3051 0 22.1041 1.79086 22.1041 4V16C22.1041 18.2091 20.3051 20 18.086 20H4.02262C1.80348 20 0.0045166 18.2091 0.0045166 16V4C0.0045166 1.79086 1.80348 0 4.02262 0ZM4.02262 2C2.91305 2 2.01357 2.89543 2.01357 4V16C2.01357 17.1046 2.91305 18 4.02262 18H18.086C19.1955 18 20.095 17.1046 20.095 16V4C20.095 2.89543 19.1955 2 18.086 2H4.02262Z"
    />
  </SvgIcon>
);

export const AddPresentation = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 26 26">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.27981 17.2691C8.11299 17.0111 8.02428 16.7107 8.02428 16.4038V7.59614C8.02428 6.71495 8.74032 6 9.62436 6C9.93255 6 10.2342 6.08878 10.4929 6.25564L17.323 10.6595C18.0649 11.1378 18.2777 12.1251 17.799 12.8653C17.676 13.0555 17.5137 13.2175 17.323 13.3405L10.4929 17.7443C9.75022 18.2232 8.7591 18.0102 8.27981 17.2691ZM10.0303 15.6607L15.708 12L10.0303 8.33918V15.6607Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.01518 2H19.0576C21.2735 2 23.0698 3.79086 23.0698 6V18C23.0698 20.2091 21.2735 22 19.0576 22H5.01518C2.79934 22 1.00305 20.2091 1.00305 18V6C1.00305 3.79086 2.79934 2 5.01518 2ZM5.01518 4C3.90726 4 3.00912 4.89543 3.00912 6V18C3.00912 19.1046 3.90726 20 5.01518 20H19.0576C20.1656 20 21.0637 19.1046 21.0637 18V6C21.0637 4.89543 20.1656 4 19.0576 4H5.01518Z"
    />
    <rect x="16" y="15" width="12" height="12" rx="6" fill="white" />
    <path d="M23 22H25C26.3333 22 26.3333 20 25 20H23V18C23 16.6667 21 16.6667 21 18V20H19C17.6667 20 17.6667 22 19 22H21V24C21 25.3333 23 25.3333 23 24V22Z" />
  </SvgIcon>
);

export const CreateLink = (props: any) => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <path d="M14.6568 11.828L13.2428 10.414L14.6568 9C15.0282 8.62856 15.3229 8.18759 15.5239 7.70228C15.7249 7.21696 15.8284 6.69681 15.8284 6.1715C15.8284 5.6462 15.7249 5.12605 15.5239 4.64073C15.3229 4.15542 15.0282 3.71445 14.6568 3.343C14.2853 2.97156 13.8444 2.67691 13.359 2.47589C12.8737 2.27487 12.3536 2.1714 11.8283 2.1714C11.303 2.1714 10.7828 2.27487 10.2975 2.47589C9.81218 2.67691 9.37121 2.97156 8.99977 3.343L7.58577 4.757L6.17177 3.343L7.58577 1.929C8.71408 0.818923 10.2353 0.199659 11.8181 0.206104C13.401 0.21255 14.9171 0.844181 16.0364 1.96341C17.1556 3.08265 17.7872 4.5988 17.7937 6.18162C17.8001 7.76444 17.1808 9.28569 16.0708 10.414L14.6568 11.828ZM11.8278 14.657L10.4138 16.071C9.85829 16.6356 9.19652 17.0846 8.46663 17.3922C7.73675 17.6998 6.95319 17.8598 6.16115 17.863C5.36911 17.8662 4.58428 17.7126 3.85191 17.411C3.11954 17.1094 2.45414 16.6658 1.89408 16.1057C1.33402 15.5456 0.890389 14.8802 0.588778 14.1479C0.287167 13.4155 0.133543 12.6307 0.136768 11.8386C0.139994 11.0466 0.300003 10.263 0.607569 9.53314C0.915134 8.80325 1.36417 8.14148 1.92877 7.586L3.34277 6.172L4.75677 7.586L3.34277 9C2.97133 9.37145 2.67668 9.81242 2.47566 10.2977C2.27463 10.783 2.17117 11.3032 2.17117 11.8285C2.17117 12.3538 2.27463 12.874 2.47566 13.3593C2.67668 13.8446 2.97133 14.2856 3.34277 14.657C3.71421 15.0284 4.15518 15.3231 4.6405 15.5241C5.12581 15.7251 5.64597 15.8286 6.17127 15.8286C6.69657 15.8286 7.21673 15.7251 7.70204 15.5241C8.18736 15.3231 8.62833 15.0284 8.99977 14.657L10.4138 13.243L11.8278 14.657ZM11.8278 4.757L13.2428 6.172L6.17177 13.242L4.75677 11.828L11.8278 4.758V4.757Z" />
  </SvgIcon>
);

export const MoreInfo = (props: any) => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="-2 -1 23 23"
    fill={props.fill}
    {...props}>
    <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 5H11V7H9V5ZM9 9H11V15H9V9Z" />
  </SvgIcon>
);

export const Download = (props: any) => (
  <SvgIcon viewBox="-2 -2 24 24" {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 16.9656L11.3892 16.6653C11.8265 16.328 12.4544 16.409 12.7918 16.8463C13.1291 17.2836 13.0481 17.9115 12.6108 18.2489L10.6277 19.7787C10.5675 19.8274 10.5025 19.8682 10.4342 19.901C10.3808 19.928 10.309 19.9545 10.234 19.9725C10.1375 19.9951 10.0448 20.0033 9.95295 19.9989C9.81197 19.9924 9.6786 19.9567 9.55877 19.8976C9.4931 19.8654 9.43047 19.8257 9.37226 19.7787L7.38919 18.2489C6.9519 17.9115 6.87088 17.2836 7.20823 16.8463C7.54557 16.409 8.17353 16.328 8.61081 16.6653L9 16.9656V6.45711C9 5.90482 9.44772 5.45711 10 5.45711C10.5523 5.45711 11 5.90482 11 6.45711V16.9656ZM10.5 0C13.0609 0 15.2376 1.76105 15.8369 4.17236C18.2436 4.77356 20 6.95407 20 9.518C20 12.3327 17.8828 14.6868 15.1101 14.9939C14.5612 15.0547 14.0669 14.659 14.0061 14.1101C13.9453 13.5612 14.341 13.0669 14.8899 13.0061C16.6514 12.8109 18 11.3114 18 9.518C18 7.71741 16.6408 6.21401 14.8706 6.02783C14.4009 5.97843 14.0298 5.60718 13.9806 5.13748C13.7947 3.36183 12.2947 2 10.5 2C8.70372 2 7.20292 3.36415 7.01891 5.14171C6.96154 5.69596 6.46222 6.0964 5.90874 6.03205C5.77394 6.01638 5.63757 6.00847 5.5 6.00847C3.56748 6.00847 2 7.57926 2 9.518C2 11.3114 3.34862 12.8109 5.11011 13.0061C5.65903 13.0669 6.05473 13.5612 5.99392 14.1101C5.93311 14.659 5.43882 15.0547 4.88989 14.9939C2.11715 14.6868 0 12.3327 0 9.518C0 6.57497 2.30383 4.17018 5.20435 4.01629C5.85706 1.68561 7.99449 0 10.5 0Z"
      fill={props.fill || defaultIconColor}
    />
  </SvgIcon>
);

export const Grid = (props: SvgIconProps) => (
  <SvgIcon width="21" height="20" viewBox="0 0 21 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.50857 0H7.70338C8.92271 0 9.91117 0.988462 9.91117 2.20779V7.4026C9.91117 8.62193 8.92271 9.61039 7.70338 9.61039H2.50857C1.28924 9.61039 0.300781 8.62193 0.300781 7.4026V2.20779C0.300781 0.988462 1.28924 0 2.50857 0ZM2.50857 1.81818C2.2934 1.81818 2.11896 1.99262 2.11896 2.20779V7.4026C2.11896 7.61777 2.2934 7.79221 2.50857 7.79221H7.70338C7.91855 7.79221 8.09299 7.61777 8.09299 7.4026V2.20779C8.09299 1.99262 7.91855 1.81818 7.70338 1.81818H2.50857ZM12.8982 10.3896H18.093C19.3123 10.3896 20.3008 11.3781 20.3008 12.5974V17.7922C20.3008 19.0115 19.3123 20 18.093 20H12.8982C11.6789 20 10.6904 19.0115 10.6904 17.7922V12.5974C10.6904 11.3781 11.6789 10.3896 12.8982 10.3896ZM12.8982 12.2078C12.683 12.2078 12.5086 12.3822 12.5086 12.5974V17.7922C12.5086 18.0074 12.683 18.1818 12.8982 18.1818H18.093C18.3082 18.1818 18.4826 18.0074 18.4826 17.7922V12.5974C18.4826 12.3822 18.3082 12.2078 18.093 12.2078H12.8982ZM12.8982 0H18.093C19.3123 0 20.3008 0.988462 20.3008 2.20779V7.4026C20.3008 8.62193 19.3123 9.61039 18.093 9.61039H12.8982C11.6789 9.61039 10.6904 8.62193 10.6904 7.4026V2.20779C10.6904 0.988462 11.6789 0 12.8982 0ZM12.8982 1.81818C12.683 1.81818 12.5086 1.99262 12.5086 2.20779V7.4026C12.5086 7.61777 12.683 7.79221 12.8982 7.79221H18.093C18.3082 7.79221 18.4826 7.61777 18.4826 7.4026V2.20779C18.4826 1.99262 18.3082 1.81818 18.093 1.81818H12.8982ZM2.50857 10.3896H7.70338C8.92271 10.3896 9.91117 11.3781 9.91117 12.5974V17.7922C9.91117 19.0115 8.92271 20 7.70338 20H2.50857C1.28924 20 0.300781 19.0115 0.300781 17.7922V12.5974C0.300781 11.3781 1.28924 10.3896 2.50857 10.3896ZM2.50857 12.2078C2.2934 12.2078 2.11896 12.3822 2.11896 12.5974V17.7922C2.11896 18.0074 2.2934 18.1818 2.50857 18.1818H7.70338C7.91855 18.1818 8.09299 18.0074 8.09299 17.7922V12.5974C8.09299 12.3822 7.91855 12.2078 7.70338 12.2078H2.50857Z"
      fill={props.fill || TextColor.Grey}
    />
  </SvgIcon>
);

export const Plus = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8.94922H17C17.5523 8.94922 18 9.39693 18 9.94922C18 10.5015 17.5523 10.9492 17 10.9492H10V17.9492C10 18.5015 9.55229 18.9492 9 18.9492C8.44771 18.9492 8 18.5015 8 17.9492V10.9492H1C0.447715 10.9492 0 10.5015 0 9.94922C0 9.39693 0.447715 8.94922 1 8.94922H8V1.94922C8 1.39693 8.44771 0.949219 9 0.949219C9.55229 0.949219 10 1.39693 10 1.94922V8.94922Z"
    />
  </SvgIcon>
);

export const List = (props: SvgIconProps) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      d="M4.66667 1.33268H15.5V2.99935H4.66667V1.33268ZM0.5 0.916016H3V3.41602H0.5V0.916016ZM0.5 6.74935H3V9.24935H0.5V6.74935ZM0.5 12.5827H3V15.0827H0.5V12.5827ZM4.66667 7.16602H15.5V8.83268H4.66667V7.16602ZM4.66667 12.9993H15.5V14.666H4.66667V12.9993Z"
      fill={props.fill}
    />
  </SvgIcon>
);

export const CreateLink2 = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7245 6.65259L17.6816 5.60968C16.8908 4.81891 15.6087 4.81891 14.8179 5.60968L12.2107 8.21696C11.4199 9.00774 11.4199 10.2898 12.2107 11.0806L12.3498 11.2198L11.2207 12.3488L11.0816 12.2097C10.2908 11.4189 9.00871 11.4189 8.21794 12.2097L5.61066 14.817C4.81988 15.6077 4.81988 16.8898 5.61066 17.6806L6.65357 18.7235C7.44434 19.5143 8.72644 19.5143 9.51721 18.7235L12.1245 16.1162C12.9153 15.3255 12.9153 14.0434 12.1245 13.2526L11.9986 13.1267L13.1276 11.9976L13.2536 12.1235C14.0443 12.9143 15.3264 12.9143 16.1172 12.1235L18.7245 9.51624C19.5153 8.72546 19.5153 7.44337 18.7245 6.65259ZM13.9055 11.2198L14.0314 11.3457C14.3926 11.7069 14.9782 11.7069 15.3394 11.3457L17.9467 8.73842C18.3079 8.37722 18.3079 7.79161 17.9467 7.43041L16.9038 6.3875C16.5426 6.0263 15.957 6.0263 15.5958 6.3875L12.9885 8.99478C12.6273 9.35598 12.6273 9.94159 12.9885 10.3028L13.1276 10.4419L13.9853 9.5843C14.2189 9.35064 14.5488 9.37005 14.7631 9.5843C14.9774 9.79856 14.9968 10.1285 14.7631 10.3621L13.9055 11.2198ZM10.4429 13.1267L9.58528 13.9843C9.35162 14.218 9.37102 14.5479 9.58528 14.7621C9.79954 14.9764 10.1294 14.9958 10.3631 14.7621L11.2207 13.9045L11.3467 14.0304C11.7079 14.3916 11.7079 14.9772 11.3467 15.3384L8.7394 17.9457C8.3782 18.3069 7.79259 18.3069 7.43139 17.9457L6.38848 16.9028C6.02728 16.5416 6.02728 15.956 6.38848 15.5948L8.99576 12.9875C9.35696 12.6263 9.94257 12.6263 10.3038 12.9875L10.4429 13.1267Z"
    />
  </SvgIcon>
);

export const View = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.11549 10.9023C4.05195 6.08994 8.04719 3 12.5 3C16.9528 3 20.948 6.08994 22.8845 10.9023C23.0385 11.285 23.0385 11.715 22.8845 12.0977C20.948 16.9101 16.9528 20 12.5 20C8.04719 20 4.05195 16.9101 2.11549 12.0977C1.9615 11.715 1.9615 11.285 2.11549 10.9023ZM12.5 18.034C16.09 18.034 19.3814 15.5234 21.0574 11.5C19.3814 7.47658 16.09 4.96599 12.5 4.96599C8.91 4.96599 5.61857 7.47658 3.94259 11.5C5.61857 15.5234 8.91 18.034 12.5 18.034ZM12.5 15.3742C10.4223 15.3742 8.73797 13.6396 8.73797 11.5C8.73797 9.36037 10.4223 7.62585 12.5 7.62585C14.5777 7.62585 16.262 9.36037 16.262 11.5C16.262 13.6396 14.5777 15.3742 12.5 15.3742ZM12.5 13.4082C13.5234 13.4082 14.3529 12.5538 14.3529 11.5C14.3529 10.4462 13.5234 9.59184 12.5 9.59184C11.4766 9.59184 10.6471 10.4462 10.6471 11.5C10.6471 12.5538 11.4766 13.4082 12.5 13.4082Z"
    />
  </SvgIcon>
);

export const Time = (props: any) => (
  <SvgIcon fill="none" {...props}>
    <path
      d="M11 4C11.5523 4 12 4.44772 12 5V10.5109L16.1139 13.7106C16.5499 14.0497 16.6284 14.678 16.2894 15.1139C15.9503 15.5499 15.322 15.6284 14.8861 15.2894L10.3861 11.7894C10.1425 11.5999 10 11.3086 10 11V5C10 4.44772 10.4477 4 11 4Z"
      fill="#92929D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0ZM11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2Z"
      fill="#92929D"
    />
  </SvgIcon>
);

export const MessageDetails = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7831 22.9762C22.4976 23.135 23.135 22.4976 22.9762 21.7831L21.8786 16.8439C22.6123 15.3503 23 13.7022 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C13.7022 23 15.3503 22.6123 16.8439 21.8786L21.7831 22.9762ZM19.9424 16.2373C19.8296 16.4481 19.7961 16.6924 19.848 16.9258L20.6829 20.6829L16.9258 19.848C16.6924 19.7961 16.4481 19.8296 16.2373 19.9424C14.9449 20.6335 13.5 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 13.5 20.6335 14.9449 19.9424 16.2373Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 14C10.1046 14 11 13.1046 11 12C11 10.8954 10.1046 10 9 10C7.89543 10 7 10.8954 7 12C7 13.1046 7.89543 14 9 14Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 14C16.1046 14 17 13.1046 17 12C17 10.8954 16.1046 10 15 10C13.8954 10 13 10.8954 13 12C13 13.1046 13.8954 14 15 14Z"
    />
  </SvgIcon>
);

export const FileProcessing = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.42578 4.7676C5.42578 4.37818 5.74007 4.0625 6.12777 4.0625C6.51547 4.0625 6.82977 4.37818 6.82977 4.7676V6.25274C8.98214 4.04006 12.351 3.40822 15.1945 4.80123C18.7946 6.56485 20.2896 10.9259 18.5337 14.5418C16.7779 18.1578 12.4361 19.6594 8.83601 17.8958C7.39823 17.1914 6.2472 16.0458 5.53489 14.6198C5.36102 14.2718 5.50099 13.848 5.84751 13.6734C6.19404 13.4988 6.6159 13.6393 6.78977 13.9874C7.36429 15.1375 8.29099 16.0598 9.45148 16.6283C12.3546 18.0505 15.8559 16.8396 17.2718 13.9236C18.6878 11.0077 17.4822 7.49091 14.5791 6.0687C12.0335 4.82164 8.99482 5.59455 7.33569 7.82302H9.87173C10.2594 7.82302 10.5737 8.1387 10.5737 8.52812C10.5737 8.91753 10.2594 9.23321 9.87173 9.23321H6.12777C5.74007 9.23321 5.42578 8.91753 5.42578 8.52812V8.47516C5.42562 8.46387 5.42578 4.7676 5.42578 4.7676Z"
    />
  </SvgIcon>
);

export const EmailOpen = (props: any) => (
  <SvgIcon {...props}>
    <path
      d="M3.66667 21H20.3333C20.7752 20.9995 21.1988 20.8227 21.5113 20.5083C21.8238 20.194 21.9995 19.7677 22 19.3232V8.59144C21.9994 8.29909 21.9231 8.01195 21.7787 7.7583C21.6342 7.50464 21.4266 7.29321 21.1762 7.14483L12.8429 2.22999C12.5872 2.07938 12.2963 2 12 2C11.7037 2 11.4128 2.07938 11.1571 2.22999L2.82379 7.14483C2.57343 7.29321 2.3658 7.50464 2.22135 7.7583C2.07691 8.01196 2.00062 8.29909 2 8.59144V19.3232C2.0005 19.7677 2.17625 20.194 2.4887 20.5083C2.80116 20.8227 3.22479 20.9995 3.66667 21V21ZM21.3333 19.3232C21.333 19.5899 21.2276 19.8456 21.0401 20.0342C20.8526 20.2229 20.5985 20.329 20.3333 20.3293H3.66667C3.40154 20.329 3.14737 20.2229 2.9599 20.0342C2.77243 19.8456 2.66698 19.5899 2.66667 19.3232V9.17678L11.1571 14.1843C11.4128 14.3349 11.7037 14.4143 12 14.4143C12.2963 14.4143 12.5872 14.3349 12.8429 14.1843L21.3333 9.17678V19.3232ZM3.16096 7.72346L11.4943 2.80862C11.6477 2.71826 11.8222 2.67063 12 2.67063C12.1778 2.67063 12.3523 2.71826 12.5057 2.80862L20.839 7.72346C20.9627 7.79682 21.0693 7.896 21.1516 8.01432C21.2339 8.13263 21.2901 8.26733 21.3163 8.40933L12.5057 13.6057C12.3523 13.696 12.1778 13.7436 12 13.7436C11.8222 13.7436 11.6477 13.696 11.4943 13.6057L2.68371 8.40933C2.70991 8.26733 2.76607 8.13263 2.84839 8.01432C2.93072 7.896 3.0373 7.79682 3.16096 7.72346Z"
      strokeWidth="1.52392"
    />
  </SvgIcon>
);

export const CloseWhite = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z"
      fill="white"
    />
  </SvgIcon>
);

export const AddResourceCompose = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2778 6.31917L14.8412 4.65242V6.31917H16.2778ZM19.5772 7.66833V17.4108C19.5772 19.4902 17.8338 21.1759 15.6832 21.1759H4.31678C2.16616 21.1759 0.422729 19.4902 0.422729 17.4108V4.58928C0.422729 2.50989 2.16616 0.824219 4.31678 0.824219H13.3451C13.9386 0.824219 14.5014 1.07909 14.8813 1.51989L19.1138 6.43059C19.4132 6.77805 19.5772 7.21603 19.5772 7.66833ZM17.4724 8.35434H14.736C13.6316 8.35434 12.7363 7.48872 12.7363 6.42093V2.85939H4.31678C3.32866 2.85939 2.52762 3.63389 2.52762 4.58928V17.4108C2.52762 18.3662 3.32866 19.1407 4.31678 19.1407H15.6832C16.6713 19.1407 17.4724 18.3662 17.4724 17.4108V8.35434ZM4.74077 14.6634H15.2592C15.4352 14.6634 15.578 14.7848 15.578 14.9347V16.4272C15.578 16.577 15.4352 16.6985 15.2592 16.6985H4.74077C4.56473 16.6985 4.42202 16.577 4.42202 16.4272V14.9347C4.42202 14.7848 4.56473 14.6634 4.74077 14.6634Z"
      fill="#2196F3"
    />
  </SvgIcon>
);

export const CampaignIcon = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7273 4.65256C19.1474 6.37924 20 8.59023 20 11.0004C20 16.5232 15.5228 21.0004 10 21.0004C4.47715 21.0004 0 16.5232 0 11.0004C0 5.47751 4.47715 1.00036 10 1.00036C12.3924 1.00036 14.5886 1.84049 16.3096 3.24182L16.8604 2.691L17.1222 1.29662C17.2241 0.753821 17.7468 0.396403 18.2896 0.498307C18.8324 0.600211 19.1898 1.12285 19.0879 1.66565L19.0308 1.96958L19.3348 1.91252C19.8776 1.81062 20.4002 2.16803 20.5021 2.71084C20.604 3.25364 20.2466 3.77628 19.7038 3.87818L18.2239 4.15602L17.7273 4.65256ZM14.8861 4.66535C13.5346 3.62143 11.8398 3.00036 10 3.00036C5.58172 3.00036 2 6.58208 2 11.0004C2 15.4186 5.58172 19.0004 10 19.0004C14.4183 19.0004 18 15.4186 18 11.0004C18 9.14277 17.3669 7.43305 16.3047 6.07522L14.8763 7.50359C15.5835 8.48812 16 9.6956 16 11.0004C16 14.3141 13.3137 17.0004 10 17.0004C6.68629 17.0004 4 14.3141 4 11.0004C4 7.68665 6.68629 5.00036 10 5.00036C11.2869 5.00036 12.4792 5.40551 13.4562 6.09521L14.8861 4.66535ZM12.0101 7.54135C11.4195 7.19738 10.7327 7.00036 10 7.00036C7.79086 7.00036 6 8.79122 6 11.0004C6 13.2095 7.79086 15.0004 10 15.0004C12.2091 15.0004 14 13.2095 14 11.0004C14 10.2495 13.7931 9.54696 13.4332 8.94664L11.9248 10.4551C11.9738 10.6284 12 10.8113 12 11.0004C12 12.1049 11.1046 13.0004 10 13.0004C8.89543 13.0004 8 12.1049 8 11.0004C8 9.89579 8.89543 9.00036 10 9.00036C10.1692 9.00036 10.3336 9.02138 10.4905 9.06095L12.0101 7.54135Z"
    />
  </SvgIcon>
);

export const NoActivity = (props: any) => (
  <SvgIcon {...props}>
    <path d="M12 5.63636C12.5021 5.63636 12.9091 6.04338 12.9091 6.54545V11.5554L16.649 14.4642C17.0454 14.7725 17.1167 15.3436 16.8085 15.7399C16.5003 16.1363 15.9291 16.2077 15.5328 15.8994L11.4419 12.7176C11.2204 12.5454 11.0909 12.2805 11.0909 12V6.54545C11.0909 6.04338 11.4979 5.63636 12 5.63636Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 3.81818C7.48131 3.81818 3.81818 7.48131 3.81818 12C3.81818 16.5187 7.48131 20.1818 12 20.1818C16.5187 20.1818 20.1818 16.5187 20.1818 12C20.1818 7.48131 16.5187 3.81818 12 3.81818Z"
    />
  </SvgIcon>
);

export const LastInteraction = (props: any) => (
  <SvgIcon {...props}>
    <path d="M17.1953 5.25C17.1953 4.86709 16.8849 4.55669 16.502 4.55669C16.119 4.55669 15.8086 4.86709 15.8086 5.25H17.1953ZM15.8086 7.5C15.8086 7.88291 16.119 8.19331 16.502 8.19331C16.8849 8.19331 17.1953 7.88291 17.1953 7.5H15.8086ZM8.94465 5.25C8.94465 4.86709 8.63424 4.55669 8.25134 4.55669C7.86843 4.55669 7.55802 4.86709 7.55802 5.25H8.94465ZM7.55802 7.5C7.55802 7.88291 7.86843 8.19331 8.25134 8.19331C8.63424 8.19331 8.94465 7.88291 8.94465 7.5H7.55802ZM4.50195 11.9437H19.502V10.5571H4.50195V11.9437ZM18.8086 11.2504V15.7504H20.1953V11.2504H18.8086ZM18.8086 15.7504C18.8086 17.4385 17.4401 18.8071 15.752 18.8071V20.1937C18.2059 20.1937 20.1953 18.2044 20.1953 15.7504H18.8086ZM15.752 18.8071H8.25195V20.1937H15.752V18.8071ZM8.25195 18.8071C6.56379 18.8071 5.19527 17.4385 5.19527 15.7504H3.80864C3.80864 18.2044 5.79798 20.1937 8.25195 20.1937V18.8071ZM5.19527 15.7504V11.2504H3.80864V15.7504H5.19527ZM6.00195 7.44335H18.002V6.05672H6.00195V7.44335ZM18.002 7.44335C18.4475 7.44335 18.8086 7.80452 18.8086 8.25004H20.1953C20.1953 7.0387 19.2133 6.05672 18.002 6.05672V7.44335ZM18.8086 8.25004V11.2504H20.1953V8.25004H18.8086ZM5.19527 11.2504V8.25004H3.80864V11.2504H5.19527ZM5.19527 8.25004C5.19527 7.80452 5.55643 7.44335 6.00195 7.44335V6.05672C4.79062 6.05672 3.80864 7.0387 3.80864 8.25004H5.19527ZM15.8086 5.25V7.5H17.1953V5.25H15.8086ZM7.55802 5.25V7.5H8.94465V5.25H7.55802Z" />
  </SvgIcon>
);

export const Queued = (props: any) => (
  <SvgIcon {...props}>
    <path d="M12 5.63636C12.5021 5.63636 12.9091 6.04338 12.9091 6.54545V11.5554L16.649 14.4642C17.0454 14.7725 17.1167 15.3436 16.8085 15.7399C16.5003 16.1363 15.9291 16.2077 15.5328 15.8994L11.4419 12.7176C11.2204 12.5454 11.0909 12.2805 11.0909 12V6.54545C11.0909 6.04338 11.4979 5.63636 12 5.63636Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 3.81818C7.48131 3.81818 3.81818 7.48131 3.81818 12C3.81818 16.5187 7.48131 20.1818 12 20.1818C16.5187 20.1818 20.1818 16.5187 20.1818 12C20.1818 7.48131 16.5187 3.81818 12 3.81818Z"
    />
  </SvgIcon>
);

export const Duplicate = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0H18C19.1523 0 20 0.847715 20 2V12C20 13.1523 19.1523 14 18 14H14V18C14 19.1523 13.1523 20 12 20H2C0.847715 20 0 19.1523 0 18V8C0 6.84772 0.847715 6 2 6H6V2C6 0.847715 6.84772 0 8 0ZM6 8H2V18H12V14H8C6.84772 14 6 13.1523 6 12V8ZM8 2V12H18V2H8Z"
      fill={props.fill || defaultIconColor}
    />
  </SvgIcon>
);
export const TemplateIcon = (props: any) => (
  <SvgIcon width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M2.33203 4.33301C1.8016 4.33301 1.29289 4.12229 0.917818 3.74722C0.542745 3.37215 0.332031 2.86344 0.332031 2.33301C0.332031 1.80257 0.542745 1.29387 0.917818 0.918794C1.29289 0.543722 1.8016 0.333008 2.33203 0.333008C2.86246 0.333008 3.37117 0.543722 3.74624 0.918794C4.12132 1.29387 4.33203 1.80257 4.33203 2.33301C4.33203 2.86344 4.12132 3.37215 3.74624 3.74722C3.37117 4.12229 2.86246 4.33301 2.33203 4.33301ZM11.6654 4.33301C11.1349 4.33301 10.6262 4.12229 10.2512 3.74722C9.87608 3.37215 9.66537 2.86344 9.66537 2.33301C9.66537 1.80257 9.87608 1.29387 10.2512 0.918794C10.6262 0.543722 11.1349 0.333008 11.6654 0.333008C12.1958 0.333008 12.7045 0.543722 13.0796 0.918794C13.4547 1.29387 13.6654 1.80257 13.6654 2.33301C13.6654 2.86344 13.4547 3.37215 13.0796 3.74722C12.7045 4.12229 12.1958 4.33301 11.6654 4.33301ZM11.6654 13.6663C11.1349 13.6663 10.6262 13.4556 10.2512 13.0806C9.87608 12.7055 9.66537 12.1968 9.66537 11.6663C9.66537 11.1359 9.87608 10.6272 10.2512 10.2521C10.6262 9.87706 11.1349 9.66634 11.6654 9.66634C12.1958 9.66634 12.7045 9.87706 13.0796 10.2521C13.4547 10.6272 13.6654 11.1359 13.6654 11.6663C13.6654 12.1968 13.4547 12.7055 13.0796 13.0806C12.7045 13.4556 12.1958 13.6663 11.6654 13.6663ZM2.33203 13.6663C1.8016 13.6663 1.29289 13.4556 0.917818 13.0806C0.542745 12.7055 0.332031 12.1968 0.332031 11.6663C0.332031 11.1359 0.542745 10.6272 0.917818 10.2521C1.29289 9.87706 1.8016 9.66634 2.33203 9.66634C2.86246 9.66634 3.37117 9.87706 3.74624 10.2521C4.12132 10.6272 4.33203 11.1359 4.33203 11.6663C4.33203 12.1968 4.12132 12.7055 3.74624 13.0806C3.37117 13.4556 2.86246 13.6663 2.33203 13.6663ZM4.9987 1.66634H8.9987V2.99967H4.9987V1.66634ZM4.9987 10.9997H8.9987V12.333H4.9987V10.9997ZM1.66536 4.99967H2.9987V8.99967H1.66536V4.99967ZM10.9987 4.99967H12.332V8.99967H10.9987V4.99967Z"
      fill={props.fill || '#1E96FC'}
    />
  </SvgIcon>
);

export const Rename = (props: any) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_3047_158889)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.63462 6.88565L13.7052 6.88565C13.9569 6.88939 14.1943 6.99711 14.367 7.18117C14.5394 7.36484 14.6341 7.60993 14.6341 7.86292C14.6341 8.11592 14.5394 8.36101 14.367 8.54467C14.1943 8.72874 13.9569 8.83643 13.7052 8.84017L13.7015 8.84022L4.88462 8.8402V15.6129H13.7513C14.002 15.6181 14.2381 15.7264 14.4098 15.9103C14.5811 16.0938 14.6751 16.3381 14.6751 16.5902C14.6751 16.8423 14.5811 17.0866 14.4098 17.2701C14.2381 17.454 14.002 17.5623 13.7513 17.5674L13.7462 17.3175V17.5675H4.63462C4.19727 17.5669 3.78031 17.384 3.47499 17.0633C3.17008 16.7429 3.00055 16.3112 3 15.8632L3 8.5902C3.00055 8.14224 3.17008 7.71017 3.47499 7.38986C3.78031 7.06913 4.19727 6.88625 4.63462 6.88565Z"
        fill={props.fill || defaultIconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4C14 4.55228 14.4477 5 15 5H16V19H15C14.4477 19 14 19.4477 14 20C14 20.5523 14.4477 21 15 21H19C19.5523 21 20 20.5523 20 20C20 19.4477 19.5523 19 19 19H18V5H19C19.5523 5 20 4.55228 20 4C20 3.44772 19.5523 3 19 3H15C14.4477 3 14 3.44772 14 4Z"
        fill={props.fill || defaultIconColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_3047_158889">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const Failed = (props: any) => (
  <SvgIcon {...props}>
    <path d="M11.9985 15.8472C11.4394 15.8472 10.9707 16.3159 10.9707 16.8751C10.9707 17.4342 11.4394 17.9029 11.9985 17.9029C12.5371 17.9029 13.0263 17.4342 13.0017 16.8997C13.0263 16.3118 12.5618 15.8472 11.9985 15.8472Z" />
    <path d="M21.5133 19.4905C22.1588 18.3763 22.1629 17.0483 21.5215 15.9383L15.0832 4.78842C14.446 3.66603 13.2948 3 12.0039 3C10.7129 3 9.56175 3.67014 8.92449 4.78431L2.47796 15.9465C1.8366 17.0689 1.84071 18.4051 2.49029 19.5192C3.13166 20.6211 4.27871 21.283 5.56144 21.283H18.4216C19.7085 21.283 20.8637 20.6128 21.5133 19.4905ZM20.1155 18.6846C19.7578 19.3013 19.1247 19.6672 18.4175 19.6672H5.55733C4.85841 19.6672 4.22938 19.3096 3.87992 18.7052C3.52634 18.0926 3.52223 17.3608 3.8758 16.7441L10.3223 5.58601C10.6718 4.97343 11.2967 4.61163 12.0039 4.61163C12.7069 4.61163 13.3359 4.97754 13.6854 5.59013L20.1278 16.7482C20.4732 17.3485 20.4691 18.0721 20.1155 18.6846Z" />
    <path d="M11.7447 8.63229C11.2554 8.77207 10.9512 9.21609 10.9512 9.75468C10.9758 10.0795 10.9964 10.4084 11.0211 10.7332C11.091 11.9707 11.1608 13.1835 11.2307 14.421C11.2554 14.8404 11.5802 15.1446 11.9996 15.1446C12.4189 15.1446 12.7478 14.8198 12.7684 14.3963C12.7684 14.1414 12.7684 13.9071 12.793 13.6481C12.8383 12.8546 12.8876 12.0611 12.9328 11.2676C12.9575 10.7537 13.0027 10.2398 13.0274 9.7259C13.0274 9.54089 13.0027 9.37644 12.9328 9.21198C12.7231 8.75152 12.2339 8.51717 11.7447 8.63229Z" />
  </SvgIcon>
);

export const Reply = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6475 6.55475C18.0986 7.67486 21.6162 11.7092 21.9984 18.4433C22.0583 19.4997 20.6508 19.9134 20.1297 18.9924C18.5333 16.1714 15.736 14.5095 11.6411 14.0123L11.6373 18.5008C11.6365 19.4023 10.537 19.8426 9.91425 19.1908L2.27699 11.1979C1.90767 10.8114 1.90767 10.2028 2.27699 9.81625L9.9279 1.8091C10.5513 1.15671 11.6517 1.59847 11.6509 2.5008L11.6475 6.55475ZM4.38311 10.5071L9.63939 16.0081L9.64203 12.9126C9.64252 12.3334 10.1335 11.8757 10.7113 11.9159C14.3408 12.1681 17.2474 13.1968 19.391 15.0034C18.1835 11.1764 15.2628 9.01618 10.5193 8.40693C10.0201 8.3428 9.64629 7.91758 9.64672 7.41422L9.64878 4.99625L4.38311 10.5071Z"
    />
  </SvgIcon>
);

export const Interested = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.42315 22.1292C7.29326 22.6929 8.32259 22.9916 9.49118 23.0191C12.7406 23.0469 15.1363 23.0469 16.696 23.0192C19.2671 22.9735 20.4142 22.1634 21.0393 20.1238C21.6181 18.2353 22.1902 13.1794 22.0034 10.6399C21.887 9.05764 21.1575 8.16324 19.8234 8.08056C19.363 8.05202 18.8581 8.117 18.1633 8.26859C17.9142 8.32294 17.1639 8.50586 16.6761 8.62477C16.4703 8.67495 16.3112 8.71373 16.2562 8.72665C15.8319 8.8264 15.4494 8.90932 15.0687 8.98236C14.4308 9.10474 13.9812 9.13155 13.7058 9.08356C13.5742 9.06062 13.5554 9.04876 13.5442 9.0302C13.4367 8.85211 13.4284 8.45322 13.5278 7.0634C13.5762 6.38673 13.599 5.94244 13.6032 5.44048C13.6261 2.67191 12.1317 1.36568 9.64481 2.14341C9.28928 2.2546 9.03025 2.56208 8.98062 2.93186C8.6453 5.4298 7.78459 7.61587 6.39359 9.50443C6.05079 8.98365 5.46162 8.63999 4.79232 8.63999H2.87565C1.81711 8.63999 0.958984 9.4996 0.958984 10.56V21.12C0.958984 22.1804 1.81711 23.04 2.87565 23.04H4.79232C5.48128 23.04 6.08533 22.6758 6.42315 22.1292ZM11.616 6.9262C11.6618 6.28673 11.6828 5.87672 11.6866 5.42453C11.697 4.17116 11.4895 3.75616 10.7803 3.84315C10.2324 7.04813 8.87276 9.81293 6.70898 12.1152V19.8524C7.40699 20.6623 8.32638 21.0712 9.52182 21.0994C12.7415 21.1268 15.1228 21.1268 16.662 21.0995C18.4269 21.0681 18.8328 20.7815 19.207 19.5603C19.7161 17.8993 20.2612 13.0823 20.0919 10.781C20.0405 10.0821 19.9852 10.0142 19.7051 9.99689C19.4631 9.9819 19.1066 10.0278 18.5712 10.1446C18.3501 10.1929 17.6533 10.3627 17.1671 10.4812C16.9407 10.5363 16.76 10.5804 16.6941 10.5959C16.2457 10.7013 15.8381 10.7896 15.4292 10.8681C13.6322 11.2129 12.5028 11.016 11.9038 10.0234C11.4855 9.33009 11.4762 8.88154 11.616 6.9262ZM4.79232 20.1785C4.79224 20.1856 4.79224 20.1928 4.79232 20.1999V21.12H2.87565V10.56H4.79232V11.7276C4.79232 11.7275 4.79232 11.7277 4.79232 11.7276V20.1785Z"
    />
  </SvgIcon>
);

export const NotInterested = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.42315 2.44872C7.29326 1.86155 8.32259 1.55039 9.49118 1.52178C12.7406 1.49279 15.1363 1.49279 16.696 1.52166C19.2671 1.56925 20.4142 2.41314 21.0393 4.53772C21.6181 6.50485 22.1902 11.7715 22.0034 14.4167C21.887 16.0649 21.1575 16.9966 19.8234 17.0827C19.363 17.1125 18.8581 17.0448 18.1633 16.8869C17.9142 16.8303 17.1639 16.6397 16.6761 16.5158C16.4703 16.4636 16.3112 16.4232 16.2562 16.4097C15.8319 16.3058 15.4494 16.2194 15.0687 16.1434C14.4308 16.0159 13.9812 15.9879 13.7058 16.0379C13.5742 16.0618 13.5554 16.0742 13.5442 16.0935C13.4367 16.279 13.4284 16.6945 13.5278 18.1423C13.5762 18.8471 13.599 19.3099 13.6032 19.8328C13.6261 22.7167 12.1317 24.0774 9.64481 23.2673C9.28928 23.1514 9.03025 22.8312 8.98062 22.446C8.6453 19.8439 7.78459 17.5668 6.39359 15.5995C6.05079 16.142 5.46162 16.5 4.79232 16.5H2.87565C1.81711 16.5 0.958984 15.6046 0.958984 14.5V3.49999C0.958984 2.39542 1.81711 1.49999 2.87565 1.49999H4.79232C5.48128 1.49999 6.08533 1.87931 6.42315 2.44872ZM11.616 18.2852C11.6618 18.9513 11.6828 19.3784 11.6866 19.8494C11.697 21.155 11.4895 21.5873 10.7803 21.4967C10.2324 18.1582 8.87276 15.2782 6.70898 12.88V4.82043C7.40699 3.97671 8.32638 3.55084 9.52182 3.52147C12.7415 3.49286 15.1228 3.49286 16.662 3.52135C18.4269 3.55402 18.8328 3.85255 19.207 5.1247C19.7161 6.8549 20.2612 11.8726 20.0919 14.2698C20.0405 14.9978 19.9852 15.0685 19.7051 15.0866C19.4631 15.1022 19.1066 15.0544 18.5712 14.9327C18.3501 14.8824 17.6533 14.7055 17.1671 14.5821C16.9407 14.5246 16.76 14.4787 16.6941 14.4626C16.2457 14.3528 15.8381 14.2608 15.4292 14.1791C13.6322 13.8199 12.5028 14.025 11.9038 15.059C11.4855 15.7811 11.4762 16.2484 11.616 18.2852ZM4.79232 4.48071C4.79224 4.47329 4.79224 4.46587 4.79232 4.45845V3.49999H2.87565V14.5H4.79232V13.2837C4.79232 13.2838 4.79232 13.2836 4.79232 13.2837V4.48071Z"
    />
  </SvgIcon>
);

export const CheckCircleIconGreen = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <circle
      cx="10.1703"
      cy="9.99992"
      r="8.87883"
      fill="#0E9C63"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M7.35643 9.23046C6.93602 8.81546 6.2588 8.81985 5.8438 9.24025C5.42881 9.66066 5.43319 10.3379 5.8536 10.7529L8.57143 13.4357C9.01855 13.8771 9.74799 13.8399 10.1479 13.3553L14.5608 8.00723C14.9367 7.55159 14.8721 6.87743 14.4165 6.50146C13.9609 6.1255 13.2867 6.19008 12.9107 6.64572L9.24207 11.0918L7.35643 9.23046Z"
      fill="white"
    />
  </SvgIcon>
);

export const ExclamationWhiteIcon = (props: any) => (
  <SvgIcon {...props}>
    <path
      d="M3.0498 8.92871H0.94043L0.641602 0.0605469H3.34863L3.0498 8.92871ZM1.99512 10.3174C2.42285 10.3174 2.76562 10.4434 3.02344 10.6953C3.28711 10.9473 3.41895 11.2695 3.41895 11.6621C3.41895 12.0488 3.28711 12.3682 3.02344 12.6201C2.76562 12.8721 2.42285 12.998 1.99512 12.998C1.57324 12.998 1.23047 12.8721 0.966797 12.6201C0.708984 12.3682 0.580078 12.0488 0.580078 11.6621C0.580078 11.2754 0.708984 10.9561 0.966797 10.7041C1.23047 10.4463 1.57324 10.3174 1.99512 10.3174Z"
      fill="white"
    />
  </SvgIcon>
);

export const Recipients = (props: any) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0005 12.2851C15.2954 12.2851 17.9664 9.56552 17.9664 6.21073C17.9664 2.85595 15.2954 0.136353 12.0005 0.136353C8.70565 0.136353 6.03462 2.85595 6.03462 6.21073C6.03462 9.56552 8.70565 12.2851 12.0005 12.2851ZM12.0005 9.85536C10.0236 9.85536 8.42099 8.22361 8.42099 6.21073C8.42099 4.19786 10.0236 2.5661 12.0005 2.5661C13.9775 2.5661 15.5801 4.19786 15.5801 6.21073C15.5801 8.22361 13.9775 9.85536 12.0005 9.85536Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0005 13.5C17.2723 13.5 21.546 17.8513 21.546 23.219V25.6487C21.546 26.3197 21.0118 26.8636 20.3528 26.8636H3.64826C2.98928 26.8636 2.45508 26.3197 2.45508 25.6487V23.219C2.45508 17.8513 6.72872 13.5 12.0005 13.5ZM19.1596 24.4339V23.219C19.1596 19.1933 15.9544 15.9297 12.0005 15.9297C8.04668 15.9297 4.84144 19.1933 4.84144 23.219V24.4339H19.1596Z"
    />
  </SvgIcon>
);

export const Sync = (props: any) => (
  <SvgIcon {...props}>
    <path d="M5.01923 8.58931C5.01923 7.52969 5.87161 6.67071 6.92308 6.67071H15.541L14.0891 8.13768C13.8416 8.38776 13.8421 8.79269 14.0903 9.04212C14.3385 9.29155 14.7403 9.29102 14.9878 9.04094L17.4786 6.52433C17.615 6.42524 17.7115 6.23611 17.7115 6.02701C17.7128 5.85916 17.6472 5.69441 17.5251 5.5717L14.9866 3.02022C14.7385 2.77079 14.3366 2.77132 14.0891 3.0214C13.8416 3.27148 13.8422 3.67641 14.0903 3.92584L15.5486 5.39164H6.92308C5.17064 5.39164 3.75 6.82328 3.75 8.58931V14.5583C3.75 14.9115 4.03413 15.1978 4.38462 15.1978C4.7351 15.1978 5.01923 14.9115 5.01923 14.5583V8.58931Z" />
    <path d="M17.0769 17.3296H8.45735L9.91061 15.8629C10.1583 15.613 10.158 15.208 9.90994 14.9585C9.66193 14.7089 9.26011 14.7092 9.01246 14.9591L6.50961 17.4852C6.37761 17.5897 6.28846 17.7722 6.28847 17.9715C6.28772 18.1396 6.35326 18.3034 6.47467 18.4255L9.01313 20.9799C9.26115 21.2294 9.66297 21.2291 9.91061 20.9792C10.1583 20.7292 10.158 20.3243 9.90994 20.0747L8.453 18.6087H17.0769C18.8294 18.6087 20.25 17.177 20.25 15.411V9.44202C20.25 9.08882 19.9659 8.80249 19.6154 8.80249C19.2649 8.80249 18.9808 9.08882 18.9808 9.44202V15.411C18.9808 16.4706 18.1284 17.3296 17.0769 17.3296Z" />
  </SvgIcon>
);
