import { useState } from 'react';
import { SortType } from '../../enums';

interface useSortProps<T> {
  defaultSortBy?: keyof T;
  defaultOrderBy?: SortType;
}

export const useSort = <T,>({
  defaultSortBy = '' as keyof T,
  defaultOrderBy = SortType.ASC
}: useSortProps<T> = {}) => {
  const [sort, setSort] = useState<{
    sortBy: keyof T;
    orderBy: SortType;
  }>({ orderBy: defaultOrderBy, sortBy: defaultSortBy });

  const handleSortChange = (sortBy: keyof T) => {
    setSort({
      sortBy,
      orderBy:
        sort.sortBy === sortBy && sort.orderBy === SortType.ASC
          ? SortType.DESC
          : SortType.ASC
    });
  };

  return {
    sort,
    setSort,
    handleSortChange
  };
};
