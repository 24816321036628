import { AxiosMethods, OutboxApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import { Email } from 'Services/Query/Outbox/Types';

export enum OutboxMutationsKey {
  SEND_EMAIL = 'send_email',
  DELETE_LINK = 'delete_link'
}

export const sendEMailMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: OutboxApi.EmailRecipients,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export const deleteOutboxLinkMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: OutboxApi.DeteleGetLink,
      method: AxiosMethods.DELETE,
      params,
      body
    })
});

export const disableOutlookShareMutation = (id: Email['Id']) => ({
  mutationFn: ({ body }: any) =>
    axiosHelper({
      endpoint: OutboxApi.OutlookDisableLinks.replace(':id', id.toString()),
      method: AxiosMethods.PATCH,
      body
    })
});
