import { Box } from '@material-ui/core';
import SInfiniteScroll from '../../../../sinfinite-scroll';
import { PresentationCard } from '../card';
import { Presentation } from '../../../../../services/react-query/query/presentations/types';
import { PresentationAction } from '../action';

interface PresentationListProps {
  presentations: Presentation[];
  selectedPresentations: Presentation[];
  onSelectPresentation: (
    isChecked: boolean,
    presentation: Presentation[]
  ) => void;
  fetchMorePresentations?: () => void;
}

export const PresentationList = ({
  presentations,
  fetchMorePresentations,
  selectedPresentations,
  onSelectPresentation
}: PresentationListProps) => (
  <Box m={2}>
    <SInfiniteScroll
      list={presentations}
      keyGetter={presentation => presentation.ID}
      fetchMore={fetchMorePresentations}
      render={presentation => (
        <PresentationCard
          presentation={presentation}
          action={
            <PresentationAction
              checked={selectedPresentations.some(
                pres => pres.ID === presentation.ID
              )}
              onSelectPresentation={onSelectPresentation}
              presentation={presentation}
            />
          }
        />
      )}
    />
  </Box>
);
