import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CircularProgress, {
  CircularProgressProps
} from '@material-ui/core/CircularProgress';
import { Backdrop } from '@material-ui/core';

export interface CircularLoaderProps extends CircularProgressProps {
  backdrop?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 20
    },
    top: {
      color: '#1a90ff',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0
    },
    circle: {
      strokeLinecap: 'round'
    },
    backdrop: {
      zIndex: 9999,
      color: theme.palette.common.white,
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute'
    }
  })
);

export function PageLoader({ backdrop = true, ...props }: CircularLoaderProps) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={backdrop}>
      <div className={classes.root}>
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle
          }}
          size={60}
          thickness={6}
          {...props}
        />
      </div>
    </Backdrop>
  );
}
