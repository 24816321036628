import { Typography, Grid, Box, Button } from '@material-ui/core';
import {
  FillBgPlusIcon,
  FillBgStarIcon,
  FillBgArrowIcon,
  FillBgEmailIcon,
  FillBgDocumentIcon,
  FillBgResourceIcon,
  FillBgPresentationIcon
} from '../../../sicons';
import { distances } from '../../../../styles';
import { DrawerContent } from '../../../../enums';
import { NavigationButton } from './button';

import {
  Container,
  ButtonContainer,
  CancelButton,
  useListStyles,
  ListContainer
} from './style';
import { Header } from '../../components/header';

interface ListProps {
  setShowContent(content: DrawerContent | null): void;
  handleClose(): void;
  options?: DrawerContent[];
}

const DEFAULT_OPTIONS = [
  DrawerContent.favorites,
  DrawerContent.resources,
  DrawerContent.myResources,
  DrawerContent.presentations,
  DrawerContent.uploadFile,
  DrawerContent.webLink,
  DrawerContent.snippet
];

// TODO: move to components and rename root foler to main navigation
export const MainNavigation = ({
  setShowContent,
  handleClose,
  options = DEFAULT_OPTIONS
}: ListProps) => {
  const classes = useListStyles();

  const actions = {
    [DrawerContent.favorites]: {
      text: 'My Favorites',
      icon: <FillBgStarIcon viewBox="0 0 22 23" htmlColor="#1E96FC" />,
      onClick: () => setShowContent(DrawerContent.favorites)
    },
    [DrawerContent.resources]: {
      text: 'Resource(s)',
      icon: <FillBgDocumentIcon viewBox="0 0 22 23" />,
      onClick: () => setShowContent(DrawerContent.resources)
    },
    [DrawerContent.myResources]: {
      text: 'My File(s)',
      icon: <FillBgResourceIcon viewBox="0 0 22 23" />,
      onClick: () => setShowContent(DrawerContent.myResources)
    },
    [DrawerContent.presentations]: {
      text: 'Presentation',
      icon: <FillBgPresentationIcon viewBox="0 0 22 23" />,
      onClick: () => setShowContent(DrawerContent.presentations)
    },
    [DrawerContent.templates]: {
      text: 'Templates',
      icon: <FillBgPresentationIcon viewBox="0 0 22 23" />,
      onClick: () => setShowContent(DrawerContent.templates)
    },
    [DrawerContent.uploadFile]: {
      text: 'Upload File',
      icon: <FillBgArrowIcon viewBox="0 0 22 23" htmlColor="#1E96FC" />,
      onClick: () => setShowContent(DrawerContent.uploadFile)
    },
    [DrawerContent.webLink]: {
      text: 'Web Link',
      icon: <FillBgPlusIcon viewBox="0 0 22 23" />,
      onClick: () => setShowContent(DrawerContent.webLink)
    },
    [DrawerContent.snippet]: {
      text: 'Snippets',
      icon: <FillBgEmailIcon viewBox="0 0 22 23" />,
      onClick: () => setShowContent(DrawerContent.snippet)
    }
  };

  return (
    <Box>
      <Header title="Add from SETVI" handleClose={handleClose} />
      <Container>
        <ListContainer>
          <Grid container>
            <Grid item xs={12}>
              <Box
                padding={`${distances.px.medium} ${distances.px.medium} ${0}`}>
                <Typography variant="body2" className={classes.topTxt}>
                  Please click the button below to insert resources and
                  presentations.
                </Typography>
              </Box>
            </Grid>

            {options.indexOf(DrawerContent.favorites) !== -1 && (
              <Grid item xs={12} className={classes.grid}>
                <Box px={distances.px.medium} width="100%">
                  <Button
                    onClick={actions[DrawerContent.favorites].onClick}
                    variant="outlined"
                    fullWidth
                    className={`${classes.button} ${classes.favButton}`}>
                    <Box
                      pl={distances.px.large}
                      className={classes.container}
                      width="100%">
                      {actions[DrawerContent.favorites].icon}
                      <Box pl={distances.px.large}>
                        <Typography variant="body2" className={classes.text}>
                          {actions[DrawerContent.favorites].text}
                        </Typography>
                      </Box>
                    </Box>
                  </Button>
                </Box>
              </Grid>
            )}

            <Grid item xs={12} className={classes.grid}>
              <Grid container className={classes.buttonContainer}>
                {options
                  .filter(option => option !== DrawerContent.favorites)
                  .map(option => (
                    <Grid key={option} className={classes.grid} item xs={6}>
                      <NavigationButton
                        label={actions[option as keyof typeof actions].text}
                        icon={actions[option as keyof typeof actions].icon}
                        onClick={
                          actions[option as keyof typeof actions].onClick
                        }
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </ListContainer>

        <ButtonContainer>
          <CancelButton
            type="button"
            variant="outlined"
            onClick={handleClose}
            fullWidth>
            Cancel
          </CancelButton>
        </ButtonContainer>
      </Container>
    </Box>
  );
};
