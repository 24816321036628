import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  },
  fadeIn: {
    opacity: 1,
    animationName: 'fadeInOpacity',
    animationIterationCount: 1,
    animationTimingFunction: 'ease-in',
    animationDuration: '1s'
  },
  '@keyframes fadeInOpacity': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  }
}));

export const PageLoader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <img
        className={classes.fadeIn}
        width="15%"
        src="https://euhd1msp.blob.core.windows.net/public/Setvi/Logos/SVG/Reversed/SETVI-Horizontal-icon-RGB-FullColor-Transparent.svg"
        alt="setvi-logo"
      />
    </Box>
  );
};
