import { ReactNode, useRef } from 'react';
import { IconButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';

import CloseIcon from '@material-ui/icons/Close';
import { distances } from '@setvi/shared/styles';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  borderRadius: '8px',
  boxShadow: 'none',
  color: '#171725',
  maxWidth: '70vw !important',
  // Close button
  '& div:nth-child(2)': {
    paddingLeft: distances.px.small,

    '& button': {
      padding: distances.px.small,

      '& span svg': {
        color: '#696974',
        fill: '#696974',
        fontSize: '16px'
      }
    }
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: '#D6EBFE',
    border: '1px solid #98CEFD',
    '& #notistack-snackbar > svg': {
      color: '#1E96FC'
    }
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: '#DEF8EB',
    border: '1px solid #9BEAC3',
    '& #notistack-snackbar > svg': {
      color: '#23A566'
    }
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: '#FFF2E3',
    border: '1px solid #FFD9AB',
    '& #notistack-snackbar > svg': {
      color: '#FF9F29'
    }
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#FFE9E9',
    border: '1px solid #FEBEBE',
    '& #notistack-snackbar > svg': {
      color: '#FC5A5A'
    }
  }
}));

interface CustomSnackbarProps {
  children: ReactNode;
}

const CustomSnackbarProvider = ({ children }: CustomSnackbarProps) => {
  const notistackRef = useRef<SnackbarProvider>(null);

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      autoHideDuration={4000}
      action={key => (
        <IconButton onClick={() => notistackRef.current?.closeSnackbar(key)}>
          <CloseIcon htmlColor="black" />
        </IconButton>
      )}
      Components={{
        info: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent
      }}>
      {children}
    </SnackbarProvider>
  );
};

export default CustomSnackbarProvider;
