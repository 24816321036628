import { useContext, createContext } from 'react';

import { RESOURCE_UPLOAD_STATUS } from '@setvi/shared/enums';

export interface UploadResourcePanelProps {
  resources: {
    id: number;
    file: File;
    status: RESOURCE_UPLOAD_STATUS;
  }[];
}

export type UploadResourcePanelContextType = {
  open: ({ resources }: UploadResourcePanelProps) => void;
  close: () => void;
  setOffset?: (offset: number) => void;
};

const contextDefaultValues: UploadResourcePanelContextType = {
  open: () => {},
  close: () => {},
  setOffset: () => {}
};

export const UploadResourcePanelContext =
  createContext<UploadResourcePanelContextType>(contextDefaultValues);

export const useUploadResourcePanel = () => {
  const context = useContext<UploadResourcePanelContextType>(
    UploadResourcePanelContext
  );

  // if `undefined`, throw an error
  if (context === undefined)
    throw new Error('useUploadResourcePanel was used outside of its Provider');

  return context;
};
