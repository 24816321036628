import platform from 'platform';
import { Editor } from '@tinymce/tinymce-react';
import { Box, makeStyles } from '@material-ui/core';

import { style } from './style';
import { useImages } from './hooks';
import { MainEditorProps, SEditorType } from './interfaces';
import { plugins, toolbar, fontSizes, fontFamily } from './components/options';
import { Browsers } from '../../enums';

const useStyles = makeStyles({
  root: {
    '& .tox-tinymce': {
      visibility: 'visible !important'
    },
    '& .tox-editor-header': {
      boxShadow: 'none !important',
      padding: '0 !important'
    },
    '& .tox .tox-toolbar-overlord>.tox-toolbar, .tox .tox-toolbar-overlord>.tox-toolbar__overflow, .tox .tox-toolbar-overlord>.tox-toolbar__primary':
      {
        backgroundSize: '100% !important'
      },
    '& .tox-toolbar-overlord': {
      borderBottom: '1px solid #e2e2ea'
    }
  }
});

const HEIGHT = 400;
const ID = `editor-${new Date().getTime()}`;

const SetviEditor = ({
  id = ID,
  height = HEIGHT,
  setups,
  content,
  multiline = true,
  helperText,
  initOptions,
  customClass,
  initialValue,

  setEditorNode,
  onEditorClick,
  handleEditorChange
}: MainEditorProps) => {
  const classes = useStyles();
  const { handlePastedImages, uploadLocalImage, deleteImage } = useImages();

  const elem = document.querySelectorAll(`#${id}`);

  return (
    <Box className={`${classes.root} ${customClass}`}>
      {!elem?.length ? <Box height={height} width="100%" /> : null}

      <Editor
        tinymceScriptSrc="/vendors/tinymce/tinymce.min.js"
        onInit={(_evt, editor) => {
          setEditorNode?.(editor);
        }}
        id={id}
        value={content}
        initialValue={initialValue}
        onEditorChange={(cleanHtml: string, editorTMCE: SEditorType) =>
          handleEditorChange(
            cleanHtml,
            editorTMCE.getContent({ format: 'raw' }),
            editorTMCE
          )
        }
        init={{
          height,
          plugins,
          toolbar: initOptions?.toolbar || toolbar,
          menubar: false,
          statusbar: false,
          relative_urls: false,
          remove_script_host: true,
          paste_merge_formats: true,
          paste_data_images: true,
          content_style: initOptions?.content_style || style,
          spellchecker_language: 'en_US',
          font_size_formats: fontSizes,
          font_family_formats: fontFamily,
          force_br_newlines: false,
          invalid_elements: 'pre',
          forced_root_block: 'p',
          toolbar_mode: 'sliding',
          images_file_types: 'jpg,svg,webp,png,jpeg,gif',
          link_default_target: '_blank',
          entity_encoding: 'raw',
          contextmenu: ['variableMenu'],
          ...initOptions,
          setup: editor => {
            editor.on('PastePostProcess', e => {
              // ? TinyMCE has bug on safari when pasting content with mouse (right click and paste). This is a current workaround to fix it.
              if (
                platform.name === Browsers.Safari &&
                e?.node?.nodeName !== 'IMG'
              ) {
                e?.preventDefault();
                editor.execCommand('InsertLineBreak');

                editor.execCommand(
                  'mceInsertContent',
                  false,
                  `<p>${e?.node?.innerHTML}</p>`
                );
              }

              handlePastedImages(e, editor);
            });

            editor?.on('click', e => {
              onEditorClick?.(editor);
              const node = e?.target;
              if (
                node.nodeName === 'A' &&
                node?.hasAttribute('href') &&
                !node?.hasAttribute('id') &&
                !node.hasAttribute('data-preview-href')
              )
                window.open(node.href, '_blank');
            });

            editor?.ui.registry.addContextToolbar('imagetoolbar', {
              predicate: node => node.nodeName === 'IMG',
              items: 'alignleft aligncenter alignright',
              position: 'node',
              scope: 'node'
            });

            editor?.ui.registry.addButton('localimage', {
              icon: 'image',
              tooltip: 'Attach Image',
              onAction: () => uploadLocalImage(editor)
            });

            editor?.on('keydown', e => {
              if (!multiline && e.keyCode === 13) e.preventDefault(); // Prevent the Enter key from creating new lines

              deleteImage(e, editor);
            });

            setups?.forEach(setup => {
              setup?.setup?.(editor);
            });
          }
        }}
      />

      {Boolean(helperText) && helperText}
    </Box>
  );
};

export default SetviEditor;
