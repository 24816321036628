export enum CampaignType {
  None = 0,
  ExistingCustomers = 1,
  Prospect = 2
}

export enum CampaignDefaultValues {
  Name = 'Campaign name',
  Description = 'Description'
}

export enum CampaignFormLimit {
  Name = 100,
  Description = 3000,
  Subject = 255,
  Body = 10000,
  RequiredReadingForm = 3000,
  RecipientsLimit = 250
}

export enum CampaignStatus {
  None = 0,
  Draft = 1,
  ReadyForSending = 2,
  Sent = 5,
  Archived = 6
}

export enum CampaignTabStatus {
  Draft = 0,
  ReadyForSending = 1,
  Sent = 2,
  Archived = 3
}

export enum EditorType {
  Body = 1,
  Subject = 2
}

export enum LinkObjectType {
  Resources = 1,
  Presentation = 2,
  ResourceChild = 3
}

export enum CampaignMenuItem {
  Delete = 'delete',
  MoreInfo = 'moreinfo',
  Archive = 'archive',
  Unarchive = 'unarchive',
  EmailStatistics = 'emailStatistics',
  StopEmailQueue = 'stopEmailQueue',
  DisableLinks = 'disableLinks'
}

export enum RecipientMenuItem {
  Interested = 'interested',
  NotInterested = 'notInterested',
  MoreInfo = 'moreInfo',
  StopEmailQueue = 'stopEmailQueue',
  Email = 'email',
  DisableLinks = 'disableLinks'
}
