import { useCallback, useState } from 'react';
import { Box } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { Header } from '../../components/header';
import { Subheader } from '../../components/subheader';
import { SSearchInput } from '../../../sforms';
import { ResourceList } from '../../components/resource/list';
import { useFavorites } from './useFavorites';
import { SEmptyState } from '../../..';
import { DrawerLoader } from '../../components/loader';
import { useSelectResources } from '../../hooks/useSelectResources';
import { Link } from '../../../../interfaces';
import { getLinkItemFromResource } from '../../../../utils';
import { SelectedResourcesPreview } from '../../components/selected-resources-preview';
import { LinkForm } from '../../components/link-form';
import { ConfirmSelection } from '../../components/confirm-selection';

interface FavoritesProps {
  handleBack: () => void;
  handleClose: () => void;
  handleInsert: (link: Link) => void;
  useLinkForm: boolean;
  hideBackButton?: boolean;
}

export const Favorites = ({
  handleClose,
  handleBack,
  handleInsert,
  useLinkForm,
  hideBackButton
}: FavoritesProps) => {
  const {
    setSearchTerm,
    searchTerm,
    resources,
    fetchMoreFavorites,
    isLoading
  } = useFavorites();

  const [linkName, setLinkName] = useState<string>('');

  const { selectedResources, handleSelectResources, handleSelectPages } =
    useSelectResources();

  const clearSelected = useCallback(() => {
    handleSelectResources(false, selectedResources);
  }, [handleSelectResources, selectedResources]);

  const handleInsertLink = useCallback(
    (name: string) => {
      handleInsert({
        Name: name,
        Placeholder: uuidv4(),
        Item: {
          Items: selectedResources.map(item => getLinkItemFromResource(item))
        }
      });
      clearSelected();
    },
    [handleInsert, selectedResources, clearSelected]
  );

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Add from SETVI" handleClose={handleClose} />
      <Subheader text="Favorites" handleBack={!hideBackButton && handleBack} />
      <Box m={2}>
        <SSearchInput onChange={setSearchTerm} defaultValue={searchTerm} />
      </Box>
      <Box overflow="auto">
        <ResourceList
          resources={resources}
          fetchMoreResources={fetchMoreFavorites}
          onSelect={handleSelectResources}
          onSelectPages={handleSelectPages}
          selectedResources={selectedResources}
          linkForm={useLinkForm}
        />
        <Box>
          {isLoading && <DrawerLoader />}
          {!isLoading && resources.length === 0 && (
            <SEmptyState
              title="No Favorites Available"
              imageSrc="/images/no-favorites.png"
            />
          )}
        </Box>
      </Box>
      <Box mt="auto">
        {selectedResources.length > 0 && (
          <SelectedResourcesPreview
            resources={selectedResources}
            onRemoveResource={resource =>
              handleSelectResources(false, [resource])
            }
            customAction={
              useLinkForm ? (
                <LinkForm
                  initialValue={linkName}
                  handleClose={clearSelected}
                  handleSubmit={handleInsertLink}
                  handleOnBlur={setLinkName}
                />
              ) : (
                <ConfirmSelection
                  onConfirm={() => handleInsertLink(linkName)}
                  onCancel={clearSelected}
                />
              )
            }
          />
        )}
      </Box>
    </Box>
  );
};
