import { useEffect, useRef } from 'react';

interface OpenProps {
  url: string;
  onPopupClose(): void;
  popupProperties: string;
}

export const usePopupWindow = () => {
  const timer = useRef<any>();

  const open = ({ url, popupProperties, onPopupClose }: OpenProps) => {
    if (timer.current) clearInterval(timer.current);

    const windowPopup = window.open(url, 'popup', popupProperties);

    if (!windowPopup)
      // eslint-disable-next-line no-alert
      return alert(
        `The window wasn't allowed to open. This is likely caused by blocking popup.`
      );

    timer.current = setInterval(() => {
      if (windowPopup?.closed) {
        clearInterval(timer.current);
        onPopupClose();
      }
    }, 1000);
  };

  useEffect(() => () => clearInterval(timer.current), []);

  return { open };
};
