import {
  QueryFunctionContext,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';
import { AxiosMethods, ContactsApi, GroupsApi } from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';
import {
  GetContactsQuery,
  GetGroupContactsQuery,
  GetGroupsQuery,
  GetRecentContactsQuery
} from './types';

export enum ContactsQueryKey {
  CONTACTS = 'contacts',
  GROUPS = 'groups',
  GROUP_CONTACTS = 'groupContacts',
  RECENT_CONTACTS = 'recentContacts'
}

const dynamicId = ':groupId';

interface getContactsQueryParams {
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  sortBy: string;
  search: string;
}

export const getContactsQuery = (
  params: getContactsQueryParams
): UseInfiniteQueryOptions<GetContactsQuery> => ({
  queryKey: [ContactsQueryKey.CONTACTS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ContactsApi.Contacts,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (
    lastPage: GetContactsQuery,
    allPages: GetContactsQuery[]
  ) => {
    const total = allPages?.reduce((acc, cur) => acc + cur?.Result?.length, 0);
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Result?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

interface getGroupsQueryParams {
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  sortBy: string;
  search: string;
}

export const getGroupsQuery = (
  params: getGroupsQueryParams
): UseInfiniteQueryOptions<GetGroupsQuery> => ({
  queryKey: [ContactsQueryKey.GROUPS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: GroupsApi.Groups,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (lastPage: GetGroupsQuery, allPages: GetGroupsQuery[]) => {
    const total = allPages?.reduce((acc, cur) => acc + cur?.Result?.length, 0);
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Result?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

interface getGroupContactsQueryParams {
  groupId: number;
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  sortBy: string;
  search: string;
}

export const getGroupsContactsQuery = (
  params: getGroupContactsQueryParams
): UseInfiniteQueryOptions<GetGroupContactsQuery> => ({
  queryKey: [ContactsQueryKey.GROUP_CONTACTS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: GroupsApi.GroupContacts.replace(
        dynamicId,
        params.groupId.toString()
      ),
      method: AxiosMethods.GET,
      params: {
        pageSize: params.pageSize,
        pageNumber: data.pageParam || params.pageNumber,
        orderBy: params.orderBy,
        sortBy: params.sortBy,
        search: params.search
      }
    }),
  getNextPageParam: (
    lastPage: GetGroupContactsQuery,
    allPages: GetGroupContactsQuery[]
  ) => {
    const total = allPages?.reduce((acc, cur) => acc + cur?.Result?.length, 0);
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Result?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

interface getRecentContactsQueryParams {
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  orderBy: string;
  search: string;
  sampleSize?: number;
}

export const getRecentContacts = (
  params: getRecentContactsQueryParams
): UseInfiniteQueryOptions<GetRecentContactsQuery> => ({
  queryKey: [ContactsQueryKey.RECENT_CONTACTS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ContactsApi.RecentContacts,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (
    lastPage: GetRecentContactsQuery,
    allPages: GetRecentContactsQuery[]
  ) => {
    const total = allPages?.reduce((acc, cur) => acc + cur?.Result?.length, 0);
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Result?.length < params.pageSize ||
      total === lastPage.Count
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});
