// @ts-nocheck
import { useEffect, useState } from 'react';

interface SQrveyClientProps {
  settings: any;
}

const SQrveyClient = ({ settings }: SQrveyClientProps) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (settings) {
      window.qrveyConfig = settings;
      setInitialized(true);
    }
  }, [settings]);

  useEffect(() => {
    if (initialized) runEndUser();
  }, [initialized]);

  return initialized && <qrvey-end-user settings="qrveyConfig" />;
};

export default SQrveyClient;
