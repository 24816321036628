import { useCallback } from 'react';
import { generateLinkViewerUrl } from '@setvi/shared/utils/viewer';
import { Link } from '@setvi/shared/interfaces';
import { getPreviewLink } from '@setvi/shared/utils';
import { Subheader } from '../../../components/subheader';
import { ReviewLinkMenu } from '../menu';

interface EditListSubheaderProps {
  handleBack: () => void;
  handleRemoveLink(link?: Link): void;
  link: Link;
}

export const EditListSubheader = ({
  handleBack,
  handleRemoveLink,
  link
}: EditListSubheaderProps) => {
  const onPreview = useCallback(
    () =>
      window
        .open(generateLinkViewerUrl(getPreviewLink(link)), '_blank')
        .focus(),
    [link]
  );

  const onDelete = useCallback(() => {
    handleRemoveLink(link);
    handleBack();
  }, [handleRemoveLink, handleBack, link]);

  return (
    <Subheader
      handleBack={handleBack}
      text={link?.Name}
      customAction={
        <ReviewLinkMenu onPreview={onPreview} onDelete={onDelete} />
      }
    />
  );
};
