import {
  Box,
  ListItem,
  ListItemIcon,
  makeStyles,
  Theme
} from '@material-ui/core';
import {
  CustomMenuVariant,
  MenuItemProps
} from 'Layouts/Main/Sidebar/MenuItems';

const useStyles = makeStyles<Theme, { customVariant: CustomMenuVariant }>(
  ({ spacing, palette }) => ({
    root: {
      fontWeight: 500,
      padding: spacing(3, 2),
      fontSize: ({ customVariant }) =>
        customVariant === CustomMenuVariant.Collapsed ? 12 : 14,
      flexDirection: ({ customVariant }) =>
        customVariant === CustomMenuVariant.Collapsed ? 'column' : 'row',
      '& .MuiListItemIcon-root': {
        minWidth: ({ customVariant }) =>
          customVariant === CustomMenuVariant.Collapsed ? 'unset' : '45px',
        marginBottom: ({ customVariant }) =>
          customVariant === CustomMenuVariant.Collapsed ? 3 : 'unset',
        color: '#92929D'
      }
    },
    selected: {
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        boxShadow: `inset 3px 0 0 0 ${palette.primary.main}`,
        color: palette.primary.main
      },
      '& .MuiListItemIcon-root': {
        color: palette.primary.main
      }
    }
  })
);

interface ItemProps extends MenuItemProps {
  collapsedDrawer: boolean;
  selected?: boolean;
  onClick?: () => void;
}

export const Item = ({
  title,
  icon,
  collapsedDrawer,
  selected,
  onClick
}: ItemProps) => {
  const classes = useStyles({
    customVariant: collapsedDrawer
      ? CustomMenuVariant.Collapsed
      : CustomMenuVariant.Extended
  });

  return (
    <ListItem
      classes={classes}
      selected={selected}
      button
      onClick={() => onClick?.()}>
      <ListItemIcon>{icon && icon}</ListItemIcon>
      <Box whiteSpace="break-spaces" textAlign="center">
        {title}
      </Box>
    </ListItem>
  );
};
