import { useState } from 'react';
import { SButton, SInput } from '@setvi/shared/components';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useBookmarkStyles } from '../style';

interface CreateProps {
  handleConfirm: (name: string) => void;
  loading: boolean;
  defaultValue: string;
  edit: boolean;
}

const validationSchema = Yup.object({
  BookmarkName: Yup.string()
    .required('Bookmark Name is required')
    .max(255, 'Bookmark Name should have fewer than 255 characters.')
});

export const BookmarkForm = ({
  handleConfirm,
  loading,
  edit,
  defaultValue = ''
}: CreateProps) => {
  const [input] = useState({
    name: 'BookmarkName',
    labelTitle: 'Bookmark Name',
    placeholder: 'Enter reference'
  });
  const classes = useBookmarkStyles();

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      BookmarkName: defaultValue
    },
    validationSchema,
    onSubmit: async values => {
      handleConfirm(values.BookmarkName);
    }
  });

  const fieldError = form.errors[input.name as keyof typeof form.errors];
  const fieldValue = form.values[input.name as keyof typeof form.values];
  const fieldTouched = form.touched[input.name as keyof typeof form.values];

  return (
    <form onSubmit={form.handleSubmit}>
      <SInput
        label={input.labelTitle}
        placeholder={input.placeholder}
        id={input.name}
        name={input.name}
        value={fieldValue}
        required
        error={!!fieldError && fieldTouched}
        helperText={fieldTouched && fieldError}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        fullWidth
      />
      <SButton
        fullWidth
        loading={loading}
        className={classes.button}
        type="submit">
        {edit ? 'Edit Bookmark' : 'Create Bookmark'}
      </SButton>
    </form>
  );
};
