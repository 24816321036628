export enum CategoryApi {
  GetCategory = '/api/v1.0/categories',
  CreateUserCategory = '/WebAPI/CreateUserCategory',
  UpdateUserCategory = '/WebAPI/UpdateUserCategory',
  DeleteUserCategory = '/WebAPI/DeleteUserResourceCategory',
  GetUserCategories = '/api/v1.0/categories/user',
  GetBreadcrumbs = '/api/v1.0/categories/breadcrumbs',
  GetCompanyCategories = '/api/v1.0/categories/company'
}

export enum AdminCategoryApi {
  GetCategories = '/api/v2/admin/ResourceCategory/:classId/categorytree/:id',
  GetCategoryDetails = '/api/v2/admin/ResourceCategory/:id/details',
  GetGroupCategories = '/api/v2/admin/ResourceCategory/1/group/:id/categorytree',
  UpdateCategoryParent = '/api/v2/admin/ResourceCategory/changeCategoryLocation',
  DuplicateCategory = '/api/v2/admin/ResourceCategory/1/copy/:id',
  CreateCategory = 'api/v2/admin/ResourceCategory',
  UpdateCategory = 'api/v2/admin/ResourceCategory',
  UpdateCategoryPermissions = '/api/v2/admin/ResourceCategory/updatePermissions',
  UpdateCategoryDetails = '/api/v2/admin/ResourceCategory/updateDetails',
  PublicCategory = '/api/v2/admin/ResourceCategory/public',
  DeleteCategory = '/api/v2/admin/ResourceCategory/:id',
  UpdateResourceCategoryStatus = '/api/v2/admin/ResourceCategory/:id/setResourcesStatus/:statusId',
  DeleteResourceCategory = '/api/v2/admin/ResourceCategory/:id/deleteCategoryWithSubcategories',
  RemoveThumbnailResourceCategory = '/api/v2/admin/ResourceCategory/:id/resetThumbnail',
  GetCategoryChildren = '/api/v2/admin/ResourceCategory/:id/categoryChildCategories'
}
