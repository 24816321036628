import { makeStyles, Theme } from '@material-ui/core';

export const useBreadcrumbsClasses = makeStyles<Theme>(() => ({
  root: {
    lineHeight: '18px',
    '& p': { fontSize: 14, cursor: 'pointer', fontWeight: 500 },
    '& p:hover': { textDecoration: 'underline' },

    '& .MuiBreadcrumbs-separator': {
      marginLeft: 4,
      marginRight: 4
    }
  }
}));
