import { Field, FieldProps } from 'formik';

import SDatePicker, { SDatePickerProps } from '../../sdate';

interface SFormikDatepickerProps extends Omit<SDatePickerProps, 'value'> {
  customErrors?: any;
  value?: Date;
}

const SFormikDatepicker = ({
  id,
  name,
  customErrors,
  onChange,
  ...rest
}: SFormikDatepickerProps) => (
  <Field name={name}>
    {({ field, meta, form }: FieldProps) => (
      <SDatePicker
        id={id}
        name={field.name}
        value={meta.value}
        helperText={meta?.error || customErrors?.[name]}
        error={
          (meta.touched && Boolean(meta.error)) || Boolean(customErrors?.[name])
        }
        onChange={(value: Date) => {
          form.setFieldValue(name, value);
          onChange?.(value);
        }}
        {...rest}
      />
    )}
  </Field>
);

export default SFormikDatepicker;
