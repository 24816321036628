import { createTheme } from '@material-ui/core/styles';

const spacing = [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80];

export const companyTheme = createTheme({
  spacing,
  palette: {
    primary: {
      main: '#D1343E'
    },
    text: {
      primary: '#323B4B',
      secondary: '#B5B5BE'
    },
    action: {
      disabledBackground: 'rgba(209, 52, 62, 0.5)',
      disabled: 'rgba(255, 255, 255, 1)'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        height: 56,
        borderRadius: '4px'
      },
      fullWidth: {
        fontSize: 16,
        fontWeight: 700
      }
    },
    MuiInputBase: {
      root: {
        color: 'black'
      }
    }
  }
});

export const setviTheme = createTheme({
  spacing,
  palette: {
    primary: {
      main: '#1E96FC'
    },
    text: {
      primary: '#323B4B',
      secondary: '#B5B5BE'
    },
    action: {
      disabledBackground: 'rgba(209, 52, 62, 0.5)',
      disabled: 'rgba(255, 255, 255, 1)'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        height: 56,
        borderRadius: '4px'
      },
      fullWidth: {
        fontSize: 16,
        fontWeight: 700
      }
    },
    MuiInputBase: {
      root: {
        color: 'black'
      }
    }
  }
});
