import { Box, List, withStyles } from '@material-ui/core';

import { FilestackUploadItemProps } from '../../../../sitems/upload-resource/filestack';
import { FilestackUploadItem } from '../../../../sitems';
import { SConfirmDialog } from '../../../../sdialogs';
import { useDialog } from '../../../../../providers';

export const StyledList = withStyles(({ spacing }) => ({
  root: {
    width: '100%',
    marginTop: spacing(1)
  }
}))(List);

interface PanelProps {
  userId: number | null;
  uploadedResources: FilestackUploadItemProps[];
  onUploadFinished?: (resourceId: number, filestackId: string) => void;
  onUploadCancel?: (resourceId: number) => void;
}

const UploadPanel = ({
  onUploadCancel,
  onUploadFinished,
  uploadedResources,
  userId
}: PanelProps) => {
  const { openDialog, closeDialog } = useDialog();

  const triggerCancel = (resourceId: number, handleUpload?: () => void) => {
    openDialog(
      <SConfirmDialog
        type="warning"
        title="Close Upload"
        description="Are you sure you want to close upload?"
        additionalMessage="The action will CANCEL the upload"
        onClose={() => {
          closeDialog(resourceId);
          handleUpload?.();
        }}
        onConfirm={() => {
          closeDialog(resourceId);
          onUploadCancel(resourceId);
        }}
        confirmText="Yes, cancel"
        closeText="No, continue"
      />,
      {
        id: resourceId
      }
    );
  };

  return (
    <Box maxHeight="100%" overflow="auto">
      <StyledList>
        {uploadedResources.map(resource => (
          <FilestackUploadItem
            userId={userId?.toString()}
            key={resource.id}
            id={resource?.id}
            file={resource.file}
            error={resource.error}
            status={resource.status}
            unzipedTotal={resource.unzipedTotal}
            unzipedProcesed={resource.unzipedProcesed}
            unzippedResources={resource.unzippedResources}
            onUploadCancel={triggerCancel}
            onUploadFinished={onUploadFinished}
          />
        ))}
      </StyledList>
    </Box>
  );
};

export default UploadPanel;
