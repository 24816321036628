import { forwardRef, useMemo } from 'react';
import { FormHelperText } from '@material-ui/core';

import { DatePickerProps } from 'rsuite/esm/DatePicker';
import { DatePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

import { CalendarIcon } from '../../sicons';
import { DateRange } from '../../../enums';
import SLabel from '../../sui/slabel';
import { DateWrapper } from './style';

/* ********************************************************************************  
 ---- reference ---- https://rsuitejs.com/components/date-picker/#date-picker 
******************************************************************************** */
export interface SDatePickerProps extends Omit<DatePickerProps, 'onChange'> {
  value: Date;
  error?: boolean;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  required?: boolean;
  helperText?: string;

  onChange?: (value: Date) => void;
}

export const CustomDateIcon = forwardRef(props => (
  <CalendarIcon style={{ width: 20, height: 20 }} {...props} />
));

const SDatePicker = ({
  id = `s-datepicker-${new Date()?.getTime()}`,
  name,
  size = 'lg',
  label,
  value,
  error,
  format = 'MM/dd/yyyy',
  minDate = new Date(DateRange.MIN),
  maxDate = new Date(DateRange.MAX),
  disabled,
  required = false,
  placement = 'autoVerticalEnd',
  helperText,
  placeholder,

  onChange,
  ...rest
}: SDatePickerProps) => {
  // This is patch for minDate because in some ocassion, the minDate is not set properly inside component
  // and it block todays date
  const minDateValue = useMemo(() => {
    const t = new Date(minDate);
    t.setHours(0, 0, 0, 0);
    return t;
  }, [minDate]);

  return (
    <DateWrapper id={id}>
      {label ? (
        <SLabel title={label} variant="body2" required={required} />
      ) : null}

      <DatePicker
        oneTap
        block
        size={size}
        name={name}
        value={value}
        format={format}
        disabled={disabled}
        placement={placement}
        caretAs={CustomDateIcon}
        placeholder={placeholder}
        shouldDisableDate={(date: Date) =>
          (minDateValue && date < minDateValue) || (maxDate && date > maxDate)
        }
        container={() => document?.getElementById(id)}
        onChange={onChange}
        style={{
          width: '100%'
        }}
        {...rest}
      />

      <FormHelperText error={error}>
        <b>{helperText}</b>
      </FormHelperText>
    </DateWrapper>
  );
};

export default SDatePicker;
