import { useTheme } from '@material-ui/core/styles';

export type EColors = 'primary' | 'regular' | 'danger';

interface IColorsProps {
  color: EColors;
}

export const useColors = ({ color }: IColorsProps) => {
  const { palette } = useTheme();

  const COLORS = {
    primary: palette.primary.main,
    regular: palette.text.secondary,
    danger: palette.error.main
  };

  return {
    newColor: COLORS?.[color] || COLORS.regular
  };
};
