import { Box, makeStyles } from '@material-ui/core';
import EmptySearchIcon from 'Components/Shared/Icons/EmptySearchIcon';
import { Text } from 'Components/Shared/UI/Text';

export const useStyles = makeStyles({
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textContainer: {
    width: 185,
    overflow: 'hidden',
    textAlign: 'center',
    color: '#B0BACC',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '1.5rem'
  },
  errorImage: {
    width: '100%'
  }
});

export const EmptySearchResults = () => {
  const classes = useStyles();
  return (
    <Box height="100%" className={classes.center}>
      <Box my={2} className={`${classes.center} ${classes.errorImage}`}>
        <EmptySearchIcon />
        <Text color="#B6BFCC" weight="medium">
          No Files Found
        </Text>
        <Box className={classes.textContainer}>
          <p>
            Don't see what you're looking for? try updating your search criteria
          </p>
        </Box>
      </Box>
    </Box>
  );
};
