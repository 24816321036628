import { Theme, makeStyles } from '@material-ui/core';
import { GridLoaderProps } from '.';

export const useGridLoaderStyles = makeStyles<Theme, GridLoaderProps>(
  ({ spacing, palette }) => ({
    grayBorder: {
      border: `1px solid ${palette.divider}`,
      borderRadius: spacing(2),
      width: ({ itemWidth }) => itemWidth
    },
    categoryLoaderMargin: {
      marginTop: spacing(2)
    },
    container: {
      height: '100%',
      marginTop: ({ gap }) => gap || 0
    },
    wrapper: {
      display: 'flex',
      gap: ({ gap }) => gap,
      flexWrap: 'wrap'
    },
    title: {
      margin: spacing(1, 0)
    },
    imageSkeleton: {
      height: 180,
      borderRadius: spacing(2)
    },
    resource: {
      display: 'flex',
      alignItems: 'cetner',
      gap: spacing(1)
    }
  })
);
