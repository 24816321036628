import { makeStyles } from '@material-ui/core';
import { distances } from '@setvi/shared/styles';

export const useBookmarkStyles = makeStyles({
  modalWrapper: {
    position: 'absolute',
    right: '10px',
    top: '80%',
    zIndex: 99
  },
  container: {
    width: 495,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '60vh',
    overflow: 'hidden',
    padding: `${distances.px.medium} ${distances.px.large}`
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 24,
    fontWeight: 700,
    marginBottom: distances.px.large
  },
  titleWithButton: {
    display: 'flex',
    alignItems: 'center',
    gap: distances.px.base
  },
  bookmark: {
    width: 146,
    minWidth: 146,
    height: 48,
    fontWeight: 500,
    fontSize: 16,
    display: 'flex',
    color: '#171725',
    alignItems: 'center'
  },
  button: {
    height: 52,
    minHeight: 52,
    fontSize: 16,
    marginTop: distances.px.large
  },
  menu: {
    '& .MuiMenu-paper': {
      transform: 'translateY(5px) !important'
    }
  }
});
