import { ReactNode } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { distances } from '@setvi/shared/styles';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: distances.px.small
  },
  typography: {
    color: theme.palette.text.primary,
    fontWeight: 'bold'
  },
  required: {
    color: theme.palette.error.main
  }
}));

export interface LabelProps extends Omit<TypographyProps, 'title'> {
  title: string | ReactNode;
  required?: boolean;
  className?: string;
}

const SLabel = ({
  title,
  required = false,
  className,
  ...props
}: LabelProps) => {
  const classes = useStyles();

  return (
    <Typography
      color="textPrimary"
      className={clsx(className, classes.typography)}
      {...props}>
      {title} {required && <span className={classes.required}>*</span>}
    </Typography>
  );
};

export default SLabel;
