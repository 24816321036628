import { useCallback, useState } from 'react';
import { useAppContext } from 'Providers/AppProvider/AppContext';

export const useSidebar = () => {
  const { layout } = useAppContext();

  const [collapsedDrawer, setCollapseDrawer] = useState<boolean>(
    localStorage.getItem('collapsedDrawer')
      ? localStorage.getItem('collapsedDrawer') === 'true'
      : true
  );
  const [expandedSidebarMenuItem, setExpandedSidebarMenuItem] = useState<any>(
    localStorage.getItem('expandedSidebarMenuItem')
      ? JSON.parse(localStorage.getItem('expandedSidebarMenuItem'))
      : {
          Campaigns: false,
          Workspaces: false
        }
  );

  const handleSetExpandedMenu = useCallback((value: any) => {
    localStorage.setItem('expandedSidebarMenuItem', JSON.stringify(value));
    setExpandedSidebarMenuItem(value);
  }, []);

  const handleSidebarState = useCallback((isDrawerCollapsed: boolean) => {
    localStorage.setItem('collapsedDrawer', String(isDrawerCollapsed));
    setCollapseDrawer(isDrawerCollapsed);
  }, []);

  return {
    expandedSidebarMenuItem,
    collapsedDrawer,
    layout,
    handleSetExpandedMenu,
    handleSidebarState
  };
};
