import { SVGProps, memo } from 'react';

export const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={166}
    height={141}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M85.087 113.341c25.804 0 46.774-20.971 46.774-46.774s-20.97-46.774-46.774-46.774c-25.9 0-46.775 20.875-46.775 46.774 0 25.803 20.876 46.774 46.775 46.774Z"
      fill="#EAEEF9"
    />
    <path
      d="M55.656 53.781h56.541c2.117 0 4.023 1.8 4.023 4.024v35.682c0 2.117-1.8 4.023-4.023 4.023H55.656c-2.117 0-4.023-1.8-4.023-4.023V57.805c0-2.224 1.906-4.024 4.023-4.024Z"
      fill="url(#a)"
    />
    <path
      d="M139.494 54.874c1.45-1.353 1.547-3.576.194-5.026-1.353-1.449-3.576-1.546-5.026-.193-1.449 1.353-1.546 3.576-.193 5.025 1.256 1.353 3.576 1.547 5.025.194Zm.193-15.946c.58-.58.677-1.546.097-2.126-.58-.58-1.546-.677-2.126-.097-.58.58-.677 1.546-.097 2.126.58.677 1.547.677 2.126.097Zm-37.976-21.937c.386-.387.483-1.063.096-1.45-.386-.387-1.063-.483-1.449-.097-.386.387-.483 1.063-.097 1.45.387.386 1.063.483 1.45.097Z"
      fill="#EAEEF9"
    />
    <g filter="url(#b)">
      <path
        d="m136.759 34.934-14.082 25.094c-.53.847-1.588 1.165-2.33.635l-24.14-14.082c-.848-.529-1.165-1.588-.636-2.329L114.1 12.276c.53-.847 1.589-1.165 2.33-.636l17.47 10.165 2.859 13.13Z"
        fill="#F2F6FA"
      />
    </g>
    <path
      d="m133.903 21.806-4.023 6.776c-.53 1.059-.212 2.118.741 2.753l6.035 3.494"
      fill="#D5DAE6"
    />
    <path
      d="M117.277 28.37c.423-.105.847-.105 1.165-.105h1.27c.424.106.953.21 1.377.317.529.212.953.424 1.376.636a4.68 4.68 0 0 1 1.588 1.27c.424.424.742.953.953 1.588.212.53.212 1.165.212 1.694-.106.636-.318 1.27-.635 1.8a6.83 6.83 0 0 1-1.059 1.377c-.424.318-.741.529-1.271.74a2.814 2.814 0 0 1-1.27.319l-1.165.106c-.318 0-.635.105-1.059.21-.317.107-.529.213-.741.425l-1.165 1.482-1.905-1.06.847-1.904c.105-.318.423-.636.635-.847.318-.212.635-.318.953-.424.423-.106.741-.106 1.165-.212.423 0 .847-.106 1.27-.212a5.108 5.108 0 0 0 1.165-.423c.423-.212.635-.53.847-.953.106-.212.212-.53.317-.847 0-.318 0-.53-.105-.741-.106-.318-.212-.53-.424-.741-.212-.212-.423-.424-.741-.636a3.936 3.936 0 0 0-1.165-.53c-.317-.105-.635-.105-.953-.105h-.741c-.106 0-.318 0-.423-.106-.212-.106-.424-.423-.424-.635l.106-1.482Zm-5.4 13.977c.106-.212.318-.424.529-.635.212-.106.424-.318.636-.318.529-.106 1.059-.106 1.482.212.212.106.424.317.635.529l.318.635c.318 1.059-.318 2.012-1.376 2.33-.53.106-1.059 0-1.483-.212-.212-.106-.423-.318-.635-.53-.106-.211-.318-.423-.318-.635a.903.903 0 0 1 0-.741c0-.212.106-.423.212-.635Z"
      fill="#CED6E2"
    />
    <g filter="url(#c)">
      <path
        d="m46.233 24.03 16.094 16.835c.529.635.529 1.481-.106 2.01L45.809 58.442c-.636.529-1.482.528-2.012-.107L23.256 36.629c-.53-.635-.53-1.481.105-2.01l11.966-11.224 10.906.636Z"
        fill="#F2F6FA"
      />
    </g>
    <path
      d="m35.219 23.182 4.447 4.659a1.663 1.663 0 0 0 2.33 0l4.129-3.917"
      fill="#D5DAE6"
    />
    <path
      d="M36.492 37.687c0-.317.106-.635.211-.953l.318-.953c.106-.317.318-.635.53-.953.211-.317.53-.635.846-.952.424-.423.848-.741 1.377-.953a3.255 3.255 0 0 1 1.376-.318c.424 0 .953.106 1.377.212.423.211.847.53 1.27.847.318.317.636.741.742 1.164.105.318.211.742.317 1.165v1.06c-.106.317-.106.634-.212.952 0 .318-.105.53-.105.847 0 .212 0 .423.211.635l.847 1.271-1.27 1.165-1.27-1.165c-.212-.212-.424-.424-.53-.74-.106-.319-.106-.53-.106-.848 0-.318.106-.636.106-.953.106-.317.106-.636.106-.953 0-.318 0-.636-.106-.953-.106-.318-.318-.635-.53-.953-.105-.211-.317-.318-.529-.424-.212-.106-.423-.106-.635-.106-.212 0-.424.106-.636.212-.21.107-.423.212-.635.424-.212.212-.423.53-.635.741-.106.212-.212.423-.318.741-.106.212-.106.425-.106.53 0 .105-.105.212-.21.318-.107.212-.425.211-.637.105l-1.164-.212Zm9.741 7.412c-.106-.106-.212-.318-.318-.529-.106-.212-.106-.424-.106-.636 0-.423.212-.847.53-1.164.105-.106.317-.212.53-.318.211-.105.423-.106.634-.106.636 0 1.271.425 1.483 1.06.105.212.105.422.105.635 0 .212-.105.423-.105.634-.106.212-.212.318-.424.53-.106.106-.318.318-.529.318-.424.105-.847.105-1.165 0-.318-.106-.529-.212-.635-.424Z"
      fill="#CED6E2"
    />
    <g filter="url(#d)">
      <path
        d="m88.269 50.5-6.247 22.34c-.212.742-1.06 1.27-1.8.954L58.516 67.44c-.741-.212-1.271-1.06-.953-1.8l8.47-28.8c.212-.741 1.059-1.27 1.8-.953l15.777 4.659 4.659 9.953Z"
        fill="#F2F6FA"
      />
    </g>
    <path
      d="m83.612 40.585-2.014 6.314c-.213.847.317 1.8 1.165 2.012l5.584 1.578"
      fill="#D5DAE6"
    />
    <path
      d="m71.643 49.123.953-.317c.318-.106.635-.212 1.06-.212h1.164c.423 0 .847.106 1.27.212.53.106 1.06.423 1.483.74a5.01 5.01 0 0 1 1.058 1.06c.212.423.424.847.53 1.27.106.53 0 1.059-.106 1.483-.106.423-.318.847-.635 1.27-.212.318-.53.636-.847.847-.318.212-.636.424-.953.53l-.953.317c-.212.106-.53.212-.74.318-.213.106-.425.318-.425.529l-.635 1.377-1.694-.741.317-1.694c0-.318.212-.53.424-.742.212-.211.423-.423.741-.53.318-.105.635-.211.847-.317.318-.106.635-.211.953-.423.318-.106.53-.318.847-.53.212-.211.424-.53.53-.953v-.53c0-.211-.106-.422-.212-.634-.106-.212-.318-.318-.53-.53-.212-.105-.423-.211-.74-.317-.319-.106-.636-.106-1.06-.106-.212 0-.529 0-.74.106-.213 0-.424.106-.53.106-.106 0-.212.106-.424 0-.212 0-.423-.212-.423-.424l-.53-1.165ZM70.055 61.3c.106-.212.106-.424.318-.53.106-.211.317-.317.423-.423a1.533 1.533 0 0 1 1.165-.106c.212.106.423.212.53.318l.423.423c.423.74.106 1.694-.635 2.012a1.533 1.533 0 0 1-1.165.106c-.212-.106-.424-.106-.53-.318-.21-.106-.317-.318-.423-.423-.106-.212-.106-.318-.212-.53.106-.106.106-.317.106-.53Z"
      fill="#CED6E2"
    />
    <g filter="url(#e)">
      <path
        d="m139.302 88.722-20.541 11.117c-.635.318-1.588.106-1.906-.635l-10.27-20.011c-.318-.636-.106-1.59.635-1.906l26.682-14.083c.636-.317 1.589-.105 1.906.636l7.412 14.505-3.918 10.377Z"
        fill="#F2F6FA"
      />
    </g>
    <path
      d="m143.113 78.558-5.718 2.965c-.741.424-1.058 1.482-.741 2.224l2.541 5.082"
      fill="#D5DAE6"
    />
    <path
      d="M128.924 75.805c.318.106.529.318.847.424.318.106.529.318.847.635.318.318.529.53.741.847.318.318.53.636.741 1.059.318.424.424 1.059.424 1.482.106.53 0 .953 0 1.483-.106.423-.318.847-.53 1.27a3.293 3.293 0 0 1-1.164.953c-.318.212-.847.318-1.271.424-.317.106-.847.106-1.165 0-.317-.106-.741-.106-1.058-.318l-.847-.423c-.212-.106-.53-.212-.742-.318-.211-.106-.529-.106-.635.106l-1.376.529-.741-1.588 1.376-.953c.212-.212.529-.212.847-.212.318 0 .529 0 .847.106.318.106.529.318.741.424.318.106.53.317.953.423.318.106.53.106.953.212.318 0 .635-.106 1.059-.318.318-.211.741-.53.741-1.058.106-.212 0-.424 0-.742-.106-.211-.106-.423-.318-.741-.105-.317-.317-.53-.529-.847-.106-.106-.318-.317-.529-.423-.106-.106-.318-.212-.424-.318-.106-.106-.212-.106-.318-.318-.105-.105-.105-.423 0-.53l.53-1.27Zm-9.847 7.306c.212-.106.318-.212.635-.106.212-.106.424 0 .53 0 .423.106.741.424.847.742.106.21.106.423.106.635v.529c-.106.318-.424.74-.742.847-.211.106-.423.106-.635.106h-.529c-.424-.106-.741-.423-.847-.741-.106-.212-.106-.424-.106-.635-.106-.212 0-.424 0-.53.106-.211.212-.317.212-.53.105-.105.423-.105.529-.317Z"
      fill="#CED6E2"
    />
    <path
      d="M117.278 76.757v17.576c0 2.224-1.906 4.13-4.129 4.13h-58.13c-2.223 0-4.128-1.906-4.128-4.13V75.275"
      stroke="#1676EE"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M115.055 65.429H95.15c-1.906 0-3.812.635-5.294 1.906l-5.293 4.235c-1.484 1.165-3.39 1.906-5.294 1.906h-22.66c-2.223 0-4.129 1.8-4.129 4.129 0 .212 0 .424.107.636l4.128 21.705c.318 2.012 2.118 3.6 4.13 3.6h48.387c2.118 0 3.812-1.482 4.129-3.494l5.824-29.858c.317-2.224-1.165-4.342-3.494-4.66-.212-.105-.424-.105-.636-.105Z"
      fill="#fff"
    />
    <g filter="url(#f)">
      <path
        d="M120.561 61.51H96.844c-2.224 0-4.553.848-6.247 2.224l-6.353 5.188a9.81 9.81 0 0 1-6.247 2.224H50.786c-2.647 0-4.977 2.117-4.977 4.976 0 .212 0 .529.106.74l4.977 26.366c.423 2.435 2.435 4.341 4.976 4.341h57.705c2.435 0 4.553-1.8 4.977-4.235l6.882-36.106c.423-2.647-1.377-5.294-4.129-5.718h-.742Z"
        fill="url(#g)"
      />
    </g>
    <path
      d="M74.82 89.251c1.375 0 2.54-1.164 2.54-2.541 0-1.376-1.165-2.541-2.54-2.541-1.378 0-2.543 1.165-2.543 2.541 0 1.377 1.165 2.541 2.542 2.541Zm19.8-.423c1.376 0 2.541-1.165 2.541-2.541 0-1.377-1.165-2.54-2.542-2.54-1.376 0-2.54 1.163-2.54 2.54 0 1.376 1.164 2.541 2.54 2.541Zm-4.554 4.235H88.69c-1.165-2.329-4.023-3.282-6.247-2.012-.953.424-1.694 1.271-2.012 2.012h-1.376c1.165-3.07 4.659-4.659 7.623-3.494 1.589.635 2.753 1.906 3.388 3.494Zm40.13 23.082c-1.164 0-.741.106-9.741-8.894-8.682 6.459-20.752.212-20.752-10.376 0-7.094 5.823-12.918 12.917-12.918 10.271 0 16.623 11.541 10.588 20.329l8.365 8.365a1.996 1.996 0 0 1-1.377 3.494Zm-17.47-28.058c-4.871 0-8.894 3.917-8.894 8.894 0 4.976 3.918 8.894 8.894 8.894.529 0 1.059 0 1.588-.106.53-.106 1.059-.212 1.483-.424a17.37 17.37 0 0 0 1.376-.635 6.788 6.788 0 0 0 1.376-1.059l1.059-1.059c.318-.423.636-.847.847-1.27.212-.424.424-.953.636-1.483a4.258 4.258 0 0 0 .317-1.588c0-.423.106-.741.106-1.164.106-5.083-3.918-9-8.788-9Z"
      fill="#989FB0"
    />
    <path
      d="m68.574 82.369 5.717-3.07m25.306 1.588-5.718-3.177"
      stroke="#989FB0"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <defs>
      <filter
        id="b"
        x={73.313}
        y={0.382}
        width={85.445}
        height={93.541}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_19969_254670"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_19969_254670"
          result="shape"
        />
      </filter>
      <filter
        id="c"
        x={0.871}
        y={12.394}
        width={83.84}
        height={79.432}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_19969_254670"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_19969_254670"
          result="shape"
        />
      </filter>
      <filter
        id="d"
        x={35.469}
        y={24.794}
        width={74.801}
        height={82.094}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_19969_254670"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_19969_254670"
          result="shape"
        />
      </filter>
      <filter
        id="e"
        x={84.441}
        y={52.062}
        width={80.777}
        height={80.921}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_19969_254670"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_19969_254670"
          result="shape"
        />
      </filter>
      <filter
        id="f"
        x={23.809}
        y={50.51}
        width={123.684}
        height={90.059}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_19969_254670"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_19969_254670"
          result="shape"
        />
      </filter>
      <linearGradient
        id="a"
        x1={83.927}
        y1={56.996}
        x2={83.927}
        y2={73.526}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#B0BACC" />
        <stop offset={1} stopColor="#969EAE" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={85.625}
        y1={60.445}
        x2={85.625}
        y2={108.065}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDFEFF" />
        <stop offset={0.996} stopColor="#ECF0F5" />
      </linearGradient>
    </defs>
  </svg>
);

const EmptySearchIcon = memo(SvgComponent);
export default EmptySearchIcon;
