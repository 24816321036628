import { memo, useEffect } from 'react';
import {
  Drawer,
  Box,
  Divider,
  IconButton,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { useInView } from 'react-intersection-observer';
import { SETVIImage } from '@setvi/shared/components';

import { Notification } from './Notification';
import { NotificationByDayList } from './useNotificationsPanel';

const Container = withStyles({
  root: {
    display: 'grid',
    gridTemplateRows: '70px auto',
    height: '100vh',
    width: 396
  }
})(Box);

const Header = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0px ${spacing(2)}px 0px ${spacing(3)}px`,
    margin: `${spacing(2)}px 0px`
  }
}))(Box);

const Content = withStyles(({ spacing }) => ({
  root: {
    height: '100%',
    paddingTop: spacing(2),
    overflowY: 'scroll'
  }
}))(Box);

const DateText = withStyles(({ spacing }) => ({
  root: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '16.7px',
    marginBottom: spacing(3)
  }
}))(Typography);

interface NotificationsPanelProps {
  isOpen: boolean;
  hasMore: boolean;
  fetchMore: () => void;
  setIsOpen: (value: boolean) => void;
  list: NotificationByDayList[];
  loading: boolean;
  getPrefixForDayEnty: (date: string) => '' | 'Today - ' | 'Yesterday - ';
}

export const NotificationsPanel = memo(
  ({
    isOpen,
    setIsOpen,
    list,
    loading,
    getPrefixForDayEnty,
    hasMore,
    fetchMore
  }: NotificationsPanelProps) => {
    const { ref: lastNotificationRef, inView } = useInView();

    useEffect(() => {
      if (inView && hasMore && !loading) {
        fetchMore();
      }
      // ! Do not modify dependency array or the inf scroll will not work properly
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    return (
      <Drawer
        BackdropProps={{ invisible: true }}
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}>
        <Container>
          <Box>
            <Header>
              <Box display="flex" alignItems="center">
                <Typography variant="h6">Notifications</Typography>
              </Box>
              <IconButton onClick={() => setIsOpen(false)}>
                <Close />
              </IconButton>
            </Header>
            <Divider />
          </Box>
          {!loading && !list.length ? (
            <Box display="flex" alignItems="center" width="100%" height="100%">
              <Box width="100%" textAlign="center">
                <SETVIImage
                  alt="no activity image"
                  src="/images/no-activity.png"
                />
                <Typography variant="subtitle2" color="textSecondary">
                  No notifications
                </Typography>
              </Box>
            </Box>
          ) : (
            <Content>
              {list?.map((item, dateIndex) => {
                const isLastDate = dateIndex + 1 === list?.length;
                return (
                  <Box px={3} key={item.day}>
                    <DateText variant="body1">
                      {getPrefixForDayEnty(item.day)}
                      {item.day}
                    </DateText>
                    {item.notifications?.map(
                      (notification, notificationIndex) => {
                        const isLastNotification =
                          notificationIndex + 1 === item.notifications.length;
                        return (
                          <Notification
                            ref={
                              isLastNotification && isLastDate
                                ? lastNotificationRef
                                : null
                            }
                            notification={notification}
                            key={notification.id}
                          />
                        );
                      }
                    )}
                  </Box>
                );
              })}
            </Content>
          )}
          {loading && (
            <h3 style={{ margin: 'auto', padding: 10 }}>Loading...</h3>
          )}
        </Container>
      </Drawer>
    );
  }
);
