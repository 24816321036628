import { AxiosMethods, GroupsApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import {
  Contact,
  Group
} from '@setvi/shared/services/react-query/query/contacts/types';

export enum GroupMutationsKey {
  CREATE_GROUP = 'create_group',
  RENAME_GROUP = 'rename_group',
  DELETE_GROUP = 'delete_group',
  ADD_CONTACT_TO_GROUP = 'add_contact_to_group',
  REMOVE_CONTACT_FROM_GROUP = 'remove_contact_from_group'
}

const dynamicId = ':groupId';

type ContactIdsProps = Pick<Contact, 'Id'>;

interface CreateGroupMutationProps {
  Name: Group['Name'];
  Description: Group['Description'];
  Contacts?: ContactIdsProps[];
}

export const createGroupMutation = () => ({
  mutationFn: ({
    Name,
    Description = '',
    Contacts = []
  }: CreateGroupMutationProps) =>
    axiosHelper({
      endpoint: GroupsApi.CreateGroup,
      method: AxiosMethods.POST,
      body: { name: Name, description: Description, contacts: Contacts }
    })
});

interface EditGroupMutationProps {
  Id: Group['Id'];
  Name: Group['Name'];
  Description: Group['Description'];
}

export const editGroupMutation = () => ({
  mutationFn: ({ Id, Name, Description }: EditGroupMutationProps) =>
    axiosHelper({
      endpoint: `${GroupsApi.EditGroup.replace(dynamicId, Id.toString())}`,
      method: AxiosMethods.PATCH,
      body: { name: Name, description: Description }
    })
});

interface DeleteGroupMutationProps {
  ID: Group['Id'][];
}

export const deleteGroupMutation = () => ({
  mutationFn: ({ ID }: DeleteGroupMutationProps) =>
    axiosHelper({
      endpoint: GroupsApi.DeleteGroups,
      method: AxiosMethods.DELETE,
      body: { ID }
    })
});

interface AddContactsToGroupMutationProps {
  GroupID: Group['Id'];
  ContactIds: Contact['Id'][];
}

export const addContactsToGroupMutation = () => ({
  mutationFn: ({ GroupID, ContactIds }: AddContactsToGroupMutationProps) =>
    axiosHelper({
      endpoint: `${GroupsApi.AddContactsToGroup.replace(
        dynamicId,
        GroupID.toString()
      )}`,
      method: AxiosMethods.PUT,
      body: { ID: ContactIds }
    })
});

export const removeContactsFromGroupMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: `${GroupsApi.RemoveContactsFromGroup.replace(
        dynamicId,
        params?.id
      )}`,
      method: AxiosMethods.DELETE,
      body
    })
});
