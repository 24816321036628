import { Box, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { distances } from '@setvi/shared/styles';

export const useStyles = makeStyles(theme => ({
  boldText: {
    fontWeight: 600
  },
  secondaryText: {
    color: theme.palette.text.secondary
  },
  description: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '14.4px',
    color: '#696974',
    marginBottom: distances.px.xsmall
  },
  notificationText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  preposition: {
    margin: `0 ${distances.px.xsmall}`,
    color: '#92929D',
    fontWeight: 400
  }
}));

export const TitleWrapper = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '16.4px',
    letter: '0.09px',
    marginBottom: distances.px.xsmall
  }
})(Typography);

export const Time = withStyles({
  root: {
    color: '#92929D',
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 400
  }
})(Typography);

export const IconBackground = withStyles({
  root: {
    height: 38,
    minWidth: 38,
    borderRadius: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: distances.px.base,
    marginTop: distances.px.xxsmall
  }
})(Box);
