import { SBase } from '@setvi/shared/components';
import { useNavigate } from 'react-router-dom';
import { Presentation } from '@setvi/shared/interfaces';
import { Content } from './Content';

export enum DialogType {
  create = 'create',
  templateCreate = 'templateCreate',
  edit = 'edit',
  duplicate = 'duplicate'
}

type DialogHeader = {
  [key: string]: {
    title: string;
    description?: string;
    placeholder?: string;
  };
};

export const dialogTypeContent: DialogHeader = {
  [DialogType.create]: {
    title: 'Create Presentation',
    placeholder: 'Enter Presentation Name'
  },
  [DialogType.templateCreate]: {
    title: 'Create Presentation',
    description: 'Create a presentation out of this template, name it below.',
    placeholder: 'Enter Presentation Name'
  },
  [DialogType.edit]: {
    title: 'Rename Presentation',
    description: 'Change the name for presentation, enter a new name below',
    placeholder: 'Existing Name Prefilled'
  },
  [DialogType.duplicate]: {
    title: 'Duplicate Presentation',
    description:
      'Create a copy of an existing presentation, you can change name below.',
    placeholder: 'Existing Name (Copy)'
  }
};

export interface PresentationDialogProps {
  onClose: () => void;
  onSuccess?: (presentation?: Presentation) => void;
  withResources?: string[];
  presentation?: Presentation;
  type?: keyof typeof DialogType;
}

export const PresentationDialog = ({
  onClose,
  onSuccess,
  withResources,
  presentation,
  type = DialogType.create
}: PresentationDialogProps) => {
  const navigate = useNavigate();

  return (
    <SBase
      width={500}
      header={{
        title: dialogTypeContent[type].title,
        onClose
      }}>
      <Content
        goTo={(path: string) => navigate(path)}
        onClose={onClose}
        onSuccess={onSuccess}
        dialogType={type}
        withResources={withResources}
        presentation={presentation}
      />
    </SBase>
  );
};
