import { Box, Typography, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import SETVIImage from '../../../../setvi-image';
import { useResourceChildrenStyles } from '../../../styles';
import { ChildResource } from '../../../../../interfaces';
import { SCheckbox } from '../../../../scheckbox';

const CheckBox = withStyles({
  root: {
    top: 6,
    right: 8,
    position: 'absolute'
  }
})(SCheckbox);

interface PageProps {
  title: number;
  isSelected?: boolean;
  isActive?: boolean;
  handleSelect?: (isChecked: boolean, slected: ChildResource[]) => void;
  setActivePage: () => void;
  page: ChildResource;
}

export const Page = ({
  title,
  page,
  isSelected,
  isActive,
  handleSelect,
  setActivePage
}: PageProps) => {
  const classes = useResourceChildrenStyles();

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    handleSelect(e.target.checked, [page]);
  };

  return (
    <Box height="fit-content" position="relative">
      <Box
        className={clsx(classes.page, {
          [classes.selectedItem]: isActive
        })}
        onClick={setActivePage}>
        <SETVIImage
          className={classes.image}
          src={page.ThumbURLWithSas}
          alt={page.Material}
        />
      </Box>
      <Typography className={classes.title}>{title}</Typography>
      {handleSelect ? (
        <CheckBox
          checked={isSelected}
          onChange={handleCheck}
          strokeColor="rgb(152, 206, 253)"
        />
      ) : null}
    </Box>
  );
};
