import {
  TreeItem,
  TreeInformation,
  TreeItemRenderContext
} from 'react-complex-tree';
import { Box, Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles<
  Theme,
  { isSelected: boolean; background: string; color: string }
>(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '4px 0',
    backgroundColor: ({ isSelected, background }) =>
      isSelected ? background : 'transparent',
    cursor: 'pointer',
    color: ({ isSelected, color }) => (isSelected ? color : '#000'),
    width: '100%',
    gap: 18,
    borderRadius: 6,

    '&:hover': {
      backgroundColor: ({ isSelected, background }) =>
        isSelected ? background : '#f5f5f5',
      opacity: ({ isSelected }) => (isSelected ? 0.6 : 1)
    },

    '& div': {
      backgroundColor: 'transparent !important'
    },

    '& svg': {
      color: ({ isSelected, color }) => (isSelected ? color : '#696974'),

      '& path': {
        fill: ({
          isSelected,
          color
        }: {
          isSelected: boolean;
          color: string;
        }) => (isSelected ? color : '#696974')
      }
    }
  }
}));

interface TreeItemProps<T = any, C extends string = never> {
  item: TreeItem<T>;
  depth: number;
  children: React.ReactNode | null;
  title: React.ReactNode;
  arrow: React.ReactNode;
  context: TreeItemRenderContext<C>;
  info: TreeInformation;
  itemColor?: string;
  itemBackground?: string;
  disableActiveStyle?: boolean;
  onClick?: (item: T) => void;
}

const ReactTreeItem = <T,>(props: TreeItemProps<T>) => {
  const classes = useStyles({
    isSelected: props.context.isFocused && !props?.disableActiveStyle,
    background: props.itemBackground || '#2196F3',
    color: props.itemColor || '#fff'
  });

  return (
    <li
      {...props.context.itemContainerWithChildrenProps}
      style={{
        paddingLeft: `${props.depth * 8}px`,
        listStyleType: 'none',
        width: '100%'
      }}>
      <button
        {...props.context.itemContainerWithoutChildrenProps}
        {...props.context.interactiveElementProps}
        type="button"
        onClick={() => props.onClick?.(props.item?.data)}
        style={{
          width: '100%',
          background: props.context.isDraggingOver ? '#EDEFF0' : 'transparent',
          borderRadius: 6
        }}>
        <Box className={classes.root}>
          <Box onClick={e => e.stopPropagation()}>{props.arrow}</Box>
          {props.title}
        </Box>
      </button>
      {props.children}
    </li>
  );
};

export default ReactTreeItem;
