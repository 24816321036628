import { SortType } from '@setvi/shared/enums';

interface SortStatic {
  sortBy: string;
  orderBy: SortType;
}

export const sortStaticData = (data: any[] = [], sort: SortStatic) =>
  data.sort((a, b) => {
    const first = a[sort.sortBy].toLowerCase();
    const second = b[sort.sortBy].toLowerCase();

    // Ascending
    if (sort.orderBy === SortType.ASC) {
      if (first < second) return -1;
      if (first > second) return 1;
      return 0;
    }

    // Descending
    if (first > second) return -1;
    if (first < second) return 1;
    return 0;
  });
