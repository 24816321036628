import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  List,
  CircularProgress,
  ClickAwayListener
} from '@material-ui/core';
import { useInView } from 'react-intersection-observer';

import SText from '../sui/stext';
import SLabel from '../sui/slabel';
import { AddLabel } from './components';
import SHighlightText from '../shighlight-text';
import { StyledItem, StyledMenu, StyledTextField } from './style';
import { SAutocompleteItem, SAutocompleteProps } from './interfaces';

const SAutocomplete = ({
  name,
  label,
  error,
  options,
  loading: isLoading,
  selected,
  disabled,
  required,
  helperText,
  placeholder,

  handleInputChange,
  handleItemClick,
  fetchMore
}: SAutocompleteProps) => {
  const { ref, inView } = useInView();

  const [search, setSearch] = useState('');
  const [_, setDebouncedSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [openMenu, setMenuOpen] = useState(false);

  useEffect(() => {
    if (inView) fetchMore?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearch(search);
      handleInputChange(search);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [handleInputChange, search]);

  useEffect(() => {
    setLoading(prev => prev && isLoading);
  }, [isLoading]);

  const onClick = (item: SAutocompleteItem) => {
    handleItemClick(item);
    setMenuOpen(false);
    setSearch('');
    handleInputChange('');
    setLoading(false);
  };

  const showAddLabel = useMemo(
    () =>
      !selected?.find(elem => elem?.name === search) &&
      !options?.find(elem => elem?.name === search) &&
      !!search,
    [options, search, selected]
  );

  const showMenu = useMemo(
    () => openMenu && (!!options?.length || showAddLabel),
    [options, openMenu, showAddLabel]
  );

  return (
    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
      <Box width="100%" position="relative">
        {Boolean(label) && (
          <SLabel
            title={label}
            variant="body2"
            required={required}
            style={{
              marginBottom: 8
            }}
          />
        )}

        <Box width="100%">
          <Box width="100%" onClick={() => setMenuOpen(true)}>
            <StyledTextField
              fullWidth
              name={name}
              error={error}
              variant="outlined"
              autoComplete="off"
              value={search}
              disabled={disabled}
              helperText={helperText}
              placeholder={placeholder}
              onFocus={() => setMenuOpen(true)}
              id={`autocomplete-${name}`}
              InputProps={{
                endAdornment: loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (!openMenu) setMenuOpen(true);
                setLoading(true);
                setSearch(event.target.value);
              }}
            />
          </Box>

          {showMenu && (
            <StyledMenu>
              {loading ? (
                <SText color="inherit" style={{ padding: 12 }}>
                  Loading...
                </SText>
              ) : (
                <Box display="flex" flexDirection="column" overflow="hidden">
                  <List
                    style={{
                      maxHeight: 150,
                      overflow: 'auto'
                    }}>
                    {!!options?.length &&
                      options?.map((item, index) => (
                        <StyledItem
                          button
                          key={item.id}
                          selected={selected?.includes(item)}
                          onClick={() => onClick(item)}
                          ref={index + 1 === options.length ? ref : null}>
                          <SHighlightText
                            weight="bold"
                            color="inherit"
                            value={item.name}
                            searchQuery={search}
                          />
                        </StyledItem>
                      ))}
                  </List>
                  {showAddLabel && (
                    <AddLabel
                      label={search || ''}
                      hasOptions={!!options?.length}
                      handleNewOption={(itemName: string, id: string) =>
                        onClick({
                          id,
                          name: itemName
                        })
                      }
                    />
                  )}
                </Box>
              )}
            </StyledMenu>
          )}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default SAutocomplete;
