import { Box, makeStyles } from '@material-ui/core';
import { SButton } from '@setvi/shared/components';
import { distances } from '@setvi/shared/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';

export interface ComposeNavigationProps {
  name: string;
  icon: ReactNode;
  active?: boolean;
  isHighlighted?: boolean;
  onClick?: () => void;
}

interface NavigationProps {
  items: ComposeNavigationProps[];
}

const useNavigationStyles = makeStyles(() => ({
  button: {
    fontSize: 14,
    marginRight: distances.small,
    fontWeight: 400,
    lineHeight: '16px'
  },
  active: {
    backgroundColor: '#D6EBFE'
  },
  highlight: {
    borderColor: '#FF9F29'
  },
  highlightActive: {
    backgroundColor: '#FFF2E3'
  }
}));

export const Navigation = ({ items }: NavigationProps) => {
  const styles = useNavigationStyles();

  return (
    <Box>
      {items.map(item => (
        <SButton
          key={item.name}
          className={clsx({
            [styles.button]: true,
            [item.isHighlighted ? styles.highlightActive : styles.active]:
              item.active,
            [styles.highlight]: item.isHighlighted
          })}
          disableRipple
          variant="outlined"
          color="default"
          startIcon={item.icon}
          onClick={item.onClick}>
          {item.name}
        </SButton>
      ))}
    </Box>
  );
};
