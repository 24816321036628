import { makeStyles } from '@material-ui/core';

export const useAddToPresentationStyles = makeStyles(
  ({ spacing, palette }) => ({
    container: {
      padding: 0
    },
    loading: {
      fontSize: 16,
      fontWeight: 500,
      textAlign: 'center'
    },
    presentation: {
      padding: spacing(3),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#FAFAFB'
      }
    },
    description: {
      color: 'rgba(21, 25, 32, 0.5)',
      fontSize: 14,
      margin: spacing(1, 0, 3, 0)
    },
    errorMessage: {
      color: palette.error.main,
      fontSize: 14,
      marginTop: spacing(1)
    }
  })
);
