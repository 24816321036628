import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const CenterBox = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})(Box);

export default CenterBox;
