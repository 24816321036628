import { AxiosMethods, NotificationsApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

export enum NotificationsMutationsKey {
  MARK_NOTIFICATION = 'mark_notification'
}

export const markNotificationsMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: NotificationsApi.MarkNotificationsRead,
      method: AxiosMethods.POST,
      params,
      body
    })
});
