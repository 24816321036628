import { useFlags } from 'launchdarkly-react-client-sdk';

export enum DropdownItemsTypes {
  pdf = 'pdf',
  email = 'email',
  moreInfo = 'moreInfo',
  download = 'download',
  viewLink = 'viewLink',
  delete = 'delete',
  favorites = 'favorites',
  getLink = 'getLink',
  goToCategory = 'goToCategory',
  createPresentation = 'createPresentation',
  addToPresentation = 'addToPresentation'
}

type ItemType = keyof typeof DropdownItemsTypes;

export type DropdownItemsType = ItemType[];

export const useResourcesDropdownItems = () => {
  const flags = useFlags();

  const resourcesItems: DropdownItemsType = [
    DropdownItemsTypes.pdf,
    DropdownItemsTypes.email,
    DropdownItemsTypes.favorites,
    DropdownItemsTypes.download,
    DropdownItemsTypes.getLink,
    DropdownItemsTypes.moreInfo,
    DropdownItemsTypes.delete,
    ...(flags?.myPresentations
      ? [
          DropdownItemsTypes.createPresentation,
          DropdownItemsTypes.addToPresentation
        ]
      : [])
  ];
  return { resourcesItems };
};
