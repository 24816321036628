import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getSnippetsQuery } from '../../../../../services';

export const useUserSnippets = () => {
  const [search, setSearch] = useState<string>('');

  // TODO: Extend api call with pagination, sorting, and search options
  const { data: snippets, isLoading } = useQuery(getSnippetsQuery());

  return {
    search,
    setSearch,
    isLoading,
    snippets:
      snippets?.filter(snippet =>
        snippet.Name.toLowerCase().includes(search.toLowerCase())
      ) || []
  };
};
