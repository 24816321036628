// ** MUI Imports
import { Theme } from '@material-ui/core';

const button = (_theme: Theme) => ({
  MuiButton: {
    root: {
      minHeight: 42,
      textTransform: 'none',
      '&.MuiButton-contained': {
        boxShadow: 'none'
      },
      '&.MuiButton-outlinedPrimary:hover': {
        border: '1px solid rgba(0, 0, 0, 0.12)'
      }
    },
    colorInherit: {
      color: '#76787A',
      borderColor: '#C2C2C5'
    }
  }
});

export default button;
