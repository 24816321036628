import { withStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const ActiveImage = withStyles({
  root: {
    borderRadius: 4
  }
})(Skeleton);

export const Loader = () => (
  <ActiveImage variant="rect" width="100%" height="100%" />
);
