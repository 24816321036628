import { AxiosMethods, SnippetsApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

export enum SnippetsQueryKey {
  SNIPPETS = 'snippets',
  COMPANY_SNIPPETS = 'company_snippets'
}

export const getSnippetsQuery = () => ({
  queryKey: [SnippetsQueryKey.SNIPPETS],
  queryFn: () =>
    axiosHelper({
      endpoint: `${SnippetsApi.Snippets}`,
      method: AxiosMethods.GET
    }),
  select: (res: any) => res?.Data
});

export const getCompanySnippetsQuery = () => ({
  queryKey: [SnippetsQueryKey.COMPANY_SNIPPETS],
  queryFn: () =>
    axiosHelper({
      endpoint: `${SnippetsApi.CompanySnippets}`,
      method: AxiosMethods.GET
    })
});
