import { useState } from 'react';
import { Box } from '@material-ui/core';
import { SSelectDropdown, SelectMenuHeader } from '@setvi/shared/components';
import {
  State,
  City
} from '@setvi/shared/services/react-query/query/user/types';
import { LocationButon } from './button';
import { Location } from './location';
import { useLocationStyles } from './style';

interface LocationSelectProps {
  states: State[];
  statesLoading: boolean;
  stateSearchTerm: string;
  selectedStateCodes: State['BillingStateCode'][];
  setSelectedStateCodes: (stateCodes: State['BillingStateCode'][]) => void;
  setSteateSearchTerm: (searchString: string) => void;

  cities: City[];
  citiesLoading: boolean;
  citySearchTerm: string;
  selectedCities: City[];
  setCitySearchTerm: (searchString: string) => void;
  setSelectedCities: (cities: City[]) => void;
}

export const LocationSelect = ({
  states,
  statesLoading,
  stateSearchTerm,
  selectedStateCodes,
  setSelectedStateCodes,
  setSteateSearchTerm,
  cities,
  citiesLoading,
  citySearchTerm,
  selectedCities,
  setCitySearchTerm,
  setSelectedCities
}: LocationSelectProps) => {
  const styles = useLocationStyles();
  const [stateAnchorEl, setStateAnchorEl] = useState<null | HTMLElement>(null);
  const [cityAnchorEl, setCityAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Box className={styles.container}>
      <SSelectDropdown
        marginTop={0}
        anchorElement={stateAnchorEl}
        onClose={() => setStateAnchorEl(null)}
        placement="bottom-start"
        anchor={
          <LocationButon
            title={
              selectedStateCodes.length === 0
                ? 'State'
                : `${selectedStateCodes.length} Selected `
            }
            titleHighlighted={selectedStateCodes.length > 0}
            active={Boolean(stateAnchorEl)}
            onClick={e => setStateAnchorEl(e.currentTarget)}
          />
        }
        content={
          <Box className={styles.contentContainer}>
            <SelectMenuHeader
              title="Filter by State"
              defaultSearchValue={stateSearchTerm}
              handleChange={value => setSteateSearchTerm(value)}
              handleClear={() => setSelectedStateCodes([])}
            />
            <Box className={styles.listContainer}>
              {statesLoading && (
                <Box className={styles.messageContainer}>Loading...</Box>
              )}
              {!statesLoading && states?.length === 0 && (
                <Box className={styles.messageContainer}>No States Found</Box>
              )}
              {states?.map(state => (
                <Location
                  key={state.BillingState}
                  title={`${state.BillingState}, ${state.BillingStateCode}, ${state.BillingCountry}`}
                  checked={selectedStateCodes.some(
                    code => code === state.BillingStateCode
                  )}
                  onChange={e => {
                    e.target.checked
                      ? setSelectedStateCodes([
                          ...selectedStateCodes,
                          state.BillingStateCode
                        ])
                      : setSelectedStateCodes(
                          selectedStateCodes.filter(
                            code => code !== state.BillingStateCode
                          )
                        );
                  }}
                />
              ))}
            </Box>
          </Box>
        }
      />
      <SSelectDropdown
        marginTop={0}
        anchorElement={cityAnchorEl}
        onClose={() => setCityAnchorEl(null)}
        placement="bottom-start"
        anchor={
          <LocationButon
            title={
              selectedCities.length === 0
                ? 'City'
                : `${selectedCities.length} Selected `
            }
            titleHighlighted={selectedCities.length > 0}
            active={Boolean(cityAnchorEl)}
            onClick={e => setCityAnchorEl(e.currentTarget)}
          />
        }
        content={
          <Box className={styles.contentContainer}>
            <SelectMenuHeader
              title="Filter by City"
              defaultSearchValue={citySearchTerm}
              handleChange={value => setCitySearchTerm(value)}
              handleClear={() => setSelectedCities([])}
            />
            <Box className={styles.listContainer}>
              {citySearchTerm.length < 2 && selectedStateCodes.length === 0 && (
                <Box className={styles.messageContainer}>
                  <Box>Please enter two or more characters.</Box>
                </Box>
              )}
              {citiesLoading && (
                <Box className={styles.messageContainer}>Loading...</Box>
              )}
              {!citiesLoading &&
                cities?.length === 0 &&
                citySearchTerm.length >= 2 && (
                  <Box className={styles.messageContainer}>No Cities Found</Box>
                )}
              {cities?.map(city => (
                <Location
                  key={`${city.BillingCity}${city.BillingState}`}
                  title={`${city.BillingCity}, ${city.BillingState}, ${city.BillingStateCode}`}
                  onChange={e =>
                    e.target.checked
                      ? setSelectedCities([...selectedCities, city])
                      : setSelectedCities(
                          selectedCities.filter(
                            c =>
                              `${c.BillingCity}${c.BillingState}` !==
                              `${city.BillingCity}${city.BillingState}`
                          )
                        )
                  }
                  checked={selectedCities.some(
                    c =>
                      `${c.BillingCity}${c.BillingState}` ===
                      `${city.BillingCity}${city.BillingState}`
                  )}
                />
              ))}
            </Box>
          </Box>
        }
      />
    </Box>
  );
};
