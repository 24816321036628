import { Box, makeStyles } from '@material-ui/core';

const useResultsHeaderStyles = makeStyles(theme => ({
  heading: {
    padding: `0px ${theme.spacing(4)}px ${theme.spacing(2)}px`
  },
  resultInfo: {
    fontWeight: 400,
    fontSize: 12,
    marginLeft: 5,
    color: '#92929D'
  },
  resultAction: {
    cursor: 'pointer'
  }
}));

interface IResultsHeader {
  totalResults: number;
}

const ResultsHeader = ({ totalResults }: IResultsHeader) => {
  const styles = useResultsHeaderStyles();
  return (
    <Box
      display="flex"
      className={styles.heading}
      justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Box fontWeight={500} fontSize="14px">
          Resources
        </Box>
        <Box className={styles.resultInfo}>
          ({totalResults} {`Result${totalResults > 1 ? 's' : ''}`})
        </Box>
      </Box>
    </Box>
  );
};

export default ResultsHeader;
