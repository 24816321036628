import { TableBody, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from '../../../../styles';

import { TableContentBodyProps } from '..';
import TableBodyCells from '../../cells';

type PaginatedContentProps = Omit<TableContentBodyProps, 'handleInfScroll'>;

export const PaginatedContent = ({
  data,
  columns,
  selected,
  isSelectable,
  searchQuery,
  loading,
  selectDisabledText,
  onRowClick,
  handleSelect,
  rowKeyGetter,
  overLimit,
  rowStyleGetter,
  selectDisabled
}: PaginatedContentProps) => {
  const classes = useStyles();
  return (
    <TableBody>
      {!loading &&
        data?.items?.map((rowData: any, idx: number) => (
          <TableRow
            hover
            key={rowKeyGetter(rowData) || idx}
            style={rowStyleGetter?.(rowData) || {}}
            className={clsx(classes.row, {
              [classes.clickable]: onRowClick
            })}
            onClick={() => onRowClick?.(rowData, idx)}>
            <TableBodyCells
              rowData={rowData}
              loading={loading}
              columns={columns}
              selectDisabled={selectDisabled}
              searchQuery={searchQuery}
              overLimit={overLimit}
              selectedItems={selected}
              isSelectable={isSelectable}
              rowKeyGetter={rowKeyGetter}
              onSelectItem={handleSelect}
              selectDisabledText={selectDisabledText}
            />
          </TableRow>
        ))}
    </TableBody>
  );
};
