import {
  QueryFunctionContext,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';

import {
  AxiosMethods,
  CompanyCampaignApi,
  ThirdPartyApi
} from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import { queryVariablesType } from '@setvi/shared/interfaces';

import { TAB_NUMBER } from 'pages/admin/pages/campaigns/pages/table/hooks/useTable';
import {
  CampaignStatusCount,
  GetAssignedGroupsType,
  GetAssignedUsersType,
  GetParamsAssignedType
} from './Types';

export enum AdminCampaignsQueryKey {
  STATUS = 'admin_campaign_status',
  STATUSES = 'admin_campaign_statuses',
  CAMPAIGN = 'admin_campaign',
  CAMPAIGNS = 'admin_campaigns',
  CAMPAIGN_LINKS = 'admin_campaign_links',
  CAMPAIGN_DOCUMENTS = 'admin_campaign_documents',
  CAMPAIGN_ANALYTICS = 'admin_campaign_analytics',
  CAMPAIGN_ASSIGNED_USERS = 'admin_campaign_assigned_users',
  CAMPAIGN_ASSIGNED_GROUPS = 'admin_campaign_assigned_groups',
  QRVEY_TOKEN = 'status',
  QRVEY_REPORT_CONFIG = 'reportConfig'
}

interface AdminCampaigns extends queryVariablesType {
  statuses: TAB_NUMBER[];
}

const dynamicId = ':campaignId';

export const adminCampaignStatusesQuery = () => ({
  queryKey: [AdminCampaignsQueryKey.STATUSES],
  queryFn: () =>
    axiosHelper({
      endpoint: CompanyCampaignApi.StatusesAdmin,
      method: AxiosMethods.GET
    }),
  retryOnMount: false,
  select: (res: any): CampaignStatusCount[] => res?.Data?.CampaignsStatuses
});

export const adminCampaignsQuery = (input?: AdminCampaigns) => ({
  queryKey: [AdminCampaignsQueryKey.CAMPAIGNS, input],
  queryFn: ({ queryKey }: any) => {
    const { page, size, search, ...rest }: AdminCampaigns = queryKey?.[1];
    const params = {
      pageNumber: page,
      pageSize: size,
      searchString: search,
      ...rest
    };

    return axiosHelper({
      endpoint: `${CompanyCampaignApi.AllAdminCapgins}`,
      method: AxiosMethods.GET,
      params
    });
  },
  cacheTime: 0,
  select: (res: any) => res?.Data
});

export const adminCampaignQuery = (Id: string) => ({
  queryKey: [AdminCampaignsQueryKey.CAMPAIGN, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: `${CompanyCampaignApi.Campaign.replace(dynamicId, Id)}`,
      method: AxiosMethods.GET
    }),
  cacheTime: 0,
  refetchOnWindowFocus: false
});

export const adminCampaignLinksQuery = (Id: string) => ({
  queryKey: [AdminCampaignsQueryKey.CAMPAIGN_LINKS, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: `${CompanyCampaignApi.CampaignLinks.replace(dynamicId, Id)}`,
      method: AxiosMethods.GET
    }),
  cacheTime: 0,
  refetchOnWindowFocus: false
});

export const adminCampaignDocumentsQuery = (Id: string) => ({
  queryKey: [AdminCampaignsQueryKey.CAMPAIGN_DOCUMENTS, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: `${CompanyCampaignApi.CampaignDocuments.replace(
        dynamicId,
        Id
      )}`,
      method: AxiosMethods.GET
    }),
  cacheTime: 0,
  refetchOnWindowFocus: false
});

export const adminCampaignAnalyticsQuery = (Id: string) => ({
  queryKey: [AdminCampaignsQueryKey.CAMPAIGN_ANALYTICS, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: `${CompanyCampaignApi.CampaignAnalytics.replace(
        dynamicId,
        Id
      )}`,
      method: AxiosMethods.GET
    }),
  cacheTime: 0,
  refetchOnWindowFocus: false
});

export const qrveyTokenQuery = () => ({
  queryKey: [AdminCampaignsQueryKey.QRVEY_TOKEN],
  queryFn: () =>
    axiosHelper({
      endpoint: ThirdPartyApi.QrveyToken,
      method: AxiosMethods.GET
    }),
  retryOnMount: false,
  select: (res: any) => res?.Data?.Token
});

export const getAssignedGroupsQuery = (
  params: GetParamsAssignedType
): UseInfiniteQueryOptions<GetAssignedGroupsType> => ({
  queryKey: [AdminCampaignsQueryKey.CAMPAIGN_ASSIGNED_GROUPS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: CompanyCampaignApi.AssignedGroups,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (lastPage: GetAssignedGroupsType) => {
    const total = lastPage?.Data?.TotalCount;
    const currentPageNumber = lastPage?.Data?.PageNumber;

    return total <= currentPageNumber * params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const getAssignedUsersQuery = (
  params: GetParamsAssignedType
): UseInfiniteQueryOptions<GetAssignedUsersType> => ({
  queryKey: [AdminCampaignsQueryKey.CAMPAIGN_ASSIGNED_USERS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: CompanyCampaignApi.AssignedUsers,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (lastPage: GetAssignedUsersType) => {
    const total = lastPage?.Data?.TotalCount;
    const currentPageNumber = lastPage?.Data?.PageNumber;

    return total <= currentPageNumber * params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const qrveyReportConfigQuery = (reportId: number) => ({
  queryKey: [AdminCampaignsQueryKey.QRVEY_REPORT_CONFIG, reportId],
  queryFn: () =>
    axiosHelper({
      endpoint: ThirdPartyApi.QrveyReport.replace(
        ':reportId',
        reportId.toString()
      ),
      method: AxiosMethods.GET
    }),
  retryOnMount: false,
  select: (res: any) => res?.Data
});
