import { useMemo } from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { SDropdownMenu } from '@setvi/shared/components';
import { TrashIcon, PenIcon } from '../../../../sicons';

interface MenuProps {
  onPreview: () => void;
  onEdit?: () => void;
  onDelete: () => void;
}

export const ReviewLinkMenu = ({ onPreview, onEdit, onDelete }: MenuProps) => {
  const menuItems = useMemo(
    () => [
      {
        icon: <OpenInNewIcon fontSize="small" />,
        text: 'Preview',
        onClick: onPreview
      },
      ...(onEdit
        ? [
            {
              icon: <PenIcon fontSize="small" />,
              text: 'Edit',
              onClick: onEdit
            }
          ]
        : []),
      {
        icon: <TrashIcon fontSize="small" />,
        text: 'Delete',
        onClick: onDelete,
        color: 'danger'
      }
    ],
    [onPreview, onEdit, onDelete]
  );

  return <SDropdownMenu menuItems={menuItems} />;
};
