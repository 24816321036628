import { STabs } from '@setvi/shared/components/stabs';

export enum SNIPPETS_TAB {
  mySnippets = 0,
  sharedSnippets = 1
}

interface TabsProps {
  currentTab: number;
  onTabChange: (newValue: number) => void;
}

export const Tabs = ({ currentTab, onTabChange }: TabsProps) => {
  const tabsList = [
    {
      label: 'My snippets',
      value: SNIPPETS_TAB.mySnippets
    },
    {
      label: 'Company snippets',
      value: SNIPPETS_TAB.sharedSnippets
    }
  ];

  return (
    <STabs
      variant="fullWidth"
      tabsList={tabsList}
      currentTab={currentTab}
      handleTabChange={onTabChange}
    />
  );
};
