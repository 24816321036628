import { AxiosMethods, CategoryApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import { Category } from 'Services/Query/Category/Types';

export enum CategoryMutationsKey {
  CREATE_CATEGORY = 'create_category'
}

export const createUserCategoryMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: CategoryApi.CreateUserCategory,
      method: AxiosMethods.POST,
      params,
      body
    })
});

interface UserCategoryMutationProps {
  Name: Category['Name'];
  CategoryId: Category['ID'];
  ParentCategoryId: Category['ParentResourceCategoryID'];
}

export const updateUserCategoryMutation = () => ({
  mutationFn: ({
    Name,
    CategoryId,
    ParentCategoryId
  }: UserCategoryMutationProps) =>
    axiosHelper({
      endpoint: CategoryApi.UpdateUserCategory,
      method: AxiosMethods.POST,
      body: {
        name: Name,
        categoryId: CategoryId,
        parentcategoryid: ParentCategoryId
      }
    })
});

export const deleteUserCategoryMutation = () => ({
  mutationFn: ({
    Name,
    CategoryId,
    ParentCategoryId
  }: UserCategoryMutationProps) =>
    axiosHelper({
      endpoint: CategoryApi.DeleteUserCategory,
      method: AxiosMethods.POST,
      body: {
        name: Name,
        categoryId: CategoryId,
        parentcategoryid: ParentCategoryId
      }
    })
});
