import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosMethods, SnippetsApi } from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';
import { GetUserSnippets, Snippet, CompanySnippet } from './types';

export enum SnippetsQueryKey {
  SNIPPETS = 'snippets',
  COMPANY_SNIPPETS = 'company_snippets'
}

export const getSnippetsQuery = (): UseQueryOptions<
  GetUserSnippets,
  unknown,
  Snippet[]
> => ({
  queryKey: [SnippetsQueryKey.SNIPPETS],
  queryFn: () =>
    axiosHelper({
      endpoint: `${SnippetsApi.Snippets}`,
      method: AxiosMethods.GET
    }),
  select: res => res?.Data
});

export const getCompanySnippetsQuery = (): UseQueryOptions<
  CompanySnippet[]
> => ({
  queryKey: [SnippetsQueryKey.COMPANY_SNIPPETS],
  queryFn: () =>
    axiosHelper({
      endpoint: `${SnippetsApi.CompanySnippets}`,
      method: AxiosMethods.GET
    })
});
