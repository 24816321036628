import { Box, makeStyles, Typography } from '@material-ui/core';
import { SETVIImage } from '@setvi/shared/components';
import { distances } from '@setvi/shared/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: distances.px.small,
    alignItems: 'center',
    justifyContent: 'center'
  },
  description: {
    fontSize: 16,
    fontWeight: 500,
    color: '#92929D'
  }
});

export const EmptyState = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <SETVIImage
        width={145}
        src="/images/no-bookmarks.svg"
        alt="no-bookmarks"
      />
      <Typography className={classes.description}>
        No Bookmarks in this file
      </Typography>
    </Box>
  );
};
