import { useCallback, useState, useTransition } from 'react';

interface usePaginationProps {
  defaultPageSize?: number;
  defaultPageNumber?: number;
}

export const usePagination = ({
  defaultPageSize = 20,
  defaultPageNumber = 1
}: usePaginationProps = {}) => {
  const [_isPending, startTransition] = useTransition();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [pageNumber, setPageNumber] = useState<number>(defaultPageNumber);

  const handlePageSizeChange = useCallback((size: number) => {
    startTransition(() => {
      setPageSize(size);
      setPageNumber(1);
    });
  }, []);

  const handlePageNumberChange = useCallback((page: number) => {
    startTransition(() => {
      setPageNumber(page);
    });
  }, []);

  return {
    pageSize,
    pageNumber,
    handlePageSizeChange,
    handlePageNumberChange
  };
};
