import { Box, withStyles } from '@material-ui/core';

export const Container = withStyles(({ palette }) => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    background: '#171725',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: palette.common.white,
    zIndex: 2
  }
}))(Box);

export const ExpandedWrapper = withStyles(({ spacing, shadows }) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
    borderRadius: 'inherit',
    boxShadow: shadows[3]
  }
}))(Box);
