import { useCallback, useState } from 'react';
import { Typography, TypographyProps, withStyles } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import SButton from '../../sbutton';
import { distances } from '../../../styles';

export interface SReadMoreProps extends TypographyProps {
  text: string;
  max?: number;
  variant?: Variant;
}

const Text = withStyles({
  root: {
    overflowY: 'auto',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  }
})(Typography);

const Button = withStyles({
  root: {
    display: 'flex',
    margin: `${distances.px.xsmall} 0 ${distances.px.xsmall} auto`
  }
})(SButton);

const truncate = (str: string, max: number) =>
  str.length > max ? str.slice(0, max - 1) : str;

const SReadMore = ({
  text = '',
  variant = 'body2',
  max = 250,
  ...rest
}: SReadMoreProps) => {
  const [truncateText] = useState(`${truncate(text, max)}...`);
  const [showMore, setShowMore] = useState(false);

  const handleTogglePreview = useCallback(
    () => setShowMore(prevState => !prevState),
    []
  );

  const isOverflow = text.length > max;
  const moreInfoText = isOverflow && !showMore ? truncateText : text;

  return (
    <Text variant={variant} {...rest}>
      {moreInfoText}
      {isOverflow && (
        <Button type="button" variant="text" onClick={handleTogglePreview}>
          {showMore ? 'Read less' : 'Read more'}
        </Button>
      )}
    </Text>
  );
};

export default SReadMore;
