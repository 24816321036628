import { Box, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { distances } from '@setvi/shared/styles';

export const ButtonContainer = withStyles(({ spacing }) => ({
  root: {
    padding: spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))(Box);

export const CancelButton = withStyles(({ palette }) => ({
  root: {
    color: palette.text.secondary,
    borderColor: palette.divider
  }
}))(Button);

export const useListStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  innerContainer: {
    paddingTop: distances.px.small,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  topTxt: {
    color: '#717480'
  },
  button: {
    border: '1px solid #D5DDEA',
    borderRadius: '8px',
    textTransform: 'none',
    height: 100,
    margin: 'auto'
  },
  favButton: {
    height: 80
  },
  text: {
    fontWeight: 700
  },
  grid: {
    display: 'flex',
    margin: '5px 0px'
  },
  reviewButton: {
    height: '56px'
  },
  buttonContainer: {
    paddingLeft: distances.px.small,
    paddingRight: distances.px.small
  }
});

export const ListContainer = withStyles(({ palette }) => ({
  root: {
    display: 'flex',
    paddingBottom: distances.px.medium,
    borderBottom: `1px solid ${palette.divider}`,
    '& .MuiButton-outlined': {
      padding: '5px 18px'
    }
  }
}))(Box);

export const Container = withStyles({
  root: {
    height: '100%',
    overflowY: 'auto'
  }
})(Box);
