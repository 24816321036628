import { useCallback, useState } from 'react';
import { Box } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { Header } from '../../components/header';
import { Subheader } from '../../components/subheader';
import { SSearchInput } from '../../../sforms';
import { ResourceList } from '../../components/resource/list';
import { SEmptyState } from '../../..';
import { DrawerLoader } from '../../components/loader';
import { useMyResources } from './useMyResources';
import { CategoryList } from '../../components/category/list';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { myResourceFilter, useAlgolia } from '../../../../hooks';
import { useSelectResources } from '../../hooks/useSelectResources';
import { Link } from '../../../../interfaces';
import { getLinkItemFromResource } from '../../../../utils';
import { SelectedResourcesPreview } from '../../components/selected-resources-preview';
import { LinkForm } from '../../components/link-form';
import { ConfirmSelection } from '../../components/confirm-selection';

interface MyResourcesProps {
  algoliaKey: string;
  sasToken: string;
  handleClose: () => void;
  handleBack: () => void;
  handleInsert: (link: Link) => void;
  useLinkForm: boolean;
  hideBackButton?: boolean;
}

export const MyResources = ({
  algoliaKey,
  sasToken,
  handleClose,
  handleBack,
  handleInsert,
  useLinkForm,
  hideBackButton
}: MyResourcesProps) => {
  const [search, setSearch] = useState<string>('');
  const {
    isLoading: isSearchLoading,
    resources: searchResources,
    searchAlgoliaResources,
    loadNextAlgoliaResult
  } = useAlgolia({
    algoliaKey,
    sasToken,
    filters: myResourceFilter
  });

  const handleSearch = useCallback(
    (searchTerm: string) => {
      setSearch(searchTerm);
      searchAlgoliaResources(searchTerm);
    },
    [searchAlgoliaResources]
  );

  const loadNextAlgoliaPage = useCallback(() => {
    loadNextAlgoliaResult(search);
  }, [loadNextAlgoliaResult, search]);

  const {
    categories,
    fetchMoreCategories,
    categoryId,
    setCategoryId,
    resources,
    fetchMoreResources,
    showResources,
    isLoading
  } = useMyResources();

  const [linkName, setLinkName] = useState<string>('');

  const { selectedResources, handleSelectResources, handleSelectPages } =
    useSelectResources();

  const clearSelected = useCallback(() => {
    handleSelectResources(false, selectedResources);
  }, [handleSelectResources, selectedResources]);

  const handleInsertLink = useCallback(
    (name: string) => {
      handleInsert({
        Name: name,
        Placeholder: uuidv4(),
        Item: {
          Items: selectedResources.map(item => getLinkItemFromResource(item))
        }
      });
      clearSelected();
    },
    [handleInsert, selectedResources, clearSelected]
  );

  const showEmptyState =
    search.length > 0
      ? !isSearchLoading && searchResources.length === 0
      : !isLoading && resources.length === 0 && categories.length === 0;

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Add from SETVI" handleClose={handleClose} />
      <Subheader text="My Files" handleBack={!hideBackButton && handleBack} />
      <Box m={2}>
        <SSearchInput onChange={handleSearch} defaultValue={search} />
      </Box>
      <Box>
        <Breadcrumbs categoryId={categoryId} onClick={setCategoryId} />
      </Box>
      <Box overflow="auto">
        {search.length > 0 ? (
          <ResourceList
            resources={searchResources}
            fetchMoreResources={loadNextAlgoliaPage}
            onSelect={handleSelectResources}
            onSelectPages={handleSelectPages}
            selectedResources={selectedResources}
            linkForm={useLinkForm}
          />
        ) : (
          <>
            <CategoryList
              categories={categories}
              fetchMoreCategories={fetchMoreCategories}
              onCategoryClick={cat => setCategoryId(cat.ID)}
            />
            {showResources && (
              <ResourceList
                resources={resources}
                fetchMoreResources={fetchMoreResources}
                onSelect={handleSelectResources}
                onSelectPages={handleSelectPages}
                selectedResources={selectedResources}
                linkForm={useLinkForm}
              />
            )}
          </>
        )}
        <>
          {(isLoading || isSearchLoading) && <DrawerLoader />}
          {showEmptyState && (
            <Box>
              <SEmptyState
                imageSrc="/images/no-resources.svg"
                title={
                  search.length > 0
                    ? 'No Match found'
                    : 'No Resources Available'
                }
                subTitle={
                  search.length > 0
                    ? 'Please try again with a different search term'
                    : 'You have no resources in this category'
                }
              />
            </Box>
          )}
        </>
      </Box>
      <Box mt="auto">
        {selectedResources.length > 0 && (
          <SelectedResourcesPreview
            resources={selectedResources}
            onRemoveResource={resource =>
              handleSelectResources(false, [resource])
            }
            customAction={
              useLinkForm ? (
                <LinkForm
                  initialValue={linkName}
                  handleClose={clearSelected}
                  handleSubmit={handleInsertLink}
                  handleOnBlur={setLinkName}
                />
              ) : (
                <ConfirmSelection
                  onConfirm={() => handleInsertLink(linkName)}
                  onCancel={clearSelected}
                />
              )
            }
          />
        )}
      </Box>
    </Box>
  );
};
