import { Box, IconButton } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import SETVIImage from '../../../../setvi-image';
import SText from '../../../../sui/stext';
import { FillFolderIcon } from '../../../../sicons';
import { useCategoryCardStyles } from './style';
import { Category } from '../../../../../interfaces';

interface CategoryCardProps {
  category: Category;
  onCategoryClick: (category: Category) => void;
}

export const CategoryCard = ({
  category,
  onCategoryClick
}: CategoryCardProps) => {
  const classes = useCategoryCardStyles();

  return (
    <Box
      className={classes.innerWrap}
      onClick={() => onCategoryClick(category)}>
      <SETVIImage
        className={classes.panelImage}
        src={category.ThumbURLWithSas}
        errorImage="/images/resource-category-error-img.png"
        alt="Category"
        loading="lazy"
      />
      <Box flex={1} ml={2}>
        <SText
          title={category.Name}
          className={classes.itemName}
          weight="medium"
          size="sm"
          maxLines={2}
        />
        <Box display="flex" alignItems="center">
          <FillFolderIcon fontSize="small" />
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <IconButton size="small">
          <ChevronRight />
        </IconButton>
      </Box>
    </Box>
  );
};
