/**
 * return the initials of a string if possible.
 * @param fullName string to get initials from
 */

interface GetInitialsProps {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
}
const removeFreeSpace = (value = '') => (value ? value.trim() : '');

export const getInitials = ({
  firstName: first_name,
  lastName: last_name,
  fullName: full_name,
  email: email_address
}: GetInitialsProps) => {
  const firstName = removeFreeSpace(first_name);
  const lastName = removeFreeSpace(last_name);
  const fullName = removeFreeSpace(full_name);
  const email = removeFreeSpace(email_address);

  if (!lastName && firstName) {
    return `${firstName[0]}`.toUpperCase();
  }
  if (lastName && !firstName) {
    return `${lastName[0]}`.toUpperCase();
  }
  if (!lastName && !firstName && email) {
    return `${email[0]}${email[1]}`.toUpperCase();
  }
  if (lastName && firstName) {
    return `${firstName[0] || ''}${lastName[0] || ''}`.toUpperCase();
  }
  if (fullName) {
    const splitName = fullName.split(' ');

    return `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`;
  }

  return '';
};
