import { AxiosMethods, ContactsApi, SortType } from '@setvi/shared/enums';
import { queryVariablesType } from '@setvi/shared/interfaces';
import { axiosHelper } from '@setvi/shared/services';
import { UseQueryOptions } from '@tanstack/react-query';
import { SuggestedContactsQuery } from './Types';

export enum ContactQueryKey {
  CONTACTS = 'contacts',
  CAMPAIGN_CONTACTS = 'campaign_contacts',
  SUGGESTED_CONTACTS = 'suggested_contacts'
}

const dynamicId = ':campaignId';

export const getContactsQuery = (input?: queryVariablesType) => ({
  queryKey: [ContactQueryKey.CONTACTS, input],
  queryFn: (data: any) => {
    const { page, size, ...rest } = data.queryKey[1];
    const params = {
      pageNumber: page,
      pageSize: size,
      ...rest
    };

    return axiosHelper({
      endpoint: `${ContactsApi.Contacts.replace(
        dynamicId,
        data.queryKey[1]?.campaignId
      )}`,
      method: AxiosMethods.GET,
      params
    });
  }
});

interface getSuggestedContactsParams {
  search: string;
  count: number;
  sortBy: string;
  orderBy: SortType;
}

export const getSuggestedContacts = (
  params: getSuggestedContactsParams
): UseQueryOptions<SuggestedContactsQuery> => ({
  queryKey: [ContactQueryKey.SUGGESTED_CONTACTS, params],
  queryFn: () =>
    axiosHelper({
      endpoint: ContactsApi.SuggestedContacts,
      method: AxiosMethods.GET,
      params
    }),
  keepPreviousData: true,
  enabled: params.search.length > 0
});
