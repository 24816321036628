import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getResource } from '../../services';
import { ChildResource } from '../../interfaces';
import { PDFResourceChildrenList } from './pdf';
import { PPTResourceChildrenList } from './ppt';
import { ResourceType } from '../../enums';

interface ListProps {
  resource: any;
  selected: ChildResource[];
  handleSelect: (isChecked: boolean, selected: ChildResource[]) => void;
}

export const SResourceChildrenList = ({
  resource,
  selected,
  handleSelect
}: ListProps) => {
  const [activePage, setActivePage] = useState<ChildResource | null>(null);

  const { data, isLoading, isFetching } = useQuery({
    ...getResource(resource.ID)
  });

  const loading = isLoading || isFetching;

  useEffect(() => data && setActivePage(data.ChildResources[0]), [data]);

  if (resource.ResourceTypeID === ResourceType.Presentation) {
    return (
      <PPTResourceChildrenList
        resource={resource}
        list={data?.ChildResources || []}
        loading={loading}
        selected={selected}
        handleSelect={handleSelect}
        setActivePage={setActivePage}
        activePage={activePage}
      />
    );
  }

  return (
    <PDFResourceChildrenList
      resource={resource}
      list={data?.ChildResources || []}
      loading={loading}
      selected={selected}
      handleSelect={handleSelect}
      setActivePage={setActivePage}
      activePage={activePage}
    />
  );
};
