import { makeStyles } from '@material-ui/core';
import { distances } from '@setvi/shared/styles';

export const useNavbarStyles = makeStyles(({ transitions }) => ({
  root: {
    borderBottom: '1px solid #e2e2ea',
    padding: `0 ${distances.px.large}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    boxShadow: 'none',
    transition: transitions.create(['width', 'margin'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen
    })
  }
}));
