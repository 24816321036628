import { makeStyles } from '@material-ui/core';
import { distances } from '@setvi/shared/styles';

export const usePresentationDialogStyles = makeStyles(
  ({ spacing, palette }) => ({
    actionPaper: {
      boxShadow: 'none',
      paddingTop: distances.px.medium,
      display: 'flex',
      gap: 10,
      '& button': {
        height: 50
      }
    },
    label: {
      color: '#44444f',
      fontWeight: 700
    },
    confirmBtn: {
      textTransform: 'capitalize'
    },
    input: {
      marginTop: spacing(2),
      caretColor: palette.primary.main,
      '& fieldset': {
        borderRadius: 8,
        border: '1px solid transparent',
        background: 'rgba(123, 151, 206, 0.06)'
      }
    },
    required: {
      color: palette.error.main
    },
    description: {
      color: 'rgba(21, 25, 32, 0.5)',
      fontSize: 14,
      margin: spacing(1, 0, 3, 0)
    }
  })
);
