import * as yup from 'yup';

interface ValidateSyncProps {
  schema: any;
  values: any;
}

type Values = {
  [field: string]: any;
};

type ValidationErrors<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]?: T[K] extends object ? ValidationErrors<T[K]> : string;
};

function updateIn(obj: any, path: string, value: any): any {
  const pathArray = path?.split('.') || '';
  let destinationObject = obj;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < pathArray?.length - 1; i++) {
    if (pathArray[i] in destinationObject === false) {
      destinationObject[pathArray[i]] = {};
    }
    destinationObject = destinationObject[pathArray[i]];
  }
  destinationObject[pathArray[pathArray.length - 1]] = value;
}

/**
 * Transform Yup errors to a ValidationErrors object
 */
export function yupToValidationErrors<T extends Values>(
  yupError: any
): ValidationErrors<T> {
  const errors: any = {} as ValidationErrors<Values>;

  if (!yupError?.length || yupError?.inner?.length === 0) {
    updateIn(errors, yupError.path, yupError.message);
    return errors;
  }
  for (const err of yupError?.inner) {
    updateIn(errors, err.path, err.message);
  }
  return errors;
}

export const validateSync = ({ schema, values }: ValidateSyncProps) => {
  try {
    yup.object().shape(schema).validateSync(values, { abortEarly: false });

    return null;
  } catch (e) {
    const errorObj = yupToValidationErrors(e);
    const error = errorObj[Object.keys(errorObj)[0]];

    return error as string;
  }
};
