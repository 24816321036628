import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

export const Container = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${spacing(2)}px ${spacing(3)}px`
  }
}))(Box);
