import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const ContentOverlay = styled.div`
  background: rgba(30, 150, 252, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  &:hover {
    opacity: 1;
  }
  width: 70px;
  height: 52px;
  border-radius: 6px;
  object-fit: cover;
`;

export const OverlayContainer = withStyles(({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: palette.common.white
  }
}))(Box);

export const OverlayContent = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
    paddingTop: spacing(1)
  }
}))(Box);
