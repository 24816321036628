import { axiosHelper } from '@setvi/shared/services';
import { AxiosMethods, CompanyCampaignApi } from '@setvi/shared/enums';

export enum AdminCampaignsMutationKey {
  UPDATE_STATUS = 'admin_update_status',
  UPDATE_CAMPAIGN = 'admin_update_campaign',
  CREATE_CAMPAIGN = 'admin_create_campaign'
}

export const createAdminCampaignMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: CompanyCampaignApi.Campaigns,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export const updateAdminCampaignMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: CompanyCampaignApi.Campaigns,
      method: AxiosMethods.PUT,
      params,
      body
    })
});

export const updateAdminCampaignStatusMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: CompanyCampaignApi.Status,
      method: AxiosMethods.PATCH,
      params,
      body
    })
});
