import { useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getCrmDataQuery, CrmTypes } from '@setvi/shared/services';
import {
  Account,
  GetCrmDataQuery,
  City,
  State
} from '@setvi/shared/services/react-query/query/user/types';
import { useCrmProps } from '../leads/useLeads';

const ACCOUNTS_LIMIT = 20;
interface useAccountsProps extends useCrmProps {
  state?: State['BillingState'][];
  city?: City[];
}
export const useAccounts = ({
  salesforceUser,
  state = [],
  city = []
}: useAccountsProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const queryVariables = useMemo(
    () => ({
      offset: 0,
      pageSize: ACCOUNTS_LIMIT,
      ownerId: salesforceUser.SalesforceUserID,
      type: CrmTypes.Account,
      searchTerm,
      state,
      city: city.map(c => c.BillingCity)
    }),
    [searchTerm, salesforceUser.SalesforceUserID, state, city]
  );

  const {
    data: accounts,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(getCrmDataQuery(queryVariables));

  const fetchMoreAccounts = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    accounts:
      accounts?.pages
        ?.map((page: GetCrmDataQuery<Account>) => page?.Data?.records)
        .flat(1) || [],
    isLoading: isLoading || isFetching,
    setSearchTerm,
    searchTerm,
    fetchMoreAccounts
  };
};
