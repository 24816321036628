import {
  AxiosMethods,
  PresentationType,
  PresentationsApi
} from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

export enum CampaignsMutationsKey {
  UPDATE_STATUS = 'update_status',
  CREATE_CAMPAIGN = 'create_campaign',
  UPDATE_CAMPAIGN = 'update_campaign'
}

const itemParsed = (params: any, modify?: boolean | null) =>
  params?.presentationItems
    ?.map((item: any) => `0:${item}${modify ? `:true` : ''}`)
    ?.toString();

export const createPresentationMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: PresentationsApi.CreatePresentation,
      method: AxiosMethods.POST,
      params,
      body: {
        name: body.name,
        items: itemParsed(body),
        message: '',
        presentationtypeid: 1
      }
    }),
  select: (res: any) => res?.Data || null
});

export const modifyPresentationMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: PresentationsApi.ModifyPresentation,
      method: AxiosMethods.POST,
      params,
      body: {
        id: body.id,
        name: body.name,
        items: itemParsed(body, true),
        message: '',
        presentationtypeid: 1,
        active: body?.archived
          ? PresentationType.ARCHIVED
          : PresentationType.PRESENTATION
      }
    }),
  select: (res: any) => res?.Data || null
});

export const deletePresentationMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: PresentationsApi.DeletePresentation,
      method: AxiosMethods.POST,
      params,
      body
    }),
  select: (res: any) => res?.Data || null
});
