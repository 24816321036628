import { useCallback, useMemo } from 'react';
import { client } from 'filestack-react';
import { useSnackbar } from 'notistack';
import CryptoJS from 'crypto-js';

interface SUploadTags {
  [key: string]: string;
}
export interface FileStackToken {
  cancel: () => void;
}

interface FileStackUploadProps {
  file: File;
  tags?: SUploadTags;
  error?: {
    show: boolean;
    message?: string;
  };
  getProgress?: (total: number) => void;
}

export const useFilestack = () => {
  const { enqueueSnackbar } = useSnackbar();
  const filestackToken: FileStackToken = useMemo(() => ({ cancel: null }), []);

  const fileStackUpload = useCallback(
    ({ file, tags, error, getProgress }: FileStackUploadProps) =>
      client
        .init(process.env.FILESTACK_KEY)
        .upload(
          file,
          {
            onProgress: async ({ totalPercent }: { totalPercent: number }) =>
              getProgress(totalPercent),
            tags
          },
          {
            location: 'azure',
            // With this we save original name of the file
            sanitizer: {
              exclude: [],
              replacement: ''
            }
          },
          filestackToken
        )
        .then(res => res)
        .catch(err => {
          if (error?.show)
            enqueueSnackbar(
              error?.message ||
                'There was an error uploading your file, please try again later',
              {
                variant: 'error'
              }
            );

          return err;
        }),
    [enqueueSnackbar, filestackToken]
  );

  const filestackDelete = async (handle: string) => {
    if (!handle) return;

    const newPolicy = {
      expiry: Math.floor(Date.now() / 1000) + 10 // Set expiration time (in this example, 1 hour from now)
      // Add other policy details as needed
    };

    // Convert the security policy to base64-encoded JSON
    const policyBase64 = btoa(JSON.stringify(newPolicy));

    // Generate the signature using your Filestack API secret
    const newSignature = CryptoJS.HmacSHA256(
      policyBase64,
      process.env.FILESTACK_HASH_KEY
    ).toString();

    await client
      .init(process.env.FILESTACK_KEY, {
        security: {
          policy: policyBase64,
          signature: newSignature
        }
      })
      .remove(handle)
      .catch(() => {
        enqueueSnackbar(
          'There was an error removing your file, please try again later',
          {
            variant: 'error'
          }
        );
      });
  };

  return {
    filestackToken,
    filestackDelete,
    fileStackUpload
  };
};
