export enum CompanySettingType {
  FocusSwitch = 1,
  EnableSyncOptions = 2,
  AllowBasicSync = 3,
  NumberRequired = 4,
  MyResources = 5,
  IsShareable = 6,
  AllowInternalDownloads = 7,
  AllowExternalDownloads = 8,
  AssignAllUsersGroups = 9,
  WebIntegrationAllowed = 10,
  LogoUrl = 11,
  BackgroundUrl = 12,
  iPhoneLogoUrl = 13,
  SntBackgroundUrl = 14,
  iPhoneBackgroundUrl = 15,
  HomePageStyle = 16,
  WebAppLogoUrl = 17,
  DefaultWebAPPiPadBanner = 18,
  DefaultMobileBanner = 19,
  CustomCNameForShareLinks = 20,
  SubjectEngageEmail = 21,
  DisplayNameEngageEmail = 22,
  CRMTaskUrl = 25,
  CRMTaskApiKey = 26,
  CRMClientUrl = 27,
  CRMClientApiKey = 28,
  AllowExternalDomainUsers = 29,
  AkeneoClientID = 30,
  AkeneoSecret = 31,
  AkeneoUsername = 32,
  AkeneoPassword = 33,
  AkeneoUrl = 34,
  AkeneoUser = 35,
  AkeneoSupportEmail = 36,
  EmailSenderStatus = 37,
  CompanyType = 38,
  SalesforceCDC_TokenURL = 39,
  SalesforceCDC_ConsumerKey = 40,
  SalesforceCDC_ConsumerSecret = 41,
  AtlasMongoDB_URL = 42,
  AtlasMongoDB_Name = 43,
  Algolia_AppID = 44,
  Algolia_IndexName = 45,
  Algolia_Admin_APIKey = 46,
  Algolia_SearchAPIKey = 47,
  PricingStrategy = 48,
  InventoryStrategy = 49
}
