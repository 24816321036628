import { SvgIcon, SvgIconProps } from '@material-ui/core';

/* *********************************************************************
    INSTRUCTIONS FOR ADDING NEW ICONS
    MAKE SURE THAT NAME IS GIVING DESCRIPTION OF ICON
    'Icon' SHOULD BE INCLUDED IN NAME
    Use 'color' attribute if you want to use color provided by theme 
    Use 'fontSize' attribute to handle size of icon
    Use 'htmlColor' attribute to add custom color to icon
    Use 'inheritViewBox' attribute if you want to override defualt viewBox
    Use 'viewBox' attribute if you want to add custom viewBox
    refrences: https://mui.com/material-ui/api/svg-icon/#main-content
********************************************************************** */

export const BoxIcon = (props: SvgIconProps) => (
  <SvgIcon width="28" height="15" viewBox="0 0 28 15" fill="none" {...props}>
    <path
      d="M14.6819 4.5625C12.7781 4.5625 11.1214 5.63342 10.287 7.20564C9.45236 5.63342 7.79652 4.5625 5.89138 4.5625C4.77272 4.5625 3.73924 4.93257 2.90532 5.55752V1.36602C2.89546 0.824414 2.45376 0.390869 1.9103 0.390869C1.36683 0.390869 0.929428 0.824414 0.916992 1.36602V9.61979H0.917993C0.961876 12.3298 3.17033 14.513 5.89138 14.513C7.79652 14.513 9.45236 13.4411 10.287 11.8704C11.1214 13.4411 12.7781 14.513 14.6819 14.513C17.4287 14.513 19.6569 12.2857 19.6569 9.53674C19.6569 6.78925 17.4287 4.5625 14.6819 4.5625ZM5.89138 12.5211C4.24312 12.5211 2.90532 11.1859 2.90532 9.53745C2.90532 7.88962 4.24312 6.55425 5.89138 6.55425C7.53951 6.55425 8.87444 7.88962 8.87444 9.53674C8.87444 11.1854 7.53951 12.5211 5.89138 12.5211ZM14.6819 12.5211C13.0332 12.5211 11.6964 11.1854 11.6964 9.53674C11.6964 7.88962 13.0332 6.55425 14.6819 6.55425C16.3299 6.55425 17.666 7.88962 17.666 9.53674C17.666 11.1854 16.3299 12.5211 14.6819 12.5211Z"
      fill="#0071F7"
    />
    <path
      d="M27.3775 12.8457L24.6733 9.5287L27.3808 6.20529C27.7232 5.76689 27.625 5.15467 27.1524 4.82947C26.6793 4.50142 26.0154 4.58547 25.6494 5.01087V5.01029L23.32 7.86513L20.9927 5.01029V5.01087C20.6304 4.58547 19.9624 4.50142 19.4907 4.82947C19.019 5.15495 18.9203 5.76689 19.2644 6.20529H19.2634L21.9662 9.5287L19.2634 12.8457H19.2644C18.9203 13.2855 19.019 13.8957 19.4907 14.2223C19.9624 14.549 20.6304 14.4658 20.9927 14.0398L23.32 11.189L25.6461 14.0398C26.0126 14.4658 26.6761 14.549 27.1492 14.2223C27.622 13.8957 27.7206 13.2855 27.3775 12.8457Z"
      fill="#0071F7"
    />
  </SvgIcon>
);

export const DropboxIcon = (props: SvgIconProps) => (
  <SvgIcon width="28" height="23" viewBox="0 0 28 23" fill="none" {...props}>
    <path
      d="M7.08332 8.55737L13.7508 4.30737L7.08332 0.057373L0.416992 4.30737L7.08332 8.55737Z"
      fill="#0061FF"
    />
    <path
      d="M20.4173 8.55737L27.0836 4.30737L20.4173 0.057373L13.751 4.30737L20.4173 8.55737Z"
      fill="#0061FF"
    />
    <path
      d="M13.7508 12.8074L7.08332 8.55737L0.416992 12.8074L7.08332 17.0574L13.7508 12.8074Z"
      fill="#0061FF"
    />
    <path
      d="M20.4173 17.0574L27.0836 12.8074L20.4173 8.55737L13.751 12.8074L20.4173 17.0574Z"
      fill="#0061FF"
    />
    <path
      d="M20.4178 18.4741L13.7514 14.2241L7.08398 18.4741L13.7514 22.7241L20.4178 18.4741Z"
      fill="#0061FF"
    />
  </SvgIcon>
);

export const OnedriveIcon = (props: SvgIconProps) => (
  <SvgIcon width="28" height="18" viewBox="0 0 28 18" fill="none" {...props}>
    <path
      d="M16.5273 12.2741L22.0316 6.93409C20.8882 2.4114 16.3472 -0.314708 11.889 0.845175C9.88129 1.36751 8.13753 2.63007 6.99805 4.38641C7.08322 4.38423 16.5273 12.2741 16.5273 12.2741Z"
      fill="#0364B8"
    />
    <path
      d="M10.5855 5.3913L10.5852 5.39222C9.53348 4.73139 8.32051 4.38209 7.08337 4.3838C7.05478 4.3838 7.02669 4.38563 6.99819 4.38597C3.31817 4.43222 0.371949 7.49605 0.417514 11.2292C0.434033 12.5833 0.85112 13.9012 1.61472 15.0121L9.86103 13.9644L16.1833 8.79276L10.5855 5.3913Z"
      fill="#0078D4"
    />
    <path
      d="M22.032 6.93379C21.911 6.9256 21.7894 6.91992 21.6663 6.91992C20.9279 6.91919 20.1972 7.0722 19.5194 7.36947L19.5192 7.36889L16.1836 8.79288L19.8609 13.4009L26.4349 15.0221C27.8536 12.3503 26.8686 9.01766 24.2349 7.57847C23.5543 7.2066 22.8032 6.98679 22.032 6.93382L22.032 6.93379Z"
      fill="#1490DF"
    />
    <path
      d="M1.61523 15.0143C2.86038 16.8303 4.90327 17.9127 7.08388 17.9115H21.6666C23.6586 17.912 25.4901 16.8031 26.4352 15.0242L16.1838 8.79492L1.61523 15.0143Z"
      fill="#28A8EA"
    />
  </SvgIcon>
);

export const GoogleDriveIcon = (props: SvgIconProps) => (
  <SvgIcon width="28" height="24" viewBox="0 0 28 24" fill="none" {...props}>
    <path
      d="M2.92549 20.4622L4.09788 22.4957C4.34148 22.9238 4.69215 23.2604 5.10408 23.5055L9.30949 20.5238L9.31715 16.2431L5.12043 14.3523L0.916996 16.228C0.916163 16.7015 1.03747 17.1752 1.28111 17.6032L2.92549 20.4622Z"
      fill="#0066DD"
    />
    <path
      d="M14.2656 7.69883L14.485 2.12258L10.0785 0.421387C9.66569 0.665033 9.31382 1.0004 9.06866 1.42758L1.286 14.8539C1.0409 15.2812 0.917826 15.7544 0.916992 16.2278L9.31715 16.2428L14.2656 7.69883Z"
      fill="#00AD3C"
    />
    <path
      d="M14.2653 7.69894L18.3019 5.52738L18.4783 0.436492C18.0664 0.191388 17.5931 0.0683669 17.1044 0.0674815L11.4534 0.0573773C10.9646 0.0564919 10.4909 0.193107 10.0781 0.421492L14.2653 7.69894Z"
      fill="#00831E"
    />
    <path
      d="M19.1839 16.2605L9.31751 16.2429L5.10449 23.5053C5.51642 23.7504 5.9897 23.8734 6.4784 23.8743L21.9957 23.902C22.4845 23.9029 22.9582 23.7663 23.371 23.5379L23.3874 18.6598L19.1839 16.2605Z"
      fill="#0084FF"
    />
    <path
      d="M23.371 23.5381C23.7838 23.2944 24.1357 22.9591 24.3808 22.5319L24.871 21.6928L27.2151 17.6496C27.4602 17.2224 27.5832 16.7492 27.5841 16.2757L22.7288 13.7209L19.1992 16.2607L23.371 23.5381Z"
      fill="#FF4131"
    />
    <path
      d="M23.3523 8.17345L19.4849 1.44637C19.2414 1.01824 18.8907 0.681628 18.4787 0.436523L14.2656 7.69897L19.1835 16.2607L27.5684 16.2757C27.5692 15.8023 27.4479 15.3286 27.2043 14.9004L23.3523 8.17345Z"
      fill="#FFBA00"
    />
  </SvgIcon>
);

export const GoogleIcon = (props: SvgIconProps) => (
  <SvgIcon width="25" height="25" viewBox="0 0 25 25" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.14 12.7047C21.14 12.0665 21.0827 11.4529 20.9764 10.8638H12.5V14.3451H17.3436C17.135 15.4701 16.5009 16.4233 15.5477 17.0615V19.3197H18.4564C20.1582 17.7529 21.14 15.4456 21.14 12.7047Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4998 21.5C14.9298 21.5 16.967 20.6941 18.4561 19.3195L15.5475 17.0613C14.7416 17.6013 13.7107 17.9204 12.4998 17.9204C10.1557 17.9204 8.17158 16.3372 7.46385 14.21H4.45703V16.5418C5.93794 19.4831 8.98158 21.5 12.4998 21.5Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.46409 14.2098C7.28409 13.6698 7.18182 13.093 7.18182 12.4998C7.18182 11.9066 7.28409 11.3298 7.46409 10.7898V8.45801H4.45727C3.84773 9.67301 3.5 11.0476 3.5 12.4998C3.5 13.9521 3.84773 15.3266 4.45727 16.5416L7.46409 14.2098Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4998 7.07955C13.8211 7.07955 15.0075 7.53364 15.9402 8.42545L18.5216 5.84409C16.9629 4.39182 14.9257 3.5 12.4998 3.5C8.98158 3.5 5.93794 5.51682 4.45703 8.45818L7.46385 10.79C8.17158 8.66273 10.1557 7.07955 12.4998 7.07955Z"
      fill="#EA4335"
    />
  </SvgIcon>
);
