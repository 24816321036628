import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useSGalleryThumbnailStyles } from '../style';
import SETVIImage from '../../setvi-image';

interface SGalleryThumbnailProps {
  src: string;
  active: boolean;
  onClick?: () => void;
}

export const SGalleryThumbnail = ({
  src,
  active,
  onClick
}: SGalleryThumbnailProps) => {
  const styles = useSGalleryThumbnailStyles();
  return (
    <Box
      onClick={onClick}
      className={clsx({ [styles.container]: true, [styles.active]: active })}>
      <SETVIImage className={styles.thumbnail} src={src} alt="thumbnail" />
    </Box>
  );
};
