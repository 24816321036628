import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';
import { SFormikTextfield } from '@setvi/shared/components';
import { TextColor } from '@setvi/shared/enums';

const useStyles = makeStyles(({ spacing }) => ({
  disabledInput: {
    '& div input': {
      color: TextColor.Black
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(3)
  },
  paddedBox: {
    paddingBottom: spacing(5)
  },
  titleText: {
    fontSize: '1rem',
    fontWeight: 500
  }
}));

interface UserInformationFormInterface {
  disabled: boolean;
  label: string;
}

export const UserInformationForm = ({
  disabled,
  label
}: UserInformationFormInterface) => {
  const classes = useStyles();

  const inputWithLabelList = [
    {
      name: 'FirstName',
      labelTitle: 'First Name',
      placeholder: 'Enter first name',
      required: true
    },
    {
      name: 'LastName',
      labelTitle: 'Last Name',
      placeholder: 'Enter last name',
      required: true
    },
    {
      name: 'Email',
      labelTitle: 'Email',
      placeholder: '',
      disabled: true
    },
    {
      name: 'PhoneNumber',
      labelTitle: 'Phone Number',
      placeholder: 'Enter phone number',
      required: window.location.origin.includes('middleby')
    }
  ];

  return (
    <>
      <Grid item xs={12}>
        <Box className={classes.paddedBox}>
          <Typography className={classes.titleText} variant="h6">
            {label}
          </Typography>
        </Box>
      </Grid>
      <Grid container className={classes.container}>
        {inputWithLabelList.map(val => (
          <Grid item key={val.name}>
            <SFormikTextfield
              name={val.name}
              label={val.labelTitle}
              placeholder={val.placeholder}
              required={val.required}
              disabled={disabled || val.disabled}
              inputClass={classes.disabledInput}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
