import { makeStyles, withStyles } from '@material-ui/core';
import SButton from '../../../../sbutton';

export const HomeButton = withStyles(({ spacing }) => ({
  root: {
    padding: spacing(0),
    width: 32,
    height: 32,
    marginRight: spacing(2),
    minHeight: 'auto'
  }
}))(SButton);

export const useHomeButtonStyles = makeStyles(theme => ({
  homeIcon: {
    color: theme.palette.common.white
  }
}));
