import { useCallback, useState } from 'react';
import { Box } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { Template } from '../../../../services/react-query/query/templates/types';
import { Link } from '../../../../interfaces';
import { Header } from '../../components/header';
import { Subheader } from '../../components/subheader';
import { DrawerLoader } from '../../components/loader';
import { ConfirmSelection } from '../../components/confirm-selection';
import { useTemplates } from './useTemplates';
import { TemplateList } from '../../components/template/list';
import { SEmptyState } from '../../..';
import { getLinkItemFromTemplateItem } from '../../../../utils';

interface TemplatesProps {
  handleClose: () => void;
  handleBack: () => void;
  handleInsert: (link: Link) => void;
  hideBackButton?: boolean;
}

export const Templates = ({
  handleClose,
  handleBack,
  handleInsert,
  hideBackButton
}: TemplatesProps) => {
  const { templates, isLoading } = useTemplates();
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>();

  const handleInsertTemplate = useCallback(() => {
    handleInsert({
      Name: selectedTemplate.Name,
      Placeholder: uuidv4(),
      ParentID: selectedTemplate.ID,
      Item: {
        ID: selectedTemplate.ID,
        Name: selectedTemplate.Name,
        ThumbURL: selectedTemplate.ThumbURL,
        Items: selectedTemplate.Items.map(item =>
          getLinkItemFromTemplateItem(item)
        )
      }
    });
  }, [handleInsert, selectedTemplate]);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Add from SETVI" handleClose={handleClose} />
      <Subheader text="Templates" handleBack={!hideBackButton && handleBack} />
      <Box overflow="auto">
        <TemplateList
          templates={templates}
          onSelectTemplate={(_isChecked, temps) =>
            setSelectedTemplate(temps[0])
          }
          selectedTemplates={[selectedTemplate]}
        />
        {isLoading && <DrawerLoader />}
        {!isLoading && templates.length === 0 && (
          <Box>
            <SEmptyState
              imageSrc="/images/no-templates.svg"
              title="No templates available"
            />
          </Box>
        )}
      </Box>
      <Box mt="auto">
        <ConfirmSelection
          onConfirm={handleInsertTemplate}
          onCancel={() => setSelectedTemplate(null)}
        />
      </Box>
    </Box>
  );
};
