import { AxiosMethods, BannersApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

export enum BannersQueryKey {
  BANNERS = 'banners'
}

export const bannersQuery = () => ({
  queryKey: [BannersQueryKey.BANNERS],
  queryFn: () =>
    axiosHelper({
      endpoint: BannersApi.Banners,
      method: AxiosMethods.POST
    }),
  select: (res: any) => res?.Data
});
