import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

export const InputContainer = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    height: 44,
    '& .MuiInputBase-root': {
      fontSize: '0.875rem'
    }
  }
}))(Box);

export const ErrorContainer = withStyles(({ spacing }) => ({
  root: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
    wordBreak: 'break-all',
    marginRight: spacing(2)
  }
}))(Box);

export const useStyles = makeStyles(theme => ({
  textLabel: {
    fontWeight: 500,
    color: theme.palette.grey[500]
  },
  textLabelContainer: {
    flexShrink: 0,
    paddingRight: 10
  },
  input: {
    width: '100%'
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '0.875rem',
    marginBottom: theme.spacing(1)
  },
  boldText: {
    fontWeight: 500
  }
}));
