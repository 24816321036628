import { Box } from '@material-ui/core';

import { SETVIImage, SText } from '@setvi/shared/components';

interface HeaderProps {
  name: string;
  icon: string;
  adornment?: JSX.Element;
}

const Header = ({ icon, name, adornment }: HeaderProps) => (
  <Box display="flex" alignItems="center" gridGap={6}>
    <Box>
      {adornment}

      <SETVIImage src={icon} alt="File icon" width={15} />
    </Box>
    <SText size="sm" title={name} weight="semibold" fontColor="#171725">
      {name}
    </SText>
  </Box>
);

export default Header;
