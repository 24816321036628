import { User } from '@setvi/shared/services/react-query/query/user/types';

import { RolesEnumId } from '../../enums';

export const userPermissions = (user: User, permissionId: number) => {
  if (user?.Role === RolesEnumId.ADMIN) return true;

  const isAllowed = user?.StandardAdminRights?.find(
    right => right?.ID === permissionId
  )?.IsUserAllowed;

  return isAllowed;
};
