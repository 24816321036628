import { useCallback, useEffect, useMemo, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Divider, IconButton, useTheme } from '@material-ui/core';

import { RESOURCE_UPLOAD_STATUS, ResourceType } from '@setvi/shared/enums';
import { useFilestack } from '@setvi/shared/hooks';
import {
  GetResourceTypeIcon,
  getFileExtension,
  isVimeoLink,
  isYoutubeLink
} from '@setvi/shared/utils';

import UploadItem from '../item';
import { UploadedResource } from '../../../../interfaces';
import { ResourceHeader, ResourceUploadStatus } from '../item/components';

export interface IFile extends File {
  mimetype?: string | number;
  filename?: string;
}

export interface FilestackUploadItemProps {
  id: number;
  file: IFile;
  status: RESOURCE_UPLOAD_STATUS;
  error?: string;
  userId?: string;
  filestackId?: string;
  unzipedTotal?: number;
  unzipedProcesed?: number;
  unzippedResources?: UploadedResource[];
  onUploadFinished?: (resourceId: number, filestackId: string) => void;
  onUploadCancel?: (resourceId: number, handleUpload?: () => void) => void;
}

const FilestackUploadItem = ({
  id,
  file,
  error,
  status,
  userId,
  filestackId,
  unzipedTotal,
  unzipedProcesed,
  unzippedResources,
  onUploadCancel,
  onUploadFinished
}: FilestackUploadItemProps) => {
  const { spacing } = useTheme();
  const { filestackToken, fileStackUpload } = useFilestack();

  const [openUnziped, setOpenUnziped] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [filestackError, setFilestackError] = useState(false);

  const handleUpload = useCallback(
    async () => {
      const res = await fileStackUpload({
        file,
        tags: {
          resourceId: id?.toString(),
          ...(userId ? { userId } : {}),
          storage:
            process.env.VERCEL_ENV === 'production' ? 'appfiles' : 'devfiles'
        },
        error: {
          show: false
        },
        getProgress: (total: number) => setProgress(total)
      });

      if (!res?.handle) setFilestackError(true);

      setProgress(100);

      if (res?.handle && !!id) onUploadFinished?.(id, res.handle);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, file]
  );

  useEffect(() => {
    const isResourceLink = [
      ResourceType.WebPage,
      ResourceType.YoutubeVideos,
      ResourceType.VimeoVideos
    ].includes(Number(file.type));

    if (filestackId || isResourceLink) onUploadFinished(id, filestackId || '');
    else handleUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadCancel = useCallback(() => {
    filestackToken?.cancel?.();
    onUploadCancel?.(id, handleUpload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filestackToken]);

  const resourceIcon = useMemo(() => {
    if (Number(file?.type) !== ResourceType.WebPage) {
      return getFileExtension(file?.type);
    }

    if (isYoutubeLink.test(file?.name || file?.filename))
      return ResourceType.YoutubeVideos;
    if (isVimeoLink.test(file?.name || file?.filename))
      return ResourceType.VimeoVideos;

    return ResourceType.WebPage;
  }, [file]);

  return (
    <UploadItem
      header={
        <ResourceHeader
          name={file?.name || file?.filename}
          icon={GetResourceTypeIcon(resourceIcon)}
          adornment={
            !!unzippedResources?.length && (
              <IconButton
                onClick={() => setOpenUnziped(prev => !prev)}
                style={{
                  padding: 0,
                  marginLeft: -4,
                  transition: 'transform 0.3s ease-in-out',
                  transform: `rotate(${openUnziped ? 180 : 0}deg)`
                }}>
                <ExpandMoreIcon fontSize="small" />
              </IconButton>
            )
          }
        />
      }
      body={
        <ResourceUploadStatus
          error={error}
          status={status}
          progress={progress}
          unzipedTotal={unzipedTotal}
          unzipedProcesed={unzipedProcesed}
          onUploadRetry={handleUpload}
          onUploadCancel={handleUploadCancel}
        />
      }
      status={status}
      progress={progress}>
      <Box height={openUnziped ? 'auto' : '0px'} overflow="hidden">
        <Box padding={spacing(0, 0, 0, 6)}>
          {unzippedResources?.map(resource => (
            <UploadItem
              header={
                <ResourceHeader
                  name={resource?.FileName || resource?.Name}
                  icon={GetResourceTypeIcon(resource?.ResourceTypeId)}
                />
              }
              body={
                <ResourceUploadStatus
                  error={
                    resource?.ErrorExceptionMessage || resource?.ErrorMessage
                  }
                  status={
                    filestackError
                      ? RESOURCE_UPLOAD_STATUS.FILESTACK_ERROR
                      : resource?.Processing
                  }
                  onUploadRetry={handleUpload}
                  onUploadCancel={handleUploadCancel}
                />
              }
              status={status}
            />
          ))}
        </Box>
        <Divider />
      </Box>
    </UploadItem>
  );
};

export default FilestackUploadItem;
