import { useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';

import { ExtendedFormik, ExtendedFormikProps } from './extended-formik';
import { FormContext } from './FormContext';

export type AutosaveFormikProps<Values, ExtraProps> = ExtendedFormikProps<
  Values,
  ExtraProps
> & {
  autosaveDelay?: number;
};

const SAutosaveFormik = <
  Values extends Record<string, unknown>,
  ExtraProps = Record<string, unknown>
>({
  autosaveDelay = 300,
  ...props
}: AutosaveFormikProps<Values, ExtraProps>) => {
  const submittedValues = useRef<Values | undefined>();
  const currentValues = useRef<Values | undefined>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSubmit = useCallback(
    _.debounce((submit: () => Promise<void>) => submit(), autosaveDelay),
    [autosaveDelay]
  );

  useEffect(() => {
    const showDialog = (ev: any) => {
      if (!_.isEqual(currentValues.current, submittedValues.current)) {
        ev.preventDefault();
        // eslint-disable-next-line no-param-reassign
        ev.returnValue = ''; // Message is not customizable
      }
    };

    window.addEventListener('beforeunload', showDialog, false);
    return () => window.removeEventListener('beforeunload', showDialog, false);
  }, [submittedValues, currentValues]);

  return (
    <FormContext.Provider value={{ autosave: true }}>
      <ExtendedFormik
        {...props}
        onSubmit={(val, formikHelpers) => {
          if (!_.isEqual(submittedValues.current, val)) {
            props.onSubmit(val, formikHelpers);
            submittedValues.current = val;
          }
        }}
        onChange={context => {
          debounceSubmit(context.submitForm);
          currentValues.current = context.values;
          if (props.onChange) props.onChange(context);
        }}
      />
    </FormContext.Provider>
  );
};

export default SAutosaveFormik;
