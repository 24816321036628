import { Accept } from 'react-dropzone';

export const AcceptedFileTypes: Accept = {
  'image/*': [
    '.jpeg',
    '.jpg',
    '.gif',
    '.png',
    '.tif',
    '.tiff',
    '.jif',
    '.jiff',
    '.jp2',
    '.jpx',
    '.j2k',
    '.j2c',
    '.raw'
  ],
  'music/*': [],
  'video/*': [
    '.mp4',
    '.mov',
    '.m4v',
    '.flv',
    '.f4v',
    '.3g2',
    '.m2v',
    '.3gp',
    '.mp3'
  ],
  'application/pdf': [],
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    [],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx'
  ],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
};
