import { memo } from 'react';
import { Box } from '@material-ui/core';
import { ResourceItem as Item } from '@setvi/shared/components/sdrawer-content/pages/review-links/edit-links/item/ResourceItem';
import { LinkItemItems } from '@setvi/shared/interfaces';
import { GetResourceTypeName } from '@setvi/shared/utils/resources';
import { useSelectedStyles } from './style';

interface ListProps {
  resources: LinkItemItems[];
}

export const List = memo(({ resources }: ListProps) => {
  const classes = useSelectedStyles();
  return (
    <>
      {resources.map(resource => (
        <Box
          className={classes.gridOrange}
          borderLeft={1}
          display="flex"
          key={resource.ID}>
          <Box className={classes.gridBox} flexGrow={1}>
            <Item
              item={resource}
              itemType={
                <span>{GetResourceTypeName(resource.ResourceTypeID)}</span>
              }
              itemAction={<div />}
            />
          </Box>
        </Box>
      ))}
    </>
  );
});
