import { AxiosMethods, CompanyCampaignApi } from '@setvi/shared/enums';
import { queryVariablesType } from '@setvi/shared/interfaces';
import { axiosHelper } from '@setvi/shared/services';
import { UseQueryOptions } from '@tanstack/react-query';
import { UserCompanyCampaign_userCompanyCampaign } from 'Types/api/UserCompanyCampaign';

export enum CompanyCampaignsQueryKey {
  USER_COMPANY_CAMPAIGN = 'user_company_campaign',
  USER_COMPANY_CAMPAIGNS = 'user_company_campaigns'
}

interface CompanyCampaigns extends queryVariablesType {
  required: boolean | null;
}

const dynamicId = ':campaignId';

export const companyCampaignQuery = (
  Id: string | number
): UseQueryOptions<UserCompanyCampaign_userCompanyCampaign> => ({
  queryKey: [CompanyCampaignsQueryKey.USER_COMPANY_CAMPAIGN, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: CompanyCampaignApi.UserCompanyCampaign.replace(
        dynamicId,
        Id.toString()
      ),
      method: AxiosMethods.GET
    }),
  select: (res: any) => ({
    ...res?.Data,
    Links: res?.Data.Links.map((link: any) => ({
      ...link,
      Placeholder: link.Placeholder || link.PlaceHolder,
      PlaceHolder: link.PlaceHolder || link.Placeholder
    }))
  }),
  refetchOnWindowFocus: false
});

export const companyCampaignsQuery = (input: CompanyCampaigns) => ({
  queryKey: [CompanyCampaignsQueryKey.USER_COMPANY_CAMPAIGNS, input],
  queryFn: (data: any) => {
    const { page, size, search, ...rest }: CompanyCampaigns = data.queryKey[1];
    const params = {
      pageNumber: page,
      pageSize: size,
      searchString: search,
      ...rest
    };
    return axiosHelper({
      endpoint: CompanyCampaignApi.UserCompanyCampaigns,
      method: AxiosMethods.GET,
      params
    });
  },
  select: (res: any) => res?.Data
});
