export enum GroupsTabs {
  selected = 0,
  all = 1
}

export enum ResourceCategoryClassName {
  Company = 1,
  User = 2,
  Group = 3
}
