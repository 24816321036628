import React from 'react';
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import clsx from 'clsx';

enum Direction {
  horizontal = 'horizontal',
  vertical = 'vertical'
}

type directionType = keyof typeof Direction;

interface StyleProps {
  direction: directionType;
  width?: number;
  border: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
  button: {
    borderRadius: 15,
    width: ({ width }) => width || 27,
    height: 27,
    border: ({ border }) => border && '1px solid',
    transform: ({ direction }) =>
      direction === Direction.horizontal && 'rotate(90deg)'
  },
  active: {
    backgroundColor: '#E0ECFF'
  }
});

interface SMoreButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  active?: boolean;
  border?: boolean;
  width?: number;
  direction?: directionType;
  style?: React.CSSProperties;
  className?: string;
}

const SMoreButton = ({
  width,
  style,
  active,
  onClick,
  className,
  border = false,
  direction = 'vertical'
}: SMoreButtonProps) => {
  const classes = useStyles({ direction, border, width });

  return (
    <IconButton
      onClick={e => onClick?.(e)}
      className={clsx(classes.button, className, { [classes.active]: active })}
      style={style}
      disableRipple
      aria-label="more"
      color={active ? 'primary' : 'default'}
      size="small">
      <MoreVert />
    </IconButton>
  );
};

export default SMoreButton;
