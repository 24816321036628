import { Box, Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { StyledButton } from '../../style';

export interface ActionButtonsProps {
  title?: string;
  style?: React.CSSProperties;
  tooltip?: string;
  disabled?: boolean;
  onClick: () => void;
}

export const DownloadButton = ({
  title = 'Download',
  style,
  tooltip = '',
  disabled,
  onClick
}: ActionButtonsProps) => (
  <Tooltip arrow placement="top" title={tooltip}>
    <Box>
      <StyledButton
        variant="text"
        startIcon={<GetAppIcon />}
        onClick={onClick}
        disabled={disabled}
        style={{
          color: '#1E96FB',
          ...style
        }}>
        {title}
      </StyledButton>
    </Box>
  </Tooltip>
);

export const RemoveButton = ({
  title = 'remove',
  style,
  tooltip = '',
  disabled,
  onClick
}: ActionButtonsProps) => (
  <Tooltip arrow placement="top" title={tooltip}>
    <Box>
      <StyledButton
        variant="text"
        startIcon={<CloseRoundedIcon />}
        onClick={onClick}
        disabled={disabled}
        style={{
          color: '#FC5A5A',
          ...style
        }}>
        {title}
      </StyledButton>
    </Box>
  </Tooltip>
);
