import { makeStyles } from '@material-ui/core';
import { distances } from '@setvi/shared/styles';

export const useLocationStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    marginBottom: distances.small,
    width: '100%'
  },
  messageContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentContainer: {
    width: 297
  },
  listContainer: {
    height: 260,
    overflow: 'auto'
  }
}));
