import { SButton } from '@setvi/shared/components';
import { Box, CircularProgress, Divider, withStyles } from '@material-ui/core';
import { CheckOutlined } from '@material-ui/icons';

interface FooterProps {
  onConfirm: () => void;
  onClose?: () => void;
  confirmLabel?: string;
  closeLabel?: string;
  startIcon?: JSX.Element;
  disableConfirm?: boolean;
  confirmLoading?: boolean;
  endIcon?: JSX.Element | null;
}

const FooterWrapper = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    padding: spacing(3, 4)
  }
}))(Box);

const SFooter = ({
  onConfirm,
  onClose,
  confirmLabel = 'Confirm',
  closeLabel = 'Close',
  disableConfirm = false,
  confirmLoading = false,
  startIcon = <CheckOutlined />,
  endIcon = null
}: FooterProps) => (
  <>
    <Divider />
    <FooterWrapper>
      <SButton
        loading={confirmLoading}
        onClick={onConfirm}
        startIcon={
          confirmLoading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            startIcon
          )
        }
        endIcon={endIcon}
        disabled={disableConfirm}>
        {confirmLabel}
      </SButton>
      {onClose && (
        <SButton
          style={{ width: 100 }}
          color="inherit"
          variant="outlined"
          onClick={onClose}>
          {closeLabel}
        </SButton>
      )}
    </FooterWrapper>
  </>
);

export default SFooter;
