import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

interface DrawerLoaderProps {
  showImage?: boolean;
}

export const DrawerLoader = ({ showImage = true }: DrawerLoaderProps = {}) => (
  <Box>
    {Array.from(new Array(5)).map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Box m={2} key={i} display="flex" alignItems="center">
        {showImage && (
          <Skeleton
            variant="rect"
            width={60}
            height={60}
            style={{ borderRadius: 5, flexShrink: 0 }}
          />
        )}
        <Box ml={2} width="100%">
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="60%" />
        </Box>
      </Box>
    ))}
  </Box>
);
