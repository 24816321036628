import { OutlinedInput } from '@material-ui/core';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { distances } from '../../../../styles';
import { TextColor } from '../../../../enums';

export const SearchField = withStyles({
  adornedStart: {
    backgroundColor: '#FAFAFB'
  },
  adornedEnd: {
    borderRadius: 4,
    backgroundColor: '#FAFAFB',
    paddingRight: 0,
    fontSize: 'revert'
  },
  input: {
    height: 'fit-content !important',
    padding: 0
  }
})(OutlinedInput);

export const useStyles = makeStyles<
  Theme,
  { width: number | string; height: number | string }
>({
  wrapper: {
    width: ({ width }) => width || '100%',
    maxWidth: '100%',
    height: ({ height }) => height || 'max-content',
    borderRadius: 8,
    backgroundColor: '#fff',
    color: TextColor.Grey,
    padding: `${distances.px.base} ${distances.px.medium}`,
    '& fieldset': {
      border: `0.5px solid ${TextColor.LightGrey} !important`
    }
  }
});
