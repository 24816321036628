import { List, Collapse } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { MenuItemProps } from '.';
import { Item } from './Item';

interface IMenuItemList {
  collapsedDrawer: boolean;
  title: MenuItemProps['title'];
  icon: MenuItemProps['icon'];
  menuItems: MenuItemProps[];
  onClick: () => void;
  isExpanded: boolean;
}
export const MenuItemList = ({
  menuItems,
  collapsedDrawer,
  icon,
  title,
  onClick,
  isExpanded
}: IMenuItemList) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Item
        title={title}
        list={menuItems}
        icon={icon}
        collapsedDrawer={collapsedDrawer}
        onClick={onClick}
      />
      <Collapse in={isExpanded} timeout="auto">
        <List component="nav" disablePadding>
          {menuItems.map(item => (
            <Item
              key={item.title}
              title={item.title}
              icon={item.icon}
              collapsedDrawer={collapsedDrawer}
              route={item.route}
              list={item.list}
              selected={location.pathname === item.route}
              onClick={() => navigate(item.route)}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};
