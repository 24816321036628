import { memo } from 'react';

import { StyledTreeItem } from '../style';

export interface STreeNodeProps<T> {
  node: T;
  onIconClick?: (e: React.MouseEvent<Element, MouseEvent>, node: T) => void;
  nodeKeyGetter: (node: T) => string | number;
  nodeChildrenGetter: (node: T) => T[];
  renderNodeLabel: (node: T) => JSX.Element;
  onNodeClick?: (node: T) => void;
  onLabelClick?: (e: React.MouseEvent<Element, MouseEvent>, node: T) => void;
}

const STreeNode = <T,>({
  node,
  onIconClick,
  nodeKeyGetter,
  nodeChildrenGetter,
  renderNodeLabel,
  onLabelClick,
  onNodeClick
}: STreeNodeProps<T>) => (
  <StyledTreeItem
    key={nodeKeyGetter(node).toString()}
    nodeId={nodeKeyGetter(node).toString()}
    onClick={() => onNodeClick?.(node)}
    onLabelClick={e => onLabelClick?.(e, node)}
    label={renderNodeLabel(node)}
    onIconClick={(e: React.MouseEvent<Element, MouseEvent>) =>
      onIconClick?.(e, node)
    }>
    {Array.isArray(nodeChildrenGetter(node)) &&
      nodeChildrenGetter(node).length > 0 &&
      nodeChildrenGetter(node).map(n =>
        STreeNode({
          node: n,
          nodeKeyGetter,
          nodeChildrenGetter,
          renderNodeLabel,
          onNodeClick,
          onIconClick
        })
      )}
  </StyledTreeItem>
);

export default memo(STreeNode);
