import { Box } from '@material-ui/core';
import { Link } from '@setvi/shared/interfaces';

import { useUpload } from './useUpload';
import { Header } from '../../components/header';
import { Subheader } from '../../components/subheader';
import { DropArea } from './drop-area';
import UploadPanel from './panel';

interface UploadProps {
  handleBack?: () => void;
  handleClose: () => void;
  handleInsert?: (link: Link) => void;
  handleComplete?: () => void;
  handleCancel?: () => void;
  userId?: number;
  categoryId?: number | null;
  hideBackButton?: boolean;
}

export const Upload = ({
  handleBack,
  handleCancel,
  handleClose,
  handleInsert,
  handleComplete,
  userId,
  categoryId = 0,
  hideBackButton
}: UploadProps) => {
  const {
    isDragActive,
    uploading,
    files,
    resetFiles,
    getRootProps,
    getInputProps,
    onUploadCancel,
    onUploadFinished
  } = useUpload({
    handleInsert,
    handleComplete,
    handleCancel,
    categoryId
  });

  const getBackButtonFunctionality = () => {
    if (files.length > 0) return resetFiles;
    if (!hideBackButton) return handleBack;
    return undefined;
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header
        title="Add from SETVI"
        handleClose={handleClose}
        disableClose={uploading}
      />
      <Subheader
        handleBack={getBackButtonFunctionality()}
        text="Upload file"
        disabled={uploading}
      />

      {files.length > 0 ? (
        <UploadPanel
          onUploadCancel={onUploadCancel}
          onUploadFinished={onUploadFinished}
          uploadedResources={files}
          userId={userId}
        />
      ) : (
        <DropArea
          rootProps={getRootProps()}
          inputProps={getInputProps()}
          isDragActive={isDragActive}
          isUploading={uploading}
        />
      )}
    </Box>
  );
};
