import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Outlet } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { muiTheme } from '@setvi/shared/styles';
import CustomSnackbarProvider from 'Providers/CustomSnackbar';
import { AppProvider } from 'Providers/AppProvider';
import ReactFavicon from 'react-favicon';
import { ReactQueryProvider } from 'Providers/ReactQuery';
import 'react-virtualized/styles.css'; // only needs to be imported once
import './App.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: process.env.DATA_DOG_APPLICATION_ID,
  clientToken: process.env.DATA_DOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'webapp',
  env: process.env.NODE_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
});

const App = () => (
  <ThemeProvider theme={muiTheme}>
    <Auth0Provider
      domain="setvisso.us.auth0.com"
      clientId="yYLDFQ0Dng54FttLE5B6iUG6urR2EPee"
      authorizationParams={{
        redirect_uri: `${window.location.origin}/token-callback`,
        // connection: 'setvi-prod-db',
        scope: 'openid name email'
      }}
      authorizeTimeoutInSeconds={300}>
      <CustomSnackbarProvider>
        <ReactQueryProvider>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <AppProvider>
              <Outlet />
              <ReactFavicon url="/icons/favicon.ico" />
              {process.env.NODE_ENV === 'development' ? (
                <ReactQueryDevtools />
              ) : null}
            </AppProvider>
          </QueryParamProvider>
        </ReactQueryProvider>
      </CustomSnackbarProvider>
    </Auth0Provider>
  </ThemeProvider>
);

export default App;
