import { useCallback, useState, MouseEvent, ChangeEvent } from 'react';
import { Resource } from '@setvi/shared/interfaces';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { useStyles } from './style';
import { Overlay } from '../../overlay';
import SETVIImage from '../../../../setvi-image';
import SText from '../../../../sui/stext';
import { GetResourceTypeIcon } from '../../../../../utils';
import { SCheckbox, SDropdownMenu } from '../../../..';
import { useSelectPagesDropdown } from '../../../hooks/useSelectPagesDropdown';
import { resourceView } from '../../../../../utils/viewer';
import { ResourceType } from '../../../../../enums';
import { selectedResourceKeyGetter } from '../../../hooks/useSelectResources';

interface ItemProps {
  resource: Resource;
  isLoading?: boolean;
  onSelect: (checked: boolean, resources: any) => void;
  onSelectPages: (resource: Resource) => void;
  isShareable?: boolean;
  selected: Resource[];
}

export const Item = ({
  resource,
  selected,
  isLoading,
  onSelect,
  onSelectPages,
  isShareable
}: ItemProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const isChecked = selected?.some(sr =>
    sr.ResourceID.includes(resource.ResourceID)
  );

  const handleSelectAllPages = (res: Resource) => {
    // removing entire file and all selected pages/slides from this file
    if (isChecked)
      return onSelect(
        false,
        selected.filter(i =>
          i.ResourceID.includes(res[selectedResourceKeyGetter])
        )
      );

    onSelect(true, [res]);
  };

  const { menuItems } = useSelectPagesDropdown({
    resource,
    isChecked,
    onSelectPages,
    onSelectAllPages: handleSelectAllPages
  });

  const hasSubmenu = [
    ResourceType.Presentation,
    ResourceType.PdfDocument
  ].includes(resource?.ResourceTypeID);

  const useSubmenu = resource?.Pages > 1 && !isChecked && isShareable;

  const onThumbnailClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      resourceView({
        name: resource.Name,
        resourceTypeId: resource.ResourceTypeID,
        resourceId: resource.ResourceID
      });
    },
    [resource]
  );

  const handleSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (hasSubmenu) return handleSelectAllPages(resource);

    setLoading(true);
    await onSelect(e.target.checked, [resource]);
    setLoading(false);
  };

  const checkbox =
    hasSubmenu && useSubmenu ? (
      <SDropdownMenu menuItems={menuItems}>
        <SCheckbox
          checked={isChecked}
          disabled={!isShareable}
          tooltip={!isShareable && 'This resource is not shareable'}
        />
      </SDropdownMenu>
    ) : (
      <SCheckbox
        checked={isChecked}
        onChange={handleSelect}
        disabled={!isShareable}
        tooltip={!isShareable && 'This resource is not shareable'}
      />
    );

  return (
    <Box className={classes.innerWrap} mb={3}>
      <Box className={classes.imageContainer} onClick={onThumbnailClick}>
        <SETVIImage
          className={classes.panelImage}
          src={resource.ThumbURLWithSas || resource.ThumbURL}
          alt="Resource"
          loading="lazy"
        />
        <Overlay />
      </Box>

      <Box flex={1} ml={2}>
        <SText title={resource.Name} weight="medium" size="sm" maxLines={2} />
        <Box display="flex" alignItems="center">
          <img
            src={GetResourceTypeIcon(resource.ResourceTypeID)}
            className={classes.resourceIcon}
          />
          <Typography variant="subtitle2" color="textSecondary">
            {resource.ResourceTypeName}
          </Typography>
        </Box>
      </Box>
      {isLoading || loading ? (
        <Box style={{ padding: 12 }}>
          <CircularProgress color="primary" size={20} thickness={4.5} />
        </Box>
      ) : (
        <Box>{checkbox}</Box>
      )}
    </Box>
  );
};
