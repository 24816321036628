import { Box, Divider, IconButton, Theme, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SText from '../../sui/stext';

export interface SHeaderProps {
  title: string;
  description?: JSX.Element | string;
  onClose?(): void;
}

const useStyles = makeStyles<Theme, { description: boolean }>(
  ({ spacing }) => ({
    container: {
      display: 'flex',
      alignItems: ({ description }) => (description ? 'start' : 'center'),
      justifyContent: 'space-between',
      padding: spacing(3, 4)
    },
    description: {
      paddingTop: spacing(3)
    },
    closeIcon: {
      width: 27,
      height: 27
    }
  })
);

const SHeader = ({ title, description, onClose }: SHeaderProps) => {
  const classes = useStyles({ description: !!description });
  return (
    <>
      <Box className={classes.container}>
        <Box>
          <SText title={title} size="xl" weight="bold" />
          {!!description && typeof description === 'string' ? (
            <SText
              maxLines={2}
              variant="caption"
              color="textSecondary"
              title={description}
              className={classes.description}
            />
          ) : (
            description
          )}
        </Box>
        {onClose ? (
          <IconButton aria-label="close" size="small" onClick={onClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        ) : null}
      </Box>
      <Divider />
    </>
  );
};

export default SHeader;
