export const newlineRegex = /\n/g;
export const htmlTags = /<[^>]*>/g;
export const removeNbsp = /&nbsp;/g;

export const mailMergeHtmlVariables = /<label class="mce-mergetag">|<\/label>/g;

export const linkAddress =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})?(\/.*)?$/;

export const contentEditableTag = /<span>|<\/span>/g;

export const isYoutubeLink =
  /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([a-zA-Z0-9_-]+)/;
export const isVimeoLink =
  /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com)\/(?:video\/)?([0-9]+)/;

export const immutableLink = (placeholder: string) =>
  new RegExp(`<a.*?href="${placeholder}".*?>(.*?)</a>`, 'g');

export const cleanHtml = (html = '') =>
  html
    ?.trim()
    .replace(htmlTags, '')
    .replace(removeNbsp, '')
    .replace(newlineRegex, '');
