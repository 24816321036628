import { useQuery } from '@tanstack/react-query';
import { getTemplatesQuery } from '@setvi/shared/services';

export const useTemplates = () => {
  const { data, isLoading } = useQuery(getTemplatesQuery());

  return {
    templates: data?.Data || [],
    isLoading
  };
};
