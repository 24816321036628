import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import { validateSync } from '@setvi/shared/utils';

import { useAppContext } from 'Providers/AppProvider/AppContext';
import { setCrmBccEmailMutation } from 'Services';

export const useIntegration = () => {
  const { user, setUser } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const [isEditable, setIsEditable] = useState(false);

  const { mutateAsync, isLoading: loading } = useMutation(
    setCrmBccEmailMutation()
  );

  const EmailFormHandler = useFormik({
    enableReinitialize: true,
    initialValues: {
      bccEmail: user?.UserCRMbccEmail || ''
    },
    onSubmit: async values => {
      if (
        validateSync({
          schema: {
            bccEmail: Yup.string().email('Invalid email')
          },
          values
        })
      ) {
        enqueueSnackbar(`Invalid email`, {
          variant: 'error'
        });
        return;
      }

      const Data = await mutateAsync({
        userCrmBccEmail: values.bccEmail || ''
      });

      if (!Data) return;

      setUser({
        ...user,
        UserCRMbccEmail: values.bccEmail || ''
      });

      setIsEditable(false);

      enqueueSnackbar(`Changes has been saved successfully`, {
        variant: 'success'
      });
    }
  });

  return {
    loading,
    isEditable,
    EmailFormHandler,
    setIsEditable
  };
};
