import { Category } from '@setvi/shared/interfaces';
import { Box } from '@material-ui/core';
import SInfiniteScroll from '../../../../sinfinite-scroll';
import SText from '../../../../sui/stext';
import { CategoryCard } from '../card';

export interface CategoryListProps {
  categories: Category[];
  fetchMoreCategories?: () => void;
  onCategoryClick: (category: Category) => void;
}

export const CategoryList = ({
  categories,
  fetchMoreCategories,
  onCategoryClick
}: CategoryListProps) => (
  <Box m={2}>
    {categories.length > 0 && (
      <Box mb={2}>
        <SText weight="medium">Categories</SText>
      </Box>
    )}
    <SInfiniteScroll
      list={categories}
      fetchMore={fetchMoreCategories}
      keyGetter={el => el.ID}
      render={category => (
        <CategoryCard category={category} onCategoryClick={onCategoryClick} />
      )}
    />
  </Box>
);
