import { Header } from '@setvi/shared/components/sdrawer-content/components/header';
import { DrawerContent } from '@setvi/shared/enums/common';
import { SSearchInput } from '@setvi/shared/components/sforms/ssearch';
import { AddressBook } from '@setvi/shared/components/sdrawer-content/components/contact/address-book';
import { TContact } from '@setvi/shared/components/sdrawer-content/components/contact';
import { Lead } from '@setvi/shared/services/react-query/query/user/types';
import { Box, Typography } from '@material-ui/core';
import { Subheader } from '../../../components/subheader';
import { useLeads } from './useLeads';
import { SalesforceUser } from '../../contacts-navigation';
import { SEmptyState } from '../../../..';
import { Recipient } from '../../../../../interfaces';

interface CrmLeadsProps {
  salesforceUser: SalesforceUser;
  selectedContacts?: Recipient[];
  modifyRecipientList: (contact: TContact) => void;
  setShowContent: (content: DrawerContent | null) => void;
  handleClose: () => void;
}

export const CrmLeads = ({
  salesforceUser,
  selectedContacts,
  modifyRecipientList,
  setShowContent,
  handleClose
}: CrmLeadsProps) => {
  const { leads, isLoading, fetchMoreLeads, setSearchTerm } = useLeads({
    salesforceUser
  });

  const contacts: TContact[] = leads?.map((lead: Lead) => ({
    Id: lead.Id,
    Email: lead.Email,
    FirstName: lead.FirstName,
    LastName: lead.LastName,
    CompanyName: lead.Company,
    Title: lead.Title,
    CRMWhoId: lead.Id
  }));

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Recipients" handleClose={handleClose} />
      <Subheader
        handleBack={() => setShowContent(DrawerContent.contactsNavigation)}
        text="CRM Leads"
      />
      <Box p={2} mb={1}>
        <SSearchInput onChange={setSearchTerm} />
      </Box>
      <Box overflow="auto">
        <AddressBook
          contacts={contacts}
          selectedContacts={selectedContacts}
          fetchMore={fetchMoreLeads}
          onContactClick={modifyRecipientList}>
          <>
            {isLoading && (
              <Box textAlign="center">
                <Typography variant="h6">Loading...</Typography>
              </Box>
            )}
            {!isLoading && contacts?.length === 0 && (
              <SEmptyState
                imageSrc="/images/no-crm-leads.png"
                subTitle="No CRM leads available"
              />
            )}
          </>
        </AddressBook>
      </Box>
    </Box>
  );
};
