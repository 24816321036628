import { useQuery } from '@tanstack/react-query';
import { getQrveyActiveLinks } from 'Services';

export const useQrveyActiveLinks = () => {
  const { data, isLoading, isFetching } = useQuery(getQrveyActiveLinks());

  return {
    activeLinks: data?.Data || [],
    isLoading: isLoading || isFetching
  };
};
