import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Menu, MenuItem, ButtonProps } from '@material-ui/core';

interface SetviPaginationMenuProps extends ButtonProps {
  options: string[];
  onOptionChange: (option: string) => void;
}

const useStyles = makeStyles({
  customBtn: {
    border: '1px solid #E2E2EA',
    borderRadius: '8px',
    maxWidth: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 15px',
    color: '#44444F'
  },
  menuContainer: {
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0
    }
  }
});

const SPaginationMenu = ({
  options,
  endIcon,
  startIcon,
  onOptionChange
}: SetviPaginationMenuProps) => {
  const classes = useStyles();

  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState(options[0] || 10);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const onMenuItemClick = (event: React.MouseEvent, option: string) => {
    event?.preventDefault();

    setAnchor(null);
    setSelected(option);
    onOptionChange(option);
  };

  return (
    <Box>
      <Button
        onClick={openMenu}
        className={classes.customBtn}
        endIcon={endIcon}
        startIcon={startIcon}>
        {selected}
      </Button>

      <Menu
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={closeMenu}
        keepMounted
        className={classes.menuContainer}
        PaperProps={{
          style: {
            maxHeight: 40 * 4,
            width: '65px'
          }
        }}>
        {options?.map((option: string) => (
          <MenuItem
            key={option}
            onClick={(event: React.MouseEvent) =>
              onMenuItemClick(event, option)
            }
            selected={option === selected}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default SPaginationMenu;
