interface SetEmailVariablesProps {
  htmlString: string;
}

export const setEmailFollowUpBodyVariables = ({
  htmlString
}: SetEmailVariablesProps) => {
  // Check if body is empty

  const parser = new DOMParser();
  const newDoc = document.createElement('div');
  const htmlEle = parser.parseFromString(htmlString, 'text/html');
  const content = htmlEle.body.children[0];
  if (!htmlString.length) return newDoc;
  // Elements inside email's pre tag
  // Loop through all the elements and look for variables
  // Found variables gets added a span with correct class and attrbiutes
  // Wrap all elements inside blockquote and append to empty div
  Array.from(content.children).forEach(e => {
    const quoteEle = document.createElement('blockquote');
    e.innerHTML = e.innerHTML.replace(
      /{{[^}]*(?:First Name|Last Name|Company Name)}}/gm,
      x => `<span class="mce-mergetag">${x}</span>`
    );
    quoteEle.appendChild(e);

    newDoc.appendChild(quoteEle);
  });

  return newDoc;
};

export const setEmailVariables = ({ htmlString }: SetEmailVariablesProps) => {
  const parser = new DOMParser();
  const htmlEle = parser.parseFromString(htmlString, 'text/html');

  htmlEle.querySelectorAll('*').forEach(e => {
    e.innerHTML = e.innerHTML.replace(
      /{{[^}]*(?:First Name|Last Name|Company Name)}}/gm,
      x => `<span class="mce-mergetag">${x}</span>`
    );
  });

  return htmlEle;
};
