import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 0C7.91202 0 8.14721 0.470382 7.9 0.8L4.4 5.46667C4.2 5.73333 3.8 5.73333 3.6 5.46667L0.1 0.8C-0.147214 0.470382 0.0879774 0 0.5 0L7.5 0Z"
      fill="#92929D"
    />
  </svg>
);

const ArrowIcon = memo(SvgComponent);
export default ArrowIcon;
