import { Table, Theme, makeStyles } from '@material-ui/core';
import { memo } from 'react';

import TableHeader, { TableHeaderProps } from '../components/table/header';
import { BROWSERS, useBrowser } from '../../../hooks';

const useStyles = makeStyles<Theme, { isSafari: boolean }>({
  table: {
    borderCollapse: 'collapse',
    position: ({ isSafari }) => (isSafari ? 'relative' : 'inherit'),

    '& tbody > tr': {
      marginTop: ({ isSafari }) => (isSafari ? 35 : 0)
    }
  },
  wrapper: {
    borderBottom: `1px solid #E6E7E9`
  }
});

interface SinfinteTableProps {
  header: TableHeaderProps;
  children: JSX.Element;
  stickyHeader?: boolean;
}

export const VirtualTable = ({
  header,
  children,
  stickyHeader = true
}: SinfinteTableProps) => {
  const { browser } = useBrowser();
  const isSafari = browser === BROWSERS.safari;
  const classes = useStyles({ isSafari });

  return (
    <Table stickyHeader={stickyHeader} className={classes.table}>
      <TableHeader {...header} />
      {children}
    </Table>
  );
};

const SVirtualTable = memo(VirtualTable) as typeof VirtualTable;
export default SVirtualTable;
