import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { getPresentationsQuery } from '../../../../services';
import {
  PRESENTATION_PAGINATION,
  PresentationType,
  SortType
} from '../../../../enums';

export const usePresnetations = () => {
  const [search, setSearch] = useState<string>('');

  const {
    data: presentations,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(
    getPresentationsQuery({
      pageNumber: 1,
      pageSize: PRESENTATION_PAGINATION.LIMIT,
      searchTerm: search,
      statuses: PresentationType.PRESENTATION,
      sortBy: 'DateAdded',
      orderBy: SortType.DESC
    })
  );

  const fetchMorePresentations = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    search,
    setSearch,
    presentations: presentations?.pages?.map(page => page.Result)?.flat() || [],
    isLoading: isLoading || isFetching,
    fetchMorePresentations
  };
};
