export enum NotificationType {
  Default = 0,
  ResourceCreate = 1,
  PresentationTemplateCreate = 2,
  CustomModuleCreate = 3,
  Admin = 4,
  SETVI = 5,
  ResourceUpdate = 6,
  PresentationTemplateUpdate = 7,
  CustomModuleUpdate = 8,
  EmailOpen = 9,
  LinkViewed = 10,
  PresentationExported = 11,
  PresentationUploaded = 12,
  EmailSyncAccountError = 13,
  EmailSyncAccountLinked = 14,
  EmailSyncAccountDisconnected = 15,
  ResourceReplace = 16,
  Share = 17,
  OutlookEmailOpen = 18,
  EngageEmailOpen = 19,
  EngageEmailLinkView = 20
}

export enum NotificationTypeTitle {
  Default = '',
  ResourceCreate = 'Resource Created',
  ResourceUpdate = 'Resource Updated',
  ResourceReplace = 'Resource Replaced',
  PresentationTemplateCreate = 'Presentation Template Created',
  PresentationTemplateUpdate = 'Presentation Template Update',
  PresentationExported = 'Presentation Exported',
  PresentationUploaded = 'Presentation Uplodaded',
  Admin = 'Admin pushed notifications',
  SETVI = 'Here goes the notifications sent and triggered by SETVI',
  EmailOpen = 'Email Opened',
  OutlookEmailOpen = 'Outlook Email Opened',
  LinkViewed = 'Link Viewed',
  EmailSyncAccountError = 'EmailSync Account Error',
  EmailSyncAccountLinked = 'EmailSync Account Linked',
  EmailSyncAccountDisconnected = 'EmailSync Account Disconnected',
  CustomModuleCreate = 'Custom Module Created',
  CustomModuleUpdate = 'Custom Module Updated',
  // ! CHECK IF NEXT LINE IS FOR PRESENTATION SHARE
  Share = 'Presentation Shared',
  EngageEmailOpen = 'Email Opened',
  EngageEmailLinkView = 'Link Viewed'
}
