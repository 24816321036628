import { Box, withStyles } from '@material-ui/core';

export const TableWrapper = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    flexGrow: 1,
    padding: spacing(3)
  }
}))(Box);
