import { AxiosMethods, PresentationsApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import { QueryFunctionContext } from '@tanstack/react-query';

export enum PresentationQueryKey {
  PRESENTATIONS = 'presentations',
  PRESENTATION = 'presentation'
}

interface getPresentationQueryVariables {
  presentationId: string;
}

export const getPresentationsQuery = (params: any) => ({
  queryKey: [PresentationQueryKey.PRESENTATIONS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: PresentationsApi.Presentations,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (lastPage: any, allPages: any[]) => {
    const total = allPages?.reduce((acc, cur) => acc + cur?.Result?.length, 0);
    const currentPageNumber = total / params.pageSize;
    return lastPage?.Result?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  cacheTime: 0
});

export const getPresentationQuery = (
  params?: getPresentationQueryVariables
) => ({
  queryKey: [PresentationQueryKey.PRESENTATION, params],
  queryFn: (data: any) =>
    axiosHelper({
      endpoint: PresentationsApi.Presentation,
      method: AxiosMethods.POST,
      params: data.queryKey[1]
    }),
  select: (res: any) => {
    const data = res?.Data?.map((presentation: any) => ({
      ...presentation,
      ThumbURL: presentation.ThumbURLWithSas,
      // @ts-ignore
      PresentationItems:
        presentation?.PresentationItems?.map((item: any) => ({
          ...item,
          ThumbURL: item.ThumbURLWithSas
        })) || [],
      DateAdded: new Date(+presentation?.DateAdded.replace(/\D/g, '')),
      LastChanged: new Date(+presentation?.LastChanged.replace(/\D/g, ''))
    }))?.sort((a: any, b: any) => {
      const c = new Date(a.LastChanged);
      const d = new Date(b.LastChanged);
      // @ts-ignore
      return d - c;
    });

    return data[0];
  }
});
