import clsx from 'clsx';
import { Field, FieldProps } from 'formik';
import { makeStyles, styled } from '@material-ui/core/styles';
import { Box, Switch, SwitchProps, Typography } from '@material-ui/core';

import SLabel from '../../../sui/slabel';

interface FormikTextfieldProps {
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  labelClass?: any;
  subText?: string;
  onChange?: (value: boolean) => void;
}

const useStyles = makeStyles(() => ({
  paperBox: {
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    width: '100%',
    backgroundColor: 'transparent'
  },
  subText: {
    color: '#989FB0',
    fontSize: '14px',
    margin: '8px 0px'
  },
  required: {
    color: '#d72638'
  }
}));

export const SetviSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 3,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translate(20px, -50%)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#037AE2',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
        backgroundColor: '#919294'
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#919294'
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#919294',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const SFormikSwitch = ({
  name,
  label,
  subText,
  required,
  disabled,
  onChange,
  labelClass
}: FormikTextfieldProps) => {
  const classes = useStyles();

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => (
        <Box>
          <Box className={classes.paperBox}>
            <SLabel
              title={label}
              className={clsx(labelClass)}
              variant="body2"
              required={required}
            />

            <SetviSwitch
              defaultChecked={meta.value}
              checked={meta.value}
              disabled={disabled}
              name={field.name}
              value={meta.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                form.setFieldValue(name, event.target.checked);
                onChange?.(event.target.checked);
              }}
            />
          </Box>

          <Typography variant="subtitle2" className={classes.subText}>
            {subText || ''}
          </Typography>
        </Box>
      )}
    </Field>
  );
};

export default SFormikSwitch;
