import { Typography } from '@material-ui/core';
import { EyeIcon as View } from '@setvi/shared/components/sicons';
import { ContentOverlay, OverlayContainer, OverlayContent } from './style';

export const Overlay = () => (
  <ContentOverlay>
    <OverlayContainer>
      <OverlayContent>
        <View fontSize="small" />
        <Typography variant="body2">View</Typography>
      </OverlayContent>
    </OverlayContainer>
  </ContentOverlay>
);
