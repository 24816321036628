import { Box, Grid, ListItem, makeStyles } from '@material-ui/core';
import { memo } from 'react';

import { SETVIImage, SHighlightText } from '@setvi/shared/components';
import { resourceView } from '@setvi/shared/utils/viewer';
import { Resource } from '@setvi/shared/interfaces';

import ResourceBreadcrumbs from 'Components/Shared/ResourceBreadcrumbs';
import DropdownMenu from 'Components/Shared/Resources/DropdownMenu';
import { DropdownItemsType } from 'Components/Shared/Resources/DropdownMenu/hooks';

interface ISearchResult {
  resource: Resource;
  searchQuery: string;
  handleSearchClose: () => void;
}

const useSearchStyles = makeStyles(() => ({
  image: {
    width: '80%',
    borderRadius: '5px',
    display: 'block'
  },
  title: {
    fontsize: 14
  },
  listItem: {
    paddingLeft: 24
  },
  content: {
    maxWidth: '100%',
    lineHeight: 0
  },
  pageInfo: {
    fontSize: 13,
    color: '#92929D'
  }
}));

const dropdownItems: DropdownItemsType = [
  'email',
  'download',
  'getLink',
  'moreInfo',
  'goToCategory'
];

const SearchResult = ({
  resource,
  searchQuery,
  handleSearchClose
}: ISearchResult) => {
  const style = useSearchStyles();

  return (
    <>
      <Grid container spacing={0}>
        <ListItem
          button
          className={style.listItem}
          onClick={() =>
            resourceView({
              name: resource.Name,
              resourceTypeId: resource.ResourceTypeID,
              resourceId: resource.ResourceID
            })
          }>
          <Grid item xs={2}>
            <SETVIImage
              src={resource.ThumbURL}
              className={style.image}
              alt={resource.Name}
            />
          </Grid>
          <Grid item xs={9}>
            <Box className={style.content}>
              <SHighlightText value={resource.Name} searchQuery={searchQuery} />
            </Box>
            <Box>
              <ResourceBreadcrumbs
                additionalOnClick={handleSearchClose}
                breadcrumbs={resource.Breadcrumbs}
              />
            </Box>
            {resource.PageNumber && (
              <Box className={style.pageInfo}>
                Found on page: {resource.PageNumber}
              </Box>
            )}
          </Grid>
          <Grid item xs={1}>
            <Box onClick={e => e.stopPropagation()}>
              <DropdownMenu
                item={resource}
                dropdownItems={dropdownItems}
                handleCloseDropdown={handleSearchClose}
              />
            </Box>
          </Grid>
        </ListItem>
      </Grid>
    </>
  );
};

export default memo(SearchResult);
