/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import {
  Presentation,
  PreviewLink,
  Resource,
  Template
} from '@setvi/shared/interfaces';
import { LinkObjectType } from '@setvi/shared/enums/campaigns';

const viewerInstance = {
  // Instance for presentations, ppt, photoAlbum, keyNote and slides type resources
  presentation: '/index.aspx',
  // Instance for individual resource
  resource: '/ResourceViewer.aspx',
  // Instance for ppt resource
  pptResource: '/index.aspx',
  // Instance for Get Link
  getLink: '/shared/index.html'
};

enum ViewerType {
  PRESENTATION = 'presentation',
  RESOURCE = 'resource',
  PPT_RESOURCE = 'pptRes',
  TEMPLATE = 'template'
}

export const generateGetLink = (code: string) => {
  const url = new URL(
    `${process.env.SHAREANDTRACK_BASE_URL}${viewerInstance.getLink}`
  );

  url.searchParams.append('id', code);
  url.searchParams.append('lang', 'en');

  return url.toString();
};

export const generateLinkViewerUrl = (link: PreviewLink) => {
  const url = new URL(
    `${process.env.VIEWER_BASE_URL}${viewerInstance.presentation}`
  );
  url.searchParams.append('preview', String(true));
  url.searchParams.append('linkName', String(link.Name));
  url.searchParams.append('token', localStorage.getItem('token'));
  link?.page && url.searchParams.append('page', String(link?.page));

  const objects = link.ParentID
    ? [{ ObjectId: link.ParentID, ObjectType: LinkObjectType.Presentation }]
    : link?.Items.map(({ ObjectId, ObjectType }) => ({ ObjectId, ObjectType }));

  url.searchParams.append(
    'objects',
    encodeURIComponent(JSON.stringify(objects))
  );

  return url.toString();
};

interface GenerateLinkViewerWithReadButton extends PreviewLink {
  documentId: number;
  isRead: boolean;
}

export const generateLinkViewerWithReadButton = ({
  documentId,
  isRead,
  ...rest
}: GenerateLinkViewerWithReadButton) =>
  `${generateLinkViewerUrl(rest)}&documentId=${documentId}&isRead=${isRead}`;

interface ResourceViewProps {
  resourceId: Resource['ResourceID'];
  name: Resource['Name'];
  resourceTypeId: Resource['ResourceTypeID'];
  documentId?: number;
  isRead?: boolean;
  banner?: any;
}

export const buildResourceViewUrl = ({
  resourceId,
  name,
  resourceTypeId,
  documentId,
  isRead,
  banner = null
}: ResourceViewProps) => {
  const isPPT =
    resourceTypeId === 12 ||
    resourceTypeId === 14 ||
    resourceTypeId === 15 ||
    resourceTypeId === 16;
  const action = isPPT ? viewerInstance.pptResource : viewerInstance.resource;

  const url = new URL(`${process.env.VIEWER_BASE_URL}${action}`);
  const params = new URLSearchParams({
    Id: resourceId,
    resType: isPPT ? ViewerType.PPT_RESOURCE : ViewerType.RESOURCE,
    banner: JSON.stringify(banner),
    title: name,
    documentId: String(documentId),
    isRead: String(isRead),
    access: localStorage.getItem('token')
  });

  url.search = params.toString();

  return url.toString();
};

export const resourceView = (props: ResourceViewProps) =>
  window.open(buildResourceViewUrl(props), '_blank');

interface PresentationViewProps {
  id: Presentation['ID'];
  name: Presentation['Name'];
  startingPage?: number;
}

export const buildPresentationViewUrl = ({
  id,
  name,
  startingPage = 0
}: PresentationViewProps) => {
  const url = new URL(
    `${process.env.VIEWER_BASE_URL}${viewerInstance.presentation}`
  );
  const params = new URLSearchParams({
    dbId: String(id),
    resType: ViewerType.PRESENTATION,
    title: name,
    access: localStorage.getItem('token'),
    startingPage: String(startingPage)
  });

  url.search = params.toString();

  return url.toString();
};

export const presentationView = (props: PresentationViewProps) =>
  window.open(buildPresentationViewUrl(props), '_blank');

interface TemplateViewProps {
  id: Template['ID'];
  name: Template['Name'];
  startingPage?: number;
}

export const buildTemplateViewUrl = ({
  id,
  name,
  startingPage = 0
}: TemplateViewProps) => {
  const url = new URL(
    `${process.env.VIEWER_BASE_URL}${viewerInstance.presentation}`
  );
  const params = new URLSearchParams({
    dbId: String(id),
    resType: ViewerType.TEMPLATE,
    title: name,
    access: localStorage.getItem('token'),
    startingPage: String(startingPage)
  });

  url.search = params.toString();

  return url.toString();
};

export const templateView = (props: any) =>
  window.open(buildTemplateViewUrl(props), '_blank');
