import { makeStyles, Theme } from '@material-ui/core/styles';

import { distances } from '../../../../styles';

export interface ContentProps {
  type?: Type;
  title: string;
  width?: widthType;
  loading?: boolean;
  closeText?: string;
  description?: string;
  confirmText?: string;
  maxWidth?: maxWidthType;
  additionalMessage?: string;
  customAdditionalMessage?: JSX.Element;
  disableConfirmButton?: boolean;
  confirmation?: boolean;
  onClose?(): void;
  onConfirm: () => void;
}

// #region CONTENT component width and maxWidth
enum CustomWidth {
  xxs = '360px',
  xs = '400px',
  sm = '460px',
  md = '600px',
  lg = '900px',
  xl = '1366px',
  xxl = '1536px'
}

export enum DefaultValues {
  width = 'auto',
  maxWidth = 'none'
}

type maxWidthType = DefaultValues.maxWidth | keyof typeof CustomWidth;
type widthType = DefaultValues.width | keyof typeof CustomWidth;

// #endregion

// #region STYLE
export const useStyles = makeStyles<
  Theme,
  { width: widthType; maxWidth: maxWidthType }
>({
  contentWrapper: {
    padding: distances.px.large,
    boxSizing: 'border-box',
    width: ({ width }) =>
      width !== DefaultValues.width ? CustomWidth[width] : DefaultValues.width,
    maxWidth: ({ maxWidth }) =>
      maxWidth !== DefaultValues.maxWidth
        ? CustomWidth[maxWidth]
        : DefaultValues.maxWidth
  },
  btnsWrapper: {
    paddingTop: distances.px.medium,
    display: 'flex',
    justifyContent: 'space-between',
    '& > .MuiButton-root': {
      minWidth: 'max-content',
      '&:first-of-type': {
        marginRight: distances.px.small
      }
    }
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 700,
    fontSize: 19,
    color: '#44444F',
    marginTop: distances.px.medium
  },
  description: {
    marginTop: distances.px.medium
  },
  additionalMessage: {
    color: '#15192080',
    lineHeight: '20px',
    padding: `${distances.px.medium} 0 ${distances.px.small}`
  }
});
// #endregion

// #region Colors
export enum Types {
  warning = 'warning',
  info = 'info',
  infoBlue = 'infoBlue',
  delete = 'delete',
  regular = 'regular',
  none = 'none'
}

export type Type = keyof typeof Types;

export enum TypeColor {
  infoBlue = '#2196F3', // blue
  info = '#23A566', // green
  warning = '#FF9F29', // orange
  delete = '#D72638', // red
  regular = '#696974', // gray
  none = '#23A566' // green
}
// #endregion
