import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const Loading = () => (
  <Box display="flex" width="100%" alignItems="center">
    <Box mr={1} width="45%">
      <Skeleton variant="rect" />
    </Box>
    <Box mr={1} width="30%">
      <Skeleton variant="rect" />
    </Box>
    <Box width="15%">
      <Skeleton variant="rect" />
    </Box>
  </Box>
);
