import { FC } from 'react';
import { Box } from '@material-ui/core';
import { ArrowForwardIos as ArrowIcon } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { Loading } from './ui-state';
import { useBreadcrumb } from './useBreadcrumb';
import {
  BreadcrumbWrapper,
  BreadcrumbButton,
  BreadcrumbActiveButton,
  BackButtonWrapper,
  BreadcrumbList,
  BlankCategoryName
} from './style';
import SText from '../../../sui/stext';
import SNavigateButton from '../../../snavigate-button';

interface BreadcrumbsProps {
  categoryId?: number;
  onClick?: (categoryId: number) => void;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ categoryId, onClick }) => {
  const { breadcrumbs, loading } = useBreadcrumb({
    categoryId: categoryId || '',
    enabled: !!categoryId
  });

  const { palette } = useTheme();

  return (
    <BreadcrumbWrapper>
      {breadcrumbs?.length > 1 && (
        <BackButtonWrapper>
          <SNavigateButton
            size="sm"
            disabled={loading}
            onClick={() => onClick(breadcrumbs[breadcrumbs.length - 2].ID)}
          />
        </BackButtonWrapper>
      )}

      {loading && <Loading />}

      <BreadcrumbList
        maxItems={2}
        separator={<ArrowIcon />}
        aria-label="breadcrumb">
        {!loading &&
          breadcrumbs?.map((breadcrumb, index) => (
            <Box key={breadcrumb.ID}>
              {index < breadcrumbs.length - 1 ? (
                <BreadcrumbButton
                  variant="contained"
                  disableElevation
                  onClick={() => onClick(breadcrumb.ID)}>
                  <SText
                    size="sm"
                    weight="bold"
                    fontColor={palette.text.secondary}
                    title={breadcrumb.Name || <BlankCategoryName />}
                  />
                </BreadcrumbButton>
              ) : (
                <BreadcrumbActiveButton variant="contained" disableElevation>
                  <SText
                    size="sm"
                    weight="bold"
                    fontColor={palette.primary.main}
                    title={breadcrumb.Name || <BlankCategoryName />}
                  />
                </BreadcrumbActiveButton>
              )}
            </Box>
          ))}
      </BreadcrumbList>
    </BreadcrumbWrapper>
  );
};
