export const htmlTags = /<[^>]*>/g;
export const mailMergeHtmlVariables = /<label class="mce-mergetag">|<\/label>/g;
export const linkAddress =
  // https://stackoverflow.com/a/9284473 regex solution found here
  // eslint-disable-next-line no-useless-escape
  /^(?:(?:https?|ftp):\/\/|((www|WWW)\.))(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
export const contentEditableTag = /<span>|<\/span>/g;
export const immutableLink = (placeholder: string) =>
  new RegExp(`<a.*?href="${placeholder}".*?>(.*?)</a>`, 'g');
export const URL = new RegExp(
  /^((https?):\/\/)?(www.)?[a-z0-9]+(?:[-.][a-z0-9]+)*\.[a-z]{2,5}(?::[0-9]{1,5})?(?:\/\S*)?$/
);
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Validate phone number
 *  this accepts:
 *    - standard telephone number format
 *    - mobile numbers
 *    - international phone numbers
 *  example: +61 123 45
 *           (01) 123-45
 *            0123456789
 */
export const PHONE_REGEX_EXP =
  /^(\+?\d{0,4})?\s?\s?(\(?\d{3}\)?)\s?\s?(\(?\d{3}\)?)\s?\s?(\(?\d{4}\)?)?$/;

export const ONLY_ALPHABETS_CHARACTERS = /^[aA-zZ\s]+$/;
