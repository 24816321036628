import { useMemo } from 'react';
import {
  Box,
  Typography,
  Divider,
  Grow,
  Grid,
  IconButton,
  Button,
  TextField
} from '@material-ui/core';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

import { ResourceItem as Item } from '@setvi/shared/components/sdrawer-content/pages/review-links/edit-links/item/ResourceItem';
import { Template, Link, LinkItemItems } from '@setvi/shared/interfaces';
import {
  getLinkItemFromPresentationItem,
  getLinkItemFromTemplateItem
} from '@setvi/shared/utils';
import { distances } from '@setvi/shared/styles';

import { ClipIcon, FillBgResourceIcon } from '@setvi/shared/components/sicons';
import SText from '@setvi/shared/components/sui/stext';
import styled from 'styled-components';
import {
  useSelectedStyles,
  useListStyles,
  ContainedBackdrop,
  SelectedBox as ResourceSelectBox
} from './style';
import { List } from './List';
import { Presentation } from '../../../../services/react-query/query/presentations/types';

export const SelectedBox = withStyles({
  root: {
    minHeight: 'auto',
    maxHeight: 'calc(100vh - 100px)'
  }
})(ResourceSelectBox);

const CenterBox = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})(Box);

export const Container = styled.div`
  display: grid;
  grid-template-rows: 188px 1fr 88px;
  max-height: calc(100vh - 100px);
`;

interface DetailProps {
  link?: Link;
  isExpanded: boolean;
  selected?: Presentation | Template;

  handleClose(): void;
  handleInsert(link: Link, currentLink?: Link): void;
}

export const SelectedPresentationPreview = ({
  isExpanded,
  handleClose,
  selected,
  handleInsert,
  link
}: DetailProps) => {
  const classes = useSelectedStyles();
  const listClasses = useListStyles();
  const selectedItems = useMemo(
    () =>
      (selected as Presentation).PresentationItems
        ? (selected as Presentation).PresentationItems.map(item =>
            getLinkItemFromPresentationItem(item)
          )
        : (selected as Template).Items?.map(item =>
            getLinkItemFromTemplateItem(item)
          ),
    [selected]
  );

  const resources: LinkItemItems[] = useMemo(
    () => selectedItems,
    [selectedItems]
  );

  const linkNameForm = useFormik({
    initialValues: {
      linkName: link?.Name ?? ''
    },
    validationSchema: Yup.object({
      linkName: Yup.string()
        .max(100, 'Limit is 100 characters')
        .required('Link name is missing')
    }),
    onSubmit: values => {
      if (selected) {
        const selectedId =
          typeof selected.ID === 'string'
            ? parseInt(selected.ID, 10)
            : selected.ID;
        handleInsert(
          {
            Name: values.linkName,
            Placeholder: uuidv4(),
            ParentID: selectedId,
            Item: {
              ID: selectedId,
              Name: selected.Name,
              ThumbURL:
                (selected as Presentation).ThumbURLWithSas ||
                (selected as Template).ThumbURL,
              Items: selectedItems
            }
          },
          link
        );
      } else {
        handleInsert(
          {
            ...link,
            Name: values.linkName
          },
          link
        );
      }

      handleClose();
    }
  });

  return (
    <ContainedBackdrop open={isExpanded}>
      <Grow in={isExpanded}>
        <SelectedBox px={1}>
          <Container>
            <Box>
              <Box
                padding={distances.px.small}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center">
                <SText
                  className={classes.bold}
                  showTooltip
                  variant="h6"
                  title={selected ? selected.Name : link.Name}
                />

                <CenterBox>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </CenterBox>
              </Box>
              <Divider />

              <Box p={2} display="flex">
                <CenterBox
                  pl={distances.px.xsmall}
                  justifyContent="flex-start !important"
                  flexGrow={1}>
                  <Box>
                    <FillBgResourceIcon viewBox="0 0 22 22" />
                  </Box>
                  <Box pl={distances.px.small}>
                    <Typography variant="body1" className={classes.title}>
                      Resources
                    </Typography>
                  </Box>
                </CenterBox>
                <CenterBox pr={distances.px.small}>
                  <Typography variant="body1" className={classes.gray}>
                    {selectedItems.length}
                  </Typography>
                </CenterBox>
              </Box>

              <Item
                disableIcon
                item={
                  selected
                    ? {
                        ...selected,
                        ThumbURL:
                          (selected as Presentation).ThumbURLWithSas ||
                          (selected as Template).ThumbURL
                      }
                    : link.Item
                }
                itemType={
                  <Grid container spacing={1}>
                    <Grid item>
                      <CenterBox height="100%">
                        <ClipIcon
                          viewBox="0 0 11 11"
                          className={listClasses.clip}
                        />
                      </CenterBox>
                    </Grid>
                    <Grid item>
                      <CenterBox height="100%">
                        <Typography variant="body1" className={classes.bold}>
                          {selectedItems.length}
                        </Typography>
                      </CenterBox>
                    </Grid>
                  </Grid>
                }
                itemAction={<div />}
                itemDate={moment.utc(selected?.LastChanged).format('L')}
              />
            </Box>
            <Box overflow="auto">
              <List resources={resources} />
            </Box>
            <Box>
              <form onSubmit={linkNameForm.handleSubmit}>
                <Box display="flex" p={2} height={88}>
                  <Box width="100%">
                    <Box>
                      <Typography variant="body2" className={classes.gray}>
                        Link Name
                      </Typography>
                    </Box>
                    <Box pt={distances.px.xsmall}>
                      <TextField
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        name="linkName"
                        defaultValue={linkNameForm.values.linkName}
                        onChange={linkNameForm.handleChange}
                        error={
                          linkNameForm.touched.linkName &&
                          Boolean(linkNameForm.errors.linkName)
                        }
                        helperText={
                          linkNameForm.touched.linkName &&
                          linkNameForm.errors.linkName
                        }
                      />
                    </Box>
                  </Box>
                  <Box p={1} display="flex" alignItems="center">
                    <Button color="primary" variant="contained" type="submit">
                      {link ? 'Update' : 'Insert'}
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Container>
        </SelectedBox>
      </Grow>
    </ContainedBackdrop>
  );
};
