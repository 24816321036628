import { Grid, Box } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Outlet } from 'react-router-dom';
import { companyTheme } from '../../Styles';
import { Footer } from './Footer';
import { Graphic } from './Graphic';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100% - 100px)',
    position: 'relative'
  }
}));

interface GuestProps {
  children?: React.JSX.Element;
}

export const Guest = ({ children }: GuestProps) => {
  const classes = useStyles();

  const renderOption = children || <Outlet />;

  return (
    <ThemeProvider theme={companyTheme}>
      <Grid container>
        <Grid item xs={6}>
          <Graphic />
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.content}>{renderOption}</Box>
          <Footer />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
