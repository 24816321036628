import { Box, Divider } from '@material-ui/core';
import SText from '../../../../sui/stext';
import { ClipIcon } from '../../../../sicons';
import { usePresentationCardStyles } from './style';
import SETVIImage from '../../../../setvi-image';
import { Presentation } from '../../../../../services/react-query/query/presentations/types';

interface PresentationCardProps {
  presentation: Presentation;
  action?: React.ReactNode;
}

export const PresentationCard = ({
  presentation,
  action
}: PresentationCardProps) => {
  const classes = usePresentationCardStyles();

  return (
    <Box mt={2}>
      <Box className={classes.container}>
        <SETVIImage
          className={classes.image}
          src={presentation.ThumbURLWithSas}
          alt={presentation.Name}
          loading="lazy"
        />
        <Box flex={1} ml={2}>
          <SText
            title={presentation.Name}
            weight="semibold"
            maxLines={2}
            size="sm"
          />
          <Box mt={1} display="flex" alignItems="center">
            <ClipIcon fontSize="small" />
            {presentation.PresentationItems.length || 0}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          {action}
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};
