import clsx from 'clsx';
import { Field, FieldProps } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Theme } from '@material-ui/core';

import { MainEditorProps } from '../../../seditor/interfaces';

import { SetviEditor } from '../../../seditor/components';
import { distances } from '../../../../styles/distances';
import SLabel from '../../../sui/slabel';

export const useStyles = makeStyles<Theme>(theme => ({
  paperBox: {
    height: '100%',
    marginTop: distances.px.large,
    position: 'relative'
  },
  typography: {
    marginBottom: distances.px.small
  },
  required: {
    color: theme.palette.error.main
  },
  inputWrapper: {
    height: '100%'
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '13px',
    marginTop: '15px',
    fontWeight: 500,
    textAlign: 'left',
    '@media (max-width: 1025px)': {
      marginTop: '30px'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '55px'
    }
  }
}));

interface SFormikEditorProps extends Partial<MainEditorProps> {
  name: string;
  label?: string;
  required?: boolean;
  labelClass?: string;
  editorClass?: string;
  customErrors?: any;
  disableMargin?: boolean;
  errorMessageClass?: string;

  handleChange?(value: string, text: string): void;
}

const SFormikEditor = ({
  name,
  label,
  required,
  labelClass,
  editorClass,
  customErrors,
  disableMargin,
  errorMessageClass,

  handleChange,
  setEditorNode,
  ...rest
}: SFormikEditorProps) => {
  const classes = useStyles();

  return (
    <Field name={name}>
      {({ meta, form }: FieldProps) => (
        <Box className={clsx(!disableMargin && classes.paperBox)}>
          {label && (
            <SLabel
              title={label}
              className={clsx(labelClass, classes.typography)}
              variant="body2"
              required={required}
            />
          )}
          <Box className={classes.inputWrapper}>
            <SetviEditor
              id={name}
              content={meta.value}
              customClass={classes.editor}
              setEditorNode={setEditorNode}
              handleEditorChange={(value: string, text: string) => {
                form.setFieldValue(name, value);
                handleChange?.(value, text);
              }}
              helperText={
                ((meta.touched && meta.error) || customErrors?.[name]) && (
                  <Typography
                    className={clsx(classes.error, errorMessageClass)}>
                    {meta.error || customErrors?.[name]}
                  </Typography>
                )
              }
              {...rest}
            />
          </Box>
        </Box>
      )}
    </Field>
  );
};

export default SFormikEditor;
