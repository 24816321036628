import { useState, useCallback } from 'react';
import { Link, LinkItemItems } from '@setvi/shared/interfaces';

interface UseEditProps {
  handleBack: () => void;
  handleInsert(link: Link, currentLink?: Link): void;
  handleRemoveLink(link?: Link): void;
  link: Link;
}

export const useEdit = ({
  handleBack,
  handleInsert,
  handleRemoveLink,
  link
}: UseEditProps) => {
  const [items, setItems] = useState<LinkItemItems[]>(link?.Item?.Items || []);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSubmit = useCallback(
    (value: string) => {
      handleInsert(
        {
          ...link,
          Name: value,
          Item: {
            ...link.Item,
            Items: items.map(item => item)
          }
        },
        link
      );
    },
    [handleInsert, link, items]
  );

  const handleRemoveItem = (removeItem: LinkItemItems) => {
    if (!removeItem) return;

    const updated = items.filter(newItem => newItem.ID !== removeItem.ID);

    if (!updated.length) {
      handleRemoveLink(link);
      handleBack();
      return;
    }

    setItems(updated);
    handleInsert(
      {
        ...link,
        Item: {
          ...link.Item,
          Items: updated
        }
      },
      link
    );
  };

  return {
    items: items?.filter(item =>
      item.Name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    handleSubmit,
    handleRemoveItem,
    setSearchTerm,
    searchTerm
  };
};
