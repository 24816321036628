import { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { SButton, SConfirmDialog } from '@setvi/shared/components';
import { useDialog } from '@setvi/shared/providers';
import { Link } from '@setvi/shared/interfaces';
import { Header } from '@setvi/shared/components/sdrawer-content/components/header';
import { LinkItem } from './LinkItem';

interface ListProps {
  links: Array<Link>;
  handleEdit: (item: Link) => void;
  handleBack?: () => void;
  handleRemoveLink(link?: Link): void;
  handleClose: () => void;
}

export const List = ({
  links,
  handleEdit,
  handleBack: _handleBack,
  handleRemoveLink,
  handleClose
}: ListProps) => {
  const { openDialog, closeDialog } = useDialog();

  const onDelete = useCallback(
    (linkItem: Link) => {
      openDialog(
        <SConfirmDialog
          type="delete"
          title="Delete link"
          description="Are you sure you want to delete this link from the email?"
          additionalMessage="The action CANNOT be reverted back"
          onClose={() => closeDialog('delete-link')}
          onConfirm={() => {
            handleRemoveLink(linkItem);
            closeDialog('delete-link');
          }}
          confirmText="Delete & Close"
          closeText="Keep it"
        />,
        { id: 'delete-link' }
      );
    },
    [openDialog, closeDialog, handleRemoveLink]
  );

  return (
    <Box display="flex" flexDirection="column" height="100%" minHeight={0}>
      <Header title="Edit & Rename Link(s)" handleClose={handleClose} />
      <Box overflow="auto" flex="1">
        {links?.map(item => (
          <LinkItem
            key={item.Placeholder}
            link={item}
            onEdit={() => handleEdit(item)}
            onDelete={() => onDelete(item)}
          />
        ))}
      </Box>
      <Box mt="auto" p={2}>
        <SButton color="default" type="button" onClick={handleClose} fullWidth>
          Cancel
        </SButton>
      </Box>
    </Box>
  );
};
