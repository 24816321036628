import { useState } from 'react';
import { SBase } from '@setvi/shared/components';
import PreviewLink from 'Components/Shared/Resources/Dialogs/GetLink/CreateLink/PreviewLink';
import { CreateLinkForm } from 'Components/Shared/Resources/Dialogs/GetLink/CreateLink/Form';

interface IGetLink {
  resourceId: number;
  onClose: () => void;
}

export const GetLink = ({ resourceId, onClose }: IGetLink) => {
  const [linkUrl, setLinkUrl] = useState<string>('');

  return (
    <SBase
      onClick={e => e.stopPropagation()}
      width={500}
      header={{
        title: 'Get Link',
        onClose
      }}>
      {linkUrl ? (
        <PreviewLink linkName={linkUrl} onClose={onClose} />
      ) : (
        <CreateLinkForm
          resourceId={resourceId}
          onLinkGenerated={link => setLinkUrl(link)}
          onClose={onClose}
        />
      )}
    </SBase>
  );
};
