import { SButton } from '@setvi/shared/components';
import { Content, ContentProps } from './content';
import { useBookmarkStyles } from '../style';

interface ListProps extends ContentProps {
  handleCreate: () => void;
  disabled: boolean;
  setActivePage: (bookmarkedIndex: number) => void;
}

export const List = ({ handleCreate, disabled, ...rest }: ListProps) => {
  const classes = useBookmarkStyles();

  return (
    <>
      <Content {...rest} />
      <SButton
        variant="outlined"
        fullWidth
        disabled={disabled}
        className={classes.button}
        onClick={handleCreate}>
        Bookmark current page
      </SButton>
    </>
  );
};
