import { Box, Typography } from '@material-ui/core';

import { UploadFileIcon } from '@setvi/shared/components/sicons';

import SButton from '../../../sbutton';
import SText from '../../../sui/stext';
import { distances } from '../../../../styles';
import { PlaceholderProps } from '../../interfaces';

const Placeholder = ({
  icon,
  title,
  subtitle,
  buttonTitle,

  open
}: PlaceholderProps) => (
  <Box
    display="flex"
    alignItems="center"
    flexDirection="column"
    gridGap={distances.px.small}>
    {icon || <UploadFileIcon htmlColor="#1E96FC" fontSize="large" />}
    <SText weight="bold" size="lg" style={{ marginTop: '8px' }}>
      {title || 'Drag & Drop'}
    </SText>
    <Typography>{subtitle || 'Resolution : 530 x 394'}</Typography>
    <SButton
      onClick={e => {
        e?.stopPropagation();
        open?.();
      }}>
      {buttonTitle || 'Upload Image'}
    </SButton>
  </Box>
);

export default Placeholder;
