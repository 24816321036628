import { useCallback } from 'react';
import { RefreshIcon } from '@setvi/shared/components/sicons';
import { useQueryClient } from '@tanstack/react-query';
import SButton from '../sbutton';

interface RefreshProps {
  queries: string[];
}

const SRefreshButton = ({ queries }: RefreshProps) => {
  const queryClient = useQueryClient();

  const handleRefresh = useCallback(() => {
    queries?.forEach(query => {
      queryClient.invalidateQueries({
        queryKey: [query]
      });
    });
  }, [queries, queryClient]);

  return (
    <SButton onClick={handleRefresh} startIcon={<RefreshIcon />}>
      Refresh
    </SButton>
  );
};

export default SRefreshButton;
