import { Box, Divider, Typography } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import Pagination, { PaginationProps } from '@material-ui/lab/Pagination';
import { usePaginationStyles } from './styles';

import ArrowIcon from './ArrowIcon';
import SPaginationMenu from './soptions';

export interface SPaginationProps extends PaginationProps {
  style?: React.CSSProperties;
  divider?: boolean;
  totalItems: number;
  options?: string[];
  itemsPerPage: number;
  handleItemsPerPage: (option: string) => void;
  handlePageChange: (page: number) => void;
}

const SPagination = ({
  style,
  options,
  page = 1,
  divider,
  handlePageChange,
  totalItems = 0,
  itemsPerPage = 10,
  shape = 'rounded',
  handleItemsPerPage,
  ...rest
}: SPaginationProps) => {
  const classes = usePaginationStyles();

  const fromPage = (page - 1) * itemsPerPage + 1;
  const toPage = (page + 0) * itemsPerPage;
  const isLastPage = page * itemsPerPage > totalItems;

  return (
    <Box>
      {divider && <Divider />}

      <Box className={classes.container} style={style}>
        <Typography>{`${fromPage}-${
          isLastPage ? totalItems : toPage
        } of ${totalItems} items`}</Typography>
        <Box className={classes.paginationRightSide}>
          <Pagination
            count={Math.ceil(totalItems / itemsPerPage) || 1}
            shape={shape}
            className={classes.paggination}
            page={page}
            onChange={(_, pg) => {
              handlePageChange(pg);
            }}
            {...rest}
          />
          <Box className={classes.perPageContainer}>
            <SPaginationMenu
              options={['5', '10', '20', '50']}
              value={itemsPerPage}
              onOptionChange={handleItemsPerPage}
              disabled={rest.disabled}
              endIcon={
                <Box className={classes.iconsContainer}>
                  <ArrowIcon
                    style={{
                      transform: 'rotate(180deg)'
                    }}
                  />
                  <ArrowIcon />
                </Box>
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SPagination;
