import {
  IconButtonProps,
  IconButton,
  withStyles,
  makeStyles,
  Theme
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

enum Direction {
  up = 90,
  down = -90,
  left = 0,
  right = 180
}

interface SNavigateButton extends Omit<IconButtonProps, 'size'> {
  size?: 'sm' | 'md' | 'lg';
  direction?: keyof typeof Direction;
}

const RoundedButton = withStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.primary.main,
    color: '#fff',
    '&.Mui-disabled': {
      color: '#fff',
      backgroundColor: palette.primary.light
    },
    '&:hover': {
      backgroundColor: palette.primary.dark
    }
  }
}))(IconButton);

const useStyles = makeStyles<Theme, { direction: keyof typeof Direction }>(
  theme => ({
    sm: {
      padding: theme.spacing(1)
    },
    md: {
      padding: theme.spacing(2)
    },
    lg: {
      padding: theme.spacing(3)
    },
    arrow: {
      transform: ({ direction }) => `rotate(${Direction[direction]}deg)`
    }
  })
);

const SNavigateButton = ({
  size = 'md',
  direction = 'left',
  ...rest
}: SNavigateButton) => {
  const classes = useStyles({ direction });

  return (
    <RoundedButton aria-label="back" className={classes[size]} {...rest}>
      <ArrowBackIcon fontSize="small" className={classes.arrow} />
    </RoundedButton>
  );
};

export default SNavigateButton;
