import { useCallback, useMemo } from 'react';
import parse from 'html-react-parser';

import { setEmailVariables } from '@setvi/shared/utils/variable-helper';
import { VARIABLE_TYPE } from '@setvi/shared/enums';

import { SPreviewProps } from './index';
import { getPreviewLink, parseHtmlForPreview, previewLinks } from '../../utils';

type UsePreviewProps = SPreviewProps;

export const usePreview = ({
  emailSubject,
  emailBody,
  variables: selectedVariables,
  links,
  selectedRecipient,
  showRecipientValues
}: UsePreviewProps) => {
  const replaceVariables = useCallback(
    (body: string, variables: Array<VARIABLE_TYPE> = []) => {
      let result = body;

      for (let i = 0; i < variables.length; i += 1) {
        const values: { [key: string]: string } = {
          'First Name': selectedRecipient.FirstName,
          'Last Name': selectedRecipient.LastName,
          'Company Name': selectedRecipient.CompanyName
        };

        result = result.replace(
          /{{(.*?)}}/g,
          (match, placeholder) => values[placeholder.trim()] || match
        );
      }
      result = result.replace(/class="mce-mergetag"/g, '');

      return result;
    },
    [selectedRecipient]
  );

  const htmlPreviewSubject = useMemo(() => {
    // Strip tags added by quill editor
    const element = document.createElement('div');
    element.innerHTML = emailSubject;
    const textWithVariables = setEmailVariables({ htmlString: emailSubject });
    const res =
      showRecipientValues && selectedRecipient ? (
        parse(replaceVariables(emailSubject, selectedVariables))
      ) : (
        <>
          {parse(
            parseHtmlForPreview(textWithVariables.documentElement.innerHTML)
          )}
        </>
      );

    return res;
  }, [
    emailSubject,
    replaceVariables,
    selectedRecipient,
    selectedVariables,
    showRecipientValues
  ]);

  const htmlPreviewBody = useMemo(() => {
    const parsedLinks = links.map(link => getPreviewLink(link));
    const parsedEmailBody = previewLinks(emailBody, parsedLinks);

    return showRecipientValues && selectedRecipient
      ? replaceVariables(parsedEmailBody, selectedVariables)
      : parsedEmailBody;
  }, [
    emailBody,
    links,
    selectedVariables,
    replaceVariables,
    selectedRecipient,
    showRecipientValues
  ]);

  return {
    htmlPreviewSubject,
    htmlPreviewBody
  };
};
