import { Box, withStyles, LinearProgress } from '@material-ui/core';

export const StyledProgress = withStyles(() => ({
  root: {
    borderRadius: '24px',
    height: '4px',
    backgroundColor: '#fff',

    '& .MuiLinearProgress-bar': {
      borderRadius: '24px'
    }
  }
}))(LinearProgress);

const Loading = ({
  loading,
  progress
}: {
  loading: boolean;
  progress: number;
}) =>
  loading ? (
    <Box width="100%" height="100%" bgcolor="#3855B314" borderRadius={6}>
      <Box
        p={3}
        width="100%"
        height="100%"
        display="flex"
        justifyContent="flex-end"
        flexDirection="column">
        <StyledProgress variant="determinate" value={progress} />
      </Box>
    </Box>
  ) : null;

export default Loading;
