import { Paper, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SButton } from '@setvi/shared/components';
import { useSnackbar } from 'notistack';
import { Presentation } from '@setvi/shared/interfaces';
import { usePresentation } from 'Hooks/usePresentation';
import { ROUTES } from 'enumsV2';
import { DialogType, dialogTypeContent } from '..';
import { usePresentationDialogStyles } from './styles';

interface ContentProps {
  goTo: (path: string) => void;
  onClose?: () => void;
  onSuccess?: (presentation?: Presentation) => void;
  dialogType: keyof typeof DialogType;
  withResources?: string[];
  presentation?: Presentation;
}

export const Content = ({
  goTo,
  onClose,
  onSuccess,
  presentation,
  withResources,
  dialogType
}: ContentProps) => {
  const { create, modify, loading } = usePresentation();
  const { enqueueSnackbar } = useSnackbar();

  const classes = usePresentationDialogStyles();

  const info = {
    [DialogType.create]: 'create',
    [DialogType.templateCreate]: 'create',
    [DialogType.edit]: 'update',
    [DialogType.duplicate]: 'duplicate'
  };

  const submitPresentation = async (values: any) => {
    const { presentationName: name } = values;

    if (!name) return;
    let presentationID: number | string;

    if (dialogType === DialogType.edit) {
      const { Data } = await modify({
        body: {
          id: presentation.ID,
          name,
          presentationItems: presentation.PresentationItems.map(
            presentationItem => presentationItem.ItemID
          )
        }
      });

      presentationID = Data?.[0]?.ID;

      if (presentationID) {
        enqueueSnackbar(`The presentation have been renamed successfully`, {
          variant: 'success'
        });
        onSuccess?.(Data?.[0]);
        onClose();
      }
    } else {
      const { Data } = await create({
        body: {
          name,
          presentationItems: withResources ? [...withResources] : []
        }
      });

      presentationID = Data?.[0]?.ID;

      if (presentationID) {
        enqueueSnackbar(
          `The presentation have been ${info[dialogType]}d successfully`,
          {
            variant: 'success'
          }
        );
        onSuccess?.(Data?.[0]);
        onClose();
      }

      // Default create success behavior is to redirect to presentation content page
      goTo(`${ROUTES.WORKSPACES_PRESENTATIONS}/${presentationID}`);
    }
  };

  const presentationForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      presentationName: presentation?.Name || ''
    },
    validationSchema: Yup.object({
      presentationName: Yup.string()
        .trim()
        .required('Presentation name is required')
        .max(100, 'Limit is 100 characters')
    }),
    onSubmit: submitPresentation
  });

  return (
    <>
      {!!dialogTypeContent[dialogType].description && (
        <Typography className={classes.description}>
          {dialogTypeContent[dialogType].description}
        </Typography>
      )}
      <form onSubmit={presentationForm.handleSubmit} noValidate>
        <Typography className={classes.label}>
          Presentation Name <span className={classes.required}>*</span>
        </Typography>
        <TextField
          className={classes.input}
          id="presentationName"
          name="presentationName"
          value={presentationForm.values.presentationName}
          onChange={presentationForm.handleChange}
          error={
            presentationForm.touched.presentationName &&
            Boolean(presentationForm.errors.presentationName)
          }
          helperText={
            <>
              {presentationForm.touched.presentationName &&
                presentationForm.errors.presentationName}
            </>
          }
          fullWidth
          type="text"
          variant="outlined"
          placeholder={dialogTypeContent[dialogType].placeholder}
        />

        <Paper component="div" className={classes.actionPaper}>
          <SButton
            onClick={onClose}
            variant="outlined"
            color="inherit"
            fullWidth>
            Close
          </SButton>
          <SButton
            className={classes.confirmBtn}
            loading={loading}
            fullWidth
            type="submit">
            {info[dialogType]}
          </SButton>
        </Paper>
      </form>
    </>
  );
};
