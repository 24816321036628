import { AxiosMethods, ContactsApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import { Contact } from '@setvi/shared/services/react-query/query/contacts/types';

export enum ContactsMutationsKey {
  ADD_CONTACTS = 'add_contacts',
  UPDATE_CONTACTS = 'update_contacts',
  DELETE_CONTACTS = 'delete_contacts'
}

export type ContactValues = Pick<
  Contact,
  'Email' | 'FirstName' | 'LastName' | 'CompanyName'
>;

interface CreateContactsMutationProps {
  contacts: ContactValues[];
  ignoreDuplicates?: boolean;
}

export const createContactsMutation = () => ({
  mutationFn: ({
    contacts,
    ignoreDuplicates = false
  }: CreateContactsMutationProps) =>
    axiosHelper({
      endpoint: ContactsApi.Contacts,
      method: AxiosMethods.POST,
      body: { Contacts: contacts, IgnoreDuplicates: ignoreDuplicates }
    })
});

export type UpdateContactValues = Pick<
  Contact,
  'Id' | 'Email' | 'FirstName' | 'LastName' | 'CompanyName'
>;

interface UpdateContactsMutationProps {
  contacts: UpdateContactValues[];
  ignoreDuplicates?: boolean;
}

export const updateContactsMutation = () => ({
  mutationFn: ({
    contacts,
    ignoreDuplicates = false
  }: UpdateContactsMutationProps) =>
    axiosHelper({
      endpoint: ContactsApi.Contacts,
      method: AxiosMethods.PUT,
      body: { Contacts: contacts, IgnoreDuplicates: ignoreDuplicates }
    })
});

interface DeleteContactsMutationProps {
  contactIds: Pick<Contact, 'Id'>[];
}

export const deleteContactsMutation = () => ({
  mutationFn: ({ contactIds }: DeleteContactsMutationProps) =>
    axiosHelper({
      endpoint: ContactsApi.Contacts,
      method: AxiosMethods.DELETE,
      body: { ids: contactIds }
    })
});
