import { ResourceBookmark } from '@setvi/shared/interfaces';
import { SDropdownMenu } from '@setvi/shared/components';
import { useDropdown } from './useDropdown';

export interface DropdownProps {
  bookmark: ResourceBookmark;
  filteredBookmarks: ResourceBookmark[];
  handleRename: () => void;
}

export const Dropdown = ({
  bookmark,
  handleRename,
  filteredBookmarks
}: DropdownProps) => {
  const { menuItems } = useDropdown({
    bookmark,
    handleRename,
    filteredBookmarks
  });
  return <SDropdownMenu menuItems={menuItems} />;
};
