import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { SText } from '@setvi/shared/components';
import { forwardRef } from 'react';
import {
  IconBackground,
  Time,
  TitleWrapper,
  useStyles
} from './NotificationsStyle';

export interface NotificationData {
  id: number;
  time: string;
  title: string;
  description?: string;
  user: string;
  icon: JSX.Element;
  iconBackgroundColor: string;
}

interface NotificationProps {
  notification?: NotificationData;
}

export const Notification = forwardRef<HTMLElement, NotificationProps>(
  ({ notification }, ref) => {
    const { palette } = useTheme();
    const classes = useStyles();

    return (
      <Box display="flex" width="100%" mb={4}>
        <IconBackground
          bgcolor={notification?.iconBackgroundColor || palette.primary.main}
          mr={2}>
          {notification?.icon}
        </IconBackground>
        <Box className={classes.notificationText}>
          <TitleWrapper ref={ref}>
            {notification.title}
            {notification.user && (
              <>
                <span className={classes.preposition}>by</span>
                {notification.user}
              </>
            )}
          </TitleWrapper>
          {notification.description && (
            <SText
              className={classes.description}
              title={notification.description}
            />
          )}
          <Time>{notification.time}</Time>
        </Box>
      </Box>
    );
  }
);
