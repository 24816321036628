import { AxiosMethods, NotificationsApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

export enum NotificationsQueryKey {
  NOTIFICATIONS = 'notifications'
}

export const getNotificationsQuery = (input?: any) => ({
  queryKey: [NotificationsQueryKey.NOTIFICATIONS, input],
  queryFn: (data: any) =>
    axiosHelper({
      endpoint: `${NotificationsApi.Notifications}`,
      method: AxiosMethods.POST,
      params: data.queryKey[1]
    }),
  select: (res: any) => ({
    Result: res.Data[0].Notifications,
    Total: res.Data[0].Total
  })
});
