import { createContext, useContext } from 'react';

export type RealmContextType = {
  client: any;
  user: any;
};

export const RealmContext = createContext<RealmContextType>({
  client: null,
  user: null
});

export const useRealmContext = () => {
  const context = useContext(RealmContext);

  if (context === undefined)
    throw new Error(`useAppContext must be used within a AppContext.Provider`);

  return context;
};
