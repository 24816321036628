import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import Pagination, { PaginationProps } from '@material-ui/lab/Pagination';

import ArrowIcon from './ArrowIcon';
import SPaginationMenu from './soptions';

interface SetviPaginationProps extends PaginationProps {
  totalItems: number;
  options?: string[];
  itemsPerPage: number;
  handleItemsPerPage: (option: string) => void;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#44444F'
  },
  paggination: {
    '& ul > li > button': {
      color: '#44444F'
    },
    '& ul > li:first-child > button': {
      border: '1px solid #E2E2EA',
      borderRadius: '8px',
      fill: '#44444F'
    },
    '& ul > li:last-child > button': {
      border: '1px solid #E2E2EA',
      borderRadius: '8px',
      fill: '#44444F'
    },
    '& .Mui-selected': {
      color: '#0062FF',
      background: 'transparent',
      fontWeight: 700
    }
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px'
  }
});

const SPagination = ({
  options,
  page = 1,
  onChange,
  count = 1,
  totalItems = 0,
  itemsPerPage = 10,
  shape = 'rounded',
  handleItemsPerPage,
  ...rest
}: SetviPaginationProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography>{`${page - 1 ? (page - 1) * itemsPerPage : 1}-${
        // TODO: fix this eslint error
        // eslint-disable-next-line no-nested-ternary
        totalItems > itemsPerPage
          ? itemsPerPage * page > totalItems
            ? totalItems
            : itemsPerPage * page
          : totalItems
      } of ${totalItems} items`}</Typography>

      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        gridGap={15}>
        <Pagination
          count={count}
          shape={shape}
          className={classes.paggination}
          page={page}
          onChange={onChange}
          {...rest}
        />
        {options ? (
          <SPaginationMenu
            options={options}
            onOptionChange={handleItemsPerPage}
            endIcon={
              <Box className={classes.iconsContainer}>
                <ArrowIcon
                  style={{
                    transform: 'rotate(180deg)'
                  }}
                />
                <ArrowIcon />
              </Box>
            }
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default SPagination;
