import { Box, makeStyles } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { Separator } from './index';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  expander: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 16,
    borderRadius: 2,
    color: '#616161',
    backgroundColor: '#f5f5f5',
    fontSize: 12,
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  },
  tooltip: {
    minWidth: 66
  }
});

interface ExpanderProps {
  separator: string;
  onClick: () => void;
}

const Expander = ({ separator, onClick }: ExpanderProps) => {
  const classes = useStyles();
  return (
    <SText
      className={classes.tooltip}
      showTooltip
      title="View All Categories"
      placement="top">
      <Box className={classes.container}>
        <Separator>{separator}</Separator>
        <div onClick={onClick} className={classes.expander}>
          •••
        </div>
        <Separator>{separator}</Separator>
      </Box>
    </SText>
  );
};

export default Expander;
