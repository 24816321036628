import { Box } from '@material-ui/core';
import { Page } from './page';
import { useResourceChildrenStyles } from '../../styles';
import { ChildResource } from '../../../../interfaces';
import { Loader } from './loader';

interface PagesListProps {
  list: ChildResource[];
  handleSelect: (isChecked: boolean, selected: ChildResource[]) => void;
  loading: boolean;
  selected: ChildResource[];
  activePage?: ChildResource;
  setActivePage: (item: ChildResource) => void;
}

export const SPagesList = ({
  list,
  loading,
  activePage,
  setActivePage,
  selected,
  handleSelect
}: PagesListProps) => {
  const classes = useResourceChildrenStyles();

  return (
    <Box
      className={classes.listWrapper}
      style={{ minWidth: (loading || list.length > 1) && 280 }}>
      <Box className={classes.list}>
        {!loading ? (
          list?.map(page => (
            <Page
              key={page.ItemID}
              page={page}
              title={page.SortOrder}
              isSelected={Boolean(
                selected?.find(i => i.ItemID === page.ItemID)
              )}
              isActive={activePage?.ItemID === page.ItemID}
              handleSelect={handleSelect}
              setActivePage={() => setActivePage(page)}
            />
          ))
        ) : (
          <Loader />
        )}
      </Box>
    </Box>
  );
};
