import {
  Tree,
  ControlledTreeEnvironment,
  ControlledTreeEnvironmentProps
} from 'react-complex-tree';

import ReactTreeItem from './item';

interface STreeProps<T> extends ControlledTreeEnvironmentProps<T> {
  treeId?: string;
  rootItem?: string;
  treeLabel?: string;
  customStyle?: CustomStyleProps;
  disableActiveStyle?: boolean;

  onItemClick?: (item: T) => void;
}

interface CustomStyleProps {
  background?: string;
  color?: string;
}

const STreeV2 = <T,>({
  items,
  treeId = 'react-tree',
  rootItem = 'root',
  treeLabel = 'Tree',
  viewState,
  customStyle,
  disableActiveStyle,

  onItemClick,
  getItemTitle,
  onExpandItem,
  onCollapseItem,
  renderItemTitle,

  ...rest
}: STreeProps<T>) => (
  <ControlledTreeEnvironment<T>
    items={items}
    viewState={viewState}
    renderItem={props => (
      <ReactTreeItem
        {...props}
        onClick={onItemClick}
        itemColor={customStyle?.color}
        itemBackground={customStyle?.background}
        disableActiveStyle={disableActiveStyle}
      />
    )}
    getItemTitle={getItemTitle}
    onExpandItem={onExpandItem}
    onCollapseItem={onCollapseItem}
    renderItemTitle={renderItemTitle}
    {...rest}>
    <Tree treeId={treeId} rootItem={rootItem} treeLabel={treeLabel} />
  </ControlledTreeEnvironment>
);

export default STreeV2;
