import { SInfiniteScroll } from '@setvi/shared/components';
import { SelectedResourcePreview } from './item';
import { Resource } from '../../../../../interfaces';

interface SelectedResourcesPreviewListProps {
  resources: Resource[];
  onRemoveItem: (resource: Resource) => void;
}

export const SelectedResourcesPreviewList = ({
  resources,
  onRemoveItem
}: SelectedResourcesPreviewListProps) => (
  <SInfiniteScroll
    list={resources}
    keyGetter={resource => resource.ID}
    render={resource => (
      <SelectedResourcePreview resource={resource} removeItem={onRemoveItem} />
    )}
  />
);
