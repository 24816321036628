import { Typography, makeStyles, withStyles } from '@material-ui/core';
import { TextColor } from '@setvi/shared/enums';

export const Breadcrumb = withStyles(({ palette }) => ({
  root: {
    textDecoration: 'none',
    color: TextColor.LightGrey,
    cursor: 'pointer',
    '&:hover': {
      color: palette.primary.main
    }
  }
}))(Typography);

export const ActiveBreadcrumb = withStyles(({ palette }) => ({
  root: {
    color: palette.primary.main,
    fontWeight: 600
  }
}))(Typography);

export const useBreadcrumbsStyles = makeStyles(({ spacing }) => ({
  container: {
    '& .MuiBreadcrumbs-separator': {
      color: TextColor.LightGrey
    }
  },
  iconWrapper: {
    color: TextColor.LightGrey,
    marginRight: spacing(1)
  }
}));
