import { Box, IconButton, makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

interface ScrollButtonProps {
  direction: 'left' | 'right';
  onClick?: () => void;
}

const useScrollButtonStyles = makeStyles(theme => ({
  root: {
    borderRadius: '0',
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)'
  }
}));

export const ScrollButtonComponent = ({
  direction,
  onClick
}: ScrollButtonProps) => {
  const classes = useScrollButtonStyles();

  return (
    <IconButton onClick={() => onClick?.()} classes={{ root: classes.root }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          border: '3px solid #696974',
          borderRadius: '50%',
          width: 24,
          height: 24
        }}>
        {direction === 'left' ? (
          <ChevronLeftIcon htmlColor="#696974" />
        ) : (
          <ChevronRightIcon htmlColor="#696974" />
        )}
      </Box>
    </IconButton>
  );
};
