import { CSSProperties } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  withStyles
} from '@material-ui/core';
import { Skeleton as MuiSkeleton } from '@material-ui/lab';
import { ColumnsType } from '@setvi/shared/types';

const Skeleton = withStyles({
  root: {
    width: '100%',
    height: 20
  }
})(MuiSkeleton);

interface STableLoaderProps {
  columns?: ColumnsType;
  rows?: number;
}
interface CellStyle {
  width?: CSSProperties['width'];
  maxWidth?: CSSProperties['maxWidth'];
}

export const TableLoader = ({ columns, rows = 8 }: STableLoaderProps) => {
  const defaultStyles: CellStyle[] = [
    { width: '10%' },
    { width: '75%' },
    { width: '5%' }
  ];

  const styles = columns || defaultStyles;

  const getCell = (style: CellStyle, elem: string) => (
    <TableCell style={style} key={elem}>
      <Skeleton variant="rect" height={20} />
    </TableCell>
  );

  return (
    <Box>
      <TableContainer>
        <Table aria-label="Folders Table">
          <TableBody>
            {Array.from({ length: rows }, _ => uuidv4()).map(elem => (
              <TableRow key={elem}>
                {styles.map(i => getCell(i, elem + uuidv4()))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
