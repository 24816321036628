import { VARIABLE_TYPE } from '../../enums';

export const VARIABLE_LIST = [
  VARIABLE_TYPE.firstName,
  VARIABLE_TYPE.lastName,
  VARIABLE_TYPE.companyName
];

export const getBodyVariables = (
  HtmlBody: string,
  variableSelector = '.mce-mergetag'
) => {
  const BodyVariables: VARIABLE_TYPE[] = [];
  const parser = new DOMParser();

  const htmlBody = parser.parseFromString(HtmlBody, 'text/html');

  htmlBody
    .querySelectorAll(variableSelector)
    ?.forEach(e =>
      VARIABLE_LIST.some(
        v => e.textContent?.includes(v) && BodyVariables.push(v)
      )
    );

  return BodyVariables;
};
