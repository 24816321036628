import { Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const TextWrapper = styled.table`
  table-layout: fixed;
  width: 100%;
  word-wrap: break-word;
`;

export const Title = withStyles(({ palette }) => ({
  root: {
    color: palette.text.disabled,
    fontWeight: 600
  }
}))(Typography);

export const useStyles = makeStyles(() => ({
  subjectContainer: {
    fontSize: '1.4em',
    '& p': {
      marginTop: 0
    }
  },
  variableStyles: {
    '& .mce-mergetag': {
      borderRadius: '25px',
      padding: '3px 8px 3px 8px',
      color: '#ffffff',
      backgroundColor: '#1E96FC'
    }
  }
}));
