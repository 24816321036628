import React, { forwardRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTextStyles } from './Text.styles';

export interface TextProps {
  children: React.ReactNode;
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
  weight?: 'thin' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold';
  color?: string;
  family?: 'sans' | 'serif';
  className?: string;
  style?: React.CSSProperties;
  lineClamp?: 1 | 2 | 3;
  noWrap?: boolean;
}

export const Text = forwardRef<HTMLSpanElement, TextProps>(
  (
    {
      children,
      size = 'base',
      weight = 'normal',
      family = 'sans',
      color = '#212121',
      className,
      style,
      lineClamp,
      noWrap
    },
    ref
  ) => {
    const classes = useTextStyles({ color });
    let classNames = `${classes.root}`;

    if (className) {
      classNames = `${classNames} ${className}`;
    }
    if (weight) {
      classNames = `${classNames} ${classes[`${weight}`]}`;
    }
    if (family) {
      classNames = `${classNames} ${classes[`${family}`]}`;
    }
    if (size) {
      classNames = `${classNames} ${classes[`${size}`]}`;
    }
    if (lineClamp) {
      classNames = `${classNames} ${classes[`lineClamp${lineClamp}`]}`;
    }
    if (noWrap) {
      classNames = `${classNames} ${classes.noWrap}`;
    }
    return (
      <Typography ref={ref} style={style} className={classNames}>
        {children}
      </Typography>
    );
  }
);
