export const viewIcon = `
    <svg width="19" height="15" fill="none"  >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.599 6.973C2.259 2.726 5.683 0 9.5 0s7.241 2.726 8.901 6.973c.132.337.132.717 0 1.054C16.741 12.274 13.317 15 9.5 15S2.259 12.274.599 8.027a1.454 1.454 0 0 1 0-1.054ZM9.5 13.265c3.077 0 5.898-2.215 7.335-5.765-1.437-3.55-4.258-5.765-7.335-5.765S3.602 3.95 2.165 7.5c1.437 3.55 4.258 5.765 7.335 5.765Zm0-2.347c-1.78 0-3.225-1.53-3.225-3.418 0-1.888 1.444-3.418 3.225-3.418 1.78 0 3.225 1.53 3.225 3.418 0 1.888-1.444 3.418-3.225 3.418Zm0-1.734c.877 0 1.588-.754 1.588-1.684 0-.93-.71-1.684-1.588-1.684-.877 0-1.588.754-1.588 1.684 0 .93.71 1.684 1.588 1.684Z"
         />
    </svg>`;

export const editIcon = ` 
    <svg  width="14" height="14" fill="none" >
        <path fill-rule="evenodd" clip-rule="evenodd" d="m11.653 4.38.79-.789-1.741-1.74-.79.79 1.74 1.74Zm-.997.997-1.74-1.74-6.51 6.51-.401 2.141 2.14-.4 6.511-6.51Zm2.825-2.74a1.35 1.35 0 0 1 0 1.908L4.988 13.04c-.1.1-.229.168-.369.194l-3.366.63a.705.705 0 0 1-.823-.822l.63-3.367a.705.705 0 0 1 .195-.369L9.748.812a1.35 1.35 0 0 1 1.908 0l1.825 1.825Zm-.306 11.238h-5.68c-.936 0-.936-1.417 0-1.417h5.68c.935 0 .935 1.417 0 1.417Z"  />
    </svg>
 `;

export const removeIcon = `
    <svg width="15" height="15" viewBox="0 0 21 20"  >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.86448 2.7L6.53656 0.683772C6.67267 0.27543 7.05481 0 7.48524 0H12.8852C13.3157 0 13.6978 0.27543 13.8339 0.683772L14.506 2.7H19.1852C19.7375 2.7 20.1852 3.14772 20.1852 3.7C20.1852 4.25229 19.7375 4.7 19.1852 4.7H18.3281L17.5943 17.1762C17.501 18.7619 16.1879 20 14.5994 20H5.77105C4.18262 20 2.8695 18.7619 2.77623 17.1762L2.04234 4.7H1.20117C0.648887 4.7 0.201172 4.25229 0.201172 3.7C0.201172 3.14772 0.648887 2.7 1.20117 2.7H5.86448ZM7.97267 2.7H12.3978L12.1645 2H8.206L7.97267 2.7ZM16.3247 4.7H4.04579L4.77278 17.0587C4.80387 17.5873 5.24158 18 5.77105 18H14.5994C15.1289 18 15.5666 17.5873 15.5977 17.0587L16.3247 4.7ZM11.8872 7.23762C11.9216 6.68641 12.3964 6.2675 12.9476 6.30195C13.4988 6.3364 13.9177 6.81117 13.8833 7.36238L13.4333 14.5624C13.3988 15.1136 12.9241 15.5325 12.3729 15.4981C11.8217 15.4636 11.4027 14.9888 11.4372 14.4376L11.8872 7.23762ZM8.93329 14.4376C8.96774 14.9888 8.54883 15.4636 7.99762 15.4981C7.44641 15.5325 6.97164 15.1136 6.93719 14.5624L6.48719 7.36238C6.45274 6.81117 6.87165 6.3364 7.42286 6.30195C7.97407 6.2675 8.44884 6.68641 8.48329 7.23762L8.93329 14.4376Z"
         />
    </svg>
 `;
