import { Box, Typography } from '@material-ui/core';
import { Header } from '@setvi/shared/components/sdrawer-content/components/header';
import { SSearchInput } from '@setvi/shared/components/sforms/ssearch';
import { AddressBook } from '@setvi/shared/components/sdrawer-content/components/contact/address-book';
import { TContact } from '@setvi/shared/components/sdrawer-content/components/contact';
import { Account } from '@setvi/shared/services/react-query/query/user/types';
import { Subheader } from '../../../../components/subheader';
import { useCrmContacts } from '../useCrmContacts';
import { SEmptyState } from '../../../../..';
import { Recipient } from '../../../../../../interfaces';

interface ContactsProps {
  account: Account;
  selectedContacts?: Recipient[];
  onBack: () => void;
  modifyRecipientList: (contact: TContact) => void;
  handleClose: () => void;
}

export const Contacts = ({
  account,
  selectedContacts,
  onBack,
  modifyRecipientList,
  handleClose
}: ContactsProps) => {
  const { contacts, fetchMoreContacts, isLoading, setSearchTerm } =
    useCrmContacts({ accountId: account.Id });

  const accountContacts: TContact[] = contacts?.map(contact => ({
    ...contact,
    CRMWhoId: contact.Id,
    CRMWhatId: account.Id
  }));

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Recipients" handleClose={handleClose} />
      <Subheader handleBack={() => onBack()} text={account.Name} />
      <Box p={2} mb={1}>
        <SSearchInput onChange={value => setSearchTerm(value)} />
      </Box>
      <Box overflow="auto">
        <AddressBook
          contacts={accountContacts}
          selectedContacts={selectedContacts}
          fetchMore={fetchMoreContacts}
          onContactClick={modifyRecipientList}>
          <>
            {isLoading && (
              <Box textAlign="center">
                <Typography variant="h6">Loading...</Typography>
              </Box>
            )}
            {!isLoading && contacts?.length === 0 && (
              <SEmptyState
                imageSrc="/images/no-contacts-2.png"
                subTitle="No contacts available"
              />
            )}
          </>
        </AddressBook>
      </Box>
    </Box>
  );
};
