import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import { validateSync } from '@setvi/shared/utils';
import { CrmTypes } from '@setvi/shared/services';
import {
  removeSalesforceEmailMutation,
  setSalesforceEmailMutation,
  saveSalesforceUserMutation
} from 'Services';
import { useAppContext } from 'Providers/AppProvider/AppContext';

export const useCRMIntegration = () => {
  const { user, setUser } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const [isConnected, setIsConnected] = useState(
    (!!user?.SalesforceEmail && !!user?.SalesforceUserID) || false
  );

  const { mutateAsync: setSalesforceEmail, isLoading: loading } = useMutation(
    setSalesforceEmailMutation()
  );
  const {
    mutateAsync: saveUserCredentials,
    isLoading: userCredentialsLoading
  } = useMutation(saveSalesforceUserMutation());
  const { mutateAsync: removeSalesforceEmail, isLoading: deleteLoading } =
    useMutation(removeSalesforceEmailMutation());

  const connectToCRM = async (email: string) => {
    setSalesforceEmail({
      email,
      type: CrmTypes.User
    })
      .then(res => {
        if (!res) return null;

        if (res.Data.records.length === 0)
          return enqueueSnackbar(
            'No salesforce account found with specified email address.',
            { variant: 'warning' }
          );

        const salesforceUserData = {
          SalesforceEmail: email || '',
          SalesforceUserID: res.Data.records[0].Id || ''
        };

        setUser({
          ...user,
          ...salesforceUserData
        });
        setIsConnected(true);

        enqueueSnackbar(`Changes has been saved successfully`, {
          variant: 'success'
        });
        return saveUserCredentials(salesforceUserData);
      })
      .catch(() => {});
  };

  const disconnectFromCRM = () => {
    removeSalesforceEmail({})
      .then(res => {
        if (!res) return;
        setUser({
          ...user,
          SalesforceEmail: '',
          SalesforceUserID: ''
        });
        setIsConnected(false);

        enqueueSnackbar(`Changes has been saved successfully`, {
          variant: 'success'
        });
      })
      .catch(() => {});
  };

  const CRMFormHandler = useFormik({
    enableReinitialize: true,
    initialValues: {
      crmEmail: user?.SalesforceEmail || ''
    },
    onSubmit: async values => {
      if (
        validateSync({
          schema: {
            crmEmail: Yup.string()
              .email('Invalid email')
              .required('BccEmail is Required')
          },
          values
        })
      ) {
        enqueueSnackbar(
          `${values.crmEmail ? 'Invalid email' : 'CRM field is required'}`,
          {
            variant: 'error'
          }
        );
        return;
      }

      !isConnected ? connectToCRM(values?.crmEmail || '') : disconnectFromCRM();
    }
  });

  return {
    loading: loading || deleteLoading || userCredentialsLoading,
    isConnected,
    CRMFormHandler,
    connectedSalesforceUser: user?.SalesforceEmail
  };
};
