import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { getGroupsQuery } from '@setvi/shared/services';
import { Group } from '@setvi/shared/services/react-query/query/contacts/types';

const GROUPS_LIMIT = 20;
export const useGroups = () => {
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [searchString, setSearchString] = useState<string>('');

  const queryVariables = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: GROUPS_LIMIT,
      orderBy: 'ASC',
      sortBy: 'Name',
      search: searchString
    }),
    [searchString]
  );

  const {
    data: groups,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(getGroupsQuery(queryVariables));

  const fetchMoreGroups = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    groups: groups?.pages?.map(page => page.Result).flat(1) || [],
    isLoading: isLoading || isFetching,
    fetchMoreGroups,
    searchString,
    setSearchString,
    selectedGroup,
    setSelectedGroup
  };
};
