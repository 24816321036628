import { AxiosMethods, TemplatesApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

export enum TemplateQueryKey {
  TEMPLATES = 'templates',
  TEMPLATE = 'template'
}

export const getTemplatesQuery = () => ({
  queryKey: [TemplateQueryKey.TEMPLATES],
  queryFn: () =>
    axiosHelper({
      endpoint: TemplatesApi.Templates,
      method: AxiosMethods.POST
    }),
  select: ({ Data }: any) => {
    const data = Data?.map((template: any) => ({
      ...template,
      ThumbURL: template.Items[0].ThumbURLWithSas,
      Items: template.Items.map((item: any) => ({
        ...item,
        ThumbURL: item.ThumbURLWithSas
      }))
    }));

    return data || [];
  }
});

export const getTemplateQuery = (templateId: string) => ({
  queryKey: [TemplateQueryKey.TEMPLATE, templateId],
  queryFn: () =>
    axiosHelper({
      endpoint: TemplatesApi.Template,
      method: AxiosMethods.POST,
      params: { templateId }
    }),
  select: ({ Data }: any) => {
    const data = Data?.map((template: any) => ({
      ...template,
      ThumbURL: template.Items[0].ThumbURLWithSas,
      Items: template.Items.map((item: any) => ({
        ...item,
        ThumbURL: item.ThumbURLWithSas
      }))
    }));

    return data?.[0] || [];
  }
});
