import { makeStyles, Theme } from '@material-ui/core/styles';

interface IStylesProps {
  color?: string;
}

export const useTextStyles = makeStyles<Theme, IStylesProps>(theme => ({
  root: {
    fontFamily:
      'Roboto, sans-serif, serif, Times, Monospace, Verdana, "Trebuchet MS", Georgia',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: props => (props.color ? props.color : theme.palette.text.primary)
  },
  xs: {
    fontSize: '0.75rem',
    lineHeight: '1rem'
  },
  sm: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem'
  },
  base: {
    fontSize: '1rem',
    lineHeight: '1.5rem'
  },
  lg: {
    fontSize: '1.125rem',
    lineHeight: '1.75rem'
  },
  xl: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem'
  },
  '2xl': {
    fontSize: '1.5rem',
    lineHeight: '2rem'
  },
  '3xl': {
    fontSize: '1.875rem',
    lineHeight: '2.25rem'
  },
  '4xl': {
    fontSize: '2.25rem',
    lineHeight: '2.5rem'
  },
  '5xl': {
    fontSize: '3rem',
    lineHeight: 1
  },
  thin: {
    fontWeight: 100
  },
  light: {
    fontWeight: 300
  },
  normal: {
    fontWeight: 400
  },
  medium: {
    fontWeight: 500
  },
  semibold: {
    fontWeight: 600
  },
  bold: {
    fontWeight: 700
  },
  sans: {
    fontFamily:
      'Roboto, Helvetica, Arial, sans-serif, serif, Times, Monospace, Verdana, "Trebuchet MS", Georgia'
  },
  serif: {
    fontFamily: 'Poppins'
  },
  lineClamp1: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis'
  },
  lineClamp2: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis'
  },
  lineClamp3: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis'
  },
  noWrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis'
  }
}));
