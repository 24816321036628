import { makeStyles } from '@material-ui/core';

export const useSQuadrantStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  grid: {
    flexGrow: 1,
    height: '100%'
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${palette.grey[300]}`,
    flexGrow: 1,
    minHeight: 0
  },
  left: {
    borderRight: `1px solid ${palette.grey[300]}`
  },
  right: {
    borderLeft: `1px solid ${palette.grey[300]}`
  },
  order2: {
    order: 2
  },
  topConatiner: {
    padding: spacing(2),
    width: '100%',
    height: 75,
    display: 'flex',
    alignItems: 'center'
  },
  bottomContainer: {
    height: 100,
    overflow: 'auto',
    flexGrow: 1,
    minHeight: '0%',
    margin: spacing(2, 3)
  }
}));
