import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { distances } from '@setvi/shared/styles';
import { EmptyState } from './empty-state';
import { Item } from './item';
import { Loader } from './Loader';
import { ResourceBookmark } from '../../../../../../interfaces';

export const ListContainer = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden auto',
    gap: distances.px.medium
  }
})(Box);

export interface ContentProps {
  bookmarks: ResourceBookmark[];
  loading: boolean;
  handleRename: (bookmark: ResourceBookmark) => void;
  setActivePage: (bookmarkedIndex: number) => void;
}

export const Content = ({
  bookmarks,
  loading,
  handleRename,
  setActivePage
}: ContentProps) => {
  if (loading) return <Loader />;
  if (!loading && bookmarks.length === 0) return <EmptyState />;

  return (
    <ListContainer>
      {bookmarks.map(bookmark => {
        const filteredBookmarks = bookmarks.filter(i => i.ID !== bookmark.ID);
        return (
          <Item
            key={bookmark.ID}
            bookmark={bookmark}
            setActivePage={() => setActivePage(bookmark.PageNumber)}
            handleRename={() => handleRename(bookmark)}
            filteredBookmarks={filteredBookmarks}
          />
        );
      })}
    </ListContainer>
  );
};
