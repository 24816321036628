import { Box } from '@material-ui/core';
import { ManageIcon } from '@setvi/shared/components/sicons';

import { ROUTES } from 'enumsV2';
import { MenuItemProps } from '../..';

enum FLAGS {
  RESOURCES = 'adminResources',
  COMPANY_CAMPAIGNS = 'adminCompanyCampaign',
  USERS = 'adminUsers'
}

export const AdminLabel = ({
  collapsedDrawer
}: {
  collapsedDrawer: boolean;
}) => (
  <Box
    bgcolor="#F1F1F5"
    fontWeight={700}
    color="#92929D"
    py={2}
    px={3}
    sx={{
      textAlign: collapsedDrawer ? 'center' : 'left'
    }}>
    ADMIN
  </Box>
);

export const adminMenuItems: MenuItemProps[] = [
  {
    title: 'Manage',
    icon: <ManageIcon />,
    baseRoute: ROUTES.ADMIN,
    list: [
      {
        title: 'Resources',
        flag: FLAGS.RESOURCES,
        route: ROUTES.ADMIN_RESOURCES,
        baseRoute: ROUTES.ADMIN_RESOURCES
      },
      {
        title: 'Users',
        // flag: FLAGS.USERS,
        route: ROUTES.ADMIN_USERS,
        baseRoute: ROUTES.ADMIN_USERS
      },
      {
        title: 'Campaigns',
        flag: FLAGS.COMPANY_CAMPAIGNS,
        route: ROUTES.ADMIN_CAMPAIGNS,
        baseRoute: ROUTES.ADMIN_CAMPAIGNS
      }
    ]
  }
];
