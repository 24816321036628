import { Box, LinearProgress, Typography, makeStyles } from '@material-ui/core';
import { distances } from '../../../../../../styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  progress: {
    display: 'block',
    width: 200
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: distances.xsmall
  }
});

interface LoadingPageProps {
  loaded: number;
  total: number;
}

export const LoadingPDFPage = ({ loaded, total }: LoadingPageProps) => {
  const percentage = total === 0 ? 0 : (100 / total) * loaded;
  const classes = useStyles({ percentage });
  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Loading PDF...</Typography>
      <LinearProgress
        className={classes.progress}
        variant="determinate"
        value={total}
      />
    </Box>
  );
};
