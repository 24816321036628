import { useMemo, useCallback } from 'react';
import { Box, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { generateLinkViewerUrl } from '@setvi/shared/utils/viewer';
import { Link } from '@setvi/shared/interfaces';
import { ReviewLinkMenu } from '../menu';
import SText from '../../../../sui/stext';
import { getPreviewLink } from '../../../../../utils';

interface LinkItemProps {
  link: Link;
  onEdit: () => void;
  onDelete: () => void;
}

const LinkContainer = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2, 3),
    cursor: 'pointer'
  }
}))(Box);

export const LinkItem = ({ onEdit, onDelete, link }: LinkItemProps) => {
  const resourceCount = useMemo(() => link?.Item?.Items?.length, [link]);

  const openPreview = useCallback(
    () =>
      window
        .open(generateLinkViewerUrl(getPreviewLink(link)), '_blank')
        .focus(),
    [link]
  );

  return (
    <>
      <LinkContainer onClick={onEdit}>
        <Box>
          <Box display="flex">
            <SText
              variant="body1"
              showTooltip
              weight="bold"
              title={link.Name}
              maxLines={2}
            />
          </Box>
          <SText variant="body2" color="textSecondary">
            {resourceCount} resources
          </SText>
        </Box>
        <Box onClick={e => e.stopPropagation()}>
          <ReviewLinkMenu
            onPreview={openPreview}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Box>
      </LinkContainer>
      <Divider />
    </>
  );
};
