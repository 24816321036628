import { Box, withStyles } from '@material-ui/core';
import { SButton } from '@setvi/shared/components';

interface ICreateLinkButtons {
  confirmButtonText: string;
  disabled?: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}

const Wrapper = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacing(2),
    '& button': {
      height: 50
    }
  }
}))(Box);

export const CreateLinkButtons = ({
  confirmButtonText,
  disabled = false,
  loading,
  onClose,
  onConfirm
}: ICreateLinkButtons) => (
  <Wrapper>
    <SButton variant="outlined" onClick={onClose} fullWidth>
      Cancel
    </SButton>
    <SButton
      disabled={disabled}
      loading={loading}
      onClick={() => onConfirm?.()}
      fullWidth
      type="submit">
      {confirmButtonText}
    </SButton>
  </Wrapper>
);
