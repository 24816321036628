import { Box } from '@material-ui/core';
import { useResourceChildrenStyles } from '../styles';
import { SPagesList, ResourceInfo, Image } from '../components';
import { ListProps } from '../pdf';

export const PPTResourceChildrenList = ({
  resource,
  selected,
  handleSelect,
  loading,
  list,
  activePage,
  setActivePage
}: ListProps) => {
  const classes = useResourceChildrenStyles();

  return (
    <>
      <Box className={classes.resourceInfoContainer}>
        <ResourceInfo
          thumbUrl={resource?.ThumbURLWithSas || resource?.ThumbURL}
          resourceID={resource?.ResourceID}
          name={resource?.Name}
          resourceTypeID={resource?.ResourceTypeID}
          resourceTypeName={resource?.ResourceTypeName}
        />
      </Box>
      <Box className={classes.container}>
        <SPagesList
          list={list}
          loading={loading}
          selected={selected}
          activePage={activePage}
          handleSelect={handleSelect}
          setActivePage={page => setActivePage(page)}
        />
        <Box className={classes.activePage}>
          <Image page={activePage} loading={loading} />
        </Box>
      </Box>
    </>
  );
};
