import {
  BreadcrumbsProps,
  Breadcrumbs as MuiBreadcrumbs
} from '@material-ui/core';
import { useBreadcrumbsClasses } from './styles';
import SText from '../sui/stext';

export interface SBreadcrumbProps<T> extends BreadcrumbsProps {
  items: Array<T>;
  parseBreadcrumb: (breadcrumb: T) => string;
  onBreadcrumbClick: (breadcrumb: T) => void;
}

const SBreadcrumbs = <T,>({
  items,
  parseBreadcrumb,
  onBreadcrumbClick,
  ...rest
}: SBreadcrumbProps<T>) => {
  const classes = useBreadcrumbsClasses();

  return (
    <MuiBreadcrumbs classes={classes} aria-label="breadcrumb" {...rest}>
      {items?.map((item, idx) => (
        <SText
          color={idx + 1 === items.length ? 'primary' : 'inherit'}
          onClick={(_event: React.MouseEvent<HTMLAnchorElement>) =>
            onBreadcrumbClick(item)
          }>
          {parseBreadcrumb(item)}
        </SText>
      ))}
    </MuiBreadcrumbs>
  );
};

export default SBreadcrumbs;
