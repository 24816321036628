import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

export const DRAWER_WIDTH = 320;

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'none',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: DRAWER_WIDTH,
    backgroundColor: theme.palette.common.white
  }
}));

interface BaseProps {
  children: JSX.Element;
}

export const Base = ({ children }: BaseProps) => {
  const classes = useStyles();

  return <Box className={classes.container}>{children}</Box>;
};
