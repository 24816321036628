import { makeStyles } from '@material-ui/core';
import { SText } from '@setvi/shared/components';
import { truncateTextToLimit } from 'Utils/textTransformation';
import { Separator } from './index';

const useStyles = makeStyles({
  breadcrumb: {
    cursor: 'pointer'
  }
});

interface BreadcrumbProps {
  name: string;
  textLimit: number | null;
  separator: string;
  haveSeparator: boolean;
  onClick: (_id: any) => void;
}

const BreadcrumbItem = ({
  name,
  textLimit,
  separator,
  haveSeparator,
  onClick
}: BreadcrumbProps) => {
  const classes = useStyles();
  return (
    <>
      <SText
        style={{ minWidth: 15 }}
        color="inherit"
        className={classes.breadcrumb}
        onClick={onClick}
        showTooltip
        placement="top"
        size="xs"
        arrow
        title={name}>
        {truncateTextToLimit(name, textLimit, true)}
      </SText>
      {haveSeparator && <Separator>{separator}</Separator>}
    </>
  );
};

export default BreadcrumbItem;
