import {
  useMutation,
  useQueryClient,
  UseMutationResult
} from '@tanstack/react-query';

export function useSubscribedMutation(
  props: any,
  queries?: string[]
): UseMutationResult<any> {
  const queryClient = useQueryClient();

  return useMutation({
    ...props,
    onSuccess: () => {
      queries?.forEach((query: string) => {
        queryClient.invalidateQueries({
          queryKey: [query]
        });
      });
    }
  });
}
