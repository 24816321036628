import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from 'enumsV2';
import { useAppContext } from 'Providers/AppProvider/AppContext';

export const NonAuthorizedRoutes = () => {
  const { token } = useAppContext();

  if (token) return <Navigate to={ROUTES.HOME} replace />;

  return <Outlet />;
};
