import { memo } from 'react';
import { Box } from '@material-ui/core';
import SHighlightText from '../../shighlight-text';

interface STreeNodeLabelProps<T> {
  item: T;
  title: string;
  search?: string;
  active?: boolean;
  actions?: (item: T) => JSX.Element;
}

const STreeNodeLabel = <T,>({
  item,
  title,
  search,
  active,
  actions
}: STreeNodeLabelProps<T>) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mr={2}>
    <SHighlightText
      value={title}
      searchQuery={search}
      highlightWeight={700}
      highlightColor={active ? '#fff' : '#1E96FB'}
    />
    {actions?.(item)}
  </Box>
);

export default memo(STreeNodeLabel);
