import { CSSProperties } from 'react';

import { PaginatedContent } from './paginated';
import { ColumnsType } from '../../../../../types';

export interface TableContentBodyProps {
  data?: any;
  selected: any;
  loading: boolean;
  columns: ColumnsType;
  isSelectable: boolean;
  rowKeyGetter: (data: any) => string | number;
  onRowClick?: (item: any, index: number) => void;
  handleSelect: (isChecked: boolean, selectedArray: any[]) => void;
  searchQuery: string;
  selectDisabled?: (row: any) => boolean;
  selectDisabledText?: string;
  overLimit?: (row: any) => boolean;
  rowStyleGetter?: (data: any) => CSSProperties;
}
const TableBodyContent = (props: TableContentBodyProps) => (
  <PaginatedContent {...props} />
);

export default TableBodyContent;
