import { makeStyles } from '@material-ui/core';
import { distances } from '@setvi/shared/styles';

export const useStepStyles = makeStyles(theme => ({
  container: {
    marginTop: distances.xxxlarge
  },
  stepNumber: {
    backgroundColor: '#9757D7',
    display: 'inline-block',
    padding: `${distances.xxsmall}px ${distances.base}px`,
    borderRadius: 15,
    color: theme.palette.common.white,
    marginBottom: distances.large,
    fontSize: 14
  },
  title: {
    fontWeight: 700
  },
  description: {
    fontSize: 14,
    color: '#777E90',
    marginTop: distances.xsmall
  }
}));

export const usePageExplainerStyles = makeStyles(({ breakpoints }) => ({
  container: {
    padding: `${distances.xxlarge}px `,
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    width: '100%'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',

    [breakpoints.down(2000)]: {
      maxWidth: 700
    }
  },
  label: {
    fontWeight: 700,
    fontSize: 48
  },
  description: {
    color: '#777E90',
    margin: `${distances.base}px 0px ${distances.xlarge}px 0px`
  },
  imageContainer: {
    marginTop: 'auto'
  },
  image: {
    width: '80%',
    [breakpoints.down(2000)]: {
      width: '100%'
    }
  },
  listLabel: {
    fontWeight: 700,
    fontSize: 18
  }
}));
