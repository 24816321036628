import { Box, Paper, Popper, makeStyles } from '@material-ui/core';

import { Recipient } from '@setvi/shared/interfaces';

import { Contact as ContactInterface } from 'Services/Query/Contacts/Types';
import { EmailDimensions } from 'providersV2/compose-email';

import { Contact } from './contact';

interface SuggestedContactsProps {
  anchorEl: HTMLElement | null;
  selectedContacts: Recipient[];
  suggestedContacts: ContactInterface[];
  onContactClick: (contact: ContactInterface) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: EmailDimensions.MAX_WIDTH_EXPANDED - 50,
    zIndex: 9
  }
}));

export const SuggestedContacts = ({
  anchorEl,
  selectedContacts,
  suggestedContacts,
  onContactClick
}: SuggestedContactsProps) => {
  const classes = useStyles();

  return (
    <Popper
      disablePortal
      open={!!anchorEl}
      anchorEl={anchorEl}
      className={classes.root}
      placement="bottom-start">
      {suggestedContacts?.length > 0 && (
        <Paper elevation={5}>
          {suggestedContacts.map(contact => (
            <Box key={contact.Email} onClick={() => onContactClick(contact)}>
              <Contact
                firstName={contact.FirstName}
                lastName={contact.LastName}
                email={contact.Email}
                selected={
                  !!selectedContacts.find(
                    selectedContact => selectedContact.Email === contact.Email
                  )
                }
              />
            </Box>
          ))}
        </Paper>
      )}
    </Popper>
  );
};
