import {
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  UseQueryOptions
} from '@tanstack/react-query';
import {
  AdminResourceApi,
  AxiosMethods,
  RESOURCE_PAGINATION,
  ResourceApi,
  SortType
} from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';
import { Resource, Resources, UploadedResource } from '../../../../interfaces';

export enum ResourcesQueryKey {
  USER = 'user_resources',
  COMPANY = 'company_resources',
  FAVORITES = 'favorites_resources',
  RESOURCE_DETAILS = 'resource_details',
  ADMIN_RESOURCES_PROCESS_STATUS = 'ADMIN_RESOURCES_PROCESS_STATUS',
  ADMIN_RESOURCE = 'ADMIN_RESOURCE'
}

export enum RESOURCE_BOOKMARKS {
  GET_ALL = 'get_all',
  SAVE_BOOKMARKS = 'save_bookmarks'
}

const dynamicId = ':categoryId';

interface QueryVariables {
  pageNumber: number;
  pageSize: RESOURCE_PAGINATION;
  search?: string;
  sortBy?: string;
  orderBy: SortType;
}

interface GetResourcesQueryProps extends QueryVariables {
  categoryId?: string | number | undefined;
}

export const getCompanyResourcesQuery = (
  input?: GetResourcesQueryProps
): UseInfiniteQueryOptions<Resources> => ({
  queryKey: [ResourcesQueryKey.COMPANY, input],
  queryFn: (data: any) =>
    axiosHelper({
      endpoint: `${ResourceApi.GetCompanyResources.replace(
        dynamicId,
        input?.categoryId?.toString()
      )}`,
      method: AxiosMethods.GET,
      params: { ...input, pageNumber: data.pageParam || input.pageNumber }
    }),
  cacheTime: 0,
  getNextPageParam: (lastPage: Resources, allPages: Resources[]) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Result?.length,
      0
    );

    const currentPageNumber = total / input.pageSize;

    return lastPage?.Data?.Result?.length < input.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const getFavoritesResourcesQuery = (
  input: QueryVariables
): UseInfiniteQueryOptions<Resources> => ({
  queryKey: [ResourcesQueryKey.FAVORITES, input],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ResourceApi.GetFavorite,
      method: AxiosMethods.GET,
      params: { ...input, pageNumber: data.pageParam || input.pageNumber }
    }),
  cacheTime: 0,
  getNextPageParam: (lastPage: Resources, allPages: Resources[]) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Result?.length,
      0
    );

    const currentPageNumber = total / input.pageSize;

    return lastPage?.Data?.Result?.length < input.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const getUserResourcesQuery = (
  input?: GetResourcesQueryProps
): UseInfiniteQueryOptions<Resources> => ({
  queryKey: [ResourcesQueryKey.USER, input],
  queryFn: (data: any) =>
    axiosHelper({
      endpoint: ResourceApi.GetUserResources.replace(
        dynamicId,
        input?.categoryId?.toString()
      ),
      method: AxiosMethods.GET,
      params: { ...input, pageNumber: data.pageParam || input.pageNumber }
    }),
  cacheTime: 0,
  getNextPageParam: (lastPage: Resources, allPages: Resources[]) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Result?.length,
      0
    );

    const currentPageNumber = total / input.pageSize;

    return lastPage?.Data?.Result?.length < input.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const getResource = (
  Id: string | number
): UseQueryOptions<Resource> => ({
  queryKey: [ResourcesQueryKey.RESOURCE_DETAILS, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: ResourceApi.GetResource.replace(':resourceId', Id.toString()),
      method: AxiosMethods.GET
    })
});

export const getResourceBookmarksQuery = (input: { resourceID: string }) => ({
  queryKey: [RESOURCE_BOOKMARKS.GET_ALL, input],
  queryFn: (data: any) =>
    axiosHelper({
      endpoint: ResourceApi.GetResourceBookmarks,
      method: AxiosMethods.POST,
      params: data.queryKey[1]
    }),
  select: (res: any) => res?.Data
});

export interface AdminUploadedResourceResponse {
  Status: string;
  Data: UploadedResource[];
  Error: null;
}

export const getResourcesProcessStatusesQuery = (resourceId: number[]) => ({
  queryKey: [ResourcesQueryKey.ADMIN_RESOURCES_PROCESS_STATUS, resourceId],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminResourceApi.GetResourceProcessStatus,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: {
        resourceId
      }
    }),
  select: (res: AdminUploadedResourceResponse) => res?.Data,
  refetchOnWindowFocus: false
});

export const getAdminResourceQuery = (id: string) => ({
  queryKey: [ResourcesQueryKey.ADMIN_RESOURCE, id],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminResourceApi.GetResource.replace(':id', id),
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (res: any) => {
    const response = {
      ...res?.Data,
      CategoryBreadcrumb: res?.Data?.ResourceCategoryBreadcrumbs?.map(
        (breadcrumb: any) => breadcrumb?.ResourceCategoryName
      ).join(' / '),
      Categories:
        res?.Data?.ResourceCategoryMembership?.map((elem: any) => ({
          Id: elem?.ResourceCategoryID,
          Name: elem?.ResourceCategoryName
        })) || []
    };

    return response;
  },
  refetchOnWindowFocus: false,
  cacheTime: 0
});
