import SInfiniteScroll, {
  SInfiniteScrollProps
} from '@setvi/shared/components/sinfinite-scroll';
import { Table, makeStyles } from '@material-ui/core';
import { CSSProperties, memo } from 'react';
import clsx from 'clsx';
import TableHeader, { TableHeaderProps } from '../components/table/header';

const useStyles = makeStyles({
  table: {
    borderCollapse: 'collapse'
  },
  wrapper: {
    borderBottom: `1px solid #E6E7E9`
  }
});

interface SinfinteTableProps<T> {
  header: TableHeaderProps;
  infiniteScroll: SInfiniteScrollProps<T>;
  stickyHeader?: boolean;
  tableStyle?: CSSProperties;
}

export const InfiniteTable = <T,>({
  header,
  tableStyle,
  infiniteScroll,
  stickyHeader = true
}: SinfinteTableProps<T>) => {
  const classes = useStyles();
  return (
    <Table
      stickyHeader={stickyHeader}
      className={classes.table}
      style={tableStyle}>
      <TableHeader {...header} />
      <SInfiniteScroll
        containerComponent="tbody"
        wrapperComponent="tr"
        wrapperClass={clsx(classes.wrapper, infiniteScroll.wrapperClass)}
        {...infiniteScroll}
      />
    </Table>
  );
};

export const SInfiniteTable = memo(InfiniteTable) as typeof InfiniteTable;
