import { Box, withStyles } from '@material-ui/core';

export const HeaderContainer = withStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing(2)
  }
}))(Box);
