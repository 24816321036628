import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { getFavoritesResourcesQuery } from '../../../../services';
import { RESOURCE_PAGINATION } from '../../../../enums';
import { resourcesSortAndOrder } from '../company-resources/useCompanyResources';

export const useFavorites = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      getFavoritesResourcesQuery({
        pageNumber: 1,
        pageSize: RESOURCE_PAGINATION.LIMIT,
        search: searchTerm,
        ...resourcesSortAndOrder
      })
    );

  const fetchMoreFavorites = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    searchTerm,
    setSearchTerm,
    resources: data?.pages?.map(page => page?.Data?.Result).flat(1) || [],
    isLoading: isLoading || isFetching,
    fetchMoreFavorites
  };
};
