// ** MUI Imports
import { Theme } from '@material-ui/core';

const alert = ({ spacing, palette }: Theme) => ({
  MuiAlert: {
    root: {
      padding: spacing(2),
      borderRadius: 8,
      '& .MuiAlert-message': {
        display: 'flex',
        alignItems: 'center',
        padding: 0
      },
      '& .MuiAlert-icon': {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        marginRight: spacing(1),
        '& svg': {
          width: '1.2em',
          height: '1.2em'
        }
      },
      '&.MuiAlert-standardInfo': {
        border: `1px solid ${palette.info.main}`
      }
    }
  }
});

export default alert;
