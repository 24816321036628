import { makeStyles } from '@material-ui/core';

export const useSelectedResourcePreviewStyles = makeStyles(
  ({ spacing, palette }) => ({
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: spacing(2, 3),
      backgroundColor: '#FAFAFB'
    },
    primaryDivider: {
      backgroundColor: palette.primary.main
    },
    container: {
      position: 'relative',
      zIndex: 200
    },
    listContainer: {
      position: 'absolute',
      height: 300,
      backgroundColor: '#fff',
      width: '100%',
      top: '-300px',
      overflow: 'auto',
      padding: spacing(2)
    },
    backdrop: {
      position: 'absolute',
      height: '100vh',
      zIndex: 100
    },
    actionContainer: {
      backgroundColor: '#fff'
    }
  })
);
