import { Box, Button, ButtonProps, withStyles } from '@material-ui/core';
import { FC } from 'react';
import { CustomMenuVariant } from 'Layouts/Main/Sidebar/MenuItems';

const ExtendedButton = withStyles(({ palette }) => ({
  root: {
    color: palette.text.primary,
    padding: '10px 0px',
    width: '90%',
    fontSize: 14
  }
}))(Button);

const CollapsedButtonContainer = withStyles(() => ({
  root: {
    width: '90%',
    textAlign: 'center',
    lineHeight: '1.2',
    fontSize: 12
  }
}))(Box);

const CollapsedButton = withStyles(({ spacing }) => ({
  root: {
    padding: '10px 0px',
    borderRadius: '50%',
    minWidth: 45,
    width: 45,
    height: 45,
    minHeight: 45,
    marginBottom: spacing(1)
  }
}))(Button);

interface MenuButtonProps extends ButtonProps {
  customVariant: CustomMenuVariant;
  icon: JSX.Element;
  label: string;
}

export const MenuButton: FC<MenuButtonProps> = ({
  customVariant,
  icon,
  label,
  ...rest
}) => {
  const extendedButton = (
    <ExtendedButton
      startIcon={icon}
      variant="outlined"
      color="primary"
      {...rest}>
      {label}
    </ExtendedButton>
  );

  const collapsedButton = (
    <CollapsedButtonContainer>
      <CollapsedButton variant="outlined" color="primary" {...rest}>
        {icon}
      </CollapsedButton>

      <Box fontWeight="700" whiteSpace="initial">
        {label}
      </Box>
    </CollapsedButtonContainer>
  );

  return customVariant === CustomMenuVariant.Collapsed
    ? collapsedButton
    : extendedButton;
};
