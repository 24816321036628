import { useInfiniteQuery } from '@tanstack/react-query';
import { CrmTypes, getCrmDataQuery } from '@setvi/shared/services';
import {
  GetCrmDataQuery,
  Contact
} from '@setvi/shared/services/react-query/query/user/types';
import { useCallback, useMemo, useState } from 'react';

interface useCrmContactsProps {
  accountId: string;
}

const CONTACTS_LIMIT = 20;
export const useCrmContacts = ({ accountId }: useCrmContactsProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const queryVariables = useMemo(
    () => ({
      offset: 0,
      pageSize: CONTACTS_LIMIT,
      accountId,
      type: CrmTypes.Contact,
      searchTerm,
      state: [],
      city: []
    }),
    [searchTerm, accountId]
  );

  const {
    data: contacts,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(getCrmDataQuery(queryVariables));

  const fetchMoreContacts = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    contacts:
      contacts?.pages
        ?.map((page: GetCrmDataQuery<Contact>) => page?.Data?.records)
        .flat(1) || [],
    isLoading: isLoading || isFetching,
    setSearchTerm,
    fetchMoreContacts
  };
};
