import { useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getGroupsContactsQuery } from '@setvi/shared/services';
import { Group } from '@setvi/shared/services/react-query/query/contacts/types';
import { SortableContactProperties } from '../../../components/contact/contact-filter';

interface useGroupsContactsProps {
  selectedGroup: Group;
}
const CONTACTS_LIMIT = 20;
export const useGroupsContacts = ({
  selectedGroup
}: useGroupsContactsProps) => {
  const [sortBy, setSortBy] = useState<SortableContactProperties>('FirstName');
  const [searchTerm, setSearchTerm] = useState<string>('');

  const queryVariables = useMemo(
    () => ({
      groupId: selectedGroup.Id,
      pageNumber: 1,
      pageSize: CONTACTS_LIMIT,
      orderBy: 'ASC',
      sortBy,
      search: searchTerm
    }),
    [selectedGroup, searchTerm, sortBy]
  );

  const {
    data: contacts,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(getGroupsContactsQuery(queryVariables));

  const fetchMoreContacts = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    contacts: contacts?.pages?.map(page => page.Result).flat(1) || [],
    isLoading: isLoading || isFetching,
    setSearchTerm,
    fetchMoreContacts,
    sortBy,
    setSortBy
  };
};
