import { Box, TextField, Typography, InputAdornment } from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Header } from '@setvi/shared/components/sdrawer-content/components/header';
import { linkAddress } from '@setvi/shared/utils/regex';
import { Link } from '@setvi/shared/interfaces';
import { LinkTextIcon, LinkUrlIcon } from '@setvi/shared/components/sicons';
import { Subheader } from '../../components/subheader';
import SLabel from '../../../sui/slabel';
import { useWebLink } from './useWebLink';
import SButton from '../../../sbutton';
import { LINK_VALIDATION } from '../../../../enums';

interface WebLinkProps {
  handleInsert?: (link: Link) => void;
  handleBack(): void;
  handleClose(): void;
  hideBackButton?: boolean;
}

export const WebLink = ({
  handleInsert,
  handleBack,
  handleClose,
  hideBackButton
}: WebLinkProps) => {
  const { onInsertWebLink, isLoading } = useWebLink({ handleInsert });

  const linkNameForm = useFormik({
    initialValues: {
      webText: '',
      url: ''
    },
    validationSchema: Yup.object({
      webText: Yup.string()
        .max(
          LINK_VALIDATION.MAX_LINK_NAME,
          `Limit is ${LINK_VALIDATION.MAX_LINK_NAME} characters`
        )
        .required('Display text is required'),
      url: Yup.string()
        .max(
          LINK_VALIDATION.MAX_LINK_ADDRESS,
          `Limit is ${LINK_VALIDATION.MAX_LINK_ADDRESS} characters`
        )
        .matches(linkAddress, 'Enter correct link address!')
        .required('Link address is required')
    }),
    onSubmit: ({ webText, url }) => onInsertWebLink(webText, url)
  });

  return (
    <Box>
      <Header title="Add from SETVI" handleClose={handleClose} />
      <Subheader
        handleBack={!hideBackButton && handleBack}
        text="Add web URL"
      />
      <Box m={3}>
        <form autoComplete="off" onSubmit={linkNameForm.handleSubmit}>
          <Typography variant="body2" color="textSecondary">
            Enter a web address and display text to adjust how the link is going
            to be displayed in the email
          </Typography>

          <Box mt={3}>
            <SLabel title="Link Address" required />
            <TextField
              autoComplete="off"
              placeholder="Link Address"
              variant="outlined"
              fullWidth
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkUrlIcon />
                  </InputAdornment>
                )
              }}
              name="url"
              value={linkNameForm.values.url}
              onChange={linkNameForm.handleChange}
              error={
                linkNameForm.touched.url && Boolean(linkNameForm.errors.url)
              }
              helperText={linkNameForm.touched.url && linkNameForm.errors.url}
            />
          </Box>

          <Box mt={3}>
            <SLabel title="Display Text" required />
            <TextField
              placeholder="Display Text"
              fullWidth
              type="text"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkTextIcon />
                  </InputAdornment>
                )
              }}
              name="webText"
              value={linkNameForm.values.webText}
              onChange={linkNameForm.handleChange}
              error={
                linkNameForm.touched.webText &&
                Boolean(linkNameForm.errors.webText)
              }
              helperText={
                linkNameForm.touched.webText && linkNameForm.errors.webText
              }
            />
          </Box>

          <Box mt={3}>
            <SButton
              disabled={
                linkNameForm.values.url.length === 0 ||
                linkNameForm.values.webText.length === 0
              }
              loading={isLoading}
              type="submit"
              fullWidth>
              Insert link
            </SButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
