import React from 'react';
import { Field, FieldProps } from 'formik';
import {
  Box,
  Typography,
  FormControlLabel,
  CheckboxProps
} from '@material-ui/core';

import { useCheckBoxStyle } from './style';
import SLabel from '../../../sui/slabel';
import { SCheckbox } from '../../../scheckbox';

interface SFormikCheckboxProps extends CheckboxProps {
  label?: string;
  subText?: string;
  required?: boolean;
  disabled?: boolean;
  labelClass?: string;
  inputClass?: string;
  labelIcon?: JSX.Element;
}

const SFormikCheckbox = ({
  name,
  label,
  subText,
  required = false,
  disabled = false,
  labelIcon,
  labelClass,
  inputClass,

  onChange,
  ...rest
}: SFormikCheckboxProps) => {
  const classes = useCheckBoxStyle();

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => (
        <Box>
          <FormControlLabel
            control={
              <SCheckbox
                name={field.name}
                disabled={disabled}
                checked={meta.value}
                className={inputClass}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => {
                  form.setFieldValue(name, checked);
                  onChange?.(event, checked);
                }}
                {...rest}
              />
            }
            label={
              <Box className={classes.labelContainer}>
                <SLabel
                  title={label}
                  className={labelClass}
                  variant="body2"
                  required={required}
                />
                {labelIcon}
              </Box>
            }
          />

          {!!subText && (
            <Typography variant="subtitle2" className={classes.subText}>
              {subText || ''}
            </Typography>
          )}
        </Box>
      )}
    </Field>
  );
};

export default SFormikCheckbox;
