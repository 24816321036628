import { Box, Typography, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SButton from '../../../sbutton';

export interface LinkFormProps {
  handleSubmit: (value: string) => void;
  handleClose: () => void;
  submitText?: string;
  initialValue: string;
  handleOnBlur?: (value: string) => void;
}

export const LinkForm = ({
  handleSubmit,
  handleClose,
  submitText = 'Add Link',
  initialValue,
  handleOnBlur
}: LinkFormProps) => {
  const linkNameForm = useFormik({
    initialValues: {
      linkName: initialValue
    },
    validationSchema: Yup.object({
      linkName: Yup.string()
        .trim()
        .max(100, 'Limit is 100 characters')
        .required('Link name is required')
    }),
    onSubmit: values => {
      handleSubmit(values.linkName.trim());
      handleClose?.();
    },
    enableReinitialize: true
  });

  return (
    <Box p={2}>
      <form onSubmit={linkNameForm.handleSubmit}>
        <Box width="100%" my={2}>
          <Box>
            <Typography variant="body2">Link Name</Typography>
          </Box>
          <Box pt={1}>
            <TextField
              name="linkName"
              autoComplete="off"
              onChange={linkNameForm.handleChange}
              value={linkNameForm.values.linkName}
              fullWidth
              error={
                linkNameForm.touched.linkName &&
                Boolean(linkNameForm.errors.linkName)
              }
              helperText={
                linkNameForm.touched.linkName && linkNameForm.errors.linkName
              }
              onBlur={e => handleOnBlur?.(e.target.value)}
            />
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <SButton color="primary" type="submit" fullWidth>
              {submitText}
            </SButton>
          </Box>
          <SButton color="default" onClick={handleClose} fullWidth>
            Cancel
          </SButton>
        </Box>
      </form>
    </Box>
  );
};
