import { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  SButton,
  SEmptyState,
  SHeader,
  SHighlightText,
  SInfiniteScroll,
  SSearchInput,
  SSelectDropdown
} from '@setvi/shared/components';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { usePresentations } from 'Components/Workspaces/MyPresentations/Presentations/usePresentations';
import { usePresentation } from 'Hooks/usePresentation';
import { ROUTES } from 'enumsV2';
import { useAddToPresentationStyles } from './style';

interface AddToPresentationProps {
  IDs: string[];
  onClose: () => void;
}

export const AddToPresentation = ({ IDs, onClose }: AddToPresentationProps) => {
  const [search, setSearch] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [selectedPresentation, setSelectedPresentation] = useState(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { presentations, isLoading, isFetching, fetchMore } = usePresentations({
    search
  });
  const { modify, loading: modifyLoading } = usePresentation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useAddToPresentationStyles();

  const loading = isLoading || isFetching;

  const handleSubmit = async () => {
    if (!selectedPresentation) return setShowErrorMessage(true);

    const selectedPresentationItems =
      selectedPresentation.PresentationItems.map((i: any) => i.ItemID);

    const response = await modify({
      body: {
        id: selectedPresentation.ID,
        name: selectedPresentation.Name,
        presentationItems: [...selectedPresentationItems, ...IDs]
      }
    });

    if (!response.Error) {
      enqueueSnackbar('Changes have been updated successfully', {
        variant: 'success'
      });
      navigate(`${ROUTES.WORKSPACES_PRESENTATIONS}/${selectedPresentation.ID}`);
      onClose();
    }
  };

  return (
    <Box overflow="visible">
      <SSelectDropdown
        anchorElement={anchorEl}
        onClose={() => setAnchorEl(null)}
        placement="bottom-start"
        anchor={
          <>
            <SHeader title="Add to Presentation" onClose={() => onClose()} />
            <Box p={3}>
              <Typography className={classes.description}>
                Select a Presentation from the list below
              </Typography>
              <Box onClick={e => setAnchorEl(e.currentTarget)}>
                <SSearchInput
                  placeholder="Select a Presentation"
                  searchedValue={search}
                  loading={isLoading}
                  error={showErrorMessage}
                  width={450}
                  onChange={val => {
                    setShowErrorMessage(false);
                    setSelectedPresentation(null);
                    setSearch(val);
                  }}
                />
                {showErrorMessage && (
                  <Typography className={classes.errorMessage}>
                    Presentation is required
                  </Typography>
                )}
              </Box>

              <Box display="flex" mt={4} gridGap={10}>
                <SButton
                  onClick={() => onClose()}
                  variant="outlined"
                  color="inherit"
                  fullWidth>
                  Close
                </SButton>
                <SButton
                  disabled={loading || modifyLoading}
                  loading={modifyLoading}
                  fullWidth
                  onClick={handleSubmit}>
                  Submit
                </SButton>
              </Box>
            </Box>
          </>
        }
        className={classes.container}
        content={
          <Box width={449} maxHeight={300} overflow="auto">
            <SInfiniteScroll
              list={presentations}
              keyGetter={presentation => presentation.ID}
              fetchMore={fetchMore}
              render={presentation => (
                <Box
                  onClick={() => {
                    setSelectedPresentation(presentation);
                    setAnchorEl(null);
                    setShowErrorMessage(false);
                    setSearch(presentation.Name);
                  }}
                  className={classes.presentation}>
                  <SHighlightText
                    value={presentation.Name}
                    searchQuery={search}
                  />
                </Box>
              )}>
              <Box p={2}>
                {loading && (
                  <Typography className={classes.loading}>
                    Loading...
                  </Typography>
                )}
                {!loading &&
                  presentations?.length < 1 &&
                  (search.length < 1 ? (
                    <SEmptyState
                      imageSrc="/images/no-presentations.png"
                      subTitle="There are no presentations."
                    />
                  ) : (
                    <SEmptyState
                      imageSrc="/images/no-resources.svg"
                      title="No Match found."
                      subTitle="Please try again with a different search term"
                    />
                  ))}
              </Box>
            </SInfiniteScroll>
          </Box>
        }
      />
    </Box>
  );
};
