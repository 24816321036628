import { SvgIcon, SvgIconProps } from '@material-ui/core';

import { TypeColor } from '../helper';

interface DialogIconProps extends SvgIconProps {
  stroke?: string;
}

export const CustomDialogIcon = ({
  stroke = TypeColor.infoBlue,
  ...props
}: DialogIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z"
      strokeWidth="3.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={stroke}
    />
    <path
      d="M24 32V24"
      strokeWidth="3.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={stroke}
    />
    <path
      d="M24 16H24.0208"
      strokeWidth="3.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={stroke}
    />
  </SvgIcon>
);
