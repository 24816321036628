import { AxiosMethods, AdminCategoryApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

import {
  CreateAdminCategoryInput,
  PublicAdminCategoryInput,
  UpdateAdminCategoryDetailsInput,
  UpdateAdminCategoryInput,
  UpdateAdminCategoryParentInput,
  UpdateAdminCategoryPermissionsInput,
  UpdateAdminResroucesCategoryStatusInput
} from './Types';

export enum AdminCategoryMutationsKey {
  CREATE_ADMIN_CATEGORY = 'CREATE_ADMIN_CATEGORY',
  UPDATE_USER_CATEGORY = 'UPDATE_USER_CATEGORY',
  DUPLICATE_CATEGORY = 'DUPLICATE_CATEGORY',
  DELETE_ADMIN_CATEGORY = 'DELETE_ADMIN_CATEGORY'
}

export const createAdminCategoryMutation = () => ({
  mutationFn: (body: CreateAdminCategoryInput) =>
    axiosHelper({
      endpoint: AdminCategoryApi.CreateCategory,
      method: AxiosMethods.POST,
      body,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const updateAdminCategoryMutation = () => ({
  mutationFn: (body: UpdateAdminCategoryInput) =>
    axiosHelper({
      endpoint: AdminCategoryApi.UpdateCategory,
      method: AxiosMethods.PUT,
      body,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const updateAdminCategoryPermissionsMutation = () => ({
  mutationFn: (body: UpdateAdminCategoryPermissionsInput) =>
    axiosHelper({
      endpoint: AdminCategoryApi.UpdateCategoryPermissions,
      method: AxiosMethods.PUT,
      body,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const updateAdminCategoryDetailsMutation = () => ({
  mutationFn: (body: UpdateAdminCategoryDetailsInput) =>
    axiosHelper({
      endpoint: AdminCategoryApi.UpdateCategoryDetails,
      method: AxiosMethods.PUT,
      body,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const updateAdminCategoryParentMutation = () => ({
  mutationFn: (body: UpdateAdminCategoryParentInput[]) =>
    axiosHelper({
      endpoint: AdminCategoryApi.UpdateCategoryParent,
      method: AxiosMethods.PATCH,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    })
});

export const removeAdminCategoryThumbnailMutation = () => ({
  mutationFn: (id: number) =>
    axiosHelper({
      endpoint: AdminCategoryApi.RemoveThumbnailResourceCategory.replace(
        ':id',
        id.toString()
      ),
      method: AxiosMethods.PATCH,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const duplicateAdminCategoryMutation = () => ({
  mutationFn: (id: number | string) =>
    axiosHelper({
      endpoint: AdminCategoryApi.DuplicateCategory.replace(
        ':id',
        id.toString()
      ),
      method: AxiosMethods.POST,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const publicAdminCategoryMutation = () => ({
  mutationFn: (body: PublicAdminCategoryInput) =>
    axiosHelper({
      endpoint: AdminCategoryApi.PublicCategory,
      method: AxiosMethods.PUT,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    })
});

// This deletes only the category and not the resources
export const deleteAdminCategoryMutation = () => ({
  mutationFn: (id: number | string) =>
    axiosHelper({
      endpoint: AdminCategoryApi.DeleteCategory.replace(':id', id?.toString()),
      method: AxiosMethods.DELETE,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

// This deletes the category and all the resources in it and all subcategories
export const deleteAdminResourceCategoryMutation = () => ({
  mutationFn: (id: number | string) =>
    axiosHelper({
      endpoint: AdminCategoryApi.DeleteResourceCategory.replace(
        ':id',
        id.toString()
      ),
      method: AxiosMethods.DELETE,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

// This deletes the category and all the resources in it and all subcategories
export const updateAdminResourceCategoryMutation = () => ({
  mutationFn: ({ id, statusId }: UpdateAdminResroucesCategoryStatusInput) =>
    axiosHelper({
      endpoint: AdminCategoryApi.UpdateResourceCategoryStatus.replace(
        ':id',
        id.toString()
      ).replace(':statusId', statusId?.toString()),
      method: AxiosMethods.PUT,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});
