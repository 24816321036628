import { axiosHelper } from '@setvi/shared/services';
import { BaseQueryResponse } from '@setvi/shared/interfaces';
import { AdminUsersApi, AxiosMethods } from '@setvi/shared/enums';

import { AdminUsersQueryResponse, AdminUsersQueryVariables } from './Types';

export enum AdminUsersQueryKey {
  ADMIN_USERS = 'ADMIN_USERS'
}

export const getAdminUsersQuery = (params: AdminUsersQueryVariables) => ({
  queryKey: [AdminUsersQueryKey.ADMIN_USERS, params],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminUsersApi.GetUsers,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params
    }),
  select: (data: BaseQueryResponse<AdminUsersQueryResponse>) => data?.Data,
  refetchOnWindowFocus: false
});
