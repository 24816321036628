import { useCallback, useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { PresentationType, SortType } from '@setvi/shared/enums';
import { getPresentationsQuery } from 'Services';

const LIMIT = 20;

interface PresentationsProps {
  type?: PresentationType;
  search?: string;
}

export const usePresentations = (
  { type, search }: PresentationsProps = {
    type: PresentationType.PRESENTATION,
    search: ''
  }
) => {
  const queryVariables = useMemo(
    () => ({
      pageNumber: 1,
      statuses: type || PresentationType.PRESENTATION,
      pageSize: LIMIT,
      sortBy: 'DateAdded',
      orderBy: SortType.DESC,
      searchTerm: search
    }),
    [search, type]
  );

  const {
    data: presentations,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(getPresentationsQuery(queryVariables));

  const fetchMore = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    presentations: (presentations?.pages?.map(page => page?.Result).flat(1) ||
      []) as any[],
    isLoading,
    isFetching,
    fetchMore
  };
};
