import { Paper, ListItem, withStyles, TextField } from '@material-ui/core';

export const StyledMenu = withStyles(({ palette }) => ({
  root: {
    borderRadius: 8,
    width: '100%',
    maxHeight: 200,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    border: `1px solid ${palette.primary.main}`,
    zIndex: 9,
    position: 'absolute',
    top: '100%',
    boxSizing: 'border-box',
    marginTop: 6,
    overflow: 'hidden',

    '& ul': {
      height: '100%',
      width: '100%',
      paddingTop: 0,
      paddingBottom: 0
    }
  }
}))(Paper);

export const StyledItem = withStyles(({ spacing }) => ({
  root: {
    padding: spacing(2, 3)
  }
}))(ListItem);

export const StyledTextField = withStyles(() => ({
  root: {
    '& input': {
      fontWeight: 700,

      '&::placeholder': {
        fontWeight: 400
      }
    }
  }
}))(TextField);
