import { Box, withStyles } from '@material-ui/core';
import { SButton, SETVIImage, SText } from '@setvi/shared/components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'enumsV2';

interface ErrorPageProps {
  src: string;
  title: string;
  description: string;
}

const Container = withStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 30,
    '& button': {
      width: 175,
      height: 50
    }
  }
})(Box);

const TextWrapper = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10
  }
})(Box);

export const ErrorPage = ({ src, title, description }: ErrorPageProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      <SETVIImage src={src} alt="Error" />
      <TextWrapper>
        <SText title={title} weight="bold" size="3xl" />
        <SText title={description} fontColor="#777E90" />
      </TextWrapper>
      <SButton
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(ROUTES.HOME)}>
        Go to homepage
      </SButton>
    </Container>
  );
};
