import { Box } from '@material-ui/core';
import { SText, SButton } from '@setvi/shared/components';
import { UploadFileIcon } from '@setvi/shared/components/sicons';
import { DropSection } from './style';

interface DropAreaProps<T> {
  rootProps: T;
  inputProps: T;
  isDragActive: boolean;
  isUploading: boolean;
}

export const DropArea = <T,>({
  rootProps,
  inputProps,
  isDragActive,
  isUploading
}: DropAreaProps<T>) => (
  <Box height="100%" display="flex" flexDirection="column" p={3}>
    <DropSection {...rootProps}>
      <input {...inputProps} />
      <Box display="flex" flexDirection="column" alignItems="center">
        <UploadFileIcon color="primary" fontSize="large" />
        {isDragActive ? (
          <SText weight="bold">Drop files here...</SText>
        ) : (
          <>
            <SText weight="bold">Drag & Drop</SText>
            <Box mt={2}>
              <SButton disabled={isUploading}>Upload File</SButton>
            </Box>
          </>
        )}
      </Box>
    </DropSection>
  </Box>
);
