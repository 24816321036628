import { makeStyles } from '@material-ui/core';

export const useButtonStyles = makeStyles(() => ({
  button: {
    flex: 1,
    border: '1px solid #E2E2EA',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'space-between',
    height: 48
  },
  buttonActive: {
    border: '1px solid #1E96FC'
  },
  titleHighlght: {
    color: '#1E96FC',
    fontWeight: 500
  }
}));
