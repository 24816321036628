import { useCallback } from 'react';
import { Box, Divider } from '@material-ui/core';
import { SConfirmDialog } from '@setvi/shared/components';
import { useDialog } from '@setvi/shared/providers';
import { SSearchInput as SearchInput } from '@setvi/shared/components/sforms/ssearch';
import { Link, LinkItemItems } from '@setvi/shared/interfaces';
import { Header } from '@setvi/shared/components/sdrawer-content/components/header';
import { ReviewItem } from './item';
import { LinkForm } from '../../../components/link-form';
import { EditListSubheader } from './EditListSubheader';
import { useEdit } from './useEdit';
import SemptyState from '../../../../sempty-state';

interface EditLinksProps {
  handleBack: () => void;
  handleInsert(link: Link, currentLink?: Link): void;
  handleRemoveLink(link?: Link): void;
  handleOpen?: () => void;
  handleClose: () => void;
  link: Link;
}

export const EditLinks = ({
  handleBack,
  handleInsert,
  handleRemoveLink,
  handleOpen,
  handleClose,
  link
}: EditLinksProps) => {
  const { handleSubmit, handleRemoveItem, setSearchTerm, searchTerm, items } =
    useEdit({
      handleBack,
      handleInsert,
      handleRemoveLink,
      link
    });

  const { openDialog, closeDialog } = useDialog();

  const onDelete = useCallback(
    (linkItem: LinkItemItems) => {
      openDialog(
        <SConfirmDialog
          type="delete"
          title="Delete resource"
          description="Are you sure you want to delete this resource from the resource link?"
          additionalMessage="The action CANNOT be reverted back!!!"
          onClose={() => closeDialog('delete-resource-link')}
          onConfirm={() => {
            handleRemoveItem(linkItem);
            closeDialog('delete-resource-link');
            if (items.length > 0) {
              handleOpen?.();
              if (items.length === 1) {
                handleClose();
              }
            }
          }}
          confirmText="Delete"
          closeText="Keep it"
        />,
        {
          id: 'delete-resource-link'
        }
      );
    },
    [
      openDialog,
      closeDialog,
      handleRemoveItem,
      items.length,
      handleOpen,
      handleClose
    ]
  );

  return (
    <Box height="100%" display="flex" flexDirection="column" minHeight={0}>
      <Header title="Edit & Rename Link(s)" handleClose={handleClose} />
      <EditListSubheader
        handleBack={handleBack}
        handleRemoveLink={handleRemoveLink}
        link={link}
      />
      <Box m={2}>
        <SearchInput onChange={setSearchTerm} />
      </Box>
      <Box flex={1} overflow="auto">
        {items?.length ? (
          items.map(resource => (
            <ReviewItem
              search={searchTerm}
              resource={resource}
              key={resource.ID}
              // show remove button only if the link is not a presentation. Note: presentation only have ParentID
              removeItem={!link.ParentID && onDelete}
            />
          ))
        ) : (
          <SemptyState
            imageSrc="/images/no-search-results.svg"
            title="No Files Found"
            subTitle="Try updating your search criteria"
          />
        )}
      </Box>
      <Box mt="auto">
        <Divider />
        <LinkForm
          handleSubmit={handleSubmit}
          handleClose={handleBack}
          initialValue={link?.Name || ''}
          submitText="Update Link"
        />
      </Box>
    </Box>
  );
};
