import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const TreeLoader = () => (
  <Box>
    <Skeleton height={30} />
    <Box ml={4}>
      <Skeleton height={30} />
      <Skeleton height={30} />
      <Skeleton height={30} />
    </Box>
    <Box ml={4}>
      <Skeleton height={30} />
      <Box ml={4}>
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
      </Box>
      <Skeleton height={30} />
      <Skeleton height={30} />
    </Box>
    <Skeleton height={30} />
    <Skeleton height={30} />
  </Box>
);

export default TreeLoader;
