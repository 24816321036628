import { AdminGroupsApi, AxiosMethods, GroupsApi } from '@setvi/shared/enums';
import { queryVariablesType } from '@setvi/shared/interfaces';
import { axiosHelper } from '@setvi/shared/services';
import {
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  UseQueryOptions
} from '@tanstack/react-query';
import {
  GetAdminGroupUsersQuery,
  GetAdminGroupsQuery,
  GetEmailGroups
} from './Types';

export enum GroupQueryKey {
  GROUPS = 'groups',
  ADMIN_GROUP = 'admin_group',
  ADMIN_GROUPS = 'admin_groups',
  ADMIN_GROUPS_USERS = 'admin_groups_users'
}

const groupId = ':groupId';

export const getGroupsQuery = (
  input?: queryVariablesType
): UseQueryOptions<GetEmailGroups> => ({
  queryKey: [GroupQueryKey.GROUPS, input],
  queryFn: ({ queryKey }: any) => {
    const { page, size, ...rest } = queryKey?.[1];
    const params = {
      pageNumber: page,
      pageSize: size,
      ...rest
    };

    return axiosHelper({
      endpoint: GroupsApi.Groups,
      method: AxiosMethods.GET,
      params
    });
  }
});

interface getAdminGroupsQueryParams {
  pageSize: number;
  pageNumber: number;
  searchString?: string;
  orderBy?: string;
  sortBy?: string;
}

export const getAdminGroupsQuery = (
  params: getAdminGroupsQueryParams
): UseInfiniteQueryOptions<GetAdminGroupsQuery> => ({
  queryKey: [GroupQueryKey.ADMIN_GROUPS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: `${AdminGroupsApi.Groups}`,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (
    lastPage: GetAdminGroupsQuery,
    allPages: GetAdminGroupsQuery[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Items?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.Data?.Items?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false,
  cacheTime: 0
});

export const getAdminGroupQuery = (Id: string) => ({
  queryKey: [GroupQueryKey.ADMIN_GROUP, Id],
  queryFn: (data: any) =>
    axiosHelper({
      endpoint: `${AdminGroupsApi.Group.replace(groupId, data.queryKey[1])}`,
      method: AxiosMethods.GET
    }),
  select: (res: any) => res?.Data
});

interface getAdminGroupUsersQueryParams {
  pageSize: number;
  pageNumber: number;
  searchString?: string;
  orderBy?: string;
  sortBy?: string;
}

export const getAdminGroupUsersQuery = (
  params: getAdminGroupUsersQueryParams,
  id: string
): UseInfiniteQueryOptions<GetAdminGroupUsersQuery> => ({
  queryKey: [GroupQueryKey.ADMIN_GROUPS_USERS, { params, id }],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: `${AdminGroupsApi.GroupUsers.replace(groupId, id)}`,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (
    lastPage: GetAdminGroupUsersQuery,
    allPages: GetAdminGroupUsersQuery[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Users?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.Data?.Users?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});
