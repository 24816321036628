import { v4 as uuidv4 } from 'uuid';
import parse from 'html-react-parser';
import { Element, DataNode } from 'domhandler/lib/node';

import {
  VARIABLE_TYPE,
  CampaignStatus,
  CampaignMenuItem,
  LinkObjectType
} from '../../enums';
import { Campaign, Link } from '../../interfaces';
import { VARIABLE_LIST } from '../variable-helper';

export const parseLinksFEtoBC = (links: Link[]) =>
  links.map(link => ({
    Placeholder: link.Placeholder,
    LinkName: link.Name,
    Objects: link.ParentID
      ? [
          {
            ObjectId: link.ParentID,
            ObjectType: LinkObjectType.Presentation
          }
        ]
      : link.Item.Items.map(item => ({
          ObjectId: item.ID,
          ObjectType: item.ObjectType
        }))
  }));

export const parseDocumentsFEtoBC = (documents: any) =>
  documents?.map((document: any) => ({
    DocumentName: document.DocumentName || '',
    Description: document?.Description || '',
    Objects: [
      {
        ObjectId: document.ID,
        ObjectType: document.ObjectType || LinkObjectType.Resources
      }
    ]
  }));

export const parseLinksBCtoFE = (links: any): Link[] =>
  links.map((link: any) => ({
    Name: link.Name,
    Placeholder: link.Placeholder || link.PlaceHolder,
    Type: link.ObjectType || link.Items?.[0]?.ObjectType,
    ParentID: link.ParentId,
    Item: {
      ID: link.ParentId,
      Name: link.ParentName,
      ThumbURL: link.ParentThumbUrlWithSas,
      Items: link.Items.map((item: any) => ({
        ID: item.ObjectId || item.ID,
        Name: item.Name,
        LastChangedDate: item.MaterialLastChangedDate,
        ObjectType: item.ObjectType,
        ResourceTypeID: item.ResourceTypeID,
        ThumbURL: item.ThumbURL,
        ObjectId: item.ObjectId,
        ResourceID: item.ResourceID,
        ItemID: item.ItemID
      }))
    }
  }));

export const parseDocumentsBCtoFE = (documents: any): any =>
  documents.map((doc: any) => ({
    Key: uuidv4(),
    Description: doc.Description || '',
    ID: doc?.Items?.[0]?.ObjectId || doc?.Id,
    DocumentName: doc?.Name || '',
    LastChangedDate: doc?.Items?.[0]?.MaterialLastChangedDate || '',
    Name: doc?.Items?.[0]?.Name || '',
    ResourceID: doc?.Items?.[0]?.ResourceID || '',
    ResourceTypeID: doc?.Items?.[0]?.ResourceTypeID || 1,
    ResourceTypeName: doc?.Items?.[0]?.ResourceTypeName || '',
    ThumbURL: doc?.Items?.[0]?.ThumbURL || '',
    ObjectType: doc?.Items?.[0]?.ObjectType || LinkObjectType.Resources
  }));

export const findMailVariablesDom = (value: string): VARIABLE_TYPE[] => {
  let foundVariables: VARIABLE_TYPE[] = [];

  parse(value, {
    replace: domNode => {
      const typedDomNode = domNode as Element;

      if (typedDomNode.attribs?.class === 'mce-mergetag') {
        const variableName = (typedDomNode.children[0] as DataNode).data;
        const foundVariable = VARIABLE_LIST.filter(
          v => v === variableName?.replace(/\u200B/g, '').trim()
        );
        if (
          foundVariable.length &&
          foundVariables.indexOf(foundVariable[0]) === -1
        ) {
          foundVariables = [...foundVariables, foundVariable[0]];
        }
      }
    }
  });

  return foundVariables;
};

export const checkIfKeyIsVariable = (key: string, variables: VARIABLE_TYPE[]) =>
  variables
    .map(v =>
      v
        .toLowerCase()
        .replace(/[^a-zA-Z ]/g, '')
        .replace(/\s/g, '')
    )
    .includes(key.toLowerCase());

export const validateFields = (recipient: any, variables: VARIABLE_TYPE[]) =>
  Object.keys(recipient)?.every(key =>
    // If key is variable check it is not empty
    checkIfKeyIsVariable(key, variables)
      ? (recipient as any)[key]?.length
      : true
  );

export const missingVariablesCheck = (
  contacts: any[],
  variables: VARIABLE_TYPE[]
) => !contacts?.every(recipient => validateFields(recipient, variables));

export const getCampaignMenuItems = (campaign: Campaign) => {
  if (campaign?.Status === CampaignStatus.Draft) {
    return [CampaignMenuItem.Delete, CampaignMenuItem.MoreInfo];
  }

  return [
    campaign?.Status === CampaignStatus.Archived
      ? CampaignMenuItem.Unarchive
      : CampaignMenuItem.Archive,
    CampaignMenuItem.EmailStatistics,
    CampaignMenuItem.StopEmailQueue,
    CampaignMenuItem.DisableLinks,
    CampaignMenuItem.MoreInfo
  ];
};
