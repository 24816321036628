import React from 'react';
import { Box, Divider } from '@material-ui/core';
import SETVIImage from '../../../../setvi-image';
import SText from '../../../../sui/stext';
import { ClipIcon } from '../../../../sicons';
import { useTemplateCardStyles } from './style';
import { Template } from '../../../../../services/react-query/query/templates/types';

interface TemplateCardProps {
  template: Template;
  action?: React.ReactNode;
}

export const TemplateCard = ({ template, action }: TemplateCardProps) => {
  const classes = useTemplateCardStyles();

  return (
    <>
      <Box className={classes.container}>
        <SETVIImage
          className={classes.image}
          src={template.Items[0]?.ThumbURLWithSas}
          alt={template.Name}
          loading="lazy"
        />
        <Box flex={1} ml={2}>
          <SText
            title={template.Name}
            weight="semibold"
            maxLines={2}
            size="sm"
          />
          <Box mt={1} display="flex" alignItems="center">
            <ClipIcon fontSize="small" />
            {template.Items.length || 0}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          {action}
        </Box>
      </Box>
      <Divider />
    </>
  );
};
