import { Button, ButtonGroup } from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

interface SingleSelectListProps<T> {
  list: T[];
  keyGetter: (item: T) => string | number;
  displayValueGetter: (item: T) => string | number | JSX.Element;
  isSelected?: (item: T) => boolean;
  isDisabled?: (item: T) => boolean;
  onSelect?: (item: T) => void;
}

const SingleSelectList = <T,>({
  list,
  keyGetter,
  displayValueGetter,
  isSelected,
  isDisabled,
  onSelect
}: SingleSelectListProps<T>) => (
  <ButtonGroup orientation="vertical" color="primary" fullWidth>
    {list?.map(option => (
      <Button
        style={{
          display: 'flex',
          justifyContent: 'start',
          color: isSelected?.(option) && '#2196F3'
        }}
        startIcon={
          <CheckRoundedIcon
            htmlColor={isSelected?.(option) ? '#2196F3' : 'transparent'}
          />
        }
        key={keyGetter(option)}
        variant="text"
        onClick={() => onSelect?.(option)}
        color="default"
        disabled={isDisabled?.(option) || false}>
        {displayValueGetter(option)}
      </Button>
    ))}
  </ButtonGroup>
);

export default SingleSelectList;
