import { Box } from '@material-ui/core';
import SInfiniteScroll from '../../../../sinfinite-scroll';
import { TemplateCard } from '../card';
import { TemplateAction } from '../action';
import { Template } from '../../../../../services/react-query/query/templates/types';

interface TemplateListProps {
  templates: Template[];
  selectedTemplates: Template[];
  onSelectTemplate: (isChecked: boolean, template: Template[]) => void;
  fetchMoreTemplates?: () => void;
}

export const TemplateList = ({
  templates,
  selectedTemplates,
  onSelectTemplate,
  fetchMoreTemplates
}: TemplateListProps) => (
  <Box m={2}>
    <SInfiniteScroll
      list={templates}
      keyGetter={template => template.ID}
      fetchMore={fetchMoreTemplates}
      render={template => (
        <TemplateCard
          template={template}
          action={
            <TemplateAction
              checked={
                selectedTemplates?.some(pres => pres?.ID === template?.ID) ||
                false
              }
              onSelectTemplate={onSelectTemplate}
              template={template}
            />
          }
        />
      )}
    />
  </Box>
);
