import { Box, withStyles } from '@material-ui/core';

export const DateWrapper = withStyles({
  root: {
    gridGap: 8,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',

    '& .rs-picker-toggle-value': {
      color: '#44444F !important'
    }
  }
})(Box);
