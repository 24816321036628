import { AxiosMethods, UsersApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

export enum UserQueryKey {
  SIGNATURE = 'user_signature'
}

export const userSignatureQuery = () => ({
  queryKey: [UserQueryKey.SIGNATURE],
  queryFn: () =>
    axiosHelper({
      endpoint: `${UsersApi.GetUserSignature}`,
      method: AxiosMethods.POST
    }),
  select: (res: any) => res?.Data[0]?.signatureHtmlLinks || '',
  cacheTime: 0,
  refetchOnWindowFocus: false
});
