import { memo, useState } from 'react';
import { Box, Grid, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { useSQuadrantStyles } from './style';
import { BarIcon } from '../sicons';
import SText from '../sui/stext';

interface TopContent {
  title?: string;
  content?: JSX.Element;
}

interface SQuadrantLayoutProps {
  defaultCollapsed?: boolean;
  showCollapseButton?: boolean;
  invertedRow?: boolean;
  topLeft?: TopContent;
  topRight?: TopContent;
  bottomLeft: JSX.Element;
  bottomRight: JSX.Element;
  header?: JSX.Element;
}

const SQuadrantLayout = ({
  defaultCollapsed = false,
  showCollapseButton = true,
  invertedRow = false,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  header
}: SQuadrantLayoutProps) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  const classes = useSQuadrantStyles();

  return (
    <Box className={classes.container}>
      {header}
      <Grid container className={classes.grid}>
        {!isCollapsed && (
          <Grid
            item
            xs={3}
            className={clsx({
              [classes.left]: !invertedRow,
              [classes.right]: invertedRow,
              [classes.order2]: invertedRow,
              [classes.gridItem]: true
            })}>
            {topLeft && (
              <Box className={classes.topConatiner}>
                {topLeft?.title && (
                  <SText weight="bold" title={topLeft.title} />
                )}
                {topLeft?.content || null}
              </Box>
            )}
            <Box className={classes.bottomContainer}>{bottomLeft}</Box>
          </Grid>
        )}
        <Grid item className={classes.gridItem} xs={isCollapsed ? 12 : 9}>
          {topRight && (
            <Box className={classes.topConatiner}>
              {showCollapseButton && (
                <IconButton
                  onClick={() => setIsCollapsed(collapsed => !collapsed)}>
                  <BarIcon />
                </IconButton>
              )}
              {topRight?.title && (
                <SText weight="bold" title={topRight.title} />
              )}
              {topRight?.content || null}
            </Box>
          )}
          <Box className={classes.bottomContainer}>{bottomRight}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(SQuadrantLayout);
