import { Box, makeStyles, Typography, withStyles } from '@material-ui/core';
import { distances } from '../../../../../../styles';
// @ts-ignore
import emptyState from './images/empty-state.png';

interface EmptyStateProps {
  search: boolean;
}

const useStyles = makeStyles(() => ({
  emptyWrapper: {
    flexGrow: 1,
    display: 'grid',
    placeItems: 'center'
  },
  emptyContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& img': {
      '@media (max-height: 780px)': {
        transform: 'scale(0.7)'
      }
    }
  }
}));

const Label = withStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    margin: `${distances.px.small} 0`,
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: '1.334'
  }
}))(Typography);

const Sublabel = withStyles({
  root: {
    color: '#b5b5be',
    fontSize: '14px',
    lineHeight: '18px'
  }
})(Typography);

export const EmptyState = ({ search }: EmptyStateProps) => {
  const classes = useStyles();
  const label = search ? 'No Match found' : 'No data available';
  const subLabel = search
    ? 'Please try again with a different search term'
    : 'No data found';

  return (
    <Box className={classes.emptyWrapper}>
      <Box className={classes.emptyContent}>
        <img src={emptyState} alt="Empty State" />
        <Label variant="h3">{label}</Label>
        <Sublabel variant="subtitle1">{subLabel}</Sublabel>
      </Box>
    </Box>
  );
};

export default EmptyState;
