import { useMemo } from 'react';
import { SButton } from '@setvi/shared/components';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { DrawerContent } from '@setvi/shared/enums';
import { AddResourceCompose } from 'Components/Shared/Icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Container } from './style';
import { Navigation, ComposeNavigationProps } from './navigation';

interface FooterProps {
  links?: boolean;
  loading: boolean;
  isMailSendingAllowed?: boolean;
  drawerType?: DrawerContent;
  handleDrawerOpen: (type: DrawerContent) => void;
}

const Footer = ({
  links,
  loading,
  isMailSendingAllowed,
  drawerType,
  handleDrawerOpen
}: FooterProps) => {
  const flags = useFlags();

  const navigationItems: ComposeNavigationProps[] = useMemo(
    () => [
      ...(links
        ? [
            {
              name: 'Edit Link(s)',
              icon: <BorderColorIcon htmlColor="#FF9F29" />,
              onClick: () => handleDrawerOpen(DrawerContent.reviewLinks),
              active: drawerType === DrawerContent.reviewLinks,
              isHighlighted: true
            }
          ]
        : []),
      {
        name: 'Resources & More',
        icon: <AddResourceCompose />,
        onClick: () => handleDrawerOpen(DrawerContent.resourcesNavigation),
        active:
          drawerType === DrawerContent.resourcesNavigation ||
          drawerType === DrawerContent.favorites ||
          drawerType === DrawerContent.resources ||
          drawerType === DrawerContent.myResources ||
          drawerType === DrawerContent.presentations ||
          drawerType === DrawerContent.templates ||
          drawerType === DrawerContent.uploadFile ||
          drawerType === DrawerContent.webLink ||
          drawerType === DrawerContent.snippet
      },
      ...(flags?.contactsGroups
        ? [
            {
              name: 'Contacts',
              icon: <PersonOutlineIcon color="primary" />,
              onClick: () => handleDrawerOpen(DrawerContent.contactsNavigation),
              active:
                drawerType === DrawerContent.contactsNavigation ||
                drawerType === DrawerContent.crmLeads ||
                drawerType === DrawerContent.crmAccounts ||
                drawerType === DrawerContent.groups ||
                drawerType === DrawerContent.contacts ||
                drawerType === DrawerContent.recentContacts
            }
          ]
        : [])
    ],
    [links, drawerType, flags?.contactsGroups, handleDrawerOpen]
  );

  return (
    <Container>
      <Navigation items={navigationItems} />
      <SButton
        color="primary"
        type="submit"
        loading={loading}
        disabled={isMailSendingAllowed}>
        Send Email
      </SButton>
    </Container>
  );
};

export default Footer;
