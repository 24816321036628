import { Box, makeStyles } from '@material-ui/core';
import { Header } from '../../../components/header';
import { Subheader } from '../../../components/subheader';
import SText from '../../../../sui/stext';
import { SPreviewEditor } from '../../../../seditor/components';
import SButton from '../../../../sbutton';

interface SnippetPreviewProps {
  title: string;
  message: string;
  handleInsert: () => void;
  handleBack: () => void;
  handleClose: () => void;
}

const useStyles = makeStyles({
  editor: {
    height: '100%'
  },
  button: {
    borderRadius: 50
  }
});

export const SnippetPreview = ({
  title,
  message,
  handleInsert,
  handleBack,
  handleClose
}: SnippetPreviewProps) => {
  const styles = useStyles();

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Add from SETVI" handleClose={handleClose} />
      <Subheader text="Snippet Details" handleBack={handleBack} />
      <Box m={2} display="flex" flexDirection="column" flex={1} minHeight={0}>
        <SText fontColor="#92929D" title="Subject" weight="bold" size="sm" />
        <Box mt={3}>
          <SText title={title} maxLines={2} weight="bold" size="xl" />
        </Box>
        <Box mt={3}>
          <SText fontColor="#92929D" title="Body" weight="bold" size="sm" />
        </Box>
        <Box mt={2} mb={2} flex={1} overflow="auto">
          <SPreviewEditor
            customClass={styles.editor}
            height="100%"
            content={message}
          />
        </Box>
        <Box>
          <SButton
            disabled={!message}
            onClick={handleInsert}
            className={styles.button}
            fullWidth>
            Use This
          </SButton>
        </Box>
      </Box>
    </Box>
  );
};
