export enum CompanyCampaignApi {
  AllAdminCapgins = '/api/v1.0/companycampaigns/admins',
  Campaigns = '/api/v1.0/companycampaigns',
  Status = '/api/v1.0/companycampaigns/status',
  StatusesAdmin = '/api/v1.0/companycampaigns/status/admins',
  AssignedUsers = '/api/v1.0/companycampaigns/users',
  Campaign = '/api/v1.0/companycampaigns/:campaignId',
  AssignedGroups = '/api/v1.0/companycampaigns/groups',
  UserCompanyCampaigns = '/api/v1.0/companycampaigns/usercampaigns',
  CampaignLinks = '/api/v1.0/companycampaigns/:campaignId/linksdata',
  CampaignDocuments = '/api/v1.0/companycampaigns/:campaignId/documentsdata',
  UserCompanyCampaign = '/api/v1.0/companycampaigns/usercampaigns/:campaignId',
  CampaignAnalytics = '/api/v1.0/companycampaigns/analytics/summary?campaignTemplateId=:campaignId'
}

export enum EmailCampaignsApi {
  Campaign = '/api/v1.0/campaigns/:campaignId',
  Campaigns = '/api/v1.0/campaigns',
  CreateCampaign = '/api/v1.0/campaigns',
  UpdateCampaign = '/api/v1.0/campaigns',
  CancelCampaign = '/api/v1.0/campaigns/:campaignId/cancelemails',
  CampaignStatuses = '/api/v1.0/campaigns/status',
  UpdateCampaignStatus = '/api/v1.0/campaigns/status',
  CampaignAnalytics = '/api/v1.0/campaigns/analytics/summary/:campaignId',
  CampaignLinkData = '/api/v1.0/campaigns/:campaignId/linksdata',
  CampaignFollowUp = '/api/v1.0/campaigns/followup',
  ResendMail = '/api/v1.0/emails/resend',
  CancelMail = '/api/v1.0/emails/cancel',
  RecipientInterested = '/api/v1.0/campaigns/interested',
  CampaignRecipients = '/api/v1.0/campaigns/:campaignId/recipients',
  CampaignDetailsRecipientViews = '/api/v1.0/campaigns/:campaignId/recipientviews/:recipientId',
  CampaignDetailsRecipientView = '/api/v1.0/campaigns/:campaignId/viewdetail/:recipientId',
  CampaignDetailsRecipientTimeline = '/api/v1.0/campaigns/:campaignId/timeline/:recipientId',
  CampaignDetailsRecipientReply = '/api/v1.0/campaigns/:campaignId/recipientrepliesdetail/:recipientId',
  CampaignDetailsRecipientMail = '/api/v1.0/campaigns/:campaignId/recipientemailsdetail/:recipientId',
  CampaignDetailsRecipientMails = '/api/v1.0/campaigns/:campaignId/recipientemails/:recipientId',
  CampaignDetailsRecipientDetails = '/api/v1.0/campaigns/:campaignId/recipientdetails/:recipientId',
  CampaignLinksDisable = '/api/v1.0/campaigns/:campaignId/links/disable',
  CampaignRecipientLinksDisable = '/api/v1.0/emails/links/disable',
  RetryFailedCampaignEmails = '/api/v1.0/campaigns/:campaignId/retryemails'
}
