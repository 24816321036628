import { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { Resource } from 'Interfaces';
import { useSubscribedMutation } from 'Hooks/React-Query';
import {
  addFavoriteResourceMutation,
  removeFavoriteResourceMutation,
  ResourcesQueryKey
} from 'Services';

export const useFavoritesActions = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [updatedResourceID, setUpdatedResourceID] = useState<string | null>(
    null
  );

  const {
    mutateAsync: addToFavoriteskMutateFunction,
    isLoading: addToFavoritesLoading
  } = useSubscribedMutation(addFavoriteResourceMutation(), [
    ResourcesQueryKey.FEATURED_RESOURCES,
    ResourcesQueryKey.RECENTLY_UPLOADED_RESOURCES,
    ResourcesQueryKey.FAVORITES_RESOURCES,
    ResourcesQueryKey.COMPANY_RESOURCES,
    ResourcesQueryKey.USER_RESOURCES
  ]);

  const {
    mutateAsync: removeFromFavoritesMutateFunction,
    isLoading: removeFromFavoritesLoading
  } = useSubscribedMutation(removeFavoriteResourceMutation(), [
    ResourcesQueryKey.FEATURED_RESOURCES,
    ResourcesQueryKey.RECENTLY_UPLOADED_RESOURCES,
    ResourcesQueryKey.FAVORITES_RESOURCES,
    ResourcesQueryKey.COMPANY_RESOURCES,
    ResourcesQueryKey.USER_RESOURCES
  ]);

  const removeFromFavorites = useCallback(
    (resourceId: Resource['ResourceID']) =>
      removeFromFavoritesMutateFunction({
        body: {
          itemid: resourceId
        }
      }).then(() => {
        setUpdatedResourceID(null);
        enqueueSnackbar('Resource has been removed from favorites.', {
          variant: 'success'
        });
      }),
    [removeFromFavoritesMutateFunction, enqueueSnackbar]
  );

  const addToFavorites = useCallback(
    (resourceId: Resource['ResourceID']) =>
      addToFavoriteskMutateFunction({
        body: {
          itemid: resourceId
        }
      }).then(() => {
        setUpdatedResourceID(null);
        enqueueSnackbar('Resource has been added to favorites.', {
          variant: 'success'
        });
      }),
    [addToFavoriteskMutateFunction, enqueueSnackbar]
  );

  const handleFavorites = useCallback(
    (resourceId: Resource['ResourceID'], isFavorite: boolean) => {
      setUpdatedResourceID(resourceId);
      isFavorite ? removeFromFavorites(resourceId) : addToFavorites(resourceId);
    },
    [addToFavorites, removeFromFavorites]
  );

  return {
    isLoading: addToFavoritesLoading || removeFromFavoritesLoading,
    updatedResourceID,

    handleFavorites
  };
};
