import { memo } from 'react';
import { Backdrop, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Gallery, GalleryProps } from './gallery';
import { useBackdropStyles } from './style';

interface SGalleryProps extends Omit<GalleryProps, 'isFullScreen'> {
  fullScreen?: boolean;
  opened?: boolean;
  onClose?: () => void;
}

const SGallery = ({ fullScreen, onClose, opened, ...rest }: SGalleryProps) => {
  const styles = useBackdropStyles();
  if (fullScreen)
    return (
      <Backdrop className={styles.backdrop} open={opened}>
        <Box className={styles.closeContainer}>
          <IconButton onClick={onClose}>
            <CloseIcon htmlColor="#fff" />
          </IconButton>
        </Box>
        <Box className={styles.galleryContainer}>
          <Gallery {...rest} isFullScreen />
        </Box>
      </Backdrop>
    );

  return <Gallery {...rest} />;
};

export default memo(SGallery);
