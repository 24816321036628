import { useEffect, useRef } from 'react';
import { Formik, FormikConfig, FormikProps, FormikValues } from 'formik';

export type ExtendedFormikProps<Values, ExtraProps> = FormikConfig<Values> &
  ExtraProps & { onChange?(context: FormikProps<Values>): void };
/**
 * Formik which has `onChange` prop.
 */
export const ExtendedFormik = <
  Values extends FormikValues = FormikValues,
  ExtraProps = Record<string, unknown>
>({
  onChange,
  children,
  render: renderProp,
  validateOnBlur = false,
  validateOnChange = false,
  ...props
}: ExtendedFormikProps<Values, ExtraProps>) => {
  const render = renderProp || children;
  return (
    <Formik
      {...props}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}>
      {renderProps => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const firstRender = useRef(true);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (!firstRender.current && onChange) {
            onChange(renderProps);
          }
          firstRender.current = false;
        }, [firstRender, renderProps, renderProps.values]);

        return typeof render === 'function' ? render(renderProps) : render;
      }}
    </Formik>
  );
};
