import { makeStyles, Theme } from '@material-ui/core';
import { distances } from '../../../../../../styles';

interface StyleProps {
  width?: number;
}

export const useStyles = makeStyles<Theme, StyleProps>({
  popper: {
    backgroundColor: '#fff',
    border: '1px solid #1E96FC',
    borderRadius: '4px',
    zIndex: 1500,
    width: ({ width }) => width - 5 || 'fit-content'
  },
  content: {
    padding: distances.px.small
  }
});
