import { Box, withStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useResourceChildrenStyles } from '../../styles';

const ItemWrapper = withStyles({
  root: {
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
})(Box);

const SkeletonItem = withStyles({
  root: {
    borderRadius: 1.3
  }
})(Skeleton);

export const Loader = () => {
  const array = Array(5).fill(null);
  const classes = useResourceChildrenStyles();
  return (
    <>
      {array.map((_, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <ItemWrapper key={index}>
          <Box className={classes.page}>
            <SkeletonItem variant="rect" className={classes.image} />
          </Box>
          <Skeleton variant="rect" width={20} className={classes.title} />
        </ItemWrapper>
      ))}
    </>
  );
};
