import { Box } from '@material-ui/core';
import { Header } from '../../components/header';
import { Subheader } from '../../components/subheader';
import { SSearchInput } from '../../../sforms';
import { usePresnetations } from './usePresentations';
import { Link } from '../../../../interfaces';
import { DrawerLoader } from '../../components/loader';
import { SEmptyState } from '../../..';
import { PresentationList } from '../../components/presentation/list';
import { useSelected } from '../../../../hooks';
import { Presentation } from '../../../../services/react-query/query/presentations/types';
import { SelectedPresentationPreview } from '../../components/selected-presentation-preview';

interface PresentationsProps {
  handleClose: () => void;
  handleBack: () => void;
  handleInsert: (link: Link) => void;
  hideBackButton?: boolean;
}

export const Presentations = ({
  handleClose,
  handleBack,
  handleInsert,
  hideBackButton
}: PresentationsProps) => {
  const {
    search,
    setSearch,
    isLoading,
    presentations,
    fetchMorePresentations
  } = usePresnetations();

  const {
    selected: selectedPresentations,
    handleSelect: handleSelectPresentations
  } = useSelected<Presentation>({
    dataKeyGetter: presentation => presentation.ID
  });

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Add from SETVI" handleClose={handleClose} />
      <Subheader
        text="Presentations"
        handleBack={!hideBackButton && handleBack}
      />
      <Box m={2}>
        <SSearchInput onChange={setSearch} defaultValue={search} />
      </Box>
      <Box overflow="auto">
        <PresentationList
          presentations={presentations}
          fetchMorePresentations={fetchMorePresentations}
          onSelectPresentation={handleSelectPresentations}
          selectedPresentations={selectedPresentations}
        />
        {isLoading && <DrawerLoader />}
        {!isLoading && presentations.length === 0 && (
          <Box>
            <SEmptyState
              imageSrc="/images/no-presentations.png"
              title="No Presentations Available"
              subTitle={
                search.length > 0 &&
                'Please try again with a different search term'
              }
            />
          </Box>
        )}
      </Box>
      {selectedPresentations.length > 0 && (
        <SelectedPresentationPreview
          isExpanded
          selected={selectedPresentations[0]}
          handleClose={() =>
            handleSelectPresentations(false, selectedPresentations)
          }
          handleInsert={handleInsert}
        />
      )}
    </Box>
  );
};
