import { makeStyles } from '@material-ui/core/styles';

export const useInfoStyles = makeStyles(theme => ({
  container: {
    maxWidth: 500
  },
  readMoreHeight: {
    maxHeight: 450
  },
  boldText: {
    fontWeight: 600
  },
  table: {
    width: '100%',
    marginBottom: theme.spacing(3)
  },
  tableProperty: {
    width: '25%',
    padding: `${theme.spacing(2)}px 0}`
  },
  test: {
    opacity: 0.2
  }
}));
