import { useState } from 'react';
import { Box } from '@material-ui/core';
import { Header } from '../../components/header';
import { Subheader } from '../../components/subheader';
import { SNIPPETS_TAB, Tabs } from './tabs';
import { UserSnippets } from './user-snippets';
import { CompanySnippets } from './company-snippets';
import {
  CompanySnippet,
  Snippet
} from '../../../../services/react-query/query/snippets/types';
import { SnippetPreview } from './preview';

interface SnippetsProps {
  handleClose: () => void;
  handleBack: () => void;
  handleInsertMessage: (message: string) => void;
  hideBackButton?: boolean;
}

export const Snippets = ({
  handleClose,
  handleBack,
  handleInsertMessage,
  hideBackButton
}: SnippetsProps) => {
  const [currentTab, setCurrentTab] = useState(SNIPPETS_TAB.mySnippets);
  const [currentSnippet, setCurrentSnippet] = useState<
    Snippet | CompanySnippet | null
  >(null);

  if (currentSnippet)
    return (
      <SnippetPreview
        handleBack={() => setCurrentSnippet(null)}
        handleClose={handleClose}
        title={currentSnippet.Name}
        message={currentSnippet.Text}
        handleInsert={() => handleInsertMessage(currentSnippet.Text)}
      />
    );

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Add from SETVI" handleClose={handleClose} />
      <Subheader text="Snippets" handleBack={!hideBackButton && handleBack} />
      <Tabs currentTab={currentTab} onTabChange={setCurrentTab} />
      {currentTab === SNIPPETS_TAB.mySnippets ? (
        <UserSnippets onSnippetClick={setCurrentSnippet} />
      ) : (
        <CompanySnippets onSnippetClick={setCurrentSnippet} />
      )}
    </Box>
  );
};
