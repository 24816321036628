import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const SingleSelectLoader = () => (
  <Box width="100%">
    <Box mb={2}>
      <Skeleton variant="rect" height="40px" />
    </Box>
    <Box mb={2}>
      <Skeleton variant="rect" height="40px" />
    </Box>
    <Box mb={2}>
      <Skeleton variant="rect" height="40px" />
    </Box>
    <Box mb={2}>
      <Skeleton variant="rect" height="40px" />
    </Box>
    <Box mb={2}>
      <Skeleton variant="rect" height="40px" />
    </Box>
  </Box>
);

export default SingleSelectLoader;
