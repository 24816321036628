import { Box, Button, withStyles } from '@material-ui/core';

import { distances } from '@setvi/shared/styles';

export const ContentContainer = withStyles(({ spacing }) => ({
  root: {
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginTop: spacing(2),
    padding: distances.px.large
  }
}))(Box);

export const ButtonContainer = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 'auto',
    height: '100%',
    gap: 12,
    flexWrap: 'wrap'
  }
}))(Box);

export const StyledButton = withStyles(() => ({
  root: {
    fontWeight: 'normal',
    padding: 0,
    minHeight: 0,
    minWidth: 0,

    '& .MuiButton-startIcon': {
      marginRight: 2
    },

    '&:disabled': {
      opacity: 0.5
    }
  }
}))(Button);
