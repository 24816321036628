import { useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  GetCrmDataQuery,
  Lead
} from '@setvi/shared/services/react-query/query/user/types';
import { getCrmDataQuery, CrmTypes } from '@setvi/shared/services';
import { SalesforceUser } from '../../contacts-navigation';

export interface useCrmProps {
  salesforceUser: SalesforceUser;
}

const LEADS_LIMIT = 20;
export const useLeads = ({ salesforceUser }: useCrmProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const queryVariables = useMemo(
    () => ({
      offset: 0,
      pageSize: LEADS_LIMIT,
      ownerId: salesforceUser.SalesforceUserID,
      type: CrmTypes.Lead,
      searchTerm,
      state: [],
      city: []
    }),
    [searchTerm, salesforceUser.SalesforceUserID]
  );

  const {
    data: leads,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(getCrmDataQuery(queryVariables));

  const fetchMoreLeads = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    leads:
      leads?.pages
        ?.map((page: GetCrmDataQuery<Lead>) => page.Data.records)
        .flat(1) || [],
    isLoading: isLoading || isFetching,
    fetchMoreLeads,
    setSearchTerm
  };
};
