import { QueryClientProvider } from '@tanstack/react-query';
import { useReactQuery } from 'Hooks/React-Query';

interface ReactQueryProviderProps {
  children: JSX.Element;
}

export const ReactQueryProvider = ({ children }: ReactQueryProviderProps) => {
  const { queryClient } = useReactQuery();

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
