import { useFormikContext } from 'formik';
import React, { useContext } from 'react';

export const FormContext = React.createContext({ autosave: false });

export const useFormContext = <Values>() => {
  const formContext = useContext(FormContext);
  const formikContext = useFormikContext<Values>();

  return { ...formContext, ...formikContext };
};
