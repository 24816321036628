import { AxiosMethods, CategoryApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import {
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  UseQueryOptions
} from '@tanstack/react-query';
import { ROUTES } from 'enumsV2';
import {
  BreadcrumbsResponse,
  CategoryDetails,
  GetCategoryDetailsQuery,
  GetCompanyCategoriesQuery,
  GetUserCategoriesQuery
} from './Types';

export enum CategoryQueryKey {
  COMPANY_CATEGORY = 'company_category',
  USER_CATEGORY = 'user_category',
  BREADCRUMB = 'breadcrumb',
  CATEGORY = 'category'
}

interface getBreadcrumbsQueryParams {
  categoryId: number;
}

export const getBreadcrumbsQuery = (params: getBreadcrumbsQueryParams) => ({
  queryKey: [CategoryQueryKey.BREADCRUMB, params],
  queryFn: () =>
    axiosHelper({
      endpoint: CategoryApi.GetBreadcrumbs,
      method: AxiosMethods.GET,
      params
    }),
  select: (res: BreadcrumbsResponse) => res?.Data,
  refetchOnWindowFocus: false
});

interface getCategoryDetailsQueryProps {
  categoryId: string | number;
}

export const getCategoryDetailsQuery = ({
  categoryId
}: getCategoryDetailsQueryProps): UseQueryOptions<
  GetCategoryDetailsQuery,
  GetCategoryDetailsQuery['Error'],
  CategoryDetails
> => ({
  queryKey: [CategoryQueryKey.CATEGORY, categoryId],
  queryFn: () =>
    axiosHelper({
      endpoint: `${CategoryApi.GetCategory}/${categoryId}`,
      method: AxiosMethods.GET
    }),
  select: res => res?.Data,
  refetchOnWindowFocus: false,
  enabled: !!categoryId
});

interface getUserCategoryQueryParams {
  pageSize: number;
  pageNumber: number;
  categoryId: number;
}

export const getUserCategoriesQuery = (
  params: getUserCategoryQueryParams
): UseInfiniteQueryOptions<GetUserCategoriesQuery> => ({
  queryKey: [CategoryQueryKey.USER_CATEGORY, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: CategoryApi.GetUserCategories,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (
    lastPage: GetUserCategoriesQuery,
    allPages: GetUserCategoriesQuery[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Result?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.Data?.Result?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false,
  enabled: window.location.pathname.includes(ROUTES.WORKSPACES_FILES)
});

interface getCompanyCategoryQueryParams {
  pageSize: number;
  pageNumber: number;
  categoryId?: number;
}

export const getCompanyCategoriesQuery = (
  params: getCompanyCategoryQueryParams
): UseInfiniteQueryOptions<GetCompanyCategoriesQuery> => ({
  queryKey: [CategoryQueryKey.COMPANY_CATEGORY, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: CategoryApi.GetCompanyCategories,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (
    lastPage: GetCompanyCategoriesQuery,
    allPages: GetCompanyCategoriesQuery[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Result?.length,
      0
    );
    const currentpageNumber = total / params.pageSize;

    return lastPage?.Data?.Result?.length < params.pageSize
      ? undefined
      : currentpageNumber + 1;
  },
  refetchOnWindowFocus: false,
  enabled: window.location.pathname.includes(ROUTES.RESOURCES)
});
