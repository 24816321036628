// ** MUI Imports
import { Theme } from '@material-ui/core';

const checkbox = (_theme: Theme) => ({
  MuiCheckbox: {
    root: {
      '&:hover, &:hover$checked': {
        backgroundColor: '#E9F4FE'
      }
    },
    colorSecondary: {
      '&.Mui-checked:hover': {
        backgroundColor: '#E9F4FE !important'
      }
    }
  }
});

export default checkbox;
