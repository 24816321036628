import { Box, BoxProps, Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useInView, IntersectionOptions } from 'react-intersection-observer';

const useStyles = makeStyles<Theme, { wrapperClick: boolean }>({
  wrapper: {
    cursor: ({ wrapperClick }) => wrapperClick && 'pointer'
  }
});

export interface SInfiniteScrollProps<T> {
  list: T[];
  keyGetter: (element: T) => string | number;
  render: (element: T, index?: number) => JSX.Element;
  fetchMore?: () => void;
  options?: IntersectionOptions;
  children?: JSX.Element;
  containerClass?: string;
  containerComponent?: BoxProps['component'];
  wrapperClass?: string;
  renderDragItem?: (element: T) => Element;
  wrapperComponent?: BoxProps['component'];
  onWrapperClick?: (element: T) => void;
  onWrapperDrag?: (element: T) => void;
  onWrapperDrop?: (element: T) => void;
}

const SInfiniteScroll = <T,>({
  list,
  keyGetter,
  render,
  fetchMore,
  options,
  children,
  containerClass,
  containerComponent,
  wrapperComponent,
  wrapperClass,
  onWrapperClick,
  onWrapperDrag,
  onWrapperDrop,
  renderDragItem
}: SInfiniteScrollProps<T>) => {
  const classes = useStyles({ wrapperClick: !!onWrapperClick });
  const { ref, inView } = useInView(options);

  useEffect(() => {
    if (inView) fetchMore?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <Box component={containerComponent} className={containerClass}>
      {list?.map((element, index, array) => (
        <Box
          key={keyGetter(element)}
          onClick={() => onWrapperClick?.(element)}
          draggable={!!onWrapperDrag}
          onDragStart={() => onWrapperDrag?.(element)}
          component={wrapperComponent}
          className={clsx(classes.wrapper, wrapperClass)}
          onDragOver={e => e.preventDefault()}
          onDragStartCapture={e => {
            const div = renderDragItem?.(element);
            if (div) e.dataTransfer.setDragImage(div, 0, 0);
          }}
          onDrop={ev => {
            ev.preventDefault();
            ev.stopPropagation();
            onWrapperDrop?.(element);
          }}
          // @ts-ignore ref is working but is not valid prop on dype definition
          // https://github.com/mui/material-ui/issues/17010#issuecomment-704869941
          // can be uncommented after update to material-ui v5 https://mui.com/material-ui/api/box/
          ref={index + 1 === array.length ? ref : null}>
          {render(element, index)}
        </Box>
      ))}
      {children}
    </Box>
  );
};

export default SInfiniteScroll;
