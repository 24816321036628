import { MouseEvent } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import SETVIImage from '../setvi-image';
import { Overlay } from './overlay';
import { Resource } from '../../interfaces';
import { resourceView } from '../../utils/viewer';

const useStyles = makeStyles({
  imageContainer: {
    position: 'relative',
    cursor: 'pointer'
  },
  panelImage: {
    width: 70,
    height: 52,
    borderRadius: 6,
    objectFit: 'cover'
  }
});

interface SResourceThumbProps {
  thumbUrl: Resource['ThumbURLWithSas'];
  name: Resource['Name'];
  resourceID: Resource['ResourceID'];
  resourceTypeID: Resource['ResourceTypeID'];
}

export const SResourceThumb = ({
  thumbUrl,
  name,
  resourceID,
  resourceTypeID
}: SResourceThumbProps) => {
  const classes = useStyles();

  const onThumbnailClick = (e: MouseEvent) => {
    e.stopPropagation();
    resourceView({
      name,
      resourceId: resourceID,
      resourceTypeId: resourceTypeID
    });
  };

  return (
    <Box className={classes.imageContainer} onClick={onThumbnailClick}>
      <SETVIImage
        className={classes.panelImage}
        src={thumbUrl}
        alt="Resource"
        loading="lazy"
      />
      <Overlay />
    </Box>
  );
};
