import { useMemo } from 'react';
import { Resource } from '../../../interfaces';
import { ISMenuItem } from '../../sdropdown-menu/MenuItem';
import { AddFile, SelectIcon } from '../../sicons';
import { GetResourceTypeName } from '../../../utils';

interface useSelectPagesDropdown {
  resource: Resource;
  isChecked: boolean;
  onSelectAllPages: (resource: Resource) => void;
  onSelectPages: (resource: Resource) => void;
}

export const useSelectPagesDropdown = ({
  resource,
  isChecked,
  onSelectAllPages,
  onSelectPages
}: useSelectPagesDropdown) => {
  const option = !isChecked ? 'Add' : 'Remove';
  const selectPageOption =
    resource.ChildResources?.length > 1 || resource.Pages > 1;

  const menuItems: ISMenuItem[] = useMemo(
    () => [
      {
        icon: <AddFile />,
        text: `${option} full ${GetResourceTypeName(resource.ResourceTypeID)}`,
        subText: `${option} entire file with all pages`,
        onClick: () => onSelectAllPages(resource)
      },
      ...(selectPageOption
        ? [
            {
              icon: <SelectIcon />,
              text: 'Select Pages',
              subText: 'Add only relevant pages',
              onClick: () => onSelectPages(resource)
            }
          ]
        : [])
    ],
    [option, resource, selectPageOption, onSelectAllPages, onSelectPages]
  );

  return { menuItems };
};
