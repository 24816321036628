import { useState, createContext } from 'react';

export type NylasContextType = {
  accessToken: string;
  setAccessToken: (value: string | null) => void;
  isConnected: boolean;
  setIsConnected: (value: boolean | null) => void;
};

const contextDefaultValues: NylasContextType = {
  accessToken: '',
  setAccessToken: () => {},
  isConnected: false,
  setIsConnected: () => {}
};

export const NylasContext =
  createContext<NylasContextType>(contextDefaultValues);

export const NylasProvider = ({ children }: { children: JSX.Element }) => {
  const [accessToken, setAccessToken] = useState('');
  const [isConnected, setIsConnected] = useState(false);

  return (
    <NylasContext.Provider
      value={{
        accessToken,
        setAccessToken,
        isConnected,
        setIsConnected
      }}>
      {children}
    </NylasContext.Provider>
  );
};
