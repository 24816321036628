import { AxiosMethods, EmailCampaignsApi } from '@setvi/shared/enums';
import { Campaign } from '@setvi/shared/interfaces';
import { axiosHelper } from '@setvi/shared/services';

export enum CampaignsMutationsKey {
  RESEND_EMAIL = 'resend_email',
  CANCEL_EMAIL = 'cancel_email',
  UPDATE_STATUS = 'update_status',
  CREATE_CAMPAIGN = 'create_campaign',
  UPDATE_CAMPAIGN = 'update_campaign',
  CANCEL_CAMPAIGN = 'cancel_campaign',
  RECIPIENT_INTERESTED = 'recipient_interested',
  RETRY_EMAILS = 'retry_emails'
}

const dynamicId = ':campaignId';

export const updateCampaignStatusesMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: EmailCampaignsApi.UpdateCampaignStatus,
      method: AxiosMethods.PATCH,
      params,
      body
    })
});

export const createCampaignMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: EmailCampaignsApi.CreateCampaign,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export const updateCampaignMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: EmailCampaignsApi.UpdateCampaign,
      method: AxiosMethods.PUT,
      params,
      body
    })
});

export const cancelCampaignMutation = () => ({
  mutationFn: (Id: number) =>
    axiosHelper({
      endpoint: `${EmailCampaignsApi.CancelCampaign.replace(
        dynamicId,
        Id.toString()
      )}`,
      method: AxiosMethods.PATCH
    })
});

export const campaignFollowUpMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: EmailCampaignsApi.CampaignFollowUp,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export const resenedEmailMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: EmailCampaignsApi.ResendMail,
      method: AxiosMethods.PUT,
      params,
      body
    })
});

export const cancelEmailMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: EmailCampaignsApi.CancelMail,
      method: AxiosMethods.PATCH,
      params,
      body
    })
});

export const recipientInterestedMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: EmailCampaignsApi.RecipientInterested,
      method: AxiosMethods.PATCH,
      params,
      body
    })
});

export const disableCampaignLinksMutation = (campaignId: Campaign['Id']) => ({
  mutationFn: ({ body }: any) =>
    axiosHelper({
      endpoint: EmailCampaignsApi.CampaignLinksDisable.replace(
        dynamicId,
        campaignId.toString()
      ),
      method: AxiosMethods.PUT,
      body
    })
});

export const disableCampaignRecipientLinksMutation = () => ({
  mutationFn: ({ body }: any) =>
    axiosHelper({
      endpoint: EmailCampaignsApi.CampaignRecipientLinksDisable,
      method: AxiosMethods.PUT,
      body
    })
});

export const retryFailedCampaignEmailsMutation = () => ({
  mutationFn: (id: string) =>
    axiosHelper({
      endpoint: EmailCampaignsApi.RetryFailedCampaignEmails.replace(
        dynamicId,
        id
      ),
      method: AxiosMethods.PATCH
    })
});
