import { VARIABLE_TYPE } from '../../enums';

export const VARIABLE_LIST = [
  VARIABLE_TYPE.firstName,
  VARIABLE_TYPE.lastName,
  VARIABLE_TYPE.companyName
];

export * from './getBodyVariables';
export * from './setEmailVariables';
export * from './getSubjectVariables';
