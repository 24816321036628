import { AxiosMethods, SnippetsApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

export enum SnippetsMutationsKey {
  CREATE_SNIPPET = 'create_snippet',
  UPDATE_SNIPPET = 'update_snippet',
  DELETE_SNIPPET = 'delete_snippet'
}

export const createSnippetMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: SnippetsApi.CreateSnippet,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export const updateSnippetMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: SnippetsApi.UpdateSnippets,
      method: AxiosMethods.PUT,
      params,
      body
    })
});

export const deleteSnippetMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: SnippetsApi.DeleteSnippets,
      method: AxiosMethods.DELETE,
      params,
      body
    })
});
