import { Box } from '@material-ui/core';
import { useCompanySnippets } from './useCompanySnippets';
import { SSearchInput } from '../../../../sforms';
import SInfiniteScroll from '../../../../sinfinite-scroll';
import { SnippetCard } from '../../../components/snippet/card';
import { DrawerLoader } from '../../../components/loader';
import { CompanySnippet } from '../../../../../services/react-query/query/snippets/types';
import { SEmptyState } from '../../../..';

interface CompanySnippetsProps {
  onSnippetClick: (snippet: CompanySnippet) => void;
}

export const CompanySnippets = ({ onSnippetClick }: CompanySnippetsProps) => {
  const { search, setSearch, snippets, isLoading } = useCompanySnippets();

  return (
    <>
      <Box m={2}>
        <SSearchInput defaultValue={search} onChange={setSearch} />
      </Box>
      <Box overflow="auto">
        <SInfiniteScroll
          list={snippets}
          keyGetter={el => el.ID}
          render={snippet => (
            <SnippetCard
              snippet={snippet}
              name={snippet.Name}
              lastModified={snippet.ChangeDate}
              onSnippetClick={onSnippetClick}
            />
          )}
        />
        {isLoading && <DrawerLoader showImage={false} />}
        {!isLoading && snippets?.length === 0 && (
          <Box>
            <SEmptyState
              imageSrc="/images/no-my-snippets.png"
              title="No Snippets Available"
            />
          </Box>
        )}
      </Box>
    </>
  );
};
