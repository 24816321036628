import { AdminResourceApi, AxiosMethods, ResourceApi } from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';

export enum ResourcesMutationsKey {
  CREATE = 'create_resource',
  CREATE_WEB_LINK = 'create_web_link_resource'
}

export const createResourceMutation = () => ({
  mutationFn: (body: any) =>
    axiosHelper({
      endpoint: AdminResourceApi.CreateResource,
      method: AxiosMethods.POST,
      body,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const saveResourceBookmarks = () => ({
  mutationFn: ({ body, params }: any) => {
    const formData = new FormData();
    formData.append('resourceID', body.resourceID);
    formData.append('bookmarks', JSON.stringify(body.bookmarks));
    return axiosHelper({
      endpoint: ResourceApi.SaveResourceBookmarks,
      method: AxiosMethods.POST,
      params,
      body: formData
    });
  },
  select: (res: any) => res
});

export interface AdminResourceUploadFinishedInput {
  resources: {
    resourceId: number;
    filestackId: string;
  }[];
}

//! This is temporary until backend is ready for new upload fucntionality
export const resourceUploadFinishedMutation = () => ({
  mutationFn: (body: AdminResourceUploadFinishedInput) =>
    axiosHelper({
      endpoint: AdminResourceApi.ResourceUploadFinished,
      method: AxiosMethods.POST,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    }),
  retry: false
});
