import { Box, Divider, ListItem } from '@material-ui/core';
import moment from 'moment';
import SText from '../../../../sui/stext';
import { useSnippetCardStyles } from './style';

interface SnippetCardProps<T> {
  snippet: T;
  name: string;
  lastModified: string;
  onSnippetClick?: (snippet: T) => void;
}

export const SnippetCard = <T,>({
  name,
  lastModified,
  onSnippetClick,
  snippet
}: SnippetCardProps<T>) => {
  const styles = useSnippetCardStyles();

  return (
    <>
      <ListItem
        button
        className={styles.container}
        onClick={() => onSnippetClick?.(snippet)}>
        <Box p={2}>
          <Box mb={2}>
            <SText title={name} maxLines={2} weight="bold" size="sm" />
          </Box>
          {lastModified && (
            <SText size="xs" fontColor="#92929D">
              Last Modified:{' '}
              {lastModified && moment(lastModified).format('M/D/YYYY')}
            </SText>
          )}
        </Box>
      </ListItem>
      <Divider />
    </>
  );
};
