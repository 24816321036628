import { Presentation } from '../../../../../services/react-query/query/presentations/types';
import { SCheckbox } from '../../../../scheckbox';

interface PresentationActionProps {
  presentation: Presentation;
  onSelectPresentation: (
    checked: boolean,
    presentation: Presentation[]
  ) => void;
  checked?: boolean;
}

export const PresentationAction = ({
  presentation,
  checked = false,
  onSelectPresentation
}: PresentationActionProps) => {
  if (presentation.PresentationItems.length === 0)
    return (
      <SCheckbox
        disabled
        tooltip="This presentation has no resources and cannot be shared."
      />
    );

  if (presentation.PresentationItems.some(item => !item.IsShareable))
    return (
      <SCheckbox
        disabled
        tooltip="This presentation has at least one resource that is not shareable."
      />
    );

  return (
    <SCheckbox
      checked={checked}
      onChange={e => onSelectPresentation(e.target.checked, [presentation])}
    />
  );
};
