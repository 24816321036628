import { FC, ReactNode, MouseEvent, useCallback } from 'react';
import clsx from 'clsx';
import { Box, Typography, ListItem, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SHighlightText } from '@setvi/shared/components';
import SETVIImage from '@setvi/shared/components/setvi-image';
import { distances } from '@setvi/shared/styles';
import styled from 'styled-components';

interface ItemProps {
  item: any;
  itemType: ReactNode;
  itemAction: ReactNode;
  itemDate?: string;
  disableIcon?: boolean;
  dragListeners?: any;
  onClick?: () => void;
  onImageClick?: () => void;
  isClickable?: boolean;
  contentOverlay?: JSX.Element;
  search?: string;
}

interface ItemStylesProps {
  isClickable: boolean;
}

const ContentOverlay = styled.div`
  background: rgba(30, 150, 252, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  &:hover {
    opacity: 1;
  }
`;

const useStyles = makeStyles<Theme, ItemStylesProps>(theme => ({
  listItem: {
    padding: `${theme.spacing(2)}px 0`,
    cursor: ({ isClickable }) => (isClickable ? 'pointer' : 'auto')
  },
  innerWrap: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: `0 ${distances.px.small}`
  },
  panelImage: {
    width: 70,
    height: 52,
    borderRadius: 6,
    objectFit: 'cover'
  },
  panelFileIcon: {
    height: 16,
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(1)
  },
  dotDivider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: 5,
    width: 5,
    borderRadius: 5,
    backgroundColor: theme.palette.text.secondary
  },
  dragHandle: {
    cursor: 'grab'
  },
  itemName: {
    width: '100%',
    maxWidth: '160px',
    cursor: 'point'
  },
  imageContainer: {
    position: 'relative'
  }
}));

export const ResourceItem: FC<ItemProps> = ({
  item,
  itemType,
  itemAction,
  dragListeners = null,
  isClickable = false,
  onClick,
  onImageClick,
  contentOverlay,
  search
}) => {
  const classes = useStyles({ isClickable });

  const onThumbnailClick = useCallback(
    (e: MouseEvent) => {
      if (onImageClick) {
        e.stopPropagation();
        onImageClick();
      }
    },
    [onImageClick]
  );

  return (
    <ListItem className={classes.listItem} key={item.ResourceID}>
      <Box className={classes.innerWrap} onClick={onClick}>
        <div
          onClick={onThumbnailClick}
          className={clsx(
            dragListeners !== null && classes.dragHandle,
            classes.imageContainer
          )}
          {...dragListeners}>
          <SETVIImage
            className={classes.panelImage}
            src={item.ThumbURLWithSas || item.ThumbURL}
            alt="Resource"
            loading="lazy"
          />
          {contentOverlay && (
            <ContentOverlay className={classes.panelImage}>
              {contentOverlay}
            </ContentOverlay>
          )}
        </div>
        <Box
          width="100%"
          ml={2}
          className={clsx(dragListeners !== null && classes.dragHandle)}
          {...dragListeners}>
          <SHighlightText
            value={item.Name}
            searchQuery={search}
            weight="medium"
            size="sm"
            className={clsx(classes.itemName)}
            maxLines={2}
          />
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" color="textSecondary">
              {itemType}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <div>{itemAction}</div>
      </Box>
    </ListItem>
  );
};
