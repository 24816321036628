import { useState, useEffect, useCallback } from 'react';

import { yupToValidationErrors } from '../../utils';

interface CustomValidateProps {
  schema: any;
  value?: any;
  name: string;
}

interface UseCustomErrorProps {
  schemas: CustomValidateProps[];
  values: any;
}

export const useCustomErrors = ({ schemas, values }: UseCustomErrorProps) => {
  const [customErrors, setCustomErrors] = useState({});

  const customValidate = useCallback(
    ({ schema, value, name }: CustomValidateProps) => {
      try {
        schema.validateSync({ [name]: value });
      } catch (e) {
        const result = yupToValidationErrors(e);
        setCustomErrors(prev => ({ ...prev, ...result }));
        return;
      }

      setCustomErrors(prev => ({ ...prev, [name]: null }));
    },
    []
  );

  useEffect(() => {
    // Run validations on mount
    if (schemas.length) {
      let onMountErrors = {};

      schemas.map(schema => {
        let error: any = null;

        // Run validations only when value is not empty
        if (values[schema.name]?.length) {
          try {
            schema.schema.validateSync({ [schema.name]: values[schema.name] });
          } catch (e) {
            error = yupToValidationErrors(e);
          }
        }

        onMountErrors = error
          ? { ...onMountErrors, ...error }
          : { ...onMountErrors, [schema.name]: null };

        return schema;
      });

      setCustomErrors(onMountErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    customErrors,
    customValidate
  };
};
