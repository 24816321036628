import { ReactNode } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useListStyles } from '../style';
import { distances } from '../../../../../styles';

export interface NavigationButtonProps {
  label: string;
  onClick?: () => void;
  icon?: ReactNode;
}

export const NavigationButton = ({
  label,
  onClick,
  icon
}: NavigationButtonProps) => {
  const classes = useListStyles();

  return (
    <Button
      onClick={() => onClick?.()}
      variant="outlined"
      className={classes.button}>
      <Box className={classes.innerContainer} minWidth="100px">
        {icon && icon}
        <Box pt={distances.px.small} pb={distances.px.small}>
          <Typography variant="body2" className={classes.text}>
            {label}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};
