import { Box, IconButton } from '@material-ui/core';
import {
  GetResourceTypeIcon,
  GetResourceTypeName
} from '@setvi/shared/utils/resources';
import { DangerIcon } from '@setvi/shared/components/sicons';
import { ResourceItem } from '@setvi/shared/components/sdrawer-content/pages/review-links/edit-links/item/ResourceItem';
import { Resource } from '../../../../../../interfaces';

interface SelectedResourcePreviewProps {
  resource: Resource;
  removeItem?: (resource: Resource) => void;
  search?: string;
}

export const SelectedResourcePreview = ({
  resource,
  removeItem,
  search
}: SelectedResourcePreviewProps) => (
  <ResourceItem
    item={resource}
    search={search}
    itemType={
      <Box display="flex">
        <img width={20} src={GetResourceTypeIcon(resource.ResourceTypeID)} />
        {GetResourceTypeName(resource.ResourceTypeID)}
      </Box>
    }
    itemAction={
      removeItem && (
        <IconButton size="small" onClick={() => removeItem(resource)}>
          <DangerIcon />
        </IconButton>
      )
    }
  />
);
