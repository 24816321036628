import { makeStyles } from '@material-ui/core/styles';
import { CustomDialogIcon } from '../icons';
import { Types, Type, TypeColor } from '../helper';

const useStyles = makeStyles(theme => ({
  svgIcon: {
    fill: 'none',
    width: '45px',
    height: '45px',
    stroke: theme.palette.error.main
  }
}));

interface DialogIconProps {
  type: Type;
}

const DialogIcon = ({ type }: DialogIconProps) => {
  const classes = useStyles();

  if (type === Types.none) return null;
  return (
    <CustomDialogIcon
      stroke={TypeColor[type]}
      viewBox="0 0 48 48"
      className={classes.svgIcon}
    />
  );
};

export default DialogIcon;
