import { withStyles } from '@material-ui/core/styles';
import { Button, Tooltip } from '@material-ui/core';

export const StyledButton = withStyles(({ palette }) => ({
  root: {
    color: palette.common.white,
    fontSize: '0.8rem',
    lineHeight: 1,
    padding: 0
  }
}))(Button);

export const DeleteButton = withStyles(({ spacing }) => ({
  root: {
    marginLeft: spacing(2)
  }
}))(StyledButton);

export const CustomTooltip = withStyles({
  popper: {
    pointerEvents: 'all'
  }
})(Tooltip);
