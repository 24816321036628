import { Field, FieldProps } from 'formik';

import {
  SAutocompleteItem,
  SAutocompleteProps
} from '../../../sautocomplete/interfaces';
import SAutocomplete from '../../../sautocomplete';

interface SFormikAutocompleteProps
  extends Omit<SAutocompleteProps, 'handleItemClick'> {
  customErrors?: any;
  handleItems?: (items: SAutocompleteItem[]) => void;
  handleItemClick?: (item: SAutocompleteItem) => void;
}

const SFormikAutocomplete = ({
  name,
  label,
  options,
  loading,
  selected,
  disabled,
  required,
  placeholder,
  customErrors,

  handleInputChange,
  handleItemClick,
  handleItems,
  ...rest
}: SFormikAutocompleteProps) => (
  <Field name={name}>
    {({ field, meta, form }: FieldProps) => (
      <SAutocomplete
        label={label}
        name={field.name}
        options={options}
        loading={loading}
        selected={meta.value}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        handleInputChange={handleInputChange}
        error={
          (meta.touched && Boolean(meta.error)) || Boolean(customErrors?.[name])
        }
        helperText={meta.error || customErrors?.[name]}
        handleItemClick={(item: SAutocompleteItem) => {
          const findIndex = selected?.findIndex(
            (i: SAutocompleteItem) => i.id.toString() === item.id.toString()
          );

          let items = selected;
          if (findIndex === -1) items.push(item);
          else
            items = selected?.filter(
              (i: SAutocompleteItem) => i.id.toString() !== item.id.toString()
            );

          form.setFieldValue(name, items);
          handleItems?.(items);
          handleItemClick?.(item);
        }}
        {...rest}
      />
    )}
  </Field>
);

export default SFormikAutocomplete;
