import { Resource } from '@setvi/shared/interfaces';
import { Box } from '@material-ui/core';
import SInfiniteScroll from '../../../../sinfinite-scroll';
import SText from '../../../../sui/stext';
import { Item } from '../card';

export interface ResourceListProps {
  resources: Resource[];
  fetchMoreResources?: () => void;
  selectedResources?: Resource[];
  onSelect: (checked: boolean, resources: Resource[]) => void;
  onSelectPages: (resource: Resource) => void;
  linkForm?: boolean;
}

export const ResourceList = ({
  resources,
  fetchMoreResources,
  selectedResources,
  onSelect,
  onSelectPages,
  linkForm
}: ResourceListProps) => (
  <Box m={2}>
    {resources.length > 0 && (
      <Box mb={2}>
        <SText weight="medium">Resources</SText>
      </Box>
    )}
    <SInfiniteScroll
      list={resources}
      fetchMore={fetchMoreResources}
      keyGetter={el => el.ID}
      render={resource => (
        <Item
          resource={resource}
          onSelect={onSelect}
          selected={selectedResources}
          onSelectPages={onSelectPages}
          // This allows the user to add a resource to a presentation even if the resource is not shareable
          // but user cant email the resource if the resource is not shareable
          isShareable={linkForm ? resource?.IsShareable : true}
        />
      )}
    />
  </Box>
);
