import { AxiosMethods, QrveyApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import { UseQueryOptions } from '@tanstack/react-query';
import { ActiveLink, GetQrveyActiveLinks, GetQrveyLinkDetails } from './Types';

export enum QrveyQueryKey {
  ACTIVE_LINKS = 'activeLinks',
  LINK_DETAILS = 'linkDetails'
}

export const getQrveyActiveLinks =
  (): UseQueryOptions<GetQrveyActiveLinks> => ({
    queryKey: [QrveyQueryKey.ACTIVE_LINKS],
    queryFn: () =>
      axiosHelper({
        endpoint: QrveyApi.ActiveLinks,
        method: AxiosMethods.GET
      })
  });

export const getQrveyLinkDetails = (
  linkId: ActiveLink['LinkId']
): UseQueryOptions<GetQrveyLinkDetails> => ({
  queryKey: [QrveyQueryKey.LINK_DETAILS, linkId],
  queryFn: () =>
    axiosHelper({
      endpoint: QrveyApi.LinkDetails.replace(':id', linkId.toString()),
      method: AxiosMethods.GET
    }),
  enabled: !!linkId,
  cacheTime: 0
});
