import {
  QueryFunctionContext,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';
import {
  AxiosMethods,
  PresentationType,
  PresentationsApi
} from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';
import { GetPresentationsQuery } from './types';

export enum PresentationQueryKey {
  PRESENTATIONS = 'presentations'
}

interface GetPresentationsQueryParams {
  pageNumber: number;
  pageSize: number;
  searchTerm?: string;
  sortBy?: string;
  orderBy?: string;
  statuses?: PresentationType;
}

export const getPresentationsQuery = (
  params: GetPresentationsQueryParams
): UseInfiniteQueryOptions<GetPresentationsQuery> => ({
  queryKey: [PresentationQueryKey.PRESENTATIONS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: PresentationsApi.Presentations,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (
    lastPage: GetPresentationsQuery,
    allPages: GetPresentationsQuery[]
  ) => {
    const total = allPages?.reduce((acc, cur) => acc + cur?.Result?.length, 0);
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Result?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false,
  cacheTime: 0
});
