import { Box, withStyles } from '@material-ui/core';

import SButton from '../../../sbutton';
import SETVIImage from '../../../setvi-image';
import { ContentProps } from '../../interfaces';

const ContentContainer = withStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    position: 'relative',

    '&:hover': {
      '& .overlay': {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 8,
        position: 'absolute',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        background: '#FFFFFF80'
      }
    }
  }
}))(Box);

const Content = ({
  image,
  loading,
  errorImage,
  buttonTitle,

  open
}: ContentProps) =>
  !loading && image ? (
    <ContentContainer>
      <SETVIImage
        src={image}
        alt="Updated Image"
        fit="contain"
        radius={8}
        errorImage={errorImage}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
      <Box display="none" className="overlay">
        <SButton
          onClick={e => {
            e?.stopPropagation();
            open?.();
          }}>
          {buttonTitle || 'Upload Image'}
        </SButton>
      </Box>
    </ContentContainer>
  ) : null;

export default Content;
