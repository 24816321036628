// ** MUI Imports
import { Theme } from '@material-ui/core';

const table = (_theme: Theme) => ({
  MuiTableRow: {
    root: {
      '&:hover': {
        backgroundColor: '#FAFAFB!important'
      }
    }
  }
});

export default table;
