export interface AlgoliaHitBreadcrumb {
  id: number;
  name: string;
  depth: number;
  resourceCategoryClass: number;
}

export enum MatchLevel {
  Full = 'full',
  None = 'none'
}

export interface CategoryDescription {
  value: string;
  matchLevel: MatchLevel;
  matchedWords: string[];
  fullyHighlighted?: boolean;
}

export interface HighlightResult {
  name: CategoryDescription;
  material: CategoryDescription;
  categoryName: CategoryDescription;
  categoryDescription: CategoryDescription;
  comment: CategoryDescription;
  breadcrumbs: HighlightResultBreadcrumb[];
  tags?: CategoryDescription[];
}

export interface HighlightResultBreadcrumb {
  name: CategoryDescription;
  depth: CategoryDescription;
}

export interface AlgoliaResponse {
  hits: AlgoliaHit[];
  nbHits: number;
  page: number;
  nbPages: number;
  hitsPerPage: number;
  exhaustiveNbHits: boolean;
  exhaustiveTypo: boolean;
  query: string;
  params: string;
  processingTimeMS: number;
}

export interface AlgoliaHit {
  id: number;
  resourceID: string;
  name: string;
  material: string;
  categoryID: number;
  categoryName: string;
  categoryDescription: string;
  tags: string[];
  resourceTypeID: number;
  resourceTypeName: string;
  companyID: number;
  companyName: string;
  comment: string;
  fileName?: string;
  thumbURL: string;
  downloadURL: string;
  isInternallyDownloadable: boolean;
  isShareable: boolean;
  resourceLastChangedDate: string;
  pages: number;
  pageNumber?: number;
  breadcrumbs: AlgoliaHitBreadcrumb[];
  permissions: number[];
  objectID: string;
  _highlightResult: HighlightResult;
  boxV2Id?: string;
}
