import { Box } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import { PlusIcon } from '../../../sicons';
import SText from '../../../sui/stext';

const AddLabel = ({
  label,
  hasOptions,
  handleNewOption
}: {
  label: string;
  hasOptions: boolean;
  handleNewOption: (name: string, id: string) => void;
}) => (
  <Box
    width="100%"
    padding={2}
    bgcolor="white"
    onClick={() => handleNewOption(label, uuidv4())}
    borderTop={hasOptions ? '1px solid #E9E9E9' : 'none'}
    style={{
      cursor: 'pointer'
    }}>
    <Box
      gridGap={4}
      display="flex"
      alignItems="center"
      marginTop={hasOptions ? 2 : 0}
      paddingY={hasOptions ? 0 : 2}>
      <PlusIcon
        color="primary"
        fontSize="small"
        style={{
          transform: 'scale(0.5)'
        }}
      />
      <SText color="primary" weight="bold">
        Create
      </SText>
      <SText weight="bold">{label}</SText>
    </Box>
  </Box>
);

export default AddLabel;
