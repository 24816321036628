import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import SText from '../../../../sui/stext';
import { useSelectedResourcePreviewStyles } from '../style';

interface SelectedResourcesPreviewHeaderProps {
  numberOfSelectedResources: number;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectedResorucePreviewHeader = ({
  numberOfSelectedResources,
  isExpanded,
  setIsExpanded
}: SelectedResourcesPreviewHeaderProps) => {
  const styles = useSelectedResourcePreviewStyles();

  return (
    <Box className={styles.headerContainer}>
      <SText
        title={`${numberOfSelectedResources} Selected`}
        variant="subtitle2"
        color="primary"
        size="sm"
        weight="semibold"
      />
      <IconButton
        color="primary"
        size="small"
        onClick={() => setIsExpanded(isExp => !isExp)}>
        {isExpanded ? <ArrowDropDown /> : <ArrowDropUp />}
      </IconButton>
    </Box>
  );
};
