import {
  Box,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  SSelectDropdown,
  SelectMenuHeader
} from '@setvi/shared/components/sforms/sselect';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useState } from 'react';
import { TContact } from '../index';

const useContactFilterStyles = makeStyles(() => ({
  radio: {
    backgroundColor: 'transparent!important'
  },
  label: {
    fontSize: 14
  }
}));

export type SortableContactProperties = keyof Omit<
  TContact,
  'Id' | 'Title' | 'Type'
>;
interface ContactFilterProps {
  sortBy: string;
  setSortBy: (value: SortableContactProperties) => void;
}

type SortOptions = {
  value: SortableContactProperties;
  label: string;
};

const sortByOptions: SortOptions[] = [
  { value: 'FirstName', label: 'First Name' },
  { value: 'LastName', label: 'Last Name' },
  { value: 'CompanyName', label: 'Company Name' },
  { value: 'Email', label: 'Email' }
];

export const ContactFilter = ({ sortBy, setSortBy }: ContactFilterProps) => {
  const classes = useContactFilterStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <SSelectDropdown
      anchorElement={anchorEl}
      anchor={
        <IconButton
          color={anchorEl ? 'primary' : 'default'}
          onClick={e => setAnchorEl(e.currentTarget)}>
          <FilterListIcon />
        </IconButton>
      }
      onClose={() => setAnchorEl(null)}
      content={
        <Box width="150px">
          <SelectMenuHeader title="Sort by" />
          <RadioGroup
            value={sortBy}
            onChange={event => {
              setSortBy(event.target.value as SortableContactProperties);
              setAnchorEl(null);
            }}>
            {sortByOptions.map(option => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    disableRipple
                    className={classes.radio}
                    icon={<CheckBoxOutlineBlankIcon htmlColor="#fff" />}
                    checkedIcon={<CheckIcon htmlColor="#44444F" />}
                  />
                }
                label={
                  <Typography className={classes.label}>
                    {option.label}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
        </Box>
      }
    />
  );
};
