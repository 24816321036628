// ** MUI Imports
import { Theme } from '@material-ui/core';

const input = (theme: Theme) => ({
  MuiInputLabel: {
    root: {
      color: theme.palette.text.secondary
    }
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 8,
      '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
        borderColor: `#E2E2EA`
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `#E2E2EA`
      },
      '&:hover.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderWidth: '1px'
      },
      '&:hover.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main
      },
      '& ~ .MuiFormHelperText-contained': {
        marginLeft: 0,
        marginRight: 0,
        fontSize: '13px',
        fontWeight: 500
      }
    }
  }
});

export default input;
