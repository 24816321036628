import { Drawer, DrawerProps } from '@material-ui/core';

export interface SDrawerProps {
  open: DrawerProps['open'];
  anchor?: DrawerProps['anchor'];
  variant?: DrawerProps['variant'];
  children: React.ReactNode;
}

const SDrawer = ({
  anchor = 'right',
  variant = 'persistent',
  open = false,
  children
}: SDrawerProps) => (
  <Drawer
    SlideProps={{ unmountOnExit: true }}
    open={open}
    anchor={anchor}
    style={{ zIndex: 1200 }}
    variant={variant}>
    {children}
  </Drawer>
);

export default SDrawer;
