import { Box, Divider, IconButton, withStyles } from '@material-ui/core';
import { SText } from '@setvi/shared/components';

interface HeaderProps {
  elements: {
    key: string;
    icon: JSX.Element;
    onClick?: () => void;
  }[];
  title: string;
}

const StyledBox = withStyles(({ spacing }) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2),
    borderRadius: 'inherit',
    justifyContent: 'space-between',

    '& button': {
      padding: 2,
      margin: 0
    }
  }
}))(Box);

const Header = ({ title, elements }: HeaderProps) => (
  <Box>
    <StyledBox>
      <SText weight="semibold" title={title}>
        {title}
      </SText>
      <Box display="flex" alignItems="center" gridGap={6}>
        {elements.map(({ icon, key, onClick }) => (
          <IconButton key={key} onClick={onClick}>
            {icon}
          </IconButton>
        ))}
      </Box>
    </StyledBox>
    <Divider />
  </Box>
);

export default Header;
