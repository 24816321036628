import { ChildResource } from '../../../../../interfaces';
import SETVIImage from '../../../../setvi-image';
import { Loader } from '../loader';

interface ImageProps {
  page: ChildResource;
  loading: boolean;
}

export const Image = ({ page, loading }: ImageProps) => {
  if (loading) return <Loader />;

  return (
    <SETVIImage
      style={{ width: '100%', objectFit: 'contain' }}
      src={page?.ThumbURLWithSas}
      alt={page?.Material}
    />
  );
};
