export const truncateTextToLimit = (
  text: string,
  characterLimit: number,
  includeDots = false
) => {
  if (!characterLimit) return text;
  return text.length > characterLimit
    ? `${text.substring(0, characterLimit - 1)}${includeDots && '...'}`
    : text;
};
