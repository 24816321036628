import { useState, useCallback, useEffect, useMemo } from 'react';

import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import { useMutation, useQuery } from '@tanstack/react-query';
import ClearIcon from '@material-ui/icons/Clear';
import { useSnackbar } from 'notistack';
import { TContact } from '@setvi/shared/components/sdrawer-content/components/contact';
import { DrawerContent, LinkObjectType } from '@setvi/shared/enums';
import { Link, Recipient } from '@setvi/shared/interfaces';
import { cleanHtml, htmlTags, parseHtmlFEtoBC } from '@setvi/shared/utils';

import {
  LinkActions,
  useEditorLinksHook
} from '@setvi/shared/components/seditor/hooks';
import { useDialog } from '@setvi/shared/providers';
import { GetLinkActionProps } from '@setvi/shared/components/seditor/interfaces';
import { DRAWER_WIDTH } from '@setvi/shared/components/sdrawer-content/components/base';

import { useNylas } from 'Hooks/useNylas';
import NylasDialog from 'Components/Campaigns/NylasDialog';
import { sendEMailMutation, userSignatureQuery } from 'Services';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { addFreeSpaceToEmailContent } from 'Utils/addFreeSpaceToEmailContent';
import { useUploadResourcePanel } from 'providersV2/upload-resource-panel/context';

import { CampaignFormLimit } from 'Enums/CampaignFormLimit';
import { DiscardEmailConfirmDialog } from 'Components/Shared';
import { useEmailServer } from 'pages/settings/subpages/integrations/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { openComposeProps } from '../context';
import { EmailDimensions } from '..';

const initialValues = {
  sendTo: [] as Recipient[],
  subject: '',
  body: '',
  textBody: ''
};

export const useCompose = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setOffset } = useUploadResourcePanel();
  const { openDialog, closeDialog } = useDialog();

  const [loading, setLoading] = useState(false);
  const { mutateAsync: sendMail, isLoading } = useMutation(sendEMailMutation());
  const [values, setValues] = useState(initialValues);
  const [openComposeEmail, setOpenComposeEmail] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentDrawerContent, setCurrentDrawerContent] =
    useState<DrawerContent | null>(null);
  const [openLinkId, setOpenLinkId] = useState('');
  const [editorNode, setEditorNode] = useState(null);
  const [importedLinks, setImportedLinks] = useState<Link[]>([]);
  const [isExpanded, setIsExpanded] = useState(true);

  const { data: signature, isFetching: isSignatureFetching } =
    useQuery(userSignatureQuery());
  const { nylasToken } = useAppContext();
  const { connect } = useEmailServer();
  const { getStatus: getNylasStatus } = useNylas(nylasToken);
  const flags = useFlags();

  const getLinkAction = useCallback(({ action, id }: GetLinkActionProps) => {
    setIsDrawerOpen(true);
    setCurrentDrawerContent(DrawerContent.reviewLinks);

    setOpenLinkId(action === LinkActions.EDIT ? id : null);
  }, []);

  const { handleLinks, handleSnippet, setLinks, links, initLinkOptions } =
    useEditorLinksHook({
      editorRef: editorNode,
      importedLinks,
      getLinkAction
    });

  const removeLink = (deleteLink: Link) => {
    handleLinks(LinkActions.REMOVE, { link: deleteLink });
  };

  const clearValues = () => setValues(initialValues);

  const closeComposeEmail = () => {
    setLinks([]);
    clearValues();
    setImportedLinks([]);
    setEditorNode(null);
    setOpenComposeEmail(false);
    setCurrentDrawerContent(null);
  };

  const open = useCallback(({ linksData, drawerContent }: openComposeProps) => {
    setOpenComposeEmail(true);
    setIsExpanded(true);

    if (linksData) setImportedLinks([linksData]);
    if (drawerContent) setCurrentDrawerContent(drawerContent);
  }, []);

  const close = () => {
    setOpenComposeEmail(false);
    setCurrentDrawerContent(null);
  };

  const insertLink = useCallback(
    (newLink: Link, oldLink?: Link) => {
      if (!oldLink) handleLinks(LinkActions.INSERT, { link: newLink });
      else handleLinks(LinkActions.UPDATE, { link: newLink, oldLink });
    },
    [handleLinks]
  );

  const toggleDrawer = (content?: DrawerContent | null) => {
    setCurrentDrawerContent(content || null);
    setIsDrawerOpen(!!content);
  };

  // API FUNCTIONS

  const addContactToRecipients = (contact: TContact) =>
    setValues({
      ...values,
      sendTo: [
        ...values.sendTo,
        {
          Email: contact.Email,
          CRMWhatId: contact.CRMWhatId || '',
          CRMWhoId: contact.CRMWhoId || ''
        }
      ]
    });

  const removeContactFromRecipients = (contact: TContact) =>
    setValues({
      ...values,
      sendTo: values.sendTo.filter(
        (recipient: any) => recipient.Email !== contact.Email
      )
    });

  const modifyRecipientList = (contact: TContact) => {
    const isContactInLRecipientList: boolean = values.sendTo.some(
      (recipient: any) => recipient.Email === contact.Email
    );

    if (isContactInLRecipientList) return removeContactFromRecipients(contact);
    addContactToRecipients(contact);
  };

  const onSubmit = async () => {
    if (cleanHtml(values.body)?.length > CampaignFormLimit.Body) return;

    if (nylasToken && flags?.integrations) {
      const nylasResponse = await getNylasStatus();

      if (nylasResponse.message || nylasResponse.sync_state === 'invalid')
        return openDialog(
          <NylasDialog
            customReconnect={() =>
              openDialog(
                <DiscardEmailConfirmDialog
                  confirmText="Discard & Reconnect"
                  onConfirm={() => {
                    closeDialog();
                    closeComposeEmail();
                    isDrawerOpen && toggleDrawer();
                    connect();
                  }}
                />
              )
            }
          />
        );
    }

    const { body, textBody, sendTo, subject } = values;

    const parsedBody = {
      body: {
        Subject: subject,
        HtmlBody: parseHtmlFEtoBC(body),
        Recipients: sendTo,
        PlainBody: textBody,
        Links: links.map(link => ({
          Objects: link.ParentID
            ? [
                {
                  ObjectId: link.ParentID,
                  ObjectType: LinkObjectType.Presentation
                }
              ]
            : link.Item.Items.map(item => ({
                ObjectId: item.ID,
                ObjectType: item.ObjectType
              })),
          LinkName: link.Name,
          Placeholder: link.Placeholder
        }))
      }
    };

    const { Data } = await sendMail(parsedBody);

    setLoading(false);

    if (Data) closeComposeEmail();

    enqueueSnackbar(
      Data
        ? 'Email is added to a Queue and It will be scheduled to send soon'
        : 'Something went wrong, please try again later',
      {
        variant: Data ? 'success' : 'error'
      }
    );
  };

  // CHIP FUNCTIONS

  const addListOfContactsToRecipients = (contacts: TContact[]) => {
    const filteredRecipients: Recipient[] = [];

    contacts.forEach(contact => {
      const isDuplicate: boolean = values.sendTo.some(
        c => contact.Email.toLowerCase() === c.Email.toLowerCase()
      );

      if (!isDuplicate) filteredRecipients.push({ Email: contact.Email });
    });

    setValues({
      ...values,
      sendTo: [...values.sendTo, ...filteredRecipients]
    });
  };

  const removeListOfContactsFromRecipients = (contacts: TContact[]) => {
    const filteredRecipients: Recipient[] = values.sendTo.filter(
      recipient =>
        !contacts.some(
          contact =>
            contact.Email.toLowerCase() === recipient.Email.toLowerCase()
        )
    );

    setValues({
      ...values,
      sendTo: filteredRecipients
    });
  };

  const modifyRecipientListWithListOfContacts = (
    contacts: TContact[],
    add: boolean
  ) => {
    if (add) return addListOfContactsToRecipients(contacts);
    removeListOfContactsFromRecipients(contacts);
  };

  const handleSnipset = (value: string) => {
    handleSnippet(value);
  };

  useEffect(() => {
    if (!signature || !editorNode) return;

    editorNode?.setProgressState(isSignatureFetching);
    const hasSignature = cleanHtml(signature).length > 0;

    setValues((prev: any) => ({
      ...prev,
      body: `${prev.body}${hasSignature ? addFreeSpaceToEmailContent(signature) : ''}`
    }));
  }, [editorNode, isSignatureFetching, setValues, signature]);

  useEffect(() => {
    if (openComposeEmail) {
      let offset = 40;
      if (!isExpanded) offset += EmailDimensions.MAX_WIDTH_MINIMIZED;
      else offset += EmailDimensions.MAX_WIDTH_EXPANDED + 20;

      if (isDrawerOpen) offset += DRAWER_WIDTH;

      setOffset(offset);
    } else setOffset(20);
  }, [isDrawerOpen, openComposeEmail, isExpanded, setOffset]);

  const onCloseCompose = () => {
    if (
      values.sendTo.length === 0 &&
      values.subject.trim().length === 0 &&
      values.body.replace(htmlTags, '').trim().length === 0
    ) {
      if (isDrawerOpen) toggleDrawer();
      return closeComposeEmail();
    }

    openDialog(
      <DiscardEmailConfirmDialog
        onConfirm={() => {
          closeDialog();
          closeComposeEmail();
          isDrawerOpen && toggleDrawer();
        }}
      />
    );
  };

  const minmizedElements = [
    {
      key: '1',
      icon: <ExpandLessRoundedIcon htmlColor="white" />,
      onClick: () => setIsExpanded(true)
    }
  ];

  const headerElements = [
    {
      key: '1',
      icon: <RemoveRoundedIcon />,
      onClick: () => {
        setIsExpanded(false);
        setIsDrawerOpen(false);
      }
    },
    {
      key: '2',
      icon: <ClearIcon />,
      onClick: () => onCloseCompose()
    }
  ];

  const title = useMemo(
    () => values?.subject || 'Write New Email',
    [values?.subject]
  );

  const rightMargin = useMemo(() => {
    if (isDrawerOpen) return DRAWER_WIDTH + 20;

    return isExpanded ? 40 : 20;
  }, [isExpanded, isDrawerOpen]);

  const link = useMemo(
    () =>
      openLinkId
        ? links.find(addedLink => addedLink?.Placeholder === openLinkId)
        : null,
    [openLinkId, links]
  );

  return {
    link,
    links,
    title,
    values,
    isLoading: isLoading || loading,
    isExpanded,
    isDrawerOpen,
    rightMargin,
    initLinkOptions,
    openComposeEmail,
    minmizedElements,
    headerElements,
    currentDrawerContent,

    open,
    close,
    onSubmit,
    setValues,
    insertLink,
    removeLink,
    toggleDrawer,
    setOpenLinkId,
    setEditorNode,
    handleSnipset,
    setIsExpanded,
    closeComposeEmail,
    modifyRecipientList,
    setCurrentDrawerContent,
    modifyRecipientListWithListOfContacts
  };
};
