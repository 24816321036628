// ** MUI Imports
import { Theme } from '@material-ui/core';

const tooltip = (_theme: Theme) => ({
  MuiTooltip: {
    tooltip: {
      backgroundColor: '#000E19',
      fontSize: 12,
      borderRadius: 7,
      '& .MuiTooltip-arrow': {
        color: '#000E19'
      }
    }
  }
});

export default tooltip;
