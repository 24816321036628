import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SETVIImage } from '@setvi/shared/components';

const useStyles = makeStyles(() => ({
  welcomeImage: {
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  }
}));

export const Graphic = () => {
  const classes = useStyles();

  return (
    <Box height="100vh" alignItems="center" display="flex">
      <SETVIImage
        src="/images/middleby-welcome.svg"
        alt="Logo"
        className={classes.welcomeImage}
      />
    </Box>
  );
};
