import { useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getContactsQuery } from '@setvi/shared/services';
import { Contact } from '@setvi/shared/services/react-query/query/contacts/types';
import { SortableContactProperties } from '../../components/contact/contact-filter';

const CONTACTS_LIMIT = 20;
export const useContacts = () => {
  const [sortBy, setSortBy] = useState<SortableContactProperties>('FirstName');
  const [searchString, setSearchString] = useState<string | undefined>(
    undefined
  );

  const queryVariables = useMemo(
    () => ({
      pageSize: CONTACTS_LIMIT,
      sortBy,
      orderBy: 'ASC',
      search: searchString,
      pageNumber: 1
    }),
    [searchString, sortBy]
  );

  const {
    data: contacts,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(getContactsQuery(queryVariables));

  const fetchMoreContacts = useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return {
    contacts: (contacts?.pages?.map(page => page.Result).flat(1) ||
      []) as Contact[],
    isLoading: isLoading || isFetching,
    fetchMoreContacts,
    setSearchString,
    sortBy,
    setSortBy
  };
};
