import { Box, Divider } from '@material-ui/core';
import SText from '../../../sui/stext';
import { HomeButton } from '../home-button';

interface BackProps {
  handleBack?: () => void;
  text?: string;
  customTitle?: JSX.Element;
  customBackButton?: JSX.Element;
  disabled?: boolean;
  customAction?: JSX.Element;
}

export const Subheader = ({
  handleBack,
  text,
  customTitle,
  disabled = false,
  customBackButton,
  customAction
}: BackProps) => (
  <>
    <Box p={2} display="flex" alignItems="center">
      {customBackButton ||
        (handleBack && <HomeButton onClick={handleBack} disabled={disabled} />)}
      <Box width="100%" ml={2}>
        {customTitle || <SText weight="bold" title={text} />}
      </Box>
      {customAction}
    </Box>
    <Divider />
  </>
);
