import { CSSProperties, memo } from 'react';
import { Box, makeStyles } from '@material-ui/core';

import SETVIImage from '../setvi-image';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 350,
    width: 'fit-content',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  title: {
    fontWeight: 600,
    fontSize: '1.25rem',
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    color: theme.palette.grey[500]
  }
}));

export interface ISEmptyState {
  svg?: JSX.Element;
  imageSrc?: string;
  imageAlt?: string;
  title?: string;
  subTitle?: string;
  width?: number;
  height?: number;
  titleStyle?: CSSProperties;
  additionalContent?: JSX.Element;
}

const SEmptyState = ({
  svg,
  title,
  width,
  height,
  imageSrc,
  imageAlt = 'Empty List',
  subTitle,
  titleStyle,
  additionalContent
}: ISEmptyState) => {
  const styles = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%">
      <Box className={styles.container}>
        {svg || (
          <SETVIImage
            width={width}
            height={height}
            src={imageSrc}
            alt={imageAlt}
          />
        )}
        {title && (
          <Box className={styles.title} style={titleStyle}>
            {title}
          </Box>
        )}
        {subTitle && <Box className={styles.subtitle}>{subTitle}</Box>}
        {additionalContent}
      </Box>
    </Box>
  );
};

export default memo(SEmptyState);
