import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  innerWrap: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  panelImage: {
    width: 70,
    height: 55,
    borderRadius: 6,
    objectFit: 'cover'
  },
  imageContainer: {
    position: 'relative',
    cursor: 'pointer',
    display: 'flex'
  },
  resourceIcon: {
    marginRight: spacing(1)
  }
}));
