import { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface SRouteGuardProps {
  children?: ReactNode;
  routeEnabled: boolean;
  redirectRoute?: string;
}

const SRouteGuard = ({
  children = <Outlet />,
  routeEnabled = true,
  redirectRoute = '/'
}: SRouteGuardProps) => {
  if (!routeEnabled) return <Navigate to={redirectRoute} />;

  return <>{children}</>;
};

export default SRouteGuard;
