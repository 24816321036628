import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const ResultsLoader = () => (
  <Box paddingX={4}>
    <Box display="flex">
      <Skeleton variant="text" width={65} height={20} />
      <Box paddingLeft={2}>
        <Skeleton variant="text" width={130} height={20} />
      </Box>
    </Box>
    {[1, 2, 3, 4, 5, 6].map(e => (
      <Box paddingY={2} key={e}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Skeleton variant="rect" width="80%" height={40} />
          </Grid>
          <Grid item xs={9}>
            <Box display="flex" flexDirection="column">
              <Skeleton variant="text" width="80%" height={20} />
              <Skeleton variant="text" width="30%" height={20} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    ))}
  </Box>
);

export default ResultsLoader;
