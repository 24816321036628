import { SConfirmDialog } from '@setvi/shared/components';
import { useDialog } from '@setvi/shared/providers';

interface DiscardEmailConfirmDialogProps {
  onConfirm: () => void;
  confirmText?: string;
}

export const DiscardEmailConfirmDialog = ({
  onConfirm,
  confirmText
}: DiscardEmailConfirmDialogProps) => {
  const { closeDialog } = useDialog();
  return (
    <SConfirmDialog
      type="delete"
      closeText="Keep it"
      title="Discard your email"
      confirmText={confirmText || 'Discard & Close'}
      additionalMessage="The action CANNOT be reverted back"
      description="Are you sure you want to discard your entered data in this email?"
      onClose={closeDialog}
      onConfirm={onConfirm}
    />
  );
};
