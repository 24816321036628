import { makeStyles } from '@material-ui/core';

export const useGalleryStyles = makeStyles(() => ({
  mainImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    cursor: 'pointer'
  },
  mainImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export const useBackdropStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 5000,
    backgroundColor: 'rgba(0, 0, 0, 0.9)'
  },
  closeContainer: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  galleryContainer: {
    width: '50vw',
    maxWidth: 800,
    minWidth: 300
  }
}));

export const useSGalleryThumbnailStyles = makeStyles(theme => ({
  container: {
    width: 50,
    borderRadius: theme.spacing(1),
    margin: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[300]}`,
    cursor: 'pointer',
    overflow: 'hidden'
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`
  }
}));
