type Listener = (...args: any[]) => void;

class EventEmitter {
  private static instance: EventEmitter;

  private events: { [key: string]: Listener[] } = {};

  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  public static getInstance(): EventEmitter {
    if (!EventEmitter.instance) {
      EventEmitter.instance = new EventEmitter();
    }
    return EventEmitter.instance;
  }

  public on(event: string, listener: Listener): void {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(listener);
  }

  public off(event: string, listener: Listener): void {
    if (!this.events[event]) return;

    this.events[event] = this.events[event].filter(
      registeredListener => registeredListener !== listener
    );
  }

  public emit(event: string, ...args: any[]): void {
    if (!this.events[event]) return;

    this.events[event].forEach(listener => listener(...args));
  }

  public once(event: string, listener: Listener): void {
    const wrapper = (...args: any[]) => {
      listener(...args);
      this.off(event, wrapper);
    };
    this.on(event, wrapper);
  }
}

export default EventEmitter.getInstance();
