import { useCallback, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Category } from '../../../../interfaces';
import {
  getUserCategoryQuery,
  getUserResourcesQuery
} from '../../../../services';
import { RESOURCE_PAGINATION, SortType } from '../../../../enums';
import { resourcesSortAndOrder } from '../company-resources/useCompanyResources';

export const useMyResources = () => {
  const [categoryId, setCategoryId] = useState<Category['ID'] | undefined>(
    undefined
  );

  const {
    data: categories,
    isLoading: isCategoryLoading,
    isFetching: isCategoryFetching,
    fetchNextPage: fetchNextCategoryPage,
    hasNextPage: hasNextCategoryPage
  } = useInfiniteQuery(
    getUserCategoryQuery({
      categoryId,
      pageNumber: 1,
      pageSize: RESOURCE_PAGINATION.LIMIT,
      sortBy: 'Order',
      orderBy: SortType.ASC
    })
  );

  const fetchMoreCategories = useCallback(() => {
    if (hasNextCategoryPage) fetchNextCategoryPage();
  }, [hasNextCategoryPage, fetchNextCategoryPage]);

  const categoryLoading = isCategoryLoading || isCategoryFetching;
  const allCategories =
    categories?.pages?.map(page => page?.Data?.Result).flat() || [];

  const allCategoriesLoaded =
    !categoryLoading &&
    allCategories.length === categories?.pages[0]?.Data?.Count;

  const {
    data: resources,
    isLoading: isResourceLoading,
    isFetching: isResourceFetching,
    fetchNextPage: fetchResourceNextPage,
    hasNextPage: hasResourceNextPage
  } = useInfiniteQuery({
    ...getUserResourcesQuery({
      categoryId,
      pageNumber: 1,
      pageSize: RESOURCE_PAGINATION.LIMIT,
      ...resourcesSortAndOrder
    })
  });

  const fetchMoreResources = useCallback(() => {
    if (hasResourceNextPage) fetchResourceNextPage();
  }, [hasResourceNextPage, fetchResourceNextPage]);

  const resourceLoading = isResourceLoading || isResourceFetching;
  const allResources =
    resources?.pages?.map(page => page?.Data?.Result).flat() || [];

  const isLoading = categoryLoading || resourceLoading;
  const showResources = allCategoriesLoaded && allResources.length > 0;

  return {
    categories: allCategories,
    resources: allResources,
    isLoading,
    categoryId,
    setCategoryId,
    showResources,
    fetchMoreCategories,
    fetchMoreResources
  };
};
