import { Box, Divider, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SText from '../../../sui/stext';

interface HeaderProps {
  title: string;
  disableClose?: boolean;
  handleClose?: () => void;
}

export const Header = ({
  title,
  disableClose = false,
  handleClose
}: HeaderProps) => (
  <>
    <Box
      p={3}
      display="flex"
      alignItems="center"
      justifyContent="space-between">
      <SText weight="bold" title={title} />
      {handleClose && (
        <IconButton size="small" onClick={handleClose} disabled={disableClose}>
          <Close />
        </IconButton>
      )}
    </Box>
    <Divider />
  </>
);
