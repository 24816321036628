import { makeStyles } from '@material-ui/core';

export const useCheckBoxStyle = makeStyles(() => ({
  subText: {
    color: '#989FB0',
    fontSize: '14px',
    margin: '8px 0px'
  },
  required: {
    color: '#d72638'
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,

    '& svg': {
      transform: 'scale(0.8)',

      '& path': {
        fill: '#B5B5BE'
      }
    }
  }
}));
