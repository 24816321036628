import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosMethods, TemplatesApi } from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';
import { GetTemplatesQuery } from './types';

export enum TemplateQueryKey {
  TEMPLATES = 'templates',
  TEMPLATE = 'template'
}

export const getTemplatesQuery = (): UseQueryOptions<GetTemplatesQuery> => ({
  queryKey: [TemplateQueryKey.TEMPLATES],
  queryFn: () =>
    axiosHelper({
      endpoint: TemplatesApi.Templates,
      method: AxiosMethods.POST
    })
});

export const getTemplateQuery = (params: any) => ({
  queryKey: [TemplateQueryKey.TEMPLATE],
  queryFn: () =>
    axiosHelper({
      endpoint: TemplatesApi.Template,
      method: AxiosMethods.POST,
      params
    }),
  select: ({ Data }: any) => {
    const data = Data?.map((template: any) => ({
      ...template,
      ThumbURL: template.Items[0].ThumbURLWithSas,
      Items: template.Items.map((item: any) => ({
        ...item,
        ThumbURL: item.ThumbURLWithSas
      }))
    }));

    return data?.[0] || [];
  }
});
