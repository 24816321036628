import { AxiosMethods, ResourceApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import { UseMutationOptions } from '@tanstack/react-query';

export enum ResourcesMutationsKey {
  CREATE_RESOURCE = 'create_resource',
  ADD_FAVORITE_RESOURCES = 'add_favorite_resources',
  CREATE_WEB_LINK_RESOURCE = 'create_web_link_resource',
  REMOVE_FAVORITE_RESOURCES = 'remove_favorite_resources'
}

export const createResourceMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: ResourceApi.CreateResource,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export const addFavoriteResourceMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: ResourceApi.AddToFavorites,
      method: AxiosMethods.POST,
      params: {
        ...params,
        type: 'resource'
      },
      body
    })
});

export const removeFavoriteResourceMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: ResourceApi.RemoveFromFavorites,
      method: AxiosMethods.POST,
      params: {
        ...params,
        type: 'resource'
      },
      body
    })
});

export const createWebLinkResourceMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: ResourceApi.CreateWebLinkResource,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export const deleteUserResourceMutation = (): UseMutationOptions => ({
  mutationFn: body =>
    axiosHelper({
      endpoint: ResourceApi.DeleteUserResource,
      method: AxiosMethods.POST,
      body
    })
});
