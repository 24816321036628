import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import { ActiveBreadcrumb, Breadcrumb, useBreadcrumbsStyles } from './styles';

interface BreadcrumbLink {
  id: number;
  name: string;
  link: string;
  current: boolean;
}

export interface BreadcrumbProps {
  BreadcrumbIcon: FC;
  breadcrumbs: Array<BreadcrumbLink>;
}

export const Breadcrumbs = ({
  BreadcrumbIcon,
  breadcrumbs
}: BreadcrumbProps) => {
  const navigate = useNavigate();
  const classes = useBreadcrumbsStyles();

  return (
    <>
      {BreadcrumbIcon && (
        <Box className={classes.iconWrapper}>
          <BreadcrumbIcon />
        </Box>
      )}
      <MuiBreadcrumbs aria-label="breadcrumb" className={classes.container}>
        {breadcrumbs.map(breadcrumb =>
          breadcrumb.current ? (
            <ActiveBreadcrumb key={breadcrumb.id}>
              {breadcrumb.name}
            </ActiveBreadcrumb>
          ) : (
            <Breadcrumb
              key={breadcrumb.id}
              onClick={() => navigate(breadcrumb.link)}>
              {breadcrumb.name}
            </Breadcrumb>
          )
        )}
      </MuiBreadcrumbs>
    </>
  );
};
