export interface VetsuiteOverview {
  _id: string;
  accountName: string;
  communicationsSystem: boolean;
  compoundingCommitment: number;
  compoundingQtd: number;
  compoundingYtd: number;
  distributionCommitment: number;
  distributionQtd: number;
  distributionYtd: number;
  enrolmentLevel: string;
  fte: number;
  fullyConfigured: boolean;
  managerEmail: string;
  managerName: string;
  paymentsSystem: boolean;
  pims: string;
  rebateAmmount: number;
  vetsuiteEnrollmentEndDate: string;
  vetsuiteEnrollmentStartDate: string;
  commitmentLevel: number;
  enrolmentType: string;
  fixedRebateDVM: number;
  updatedAt: Date;
}

export interface PerformanceOverview {
  date: string;
  distribution: number;
  compound: number;
  commitment: number;
}

export interface VetsuiteTransactions {
  items: Transaction[];
  pagination: Pagination[];
}

export interface Transaction {
  _id: string;
  covetrusId: string;
  date: string;
  purchaseType: PurchaseType;
  sales: number;
  qualifiedSales: number;
}

export enum PurchaseType {
  Compounding = 'Compounding',
  Distribution = 'Distribution'
}

export interface Pagination {
  totalDocs: number;
  page: number;
  totalPages: number;
}

export interface GetCovetrusUser {
  contact: Contact;
  vetsuite: VetsuiteOverview[];
}

export interface Contact {
  _id: string;
  name: string;
  email: string;
  phone: string;
  accountId: string;
  title: string;
  salutation: string;
  custom: Custom;
  lastModifiedDate: string;
}

export interface Custom {
  cvetContactId: string;
}

export interface GetVetsuiteToken {
  access_token: string;
  id_token: string;
  scope: string;
  expires_in: number;
  token_type: string;
}
