import { AxiosMethods, AdminCategoryApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import {
  QueryFunctionContext,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';
import {
  AdminCategory,
  AdminChildrenCategoriesQuery,
  CategoryChildrenQueryParams
} from 'Services/Query/Admin/Category/Types';

export enum AdminCategoryQueryKey {
  ADMIN_CATEGORIES = 'ADMIN_CATEGORIES',
  ADMIN_CATEGORY_CHILDREN = 'ADMIN_CATEGORY_CHILDREN',
  ADMIN_GROUP_CATEGORIES = 'ADMIN_GROUP_CATEGORIES',
  ADMIN_CATEGORY_DETAILS = 'ADMIN_CATEGORY_DETAILS'
}

export const getAdminCategoriesQuery = (
  classId: number,
  categoryId?: number
) => ({
  queryKey: [AdminCategoryQueryKey.ADMIN_CATEGORIES, categoryId],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminCategoryApi.GetCategories.replace(
        ':classId',
        classId?.toString()
      ).replace(':id', (categoryId || 0)?.toString()),
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (res: { Data: { Items: AdminCategory[] } }) => res?.Data?.Items,
  // const nestedArray = res?.data?.items;
  // nestedArray.sort((a, b) => a.order - b.order);

  // // Sorting the children arrays based on their "order" property
  // nestedArray.forEach(item => {
  //   if (item.children) {
  //     item.children.sort((childA, childB) => childA.order - childB.order);
  //   }
  // });

  // return {
  //   items: nestedArray
  // };,
  refetchOnWindowFocus: false,
  refetchOnMount: false
});

export const getAdminCategoryDetailsQuery = (Id: string | number) => ({
  queryKey: [AdminCategoryQueryKey.ADMIN_CATEGORY_DETAILS, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminCategoryApi.GetCategoryDetails.replace(
        ':id',
        Id.toString()
      ),
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (res: any) => res?.Data,
  cacheTime: 0,
  refetchOnWindowFocus: false
});

export const getAdminGroupCategoriesQuery = (id: string | number) => ({
  queryKey: [AdminCategoryQueryKey.ADMIN_GROUP_CATEGORIES, id],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminCategoryApi.GetGroupCategories.replace(
        ':id',
        id.toString()
      ),
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (res: any) => res?.Data,
  refetchOnWindowFocus: false
});

export const getCategoyChildrenQuery = (
  params: CategoryChildrenQueryParams
): UseInfiniteQueryOptions<AdminChildrenCategoriesQuery> => ({
  queryKey: [AdminCategoryQueryKey.ADMIN_CATEGORY_CHILDREN, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: `${AdminCategoryApi.GetCategoryChildren.replace(
        ':id',
        params.categoryId?.toString()
      )}`,
      method: AxiosMethods.GET,
      params: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      },
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  getNextPageParam: (lastPage: AdminChildrenCategoriesQuery) => {
    const total = lastPage?.Data?.TotalChildrenCategoriesCount;
    const currPage = lastPage?.Data?.PageNumber;
    const currSize = lastPage?.Data?.PageSize;

    return currPage * currSize >= total ? undefined : currPage + 1;
  },
  refetchOnWindowFocus: false,
  cacheTime: 0
});
