import { Box } from '@material-ui/core';
import { Form, Formik } from 'formik';
import SButton from '../../sbutton';
import DialogIcon from './dialog-main-icon';
import { SFormikTextfield, SText } from '../..';

import {
  ContentProps,
  DefaultValues,
  TypeColor,
  Types,
  useStyles
} from './helper';

const SConfirmDialog = ({
  type = Types.infoBlue,
  title,
  width = 'xs',
  loading,
  maxWidth = DefaultValues.maxWidth,
  closeText = 'Close',
  description,
  confirmText = 'Confirm',
  additionalMessage,
  disableConfirmButton = false,
  customAdditionalMessage,
  confirmation = false,
  onClose,
  onConfirm
}: ContentProps) => {
  const classes = useStyles({ width, maxWidth });
  const backgroundColor = TypeColor[type];

  return (
    <Formik
      initialValues={{ confirmField: '' }}
      validateOnMount
      validate={({ confirmField }) => {
        if (disableConfirmButton) return { confirmField: '' };
        if (confirmation && confirmField !== confirmText.toUpperCase())
          return { confirmField: '' };
        return {};
      }}
      onSubmit={onConfirm}>
      {({ isValid }) => (
        <Form className={classes.contentWrapper}>
          <Box className={classes.dialogHeader}>
            <DialogIcon type={type} />
          </Box>
          <SText className={classes.title} noLineClamp>
            {title}
          </SText>
          <Box>
            {description && (
              <SText className={classes.description} noLineClamp>
                {description}
              </SText>
            )}
            <Box className={classes.additionalMessage}>
              {additionalMessage && !customAdditionalMessage && (
                <SText>{additionalMessage}</SText>
              )}
              {customAdditionalMessage && customAdditionalMessage}
            </Box>
            {confirmation && (
              <Box>
                <SText fontColor="#15192080" gutterBottom>
                  Type "{confirmText.toUpperCase()}" to confirm the action
                </SText>
                <SFormikTextfield
                  name="confirmField"
                  placeholder={confirmText.toUpperCase()}
                />
              </Box>
            )}
            <Box component="div" className={classes.btnsWrapper}>
              {onConfirm && (
                <SButton
                  type="submit"
                  fullWidth
                  disabled={!isValid}
                  style={{ backgroundColor }}
                  variant="contained"
                  loading={loading}>
                  {confirmText}
                </SButton>
              )}
              <SButton
                variant={onConfirm ? 'outlined' : 'contained'}
                color={onConfirm ? 'inherit' : 'primary'}
                fullWidth
                onClick={onClose}
                style={{
                  backgroundColor: !onConfirm ? backgroundColor : 'inherit',
                  marginRight: !onConfirm ? 0 : 'inherit'
                }}>
                {closeText}
              </SButton>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SConfirmDialog;
