import { useEffect, useState } from 'react';
import { SortType } from '../../../enums';
import { SStaticPaginatedProps } from '../static-paginated';

interface sortInterface {
  sortBy: string;
  orderBy: SortType;
}

type StaticPaginatedHookProps = Pick<SStaticPaginatedProps, 'data' | 'input'>;

export const useStaticPaginatedData = ({
  data,
  input
}: StaticPaginatedHookProps) => {
  const [initSearch, setInitSearch] = useState('');
  const [items, setItems] = useState({
    items: [],
    totalCount: data?.totalCount || 0
  });
  const [sortedColumn, setSortedColumn] = useState<sortInterface>({
    sortBy: '',
    orderBy: SortType.ASC
  });

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const slicedItems = data?.items?.slice(
      (page - 1) * rowsPerPage,
      page * rowsPerPage || []
    );
    setItems({
      items: slicedItems,
      totalCount: data?.totalCount || 0
    });
    if (slicedItems.length === 0) setPage(1);
  }, [page, rowsPerPage, data]);

  useEffect(() => {
    if (initSearch !== input?.searchString) {
      setItems((prev: any) => ({
        ...prev,
        items: data?.items?.filter(
          (elem: any) =>
            elem?.Name?.toLowerCase().includes(
              input?.searchString?.toLowerCase()
            ) ||
            elem?.Description?.toLowerCase().includes(
              input?.searchString?.toLowerCase()
            )
        )
      }));
      setInitSearch(input?.searchString);
      setPage(1);
    }
  }, [data, input, initSearch]);

  const sortItems = (property: any, sortType: any) => {
    let sortOrder = 1;
    if (sortType === SortType.DESC) sortOrder = -1;

    return (a: any, b: any) => {
      const firstValue =
        typeof a[property] === 'string'
          ? a[property].toLowerCase()
          : a[property];
      const secondValue =
        typeof b[property] === 'string'
          ? b[property].toLowerCase()
          : b[property];

      const result =
        // eslint-disable-next-line
        firstValue < secondValue ? -1 : firstValue > secondValue ? 1 : 0;
      return result * sortOrder;
    };
  };

  const onSortItems = (columnForSort: string) => {
    const currentSortType =
      sortedColumn?.orderBy === SortType.ASC ? SortType.DESC : SortType.ASC;

    const newSortType =
      columnForSort === sortedColumn.sortBy ? currentSortType : SortType.ASC;

    setItems((prev: any) => ({
      ...prev,
      items: prev?.items?.sort(sortItems(columnForSort, newSortType))
    }));

    setSortedColumn({
      sortBy: columnForSort,
      orderBy: newSortType
    });
  };

  const changePageLimit = (limit: string) => {
    setRowsPerPage(parseInt(limit, 10));
    setPage(1);
  };

  const handlePageChange = (newPage: number) => setPage(newPage);

  return {
    items,
    sortedColumn,
    onSortItems,
    page,
    rowsPerPage,
    changePageLimit,
    handlePageChange
  };
};
