import { Header } from '@setvi/shared/components/sdrawer-content/components/header';
import { DrawerContent } from '@setvi/shared/enums/common';
import { SSearchInput } from '@setvi/shared/components/sforms/ssearch';
import { TContact } from '@setvi/shared/components/sdrawer-content/components/contact';
import {
  Box,
  Checkbox,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { SInfiniteScroll, SEmptyState } from '@setvi/shared/components';
import { Subheader } from '../../components/subheader';
import { useGroups } from './useGroups';
import { Group } from './group';
import { GroupsContacts } from './groups-contacts';
import { Recipient } from '../../../../interfaces';

interface GroupsProps {
  selectedContacts?: Recipient[];
  modifyRecipientList: (contact: TContact) => void;
  modifyRecipientListWithListOfContacts: (
    contacts: TContact[],
    add: boolean
  ) => void;
  setShowContent: (content: DrawerContent | null) => void;
  handleClose: () => void;
}

const useGroupsStyles = makeStyles(() => ({
  loader: {
    textAlign: 'center'
  }
}));

export const Groups = ({
  selectedContacts,
  modifyRecipientList,
  modifyRecipientListWithListOfContacts,
  setShowContent,
  handleClose
}: GroupsProps) => {
  const styles = useGroupsStyles();
  const {
    groups,
    isLoading,
    fetchMoreGroups,
    searchString,
    setSearchString,
    setSelectedGroup,
    selectedGroup
  } = useGroups();

  if (selectedGroup)
    return (
      <GroupsContacts
        selectedGroup={selectedGroup}
        modifyRecipientList={modifyRecipientList}
        handleBack={() => setSelectedGroup(null)}
        handleClose={handleClose}
        selectedContacts={selectedContacts}
      />
    );

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header title="Recipients" handleClose={handleClose} />
      <Subheader
        handleBack={() => setShowContent(DrawerContent.contactsNavigation)}
        text="Groups"
      />
      <Box p={2} mb={1}>
        <SSearchInput
          defaultValue={searchString}
          onChange={value => setSearchString(value)}
        />
      </Box>
      <Box overflow="auto">
        <SInfiniteScroll
          list={groups}
          keyGetter={group => group.Id}
          fetchMore={fetchMoreGroups}
          render={group => (
            <Group
              group={group}
              onGroupClick={() => {
                setSearchString('');
                setSelectedGroup(group);
              }}
              checkbox={
                <Tooltip
                  disableHoverListener={group.ContactCount > 0}
                  title="Group does not have any contacts"
                  arrow>
                  <span>
                    <Checkbox
                      checked={
                        group.Contacts.length > 0 &&
                        group.Contacts.every(contact =>
                          selectedContacts.some(
                            selected =>
                              selected.Email.toLowerCase() ===
                              contact.Email.toLowerCase()
                          )
                        )
                      }
                      onChange={e => {
                        modifyRecipientListWithListOfContacts(
                          group.Contacts,
                          e.target.checked
                        );
                      }}
                      color="primary"
                      disabled={group.ContactCount === 0}
                    />
                  </span>
                </Tooltip>
              }
            />
          )}>
          <>
            {isLoading && (
              <Typography className={styles.loader} variant="h6">
                Loading...
              </Typography>
            )}
            {!isLoading && groups?.length === 0 && (
              <SEmptyState
                imageSrc="/images/no-groups.png"
                subTitle="No Groups Available"
              />
            )}
          </>
        </SInfiniteScroll>
      </Box>
    </Box>
  );
};
