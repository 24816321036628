import { memo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { SETVIImage } from '@setvi/shared/components';
import { usePageExplainerStyles, useStepStyles } from './styles';

export interface StepProps {
  stepNumber: number | string;
  stepColor: string;
  title: string;
  description: string;
}

const Step = ({ stepNumber, stepColor, title, description }: StepProps) => {
  const styles = useStepStyles();
  return (
    <Box className={styles.container}>
      <Box className={styles.stepNumber} style={{ backgroundColor: stepColor }}>
        {stepNumber}
      </Box>
      <Typography className={styles.title}>{title}</Typography>
      <Typography className={styles.description}>{description}</Typography>
    </Box>
  );
};

interface SPageExplainerProps {
  label: string | JSX.Element;
  description: string | JSX.Element;
  action?: JSX.Element;
  imageSrc: string;
  imageAlt?: string;
  stepsHeader: string;
  steps: StepProps[];
}

const SPageExplainer = ({
  label,
  description,
  action,
  imageSrc,
  imageAlt,
  stepsHeader,
  steps
}: SPageExplainerProps) => {
  const styles = usePageExplainerStyles();

  return (
    <Box className={styles.container}>
      <Box className={styles.section}>
        {typeof label === 'string' ? (
          <Typography className={styles.label}>{label}</Typography>
        ) : (
          label
        )}
        <Box className={styles.description}>{description}</Box>
        {action && <Box mb={4}>{action}</Box>}
        <Box className={styles.imageContainer}>
          <SETVIImage
            className={styles.image}
            src={imageSrc}
            alt={imageAlt || 'illustration'}
          />
        </Box>
      </Box>
      <Box className={styles.section} ml={5}>
        <Typography className={styles.listLabel}>{stepsHeader}</Typography>
        {steps.map(s => (
          <Step
            key={s.title}
            stepNumber={s.stepNumber}
            stepColor={s.stepColor}
            title={s.title}
            description={s.description}
          />
        ))}
      </Box>
    </Box>
  );
};

export default memo(SPageExplainer);
