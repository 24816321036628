import { useState } from 'react';
import { SBase, SResourceChildrenList } from '@setvi/shared/components';
import { Resource, ChildResource } from '@setvi/shared/interfaces';
import { GetResourceTypeName } from '../../../../utils';

interface SelectPagesProps {
  resource: Resource;
  onClose: () => void;
  onConfirm: (selectedPages: ChildResource[]) => void;
}

export const SelectPages = ({
  resource,
  onClose,
  onConfirm
}: SelectPagesProps) => {
  const [selected, setSelected] = useState<ChildResource[]>([]);

  const handleSelect = (
    isChecked: boolean,
    resourceChildren: ChildResource[]
  ) => {
    if (isChecked) {
      setSelected([...selected, ...resourceChildren]);
    } else {
      setSelected(
        selected.filter(
          res => !resourceChildren.find(r => r.ItemID === res.ItemID)
        )
      );
    }
  };

  return (
    <SBase
      width="65vw"
      height="90vh"
      header={{
        title: `Select ${GetResourceTypeName(resource.ResourceTypeID)} Pages`,
        description: `You can select the pages from ${GetResourceTypeName(
          resource.ResourceTypeID
        )} to include from thumbnails below`,
        onClose
      }}
      footer={{
        onConfirm: () => {
          onConfirm(selected);
        },
        onClose,
        confirmLabel: `Add Selected Pages (${selected.length})`,
        disableConfirm: selected.length === 0
      }}>
      <SResourceChildrenList
        resource={resource}
        handleSelect={handleSelect}
        selected={selected}
      />
    </SBase>
  );
};
