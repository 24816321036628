import { Divider } from '@material-ui/core';

import { SContentLayoutProps } from '../../../interfaces';
import { Container, HeaderContainer } from './style';

interface SPreviewLayoutProps extends SContentLayoutProps {
  icon?: JSX.Element;
}

const SPreviewLayout = ({
  header,
  footer,
  icon,
  children
}: SPreviewLayoutProps) => (
  <Container>
    <HeaderContainer>
      {header}
      {icon}
    </HeaderContainer>
    <Divider />
    {children}
    {footer && <Divider />}
    {footer}
  </Container>
);

export default SPreviewLayout;
