import { makeStyles, Theme } from '@material-ui/core';

export const useAvatarClasses = makeStyles<Theme>(({ palette }) => ({
  root: {
    color: palette.common.white,
    fontWeight: 700
  },
  sm: {
    height: 28,
    width: 28,
    fontSize: 14
  },
  md: {
    height: 36,
    width: 36,
    fontSize: 16
  },
  lg: {
    height: 40,
    width: 40,
    fontSize: 17
  }
}));
