import { Typography, makeStyles } from '@material-ui/core';
import { SConfirmDialog } from '@setvi/shared/components';
import { useDialog } from '@setvi/shared/providers';
import { useEmailServer } from 'pages/settings/subpages/integrations/hooks';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none'
    }
  }
});

interface NylasDialogProps {
  customReconnect?: () => void;
}

const NylasDialog = ({ customReconnect }: NylasDialogProps) => {
  const { connect: reconnect } = useEmailServer();
  const { closeDialog } = useDialog();

  const classes = useStyles();

  const handleReconnect = () => {
    closeDialog();
    customReconnect ? customReconnect() : reconnect();
  };

  return (
    <SConfirmDialog
      type="warning"
      title="Sign in to email"
      description="Your SETVI email account has been disconnected. 
      To continue sending emails, you need to reconnect. Please note that by reconnecting, you will lose all your progress"
      customAdditionalMessage={
        <Typography>
          If you are facing issue, reach out to{' '}
          <a className={classes.link} href="mailto:support@setvi.com">
            support@setvi.com
          </a>
        </Typography>
      }
      onClose={closeDialog}
      onConfirm={handleReconnect}
      confirmText="Reconnect"
      closeText="Cancel"
      width="sm"
    />
  );
};

export default NylasDialog;
