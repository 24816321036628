import { Avatar, AvatarProps } from '@material-ui/core';
import clsx from 'clsx';
import { useAvatarClasses } from './styles';

const stc = require('string-to-color');

type Size = 'sm' | 'md' | 'lg';

export interface SAvatarProps extends AvatarProps {
  value: string;
  className?: string;
  height?: number;
  width?: number;
  size?: Size;
}

export const SAvatar = ({
  className,
  value,
  size = 'md',
  ...rest
}: SAvatarProps) => {
  const classes = useAvatarClasses();

  return (
    <Avatar
      style={{ backgroundColor: stc(value) }}
      className={clsx(classes.root, [classes[size]], className)}
      {...rest}>
      {value}
    </Avatar>
  );
};
