import { Template } from '../../../../../services/react-query/query/templates/types';
import { SCheckbox } from '../../../../scheckbox';

interface TemplateActionProps {
  template: Template;
  onSelectTemplate: (checked: boolean, template: Template[]) => void;
  checked?: boolean;
}

export const TemplateAction = ({
  template,
  onSelectTemplate,
  checked = false
}: TemplateActionProps) => {
  if (template.Items.length === 0)
    return (
      <SCheckbox
        disabled
        tooltip="This template has no resources and cannot be shared."
      />
    );

  if (template.Items.some(item => !item.IsShareable))
    return (
      <SCheckbox
        disabled
        tooltip="This template has at least one resource that is not shareable."
      />
    );

  return (
    <SCheckbox
      checked={checked}
      onChange={e => onSelectTemplate(e.target.checked, [template])}
    />
  );
};
